<template>
  <b-modal
    centered
    title="Voorbeeld"
    :visible="true"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    size="lg"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div v-for="question in questions" :key="question.name">
      <b-form-group
        v-show="question.enabled"
        :label="
          `${question.question} ${question.validation.required ? '*' : ''}`
        "
        label-for="question.name"
      >
        <component
          :is="inputComponent[question.type].component"
          v-bind="inputComponent[question.type].properties"
          name="question.name"
          disabled
        ></component>
      </b-form-group>
    </div>
    <b-form-group>
      <label for="four-weeks">
        <b-form-checkbox name="four-weeks" disabled plain />
        <small
          >Ik ga akkoord met de algemene voorwaarden. Mijn gegevens mogen
          gebruikt worden voor wervings-doeleinden.*</small
        >
      </label>
      <label for="twelve-weeks">
        <b-form-checkbox name="twelve-weeks" disabled plain />
        <small
          >Ja, ik heb eventueel ook interesse in andere passende
          carrieremogelijkheden, dus jullie mogen mijn gegevens 12 maanden
          bewaren.</small
        >
      </label>
    </b-form-group>
    <div slot="modal-footer">* verplichte velden</div>
  </b-modal>
</template>
<script>
import PreviewFormFileInput from './preview-form-file-input'
export default {
  components: {
    PreviewFormFileInput
  },
  props: {
    questions: {
      type: Array,
      required: true
    }
  },
  computed: {
    inputComponent () {
      return {
        text: {
          component: 'b-form-input',
          properties: {
            type: 'text'
          }
        },
        email: {
          component: 'b-form-input',
          properties: {
            properties: {
              type: 'email'
            }
          }
        },
        tel: {
          component: 'b-form-input',
          properties: {
            type: 'tel'
          }
        },
        textarea: {
          component: 'b-form-textarea',
          properties: {
            rows: 5
          }
        },
        file: {
          component: 'preview-form-file-input'
        },
        checkbox: {
          component: 'b-form-checkbox',
          properties: {
            checked: true
          }
        }
      }
    }
  }
}
</script>
