<template>
  <div>
    <b-input-group class="mr-2">
      <b-input-group-prepend class="d-lg-none">
        <div
          class="d-flex align-items-center justify-content-center"
          style="background-color: #fff; border: 1px solid #ced4da; border-right: none; min-width: 40px;"
        >
          <i class="fa fa-search" style="color: #bfbfbf" />
        </div>
      </b-input-group-prepend>
      <b-form-input
        ref="global-search-input"
        style="border-right: none;"
        v-model="userInput"
        @change="debouncedSearch()"
        @keyup="debouncedSearch()"
        placeholder="Zoek in de site"
      />
      <b-input-group-append>
        <b-btn
          class="px-2"
          style="background-color: #fff; border: 1px solid #ced4da; border-left: none;"
          @click="reset"
        >
          <i class="fa fa-times" style="color: #bfbfbf" />
        </b-btn>
      </b-input-group-append>
    </b-input-group>

    <b-list-group class="search-results w-100">
      <b-list-group-item v-if="loading">
        <div class="text-center">
          <i class="fa fa-spinner fa-spin" />
        </div>
      </b-list-group-item>
      <template v-else>
        <search-results-vacancies @extend-search="debouncedSearch($event)" />
        <search-results-prospects @extend-search="debouncedSearch($event)" />
        <search-results-attachments @extend-search="debouncedSearch($event)" />
      </template>
    </b-list-group>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import { LOAD_SEARCH_RESULTS, CLEAR_SEARCH_RESULTS, LOAD_EXTRA_SEARCH_RESULTS } from '../../store/keys-actions'
import SearchResultsVacancies from './search-results-vacancies'
import SearchResultsProspects from './search-results-prospects'
import SearchResultsAttachments from './search-results-attachments'

export default {
  components: {
    SearchResultsVacancies,
    SearchResultsProspects,
    SearchResultsAttachments
  },
  data () {
    return {
      userInput: '',
      loading: false,
      previousInput: ''
    }
  },
  watch: {
    userInput () {
      this.$emit('input', this.userInput)
    },
    value () {
      this.userInput = this.value
    }
  },
  methods: {
    reset () {
      this.userInput = ''
      this.previousInput = ''
      this.$store.dispatch(CLEAR_SEARCH_RESULTS)
      this.$refs['global-search-input'].focus()
    },
    debouncedSearch: debounce(function (args) {
      this.handleSearch(args)
    }, 700),
    handleSearch (searchType = '') {
      if (this.userInput.length < 2) return null
      const searchOptions = {
        searchKeyword: this.userInput,
        itemCount: 4,
        extendedItemCount: 30,
        searchType
      }
      return this.loadSearch(searchOptions)
    },
    async loadSearch (searchOptions) {
      const { searchType } = searchOptions
      if (searchType === '' && this.userInput !== this.previousInput) {
        this.previousInput = this.userInput
        this.loading = true
        await this.$store.dispatch(LOAD_SEARCH_RESULTS, searchOptions)
        this.loading = false
        return null
      }
      if (searchType !== '') await this.$store.dispatch(LOAD_EXTRA_SEARCH_RESULTS, searchOptions)
      return null
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.search-results {
  position: absolute;
  z-index: $zindex-popover;
  min-width: 335px;
}
</style>
