export const EMAILTYPE_NORMAL = 'EMAILTYPE_NORMAL'
export const EMAILTYPE_BULK = 'EMAILTYPE_BULK'
export const EMAILTYPE_BULK_REJECT = 'EMAILTYPE_BULK_REJECT'
export const EMAILTYPE_BULK_BACKUP = 'EMAILTYPE_BULK_BACKUP'
export const EMAILTYPE_FINISH_SELECTION = 'EMAILTYPE_FINISH_SELECTION'
export const EMAILTYPE_CLOSE = 'EMAILTYPE_CLOSE'
export const EMAILTYPE_BULK_VACANCYINVITE = 'EMAILTYPE_BULK_VACANCYINVITE'
export const EMAILTYPE_VACANCYINVITE = 'EMAILTYPE_VACANCYINVITE'
export const EMAILTYPE_SHARE_SELECTION = 'EMAILTYPE_SHARE_SELECTION'
export const EMAILTYPE_TEAM_PLAIN = 'EMAILTYPE_TEAM_PLAIN'
export const EMAILTYPE_SHARE_PROSPECTS = 'EMAILTYPE_SHARE_PROSPECTS'
export const EMAILTYPE_SHARE_CANDIDATES = 'EMAILTYPE_SHARE_CANDIDATES'
