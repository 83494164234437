var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row border-top border-light-gray"},[_vm._m(0),_c('div',{staticClass:"col-8 col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-2 col-md-6 border-right border-light-gray"},[_c('div',{staticClass:"d-none d-md-block py-4"},[_c('div',{staticClass:"d-flex flex-column align-items-start mr-3"},[_c('div',{staticClass:"w-100 mt-2 p-2 notification speech-bubble right"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"text-dark"},[_c('i',{staticClass:"fa fa-circle small",class:_vm.oneChannelInfo.oneChannelVacancyLinkActive
                        ? 'text-success'
                        : 'text-light-gray'}),_vm._v(" Sollicitatieformulier "),(!_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('span',[_vm._v("niet")]):_vm._e(),_vm._v(" actief ")]),_c('b-link',{staticClass:"small",attrs:{"to":{
                    name: 'vacancyPublishChannels',
                    params: { vacancyId: _vm.vacancyId }
                  }}},[_vm._v("bewerken")])],1),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.publicationChannelsInfo),function(category){return _c('div',{key:category.title},[_c('div',[_vm._v(" "+_vm._s(category.title)+" ("+_vm._s(category.channels.length)+") ")]),_vm._l((category.channels),function(channel){return _c('img',{key:channel.channelId,attrs:{"width":"210","src":("" + (channel.logoUrlLocal))}})})],2)}),0)]),_c('div',{staticClass:"w-100 mt-2 p-2 notification speech-bubble right"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"text-dark"},[_c('i',{staticClass:"fa fa-circle small",class:_vm.oneChannelInfo.oneChannelVacancyLinkActive
                        ? 'text-success'
                        : 'text-light-gray'}),_vm._v(" Sollicitatieformulier "),(!_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('span',[_vm._v("niet")]):_vm._e(),_vm._v(" actief ")]),_c('b-link',{staticClass:"small",attrs:{"to":{
                    name: 'vacancyPublishChannels',
                    params: { vacancyId: _vm.vacancyId }
                  }}},[_vm._v("bewerken")])],1),(_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('div',{staticClass:"d-flex justify-content-between align-items-baseline flex-wrap overflow-hidden"},[_c('small',[_vm._v(" Link naar vacaturetekst: "),_c('b-link',{attrs:{"href":_vm.oneChannelInfo.vacancyApplicationUrl.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.oneChannelInfo.vacancyApplicationUrl.url)+" ")])],1),_c('small',[_vm._v(" Link naar sollicitatie pagina: "),_c('b-link',{attrs:{"href":((_vm.oneChannelInfo.vacancyApplicationUrl.url) + "/solliciteren"),"target":"_blank"}},[_vm._v(_vm._s(_vm.oneChannelInfo.vacancyApplicationUrl.url)+"/solliciteren")])],1)]):_vm._e()])])])]),_c('div',{staticClass:"col-10 col-md-6"},[_c('div',{staticClass:"py-4"},[_c('div',{staticClass:"ml-2"},[_c('div',{staticClass:"d-flex d-md-none flex-column ml-auto p-2 mb-1 notification speech-bubble left"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"text-dark"},[_c('i',{staticClass:"fa fa-circle small",class:_vm.oneChannelInfo.oneChannelVacancyLinkActive
                        ? 'text-success'
                        : 'text-light-gray'}),_vm._v(" Sollicitatieformulier "),(!_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('span',[_vm._v("niet")]):_vm._e(),_vm._v(" actief ")]),_c('b-link',{staticClass:"small",attrs:{"to":{
                    name: 'vacancyPublishChannels',
                    params: { vacancyId: _vm.vacancyId }
                  }}},[_vm._v("bewerken")])],1),(_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('div',{staticClass:"d-flex flex-column"},[_c('small',[_vm._v(" Link naar vacaturetekst: "),_c('b-link',{attrs:{"href":_vm.oneChannelInfo.vacancyApplicationUrl.url,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.oneChannelInfo.vacancyApplicationUrl.url)+" ")])],1),_c('small',[_vm._v(" Link naar sollicitatie pagina: "),_c('b-link',{attrs:{"href":((_vm.oneChannelInfo.vacancyApplicationUrl.url) + "/solliciteren"),"target":"_blank"}},[_vm._v(_vm._s(_vm.oneChannelInfo.vacancyApplicationUrl.url)+"/solliciteren")])],1)]):_vm._e()]),_c('div',{staticClass:"d-flex d-md-none flex-column ml-auto p-2 mb-1 notification speech-bubble left"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('strong',{staticClass:"text-dark"},[_c('i',{staticClass:"fa fa-circle small",class:_vm.oneChannelInfo.oneChannelVacancyLinkActive
                        ? 'text-success'
                        : 'text-light-gray'}),_vm._v(" Sollicitatieformulier "),(!_vm.oneChannelInfo.oneChannelVacancyLinkActive)?_c('span',[_vm._v("niet")]):_vm._e(),_vm._v(" actief ")]),_c('b-link',{staticClass:"small",attrs:{"to":{
                    name: 'vacancyPublishChannels',
                    params: { vacancyId: _vm.vacancyId }
                  }}},[_vm._v("bewerken")])],1),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.publicationChannelsInfo),function(category){return _c('div',{key:category.title},[_c('div',[_vm._v(" "+_vm._s(category.title)+" ("+_vm._s(category.channels.length)+") ")]),_vm._l((category.channels),function(channel){return _c('img',{key:channel.channelId,attrs:{"src":("" + (channel.logoUrlLocal)),"width":"210"}})})],2)}),0)])]),(_vm.notifications.length)?_c('div',_vm._l((_vm.notifications),function(item){return _c('notification',{key:item.entityId,staticClass:"ml-2",attrs:{"notification":item,"main-content-background":true}})}),1):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-4 col-md-3 pl-4 pt-4 pb-5 bg-background"},[_c('h5',{staticClass:"ml-2 font-weight-bold"},[_vm._v("Publicatie")])])}]

export { render, staticRenderFns }