<template>
  <div class="p-2">
    <div class="d-flex flex-column flex-sm-row justify-content-between">
      <div class="d-flex mb-2">
        <avatar :fullname="fullname"></avatar>
        <div class="pl-3">
          <b-link
            :to="{
              name: 'prospectJudgementDetail',
              params: {
                vacancyId: vacancyId,
                index: privateProspect.prospectId,
                roundId: latestRoundId
              }
            }"
          >
            <h4>{{ fullname }}</h4>
          </b-link>
          <div v-if="privateProspect.phoneNumber" style="color: #8498af">
            <small>
              <strong>{{ privateProspect.phoneNumber }}</strong>
            </small>
          </div>
        </div>
      </div>
      <judgement-score-box :private-prospect="privateProspect" />
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import includes from 'lodash/includes'
import join from 'lodash/join'
import Avatar from '../avatar'
import JudgementScoreBox from '../judgement-score-box'

export default {
  name: 'prospectJudgementListItem',
  components: {
    JudgementScoreBox,
    Avatar
  },
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    },
    latestRoundId: {
      type: Number
    },
    vacancyId: {
      required: true
    }
  },
  data () {
    return {
      privateProspect: cloneDeep(this.value)
    }
  },
  watch: {
    privateProspect: {
      handler: function () {
        if (isEqual(this.privateProspect, this.value)) {
          return
        }
        this.$emit('input', this.privateProspect)
      },
      deep: true
    },
    value: {
      handler: function () {
        this.privateProspect = cloneDeep(this.value)
      },
      deep: true
    }
  },
  computed: {
    fullname () {
      const keys = ['firstName', 'middleName', 'lastName']
      const fullNameArray = map(this.privateProspect, (item, key) => {
        if (!includes(keys, key)) {
          return
        }
        return item
      })
      return join(fullNameArray, ' ')
    }
  }
}
</script>
