<template>
  <div class="row border-top border-light-gray">
    <div class="col-4 col-md-3 pl-4 pt-4 pb-5 bg-background">
      <h5 class="ml-2 font-weight-bold">Vacature-invoer</h5>
    </div>
    <div class="col-8 col-md-9">
      <div class="row">
        <div class="col-2 col-md-6 border-right border-light-gray">
          <div class="d-none d-md-block py-4">
            <div class="d-flex flex-column align-items-start mr-3">
              <div class="w-100 mt-2 p-2 notification speech-bubble right">
                <div class="d-flex justify-content-between">
                  <strong class="text-dark">Vacature</strong>
                  <b-link
                    class="small"
                    :to="{ name: 'vacancyDescription', params: { vacancyId } }"
                    >bewerken</b-link
                  >
                </div>
                <div>{{ title }}</div>
                <small>
                  <span v-html="shorterDescription"></span>
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10 col-md-6 py-4">
          <div class="ml-2">
            <div
              class="d-flex d-md-none flex-column ml-auto p-2 notification speech-bubble left"
            >
              <div class="d-flex justify-content-between">
                <strong class="text-dark">Vacature</strong>
                <b-link
                  class="small"
                  :to="{ name: 'vacancyDescription', params: { vacancyId } }"
                  >bewerken</b-link
                >
              </div>
              <div>{{ title }}</div>
              <small>
                <span v-html="shorterDescription"></span>
              </small>
            </div>
          </div>
          <div v-if="notifications.length">
            <notification
              class="ml-2"
              v-for="item in formattedNotifications"
              :key="item.entityId"
              :notification="item"
              :main-content-background="true"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '../../notification-list/notification'
import {
  NOTIFTYPE_TEAM_MEMBER_ADDED,
  NOTIFTYPE_TEAM_MEMBER_REMOVED
} from '../../../constants/prospect/prospect-notification-type'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    Notification
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    vacancyId: {
      required: true
    },
    notifications: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    shorterDescription () {
      var html = this.description ? this.description : ''
      var div = document.createElement('div')
      div.innerHTML = html
      var text = div.textContent || div.innerText || ''
      return `${text.substring(0, 200)}...`
    },
    formattedNotifications () {
      return map(cloneDeep(this.notifications), (notification) => {
        if (notification.lNotificationType === NOTIFTYPE_TEAM_MEMBER_ADDED) {
          notification.title = `Teamleden toegevoegd: ${notification.selectorName}`
        }
        if (notification.lNotificationType === NOTIFTYPE_TEAM_MEMBER_REMOVED) {
          notification.title = `Teamleden verwijderd: ${notification.selectorName}`
        }
        return notification
      })
    }
  }
}
</script>
