<template>
  <page>
    <overview-nav-header slot="header" />
    <overview-vacancy-list slot="main-content" />
    <overview-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import OverviewNavHeader from './overview-header.vue'
import OverviewAside from '../aside/aside-content.vue'
import OverviewVacancyList from './overview-vacancy-list'

export default {
  name: 'OverviewVacanciesPage',
  components: {
    Page,
    OverviewNavHeader,
    OverviewAside,
    OverviewVacancyList
  }
}
</script>
