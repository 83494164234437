export default {
  messages: {
    maxArrayLength: (field, [max]) =>
      `Maximaal ${max} opties kunnen worden geselecteerd`,
    minArrayLength: (field, [min]) =>
      `Minimaal ${min} opties moeten worden geselecteerd`,
    urlOrMailto: () =>
      'De waarde moet een geldige link zijn, bijvoorbeeld "https://www.bedrijf.nl/solliciteren" of "mailto:solliciteren@bedrijf.nl"'
  },
  custom: {
    hoursPerWeekMin: {
      max_value:
        'Minimaal aantal uur mag niet groter zijn dan maximaal aantal uur.',
      is_not:
        "Minimaal aantal uur moet minder zijn dan maximaal aantal uur. Om dezelfde waarde voor beide in te stellen gebruikt u de optie 'Exact' in plaats van 'Min. / Max.'"
    }
  },
  attributes: {
    appSrcAddress: 'Solliciteren'
  }
}
