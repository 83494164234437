<template>
  <div class="row pt-4 pb-3" style="min-height: 150px;">
    <div class="col-12">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          {{ vacancy.title || "..." }}
        </h1>
        <div>
          <small>
            <vacancy-status-icon :vacancy="vacancy" /> vacature nr.
            {{ vacancy.vacancyId || "..." }}
          </small>
        </div>
        <vacancy-header-actions
          @create-new-prospect="createNewProspect"
          @archive-vacancy="archiveVacancy"
          :loading="loading"
          :show-new-prospect-button="showNewProspectButton"
          :vacancy-is-draft="vacancyIsDraft"
          :is-vacancy-archived="isVacancyArchived"
        />
      </div>
      <div class="row no-gutter">
        <vacancy-header-navigation
          :disable-form-link="disableFormLink"
          :show-applicants-link="showApplicantsLink"
          :show-form-link="showFormLink"
          :show-publication-link="showPublicationLink"
          :show-team-link="showTeamLink"
          :show-mimir-link="showMimirLink"
          :vacancy="vacancy"
        />
        <vacancy-header-tasks
          v-if="routeIsVacancyOverview"
          :latestRoundIndex="latestRoundIndex"
          :stats="stats"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VacancyStatusIcon from '../vacancy-status-icon'
import {
  APPLICANT_TYPES,
  VACANCY,
  LOADING,
  VACANCY_ROUNDS,
  VACANCY_STATS,
  IS_VACANCY_ARCHIVED
} from '../../../store/keys-getters'
import {
  EDIT_FAVORITE_TEMPLATE,
  VIEW_VACANCY_PUBLICATION_CHANNELS,
  VIEW_VACANCY_TEAM,
  EDIT_VACANCY_PUBLICATION_MIMIR
} from '../../../constants/permissions'
import {
  ADD_NEW_APPLICANT,
  LOAD_APPLICANT_TYPES,
  ARCHIVE_VACANCY,
  LOAD_VACANCY_STATS
} from '../../../store/keys-actions'
import {
  VACANCYSTATUS_PUBLISHED,
  VACANCYSTATUS_SELECTION_STARTED
} from '../../../constants/vacancy-status'
import modal from '../../../services/modal'
import NewProspectModal from '../vacancy-prospects/new-prospect-modal'
import YesNoModal from '../../confirm-yes-no-modal'
import defaults from 'lodash/defaults'
import VacancyHeaderNavigation from './vacancy-header-navigation'
import VacancyHeaderActions from './vacancy-header-actions'
import VacancyHeaderTasks from './vacancy-header-tasks'
import find from 'lodash/find'

export default {
  name: 'VacancyHeader',
  components: {
    VacancyHeaderActions,
    VacancyHeaderNavigation,
    VacancyStatusIcon,
    VacancyHeaderTasks
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    isVacancyArchived () {
      return this.$store.getters[IS_VACANCY_ARCHIVED]
    },
    vacancyPublishedOrSelectionStarted () {
      if (!this.vacancy) {
        return false
      }
      return (
        this.vacancy.lStatus === VACANCYSTATUS_PUBLISHED ||
        this.vacancy.lStatus === VACANCYSTATUS_SELECTION_STARTED
      )
    },
    showFormLink () {
      return (
        this.vacancyPublishedOrSelectionStarted &&
        this.hasPermission(EDIT_FAVORITE_TEMPLATE)
      )
    },
    disableFormLink () {
      return this.$store.getters[VACANCY].appSrc === 'appSrc_3'
    },
    showTeamLink () {
      return (
        this.vacancyPublishedOrSelectionStarted &&
        this.hasPermission(VIEW_VACANCY_TEAM)
      )
    },
    showPublicationLink () {
      return (
        this.vacancyPublishedOrSelectionStarted &&
        this.hasPermission(VIEW_VACANCY_PUBLICATION_CHANNELS)
      )
    },
    showApplicantsLink () {
      return this.vacancyPublishedOrSelectionStarted
    },
    showMimirLink () {
      return (
        this.vacancyPublishedOrSelectionStarted &&
        this.hasPermission(EDIT_VACANCY_PUBLICATION_MIMIR)
      )
    },
    showNewProspectButton () {
      return (
        this.$route.name === 'vacancySelectionRound' ||
        this.$route.name === 'vacancyProspectDetail' ||
        this.routeIsVacancyOverview
      )
    },
    vacancyIsDraft () {
      return (
        this.vacancy.status === 'VACANCYSTATUS_CREATED' ||
        this.vacancy.status === 'VACANCYSTATUS_SUBMITTED'
      )
    },
    routeIsVacancyOverview () {
      return this.$route.name === 'vacancy'
    },
    applicantTypes () {
      return this.$store.getters[APPLICANT_TYPES]
    },
    vacancyRounds () {
      return this.$store.getters[VACANCY_ROUNDS]
    },
    latestRoundIndex () {
      const latestRound = find(
        this.vacancyRounds,
        round => round.roundId === this.vacancy.latestRoundId
      )
      return (latestRound || {}).index
    },
    stats () {
      return this.$store.getters[VACANCY_STATS]
    }
  },
  methods: {
    createNewProspect () {
      modal(NewProspectModal, { applicantTypes: this.applicantTypes }).then(
        data => {
          const filteredData = this.filterPropertiesByKey(data, null, [
            'selectedAttachment'
          ])
          this.$store.dispatch(
            ADD_NEW_APPLICANT,
            defaults(
              {
                vacancyId: this.vacancy.vacancyId
              },
              filteredData
            )
          )
        }
      )
      // TODO after creating a new prospect, app might redirect to their detail page or to the prospect listing
      if (this.$route.name !== 'vacancySelectionRound') {
        this.$router.push({
          name: 'vacancySelectionRound',
          params: {
            vacancyId: this.vacancy.vacancyId,
            roundId: this.vacancy.latestRoundId
          }
        })
      }
    },
    archiveVacancy () {
      modal(YesNoModal, {
        okButtonText: 'Vacature archiveren',
        question:
          'Weet je zeker dat je de vacature wilt afsluiten? Na het afsluiten kun je de vacature enkel bekijken in het archief. Een link naar het archief vind je bovenaan het vacature-dashboard. Vacatures kunnen niet heropend worden i.v.m. rapportages.'
      }).then(ok => {
        if (!ok) {
          return
        }
        this.$store.dispatch(ARCHIVE_VACANCY).then(() => {
          this.$router.push({ name: 'home' })
        })
      })
    },
    filterPropertiesByKey (obj, keysToInclude, keysToExclude) {
      if (!Array.isArray(keysToInclude) && !Array.isArray(keysToExclude)) {
        return obj
      }

      let _obj

      if (keysToInclude) {
        _obj = keysToInclude.reduce((acc, key) => {
          if (obj[key]) {
            acc[key] = obj[key]
          }
          return acc
        }, {})
      } else {
        _obj = obj
      }

      if (keysToExclude) {
        keysToExclude.forEach(key => {
          if (_obj[key]) delete _obj[key]
        })
      }
      return _obj
    }
  },
  mounted () {
    if (this.showNewProspectButton) {
      this.$store.dispatch(LOAD_APPLICANT_TYPES)
    }
    if (this.routeIsVacancyOverview) {
      this.$store.dispatch(LOAD_VACANCY_STATS)
    }
  }
}
</script>
