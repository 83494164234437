export const LOADING = 'LOADING'
export const MODALS = 'MODALS'

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED'
export const USER_DATA_LOADED = 'USER_DATA_LOADED'
export const CURRENT_USER = 'CURRENT_USER'
export const USERNAME = 'USERNAME'
export const IS_ADMIN = 'IS_ADMIN'
export const USERS = 'USERS'
export const USER = 'USER'
export const USER_PERMISSIONS = 'USER_PERMISSIONS'

export const CURRENT_TENANT = 'CURRENT_TENANT'
export const EMAIL_TEMPLATES = 'EMAIL_TEMPLATES'
export const EMAIL_PLACEHOLDERS = 'EMAIL_PLACEHOLDERS'
export const APPLICANT_TYPES = 'APPLICANT_TYPES'
export const CANDIDATE_BANKS = 'CANDIDATE_BANKS'
export const DEFAULT_SHARE_PER_EMAIL_TEMPLATE =
  'DEFAULT_SHARE_PER_EMAIL_TEMPLATE'

export const SELECTION_COMMITTEE_PROSPECTS = 'SELECTION_COMMITTEE_PROSPECTS'
export const CURRENT_SELECTION_COMMITTEE_PROSPECT =
  'CURRENT_SELECTION_COMMITTEE_PROSPECT'
export const SELECTION_COMMITTEE_PROSPECTS_ORDER =
  'SELECTION_COMMITTEE_PROSPECTS_ORDER'

export const VACANCY = 'VACANCY'
export const VACANCY_FORM = 'VACANCY_FORM'
export const VACANCY_DESCRIPTION_TEMPLATES = 'VACANCY_DESCRIPTION_TEMPLATES'
export const VACANCY_TEAM_PERMISSIONS = 'VACANCY_TEAM_PERMISSIONS'
export const VACANCY_DESCRIPTION_DROPDOWN_OPTIONS =
  'VACANCY_DESCRIPTION_DROPDOWN_OPTIONS'
export const VACANCY_TEAM = 'VACANCY_TEAM'
export const VACANCY_COMPETENCES = 'VACANCY_COMPETENCES'
export const VACANCY_COMPETENCES_OPTIONS = 'VACANCY_COMPETENCES_OPTIONS'
export const PUBLICATION_CHANNELS = 'PUBLICATION_CHANNELS'
export const PUBLICATION_CHANNELS_JOB_STRATEGIES =
  'PUBLICATION_CHANNELS_JOB_STRATEGIES'
export const MIMIR_CHANNELS_DROPDOWNS = 'MIMIR_CHANNELS_DROPDOWNS'
export const VACANCY_PROSPECTS = 'VACANCY_PROSPECTS'
export const SELECTION_ROUND_STATS = 'SELECTION_ROUND_STATS'
export const CURRENT_PROSPECT = 'CURRENT_PROSPECT'
export const CURRENT_NOTIFICATION = 'CURRENT_NOTIFICATION'
export const RATING_TYPE_FOR_PROSPECT_NAVIGATION =
  'RATING_TYPE_FOR_PROSPECT_NAVIGATION'
export const VACANCY_ROUNDS = 'VACANCY_ROUNDS'
export const VACANCY_SUBPROCESS_NOTIFICATIONS =
  'VACANCY_SUBPROCESS_NOTIFICATIONS'
export const VACANCY_PUBLICATION_SUBPROCESS_INFO =
  'VACANCY_PUBLICATION_SUBPROCESS_INFO'
export const CURRENT_TAB_PROSPECTS_OVERVIEW = 'CURRENT_TAB_PROSPECTS_OVERVIEW'
export const VACANCY_STATS = 'VACANCY_STATS'
export const IS_VACANCY_ARCHIVED = 'IS_VACANCY_ARCHIVED'

export const GDPR_EXPIRED_APPLICANTS = 'GDPR_EXPIRED_APPLICANTS'
export const GDPR_SOON_EXPIRING_APPLICANTS = 'GDPR_SOON_EXPIRING_APPLICANTS'
export const GDPR_NOT_SOON_EXPIRING_APPLICANTS =
  'GDPR_NOT_SOON_EXPIRING_APPLICANTS'
export const GDPR_NO_CONSENT_APPLICANTS = 'GDPR_NO_CONSENT_APPLICANTS'

export const ARCHIVED_VACANCIES = 'ARCHIVED_VACANCIES'
export const OPEN_VACANCIES = 'OPEN_VACANCIES'
export const ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER =
  'ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER'
export const OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER =
  'OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER'
export const ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER =
  'ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER'
export const OPEN_VACANCY_COUNT = 'OPEN_VACANCY_COUNT'
export const ARCHIVED_VACANCY_COUNT = 'ARCHIVED_VACANCY_COUNT'
export const PENDING_TODO_COUNT_BY_VACANCY_ID =
  'PENDING_TODO_COUNT_BY_VACANCY_ID'
export const PENDING_TODOS = 'PENDING_TODOS'

export const TOKEN_PROSPECT_DATA = 'TOKEN_PROSPECT_DATA'
export const TOKEN_PROSPECT_LOADING_STATE = 'TOKEN_PROSPECT_LOADING_STATE'

export const CURRENT_MERGED_JOB = 'CURRENT_MERGED_JOB'
export const MERGED_JOB_LOADING_STATUS = 'MERGED_JOB_LOADING_STATUS'
export const MERGED_JOB_ERROR_STATUS = 'MERGED_JOB_ERROR_STATUS'

// global search
export const GET_SEARCH_VACANCIES = 'GET_SEARCH_VACANCIES'
export const GET_SEARCH_PROSPECTS = 'GET_SEARCH_PROSPECTS'
export const GET_SEARCH_ATTACHMENTS = 'GET_SEARCH_ATTACHMENTS'
export const GET_EXTENDED_SEARCH_STATE = 'GET_EXTENDED_SEARCH_STATE'
