export default {
  employmentType_1: 'Fulltime',
  employmentType_2: 'Parttime',
  employmentType_3: 'Vast',
  employmentType_4: 'Bijbaan',
  employmentType_5: 'Tijdelijk',
  employmentType_6: 'Stage',
  employmentType_7: 'Traineeship',
  employmentType_8: 'Detachering',
  employmentType_9: 'Uitzendwerk',
  employmentType_10: 'Vakantiebaan',
  employmentType_11: 'Onregelmatige dienst',
  employmentType_12: 'Klus',
  employmentType_13: 'Tijdelijk met uitzicht op vast'
}
