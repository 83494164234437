export default {
  CANDAVAILABLEPERIOD_IMMEDIATELY: 'onmiddellijk',
  CANDAVAILABLEPERIOD_MONTH_0_1: 'minder dan maand',
  CANDAVAILABLEPERIOD_MONTH_1: 'in 1 maand',
  CANDAVAILABLEPERIOD_MONTH_2: 'in 2 maanden',
  CANDAVAILABLEPERIOD_MONTH_3: 'in 3 maanden',
  CANDAVAILABLEPERIOD_MONTH_3_6: '3 tot 6 maanden',
  CANDAVAILABLEPERIOD_MONTH_6_PLUS: 'meer dan 6 maanden',
  CANDAVAILABLEPERIOD_NEGOTIABLE: 'Per direct beschikbaar'
}
