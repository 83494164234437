<template>
  <b-card>
    <div class="d-flex justify-content-between">
      <b-img :src="privateValue.logoUrlLocal" height="65" />
      <div
        v-if="
          privateValue.startedApplicationsCount > 0 ||
            privateValue.viewCount > 0 ||
            privateValue.applicantsCount > 0
        "
        class="d-flex flex-column ml-auto"
      >
        <div><i class="fa fa-eye" /> {{ privateValue.viewCount }} Views</div>
        <div>
          <i class="fa fa-check" />
          {{ privateValue.startedApplicationsCount }} Clicks
        </div>
        <div>
          <i class="fa fa-user" />
          {{ privateValue.applicantsCount }} Sollicitanten
        </div>
      </div>
      <div class="d-flex flex-column align-items-end w-40 pl-2">
        <b-button-group class="m-2 pt-1 pb-1" size="sm">
          <b-btn
            :disabled="disabled"
            class="pl-3 pr-3"
            :class="{ disabled: disabled }"
            @click="handlePublicationState(true)"
            :variant="privateValue.isSelected ? 'success' : 'outline-success'"
          >
            Aan
          </b-btn>
          <b-btn
            :disabled="disabled"
            class="pl-3 pr-3"
            :class="{ disabled: disabled }"
            @click="handlePublicationState(false)"
            :variant="!privateValue.isSelected ? 'danger' : 'outline-danger'"
          >
            Uit
          </b-btn>
        </b-button-group>
        <div v-if="displayJobCategoryDropdown">
          <multiselect-dropdown
            v-model="privateValue.jobBoardCategories"
            :options="jobCategories"
            name="jobCategory"
            label="vakgebied"
            :required="displayJobCategoryDropdown"
            :min="1"
            :max="3"
          />
        </div>
        <div
          v-if="displayMimirDropdowns"
          class="d-flex flex-column align-items-start"
        >
          <b-form-select
            v-if="displayMimirFunctionOptions"
            v-model="privateValue.mimirKeyJobPublishFunctionName"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('mimirFunction') }"
            class="mb-2"
            id="mimirFunction"
            name="mimirFunction"
            data-vv-as="Functiecategorie"
          >
            <option :value="null" disabled>Functiecategorie *</option>
            <option
              v-for="option in mimirOptions.functionName"
              :key="option.key"
              :value="`${option.key}`"
              >{{ option.name }}</option
            >
          </b-form-select>
          <b-form-select
            v-if="displayMimirBranchOptions"
            v-model="privateValue.mimirKeyJobPublishBranch"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('mimirBranch') }"
            id="mimirBranch"
            name="mimirBranch"
            data-vv-as="Vakgebied"
          >
            <option :value="null" disabled>Vakgebied *</option>
            <option
              v-for="option in mimirOptions.branch"
              :key="option.key"
              :value="`mimirkey_${option.key}`"
              >{{ option.name }}</option
            >
          </b-form-select>
        </div>
        <div
          v-if="displayMimirFunctionOptions && errors.has('mimirFunction')"
          class="text-error"
        >
          {{ errors.first("mimirFunction") }}
        </div>
        <div
          v-if="displayMimirBranchOptions && errors.has('mimirBranch')"
          class="text-error"
        >
          {{ errors.first("mimirBranch") }}
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'

import MultiselectDropdown from '../../multiselect-dropdown'

export default {
  name: 'Channel',
  components: {
    MultiselectDropdown
  },
  inject: {
    $validator: '$validator'
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    jobCategories: {
      type: Array,
      default: () => []
    },
    mimirOptions: {
      type: Object,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      privateValue: cloneDeep(this.value)
    }
  },
  watch: {
    privateValue: {
      handler: function () {
        if (isEqual(this.privateValue, this.value)) {
          return
        }
        this.$emit('input', this.privateValue)
      },
      deep: true
    },
    value: {
      handler: function () {
        this.privateValue = cloneDeep(this.value)
      },
      deep: true
    }
  },
  computed: {
    displayMimirDropdowns () {
      return !isEmpty(this.mimirOptions) && this.privateValue.isSelected
    },
    displayJobCategoryDropdown () {
      return !isEmpty(this.jobCategories) && this.privateValue.isSelected
    },
    displayMimirBranchOptions () {
      return this.displayMimirDropdowns && !isEmpty(this.mimirOptions.branch)
    },
    displayMimirFunctionOptions () {
      return (
        this.displayMimirDropdowns && !isEmpty(this.mimirOptions.functionName)
      )
    }
  },
  methods: {
    handlePublicationState (state) {
      if (this.disabled) {
        return
      }
      this.privateValue.isSelected = state
    }
  }
}
</script>

<style lang="scss" scoped>
.disabled {
  cursor: default;
}
</style>
