<template>
  <b-modal
    centered
    title="Nieuwe sollicitant"
    :visible="true"
    :ok-disabled="false"
    @ok="save"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    ok-title="Opslaan"
    size="lg"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div class="d-flex pb-2 justify-content-between">
      <label for="avatarUrl">Avatar</label>
      <div class="w-75 d-flex align-items-start">
        <b-form-file
          ref="avatarUrl"
          v-model="avatarUrl"
          name="avatarUrl"
          browse-text="Bladeren"
          placeholder="Geen afbeelding gekozen"
        />
      </div>
    </div>

    <div class="d-flex justify-content-between pb-2">
      <label for="firstname">Voornaam*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('prospectFirstname') }"
          v-validate="'required'"
          data-vv-as="Voornaam"
          v-model="firstname"
          type="text"
          name="firstname"
        />
        <div v-show="errors.has('firstname')" class="text-error">
          {{ errors.first("firstname") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="middleName">Tussenvoegels</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          v-model="middleName"
          type="text"
          name="prospectInitials"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="lastname">Achternaam*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('lastname') }"
          v-validate="'required'"
          data-vv-as="Achternaam"
          v-model="lastname"
          type="text"
          name="lastname"
        />
        <div v-show="errors.has('lastname')" class="text-error">
          {{ errors.first("lastname") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="dateOfBirth">Geboortedatum</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <datepicker
          v-model="dateOfBirth"
          placeholder="DD-MM-YYYY"
          :language="datepickerLanguage.nl"
          name="dateOfBirth"
          :ref="dateOfBirth"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="gender">Geslacht*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-radio-group
          v-model="gender"
          name="gender"
          v-validate="'required'"
          dataa-vv-as="Geslacht"
        >
          <b-form-radio value="GENDER_M">man</b-form-radio>
          <b-form-radio value="GENDER_F">vrouw</b-form-radio>
        </b-form-radio-group>
        <div v-show="errors.has('gender')" class="text-error">
          {{ errors.first("gender") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="address">Straat en huisnr.</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          v-model="address"
          type="text"
          name="address"
        />
      </div>
    </div>

    <div class="d-flex pb-2 align-items-center justify-content-between">
      <div class="d-flex flex-column align-items-start w-20">
        <label class="mb-0" for="postcode">Postcode en &nbsp;</label>
        <label for="city">plaats</label>
      </div>
      <div class="w-75 d-flex align-items-start">
        <input
          class="form-control w-20 mr-3"
          v-model="postcode"
          type="text"
          name="postcode"
        />
        <input class="form-control" v-model="city" type="text" name="city" />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="emailAddress">E-mail*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('emailAddress') }"
          v-validate="'required|email'"
          data-vv-as="E-mail"
          v-model="emailAddress"
          type="text"
          name="emailAddress"
        />
        <div v-show="errors.has('emailAddress')" class="text-error">
          {{ errors.first("emailAddress") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="email">Telefoon</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          data-vv-as="Telefoon"
          v-model="phoneNumber"
          type="text"
          name="phoneNumber"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="applicantTypeId">Sollicitant type</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-select
          name="applicantTypeId"
          v-model="applicantTypeId"
          class="mb-2"
        >
          <option
            v-for="type in applicantTypes"
            :key="type.id"
            :value="type.id"
            >{{ type.name }}</option
          >
        </b-form-select>
      </div>
    </div>

    <div
      class="d-flex justify-content-between"
      :class="source === 'SOURCE_OTHER' ? 'pb-3' : 'pb-2'"
    >
      <label for="gender">Bron*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-radio-group
          v-model="source"
          name="source"
          v-validate="'required'"
          data-vv-as="Bron"
        >
          <b-form-radio value="SOURCE_EMAIL">E-mail</b-form-radio>
          <b-form-radio value="SOURCE_OTHER">
            Anders
            <span v-if="source === 'SOURCE_OTHER'">, namelijk:</span>
          </b-form-radio>
        </b-form-radio-group>
        <b-form-input
          v-if="source === 'SOURCE_OTHER'"
          v-model="sourceText"
          name="sourceText"
          v-validate="'required'"
          data-vv-as="Bron uitleg"
        ></b-form-input>
        <div v-show="errors.has('source')" class="text-error">
          {{ errors.first("source") }}
        </div>
        <div v-show="errors.has('sourceText')" class="text-error">
          {{ errors.first("sourceText") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="attachments">Bijlagen</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <div class="w-100 d-flex align-items-start">
          <b-form-file
            v-validate="'size:10000'"
            multiple
            ref="attachments"
            v-model="selectedAttachment"
            name="attachment"
            browse-text="Bladeren"
            placeholder="Geen bestand gekozen"
          />
          <b-btn
            class="ml-3"
            variant="primary"
            :disabled="!selectedAttachment"
            @click="addAttachment"
            >Uploaden</b-btn
          >
        </div>

        <div v-show="errors.has('attachment')" class="text-error">
          {{ errors.first("attachment") }}
        </div>

        <div v-if="attachments" class="mt-1 d-flex flex-wrap">
          <div
            v-for="(attachment, index) in attachments"
            :key="`button-${attachment.name}-${index}`"
            class="mr-2 mb-2"
          >
            <b-btn
              variant="link"
              target="_blank"
              class="border border-medium-gray bg-white border-right-0"
              >{{ attachment.name }}</b-btn
            >
            <b-btn
              variant="white"
              @click="deleteAttachment(index)"
              class="border border-left-0 border-medium-gray bg-white"
            >
              <i class="fa fa-trash-o" />
            </b-btn>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'
import find from 'lodash/find'
import defaults from 'lodash/defaults'

export default {
  name: 'NewProspectModal',
  components: {
    Datepicker
  },
  props: {
    applicantTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      avatarUrl: null,
      firstname: null,
      middleName: null,
      lastname: null,
      dateOfBirth: null,
      gender: null,
      address: null,
      postcode: null,
      city: null,
      emailAddress: null,
      phoneNumber: null,
      applicantTypeId: 1,
      source: null,
      attachments: null,
      sourceText: null,
      selectedAttachment: null
    }
  },
  computed: {
    datepickerLanguage () {
      return {
        nl: nl
      }
    },
    applicantType () {
      const currentType = find(this.applicantTypes, [
        'id',
        this.applicantTypeId
      ])
      return {
        applicantTypeId: String(currentType.id),
        applicantType: currentType.name
      }
    }
  },
  methods: {
    clearFiles (ref) {
      this.$refs[ref].reset()
    },
    save (evt) {
      evt.preventDefault()
      this.$validator.validate().then(isValid => {
        if (!isValid) {
          return
        }
        this.$emit('resolve', defaults(this.applicantType, this.$data))
        this.$emit('remove')
      })
    },
    addAttachment () {
      this.attachments = this.attachments
        ? [...this.attachments, ...this.selectedAttachment]
        : this.selectedAttachment
      this.selectedAttachment = null
    },
    deleteAttachment (index) {
      this.attachments.splice(index, 1)
    }
  }
}
</script>
