<template>
  <div class="icon-box">
    <i class="fa fa-check-square-o stacked-icon checkbox" />
    <font-awesome-icon icon="user-friends" class="stacked-icon users" />
  </div>
</template>

<script>
export default {
  name: 'TodoEveryoneIcon'
}
</script>

<style lang="scss" scoped>
.icon-box {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  position: relative;
  padding: 2.3px;
  height: 17px;
  width: 28px;
  text-align: left;
  .stacked-icon {
    display: block;
    position: absolute;
    white-space: normal;
    top: 0;
    left: 0;
  }
  .checkbox {
    left: 12px;
    color: #bbbbbb;
  }
  .users {
    top: 4px;
    color: #9f9f9f;
    transform: rotateY(180deg) scale(0.9);
  }
}
</style>
