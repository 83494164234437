<template>
  <b-modal
    centered
    :title="`Toestemmingen`"
    :visible="true"
    @ok="$emit('resolve', true)"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    ok-only
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div
      v-for="consent in sortedConsents"
      :key="consent.endDate"
      class="pb-4 pt-4"
    >
      <div class="pb-1 font-italic">"{{ consent.consentMessage }}"</div>
      <div class="d-flex ">
        <div class="pr-3">
          <strong>Vanaf:</strong> {{ consent.consentGivenDate | formatDate }}
        </div>
        <div><strong>Tot:</strong> {{ consent.endDate | formatDate }}</div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'
import sortBy from 'lodash/sortBy'
import reverse from 'lodash/reverse'

export default {
  props: {
    consents: {
      required: true,
      type: Array
    }
  },
  computed: {
    sortedConsents () {
      return reverse(sortBy(this.consents, ['endDate']))
    }
  },
  filters: {
    formatDate (date) {
      return moment
        .unix(date)
        .local()
        .format('LL')
    }
  }
}
</script>
