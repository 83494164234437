<template>
  <div
    class="top-bar text-center bg-new small py-1 alert alert-warning m-0 border-0"
  >
    <!-- Wij zijn ONE aan het updaten om de gebruikerservaring te verbeteren. Mocht u
    een probleem vinden neem dan contact met ons op via
    <b-link class="alert-link" href="mailto:supportdesk@jobsrepublic.nl"
      >supportdesk@jobsrepublic.nl</b-link
    >. -->
    Op dit moment is er een storing waardoor een deel van de applicatie niet correct werkt. We werken hard aan een oplossing.
  </div>
</template>

<script>
export default {
  name: 'infoBar'
}
</script>
