<template>
  <div class="ml-auto">
    <div v-if="showNewProspectButton && !isVacancyArchived">
      <b-btn
        v-if="canArchiveVacancy"
        variant="link"
        class="text-dark"
        @click="$emit('archive-vacancy')"
      >
        <div v-if="loading"><i class="fa fa-spinner fa-spin" /></div>
        <div v-if="!loading">vacature afsluiten</div>
      </b-btn>
      <b-btn
        v-if="!vacancyIsDraft && canCreateApplicants"
        variant="secondary"
        @click="$emit('create-new-prospect')"
      >
        <div v-if="loading"><i class="fa fa-spinner fa-spin" /></div>
        <div v-if="!loading"><i class="fa fa-plus" /> Nieuwe sollicitant</div>
      </b-btn>
    </div>
    <div v-if="isVacancyArchived">
      <b-btn variant="link" class="text-dark" :to="{ name: 'archive' }">
        <div>terug naar archief</div>
      </b-btn>
    </div>
  </div>
</template>

<script>
import { FINISH_VACANCY_SELECTION, CREATE_VACANCY_APPLICANT } from '../../../constants/permissions'

export default {
  name: 'VacancyHeaderActions',
  props: {
    showNewProspectButton: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    vacancyIsDraft: {
      type: Boolean,
      default: false
    },
    isVacancyArchived: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    canArchiveVacancy () {
      return this.hasPermission(FINISH_VACANCY_SELECTION)
    },
    canCreateApplicants () {
      return this.hasPermission(CREATE_VACANCY_APPLICANT)
    }
  }
}
</script>
