import apiRequest from '../../services/api-request'
import searchTypes from '../../constants/global-search'
import {
  SET_SEARCH_RESULTS,
  SET_EXTRA_SEARCH_RESULTS,
  SET_SEARCH_LOADING,
  SET_EXTENDED_SEARCH_STATE,
  RESET_EXTENDED_SEARCH_STATE
} from '../keys-mutations'
import {
  GET_SEARCH_VACANCIES,
  GET_SEARCH_PROSPECTS,
  GET_SEARCH_ATTACHMENTS,
  GET_EXTENDED_SEARCH_STATE
} from '../keys-getters'
import {
  LOAD_SEARCH_RESULTS,
  LOAD_EXTRA_SEARCH_RESULTS,
  CLEAR_SEARCH_RESULTS
} from '../keys-actions'

const initialExtendedSearchState = Object.keys(searchTypes).reduce(
  (acc, el) => {
    return (acc = Object.assign(acc, {
      [el]: { loading: false, searched: false }
    }))
  },
  {}
)

const getOwnProspects = (prospects, vacancies) => {
  if (!vacancies.length) return []
  const ids = vacancies.map(vacancy => vacancy.vacancyId)
  return prospects.filter(prospect => ids.includes(prospect.vacancyId))
}

export default {
  state: {
    searchResults: {},
    extendedSearchState: Object.assign({}, initialExtendedSearchState)
  },
  mutations: {
    [SET_SEARCH_RESULTS]: (state, searchResults) => {
      state.searchResults = searchResults
    },
    [SET_EXTRA_SEARCH_RESULTS]: (state, { searchResults, searchType }) => {
      const key = searchTypes[searchType]
      state.searchResults = Object.assign({}, state.searchResults, {
        [key]: searchResults
      })
    },
    [SET_SEARCH_LOADING]: (state, loading) => (state.loading = loading),
    [SET_EXTENDED_SEARCH_STATE]: (state, { searchType, searchState }) =>
      (state.extendedSearchState[searchType] = searchState),
    [RESET_EXTENDED_SEARCH_STATE]: state => {
      state.extendedSearchState = Object.assign({}, initialExtendedSearchState)
    }
  },
  getters: {
    [GET_SEARCH_VACANCIES]: state =>
      state.searchResults[searchTypes.vacancies] || [],
    [GET_SEARCH_PROSPECTS]: (state, rootState) => {
      const prospects = state.searchResults[searchTypes.applicants] || []
      if (!prospects.length) return []
      const ownVacancies =
        rootState.ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER
      return getOwnProspects(prospects, ownVacancies)
    },
    [GET_SEARCH_ATTACHMENTS]: state =>
      state.searchResults[searchTypes.attachments] || [],
    [GET_EXTENDED_SEARCH_STATE]: state => searchType =>
      state.extendedSearchState[searchType] || {}
  },
  actions: {
    [LOAD_SEARCH_RESULTS]: async ({ commit }, { searchKeyword, itemCount }) => {
      try {
        await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/search/${searchKeyword}?take=${itemCount}`
        }).then(data => {
          return commit(SET_SEARCH_RESULTS, data)
        })
      } finally {
        commit(SET_SEARCH_LOADING, false)
      }
    },
    [LOAD_EXTRA_SEARCH_RESULTS]: async (
      { commit },
      { searchKeyword, extendedItemCount, searchType }
    ) => {
      let searchState = { loading: true, searched: false }
      commit(SET_EXTENDED_SEARCH_STATE, { searchType, searchState })
      try {
        await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/search/${searchKeyword}/${searchType}?take=${extendedItemCount}`
        }).then(data => {
          return commit(SET_EXTRA_SEARCH_RESULTS, {
            searchResults: data,
            searchType
          })
        })
      } finally {
        searchState = { loading: false, searched: true }
        commit(SET_EXTENDED_SEARCH_STATE, { searchType, searchState })
      }
    },
    [CLEAR_SEARCH_RESULTS]: ({ commit }) => {
      commit(SET_SEARCH_RESULTS, {})
      commit(RESET_EXTENDED_SEARCH_STATE)
    }
  }
}
