<template>
  <div class="d-flex flex-column p-3 justify-content-start bg-judgment-card">
    <div class="d-flex flex-column">
      <b-form-group label="Geef een beoordeling">
        <b-form-radio-group
          buttons
          stacked
          button-variant="link"
          id="radios2"
          v-model="
            (privateProspect.prospectJudgementForCurrentUser || {})
              .judgementScore
          "
          name="judgementScorescore"
          size="sm"
        >
          <b-form-radio
            class="pl-0 text-left"
            :class="
              (privateProspect.prospectJudgementForCurrentUser || {})
                .judgementScore === 1
                ? 'text-success'
                : ''
            "
            :value="1"
          >
            <i class="fa fa-check" style="width: 16px" />Positief advies
          </b-form-radio>
          <b-form-radio
            class="pl-0 text-left"
            :class="
              (privateProspect.prospectJudgementForCurrentUser || {})
                .judgementScore === 0
                ? 'text-dark'
                : ''
            "
            :value="0"
          >
            <i class="fa fa-question" style="width: 16px" />Neutraal
          </b-form-radio>
          <b-form-radio
            class="pl-0 text-left"
            :class="
              (privateProspect.prospectJudgementForCurrentUser || {})
                .judgementScore === -1
                ? 'text-danger'
                : ''
            "
            :value="-1"
          >
            <i class="fa fa-times" style="width: 16px" />Negatief advies
          </b-form-radio>
        </b-form-radio-group>
        <b-form-textarea
          rows="3"
          placeholder="Geef een toelichting (optioneel)"
          v-model="
            (privateProspect.prospectJudgementForCurrentUser || {}).comment
          "
        ></b-form-textarea>
      </b-form-group>
    </div>
  </div>
</template>
<script>
export default {
  name: 'judgement-score-box',
  props: {
    privateProspect: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
