<template>
  <div class="d-flex p-2" v-if="member.user.enabled && member.user.fullName">
    <div class="pr-2">
      <avatar size="sm" :fullname="member.user.fullName" />
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex align-items-baseline">
        <div class="text-dark pr-2">
          <small
            ><strong>{{ member.user.fullName }}</strong></small
          >
        </div>
        <small v-if="!isCurrentUser"
          ><b-link @click="removeTeamMember">verwijderen</b-link></small
        >
      </div>
      <div>
        <small>{{ member.user.title }}</small>
      </div>
    </div>
    <div class="ml-auto align-self-center">
      <small>{{ role }}</small>
    </div>
  </div>
</template>
<script>
import Avatar from '../../avatar'
import { DELETE_VACANCY_TEAM_MEMBER } from '../../../store/keys-actions'
export default {
  components: {
    Avatar
  },
  props: {
    member: {
      type: Object,
      required: true
    },
    currentUser: {
      type: Object,
      required: true
    }
  },
  computed: {
    role () {
      const map = {
        1: 'Recruiter',
        2: 'Manager',
        7: 'Selectie commissie'
      }
      return map[this.member.lTeamRole]
    },
    isCurrentUser () {
      return this.currentUser.username === this.member.user.username
    }
  },
  methods: {
    removeTeamMember () {
      this.$store.dispatch(DELETE_VACANCY_TEAM_MEMBER, this.member.teamMemberId)
    }
  }
}
</script>
