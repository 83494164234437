<template>
  <page>
    <vacancy-nav-header slot="header" />
    <overview slot="main-content" />
  </page>
</template>

<script>
import Page from '../../page-types/no-aside-no-padding-content-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header.vue'
import Overview from './overview'
import {
  SET_VACANCY_SUBPROCESS_NOTIFICATIONS,
  SET_VACANCY_PUBLICATION_SUBPROCESS_INFO,
  SET_VACANCY_STATS
} from '../../../store/keys-mutations'

export default {
  name: 'VacancyOverviewPage',
  components: {
    Page,
    VacancyNavHeader,
    Overview
  },
  beforeRouteLeave (to, from, next) {
    next()
    this.$store.commit(SET_VACANCY_PUBLICATION_SUBPROCESS_INFO, {})
    this.$store.commit(SET_VACANCY_SUBPROCESS_NOTIFICATIONS, {})
    this.$store.commit(SET_VACANCY_STATS, {})
  }
}
</script>
