<template>
  <page>
    <prospect-judgements-header slot="header" />
    <prospect-judgement-list slot="main-content" />
    <overview-aside slot="side-content" />
  </page>
</template>

<script>
import { LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS } from '../../store/keys-actions'
import Page from '../page-types/default-page'
import ProspectJudgementsHeader from './prospect-judgements-header'
import OverviewAside from '../aside/aside-content.vue'
import ProspectJudgementList from './prospect-judgement-list'

export default {
  name: 'SelectionCommitteePage',
  components: {
    Page,
    ProspectJudgementsHeader,
    OverviewAside,
    ProspectJudgementList
  },
  created () {
    this.$store.dispatch(LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS)
  }
}
</script>
