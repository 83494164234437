<template>
  <page>
    <vacancy-nav-header slot="header" />
    <vacancy-publication-channels slot="main-content" />
    <vacancy-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../../page-types/default-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header.vue'
import VacancyPublicationChannels from './publication-channels.vue'
import VacancyAside from '../vacancy-aside.vue'

export default {
  name: 'VacancyPublicationChannelPage',
  components: {
    Page,
    VacancyNavHeader,
    VacancyPublicationChannels,
    VacancyAside
  }
}
</script>
