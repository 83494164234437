import Vue from 'vue'
import apiRequest from '../../services/api-request'
import { stripHostFromAttachment } from '../../services/attachment-data-cleaner'
import {
  SET_TOKEN_PROSPECT_DATA,
  SET_TOKEN_PROSPECT_LOADING_STATE
} from '../keys-mutations'
import { LOAD_TOKEN_PROSPECT_DATA } from '../keys-actions'
import {
  TOKEN_PROSPECT_DATA,
  TOKEN_PROSPECT_LOADING_STATE
} from '../keys-getters'
import each from 'lodash/each'

export default {
  state: {
    info: null,
    list: null,
    vacancy: null,
    isLoading: false,
    isError: false
  },
  mutations: {
    [SET_TOKEN_PROSPECT_DATA]: (state, { info, list, vacancy }) => {
      state.info = info
      state.list = list
      state.vacancy = vacancy
    },
    [SET_TOKEN_PROSPECT_LOADING_STATE]: (state, isLoading) => {
      state.isLoading = isLoading
    }
  },
  getters: {
    [TOKEN_PROSPECT_DATA]: state => state,
    [TOKEN_PROSPECT_LOADING_STATE]: state => state.isLoading
  },
  actions: {
    [LOAD_TOKEN_PROSPECT_DATA]: async ({ commit }) => {
      try {
        commit(SET_TOKEN_PROSPECT_LOADING_STATE, true)
        const response = await apiRequest({
          method: 'GET',
          url: '/api/erecruiter-web-api/tokenProspects/vacancy'
        })
          .then(vacancyId =>
            apiRequest({
              method: 'GET',
              url: `/api/erecruiter-web-api/vacancies/${vacancyId}`
            })
          )
          .then(vacancy =>
            Promise.all([
              apiRequest({
                method: 'GET',
                url: '/api/erecruiter-web-api/tokenProspects/info'
              }),
              apiRequest({
                method: 'GET',
                url: '/api/erecruiter-web-api/tokenProspects/0/1000'
              })
            ]).then(([info, list]) => {
              each(list, prospect => {
                stripHostFromAttachment(prospect.avatarUrl, 'url')
                each(prospect.attachments, attachment => {
                  stripHostFromAttachment(attachment, 'url')
                  stripHostFromAttachment(attachment, 'convertedPdfUrl')
                })
              })

              commit(SET_TOKEN_PROSPECT_DATA, { info, list, vacancy })
            })
          )
        commit(SET_TOKEN_PROSPECT_LOADING_STATE, false)
        return response
      } catch (error) {
        commit(SET_TOKEN_PROSPECT_LOADING_STATE, false)
        Vue.notify({
          type: 'warning',
          text: `De sollicitanten konden op dit moment niet worden geladen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            error.message
          }`
        })
        return null
      }
    }
  }
}
