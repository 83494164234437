<template>
  <page>
    <gdpr-nav-header slot="header" />
    <router-view slot="main-content"></router-view>
  </page>
</template>
<script>
import GdprNavHeader from './gdpr-nav-header'
import Page from '../page-types/default-page'
import { LOAD_GDPR_APPLICANTS, LOAD_VACANCY_LIST, LOAD_ARCHIVED_VACANCIES } from '../../store/keys-actions'

export default {
  components: {
    GdprNavHeader,
    Page
  },
  mounted () {
    return Promise.all([
      this.$store.dispatch(LOAD_GDPR_APPLICANTS),
      this.$store.dispatch(LOAD_VACANCY_LIST),
      this.$store.dispatch(LOAD_ARCHIVED_VACANCIES)
    ])
  }
}
</script>
