import Vue from 'vue'
import cookie from 'js-cookie'
import apiRequest from '../../services/api-request'
import {
  SET_CURRENT_TENANT,
  SET_AVAILABLE_TENANTS,
  SET_CANDIDATE_BANKS
} from '../keys-mutations'
import {
  LOAD_CURRENT_TENANT_DETAILS,
  SWITCH_TENANT,
  LOAD_CANDIDATE_BANKS
} from '../keys-actions'
import { CURRENT_TENANT, CANDIDATE_BANKS } from '../keys-getters'

export default {
  state: {
    availableTenants: [],
    currentTenant: {},
    emailTemplates: [],
    candidateBanks: []
  },
  mutations: {
    [SET_CURRENT_TENANT]: (state, currentTenant) => {
      state.currentTenant = currentTenant
    },
    [SET_AVAILABLE_TENANTS]: (state, tenants) =>
      (state.availableTenants = tenants),
    [SET_CANDIDATE_BANKS]: (state, candidateBanks) =>
      (state.candidateBanks = candidateBanks)
  },
  getters: {
    [CURRENT_TENANT]: state => state.currentTenant,
    [CANDIDATE_BANKS]: state => state.candidateBanks
  },
  actions: {
    [LOAD_CURRENT_TENANT_DETAILS]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/tenant/'
      }).then(data => {
        commit(SET_CURRENT_TENANT, data)
      })
    },
    [SWITCH_TENANT]: ({ dispatch }, tenant) => {
      return apiRequest({
        method: 'PUT',
        url: '/api/erecruiter-web-api/tenant',
        data: tenant
      }).then(() => dispatch(LOAD_CURRENT_TENANT_DETAILS))
    },
    [LOAD_CANDIDATE_BANKS]: ({ commit, getters }) => {
      const { tenantId } = getters[CURRENT_TENANT]
      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/tenants/${tenantId}/candidateBanks`
      })
        .then(data => commit(SET_CANDIDATE_BANKS, data))
        .catch(error =>
          Vue.notify({
            type: 'warning',
            text: `Er is iets fout gegaan met de CV database. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              error.message
            }`
          })
        )
    }
  }
}
