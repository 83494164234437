<template>
  <div class="px-1 px-md-2">
    <div
      class="my-1 my-md-2 d-flex justify-content-between vacancy-item__container"
    >
      <div
        class="vacancy-item__left d-flex flex-column justify-content-between"
      >
        <div class="mb-1 top-row">
          <b-link
            :to="{
              name: 'vacancy',
              params: { vacancyId: vacancy.vacancyId }
            }"
          >
            <h4 class="vacancy-title d-none d-md-block mb-2 mb-md-1">
              <strong>{{ vacancy.title }}</strong>
            </h4>
            <h5 class="vacancy-title d-md-none mb-2 mb-md-1">
              <strong>{{ vacancy.title }}</strong>
            </h5>
          </b-link>
          <div v-if="isDraft" class="pr-3 vacancy-draft d-none d-md-block">
            <small>Concept</small>
          </div>

          <div class="d-flex wrap-nowrap d-md-none">
            <div
              class="d-inline-flex flex-row align-items-center vacancy-prospects"
              v-b-tooltip.hover
              :title="tooltip.prospects"
              :class="{ 'not-zero': vacancy.prospectCount > 0 }"
            >
              <i class="fa fa-user mr-1" />
              <span>{{ vacancy.prospectCount }}</span>
              <b-badge
                v-show="newProspects > 0"
                pill
                :to="{
                  name: 'vacancySelectionRound',
                  params: {
                    vacancyId: vacancy.vacancyId,
                    roundId: vacancy.latestRoundId
                  }
                }"
                variant="secondary"
                class="ml-2 -new-prospects"
                >+{{ newProspects }}</b-badge
              >
            </div>
            <div
              class="d-inline-flex flex-row align-items-center vacancy-todos -everyone ml-4"
              v-b-tooltip.hover
              :title="tooltip.todos.everyone"
              :class="{ 'not-zero': pendingPublicTodoCount > 0 }"
            >
              <div>
                <todo-everyone-icon
                  class="mr-1 everyone-icon"
                  :class="{ 'not-zero': pendingPublicTodoCount > 0 }"
                />
              </div>
              <span>{{ pendingPublicTodoCount }}</span>
            </div>
            <div
              class="d-inline-flex flex-row align-items-center vacancy-todos -myself ml-4"
              v-b-tooltip.hover
              :title="tooltip.todos.myself"
              :class="{ 'not-zero': pendingPrivateTodoCount > 0 }"
            >
              <div>
                <todo-myself-icon
                  class="mr-1 myself-icon"
                  :class="{ 'not-zero': pendingPrivateTodoCount > 0 }"
                />
              </div>
              <span class="mr-2">{{ pendingPrivateTodoCount }}</span>
            </div>
          </div>
        </div>
        <div class="vacancy-tenant">{{ vacancy.tenantName }}</div>
      </div>
      <div
        class="vacancy-item__right ml-4 d-flex flex-column align-items-end justify-content-between"
      >
        <div class="top-row d-flex flex-column align-items-end d-md-none">
          <div class="text-nowrap">
            <strong class="vacancy-id">{{ vacancy.vacancyId }}</strong>
            <vacancy-status-icon class="ml-2" :vacancy="vacancy" />
          </div>
          <div v-if="isDraft" class="vacancy-draft d-block d-md-none">
            <small>Concept</small>
          </div>
        </div>

        <div class="top-row d-none d-md-flex wrap-nowrap">
          <div
            class="d-inline-flex flex-row align-items-center vacancy-prospects"
            v-b-tooltip.hover
            :title="tooltip.prospects"
            :class="{ 'not-zero': vacancy.prospectCount > 0 }"
          >
            <i class="fa fa-user mr-1" />
            <span>{{ vacancy.prospectCount }}</span>
            <b-badge
              v-show="newProspects > 0"
              pill
              :to="{
                name: 'vacancySelectionRound',
                params: {
                  vacancyId: vacancy.vacancyId,
                  roundId: vacancy.latestRoundId
                }
              }"
              variant="secondary"
              class="ml-2 -new-prospects"
              >+{{ newProspects }}</b-badge
            >
          </div>
          <div
            class="d-inline-flex flex-row align-items-center vacancy-todos -everyone ml-4"
            v-b-tooltip.hover
            :title="tooltip.todos.everyone"
            :class="{ 'not-zero': pendingPublicTodoCount > 0 }"
          >
            <div>
              <todo-everyone-icon
                class="mr-1 everyone-icon"
                :class="{ 'not-zero': pendingPublicTodoCount > 0 }"
              />
            </div>
            <span>{{ pendingPublicTodoCount }}</span>
          </div>
          <div
            class="d-inline-flex flex-row align-items-center vacancy-todos -myself ml-4"
            v-b-tooltip.hover
            :title="tooltip.todos.myself"
            :class="{ 'not-zero': pendingPrivateTodoCount > 0 }"
          >
            <div>
              <todo-myself-icon
                class="mr-1 myself-icon"
                :class="{ 'not-zero': pendingPrivateTodoCount > 0 }"
              />
            </div>
            <span class="mr-2">{{ pendingPrivateTodoCount }}</span>
          </div>
          <span class="ml-3 text-nowrap">
            <vacancy-status-icon :vacancy="vacancy" />
            <strong class="vacancy-id ml-1">{{ vacancy.vacancyId }}</strong>
          </span>
        </div>
        <div class="bottom-row">
          <span
            class="vacancy-creation-date text-nowrap"
            v-b-tooltip.hover
            :title="tooltip.createDate"
            >{{ vacancy.dateCreated | formatDate }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import VacancyStatusIcon from '../vacancy/vacancy-status-icon'
import TodoMyselfIcon from './todo-myself-icon'
import TodoEveryoneIcon from './todo-everyone-icon'

import { PENDING_TODO_COUNT_BY_VACANCY_ID } from '../../store/keys-getters'

export default {
  name: 'OverviewVacancyListItem',
  components: {
    VacancyStatusIcon,
    TodoMyselfIcon,
    TodoEveryoneIcon
  },
  data () {
    return {
      tooltip: {
        prospects: 'Aantal sollicitanten',
        todos: {
          myself: 'Mijn to-dos',
          everyone: 'To-dos van iedereen'
        },
        createDate: 'Aanmaak datum'
      }
    }
  },
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  },
  computed: {
    isDraft () {
      return (
        this.vacancy.status === 'CREATED' || this.vacancy.status === 'SUBMITTED'
      )
    },
    newProspects () {
      return (
        this.vacancy.prospectCount -
        this.vacancy.prospectsViewedByCurrentUserCount
      )
    },
    pendingPrivateTodoCount () {
      return this.$store.getters[PENDING_TODO_COUNT_BY_VACANCY_ID][
        this.vacancy.vacancyId
      ].private.total
    },
    pendingPublicTodoCount () {
      return this.$store.getters[PENDING_TODO_COUNT_BY_VACANCY_ID][
        this.vacancy.vacancyId
      ].public.total
    }
  },
  filters: {
    formatDate (date) {
      return moment
        .utc(date)
        .local()
        .format('L')
    },
    formatPublicationScope (scope) {
      if (scope === 'EXTERNAL') {
        return 'Extern'
      }
      if (scope === 'INTERNAL') {
        return 'Intern'
      }
      if (scope === 'REGIONAL') {
        return 'Regionaal'
      }
      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.vacancy-item__container {
  .vacancy-item__right {
    flex-basis: 37%;
  }
  .vacancy {
    &-draft {
      text-transform: uppercase;
      color: #f68b1e;
    }
    &-tenant {
      color: #9f9f9f !important;
      font-size: 16px;
    }
    &-id {
      color: #9f9f9f !important;
    }
    &-prospects {
      color: #d4d4d4;
      .-new-prospects {
        color: #727272;
        font-weight: bold;
        font-size: 14px;
      }
    }
    &-todos {
      color: #d4d4d4;
      .everyone-icon,
      .myself-icon {
        opacity: 0.5;
      }
    }
    &-creation-date {
      font-size: 16px;
      color: #9f9f9f !important;
    }
  }
}
.not-zero {
  font-weight: bold;
  color: #9f9f9f !important;
  opacity: 1 !important;
}
</style>
