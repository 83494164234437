<template>
  <b-card bg-variant="light" no-body class="row border-0">
    <div class="pt-5">
      <template v-if="hasAttachments">
        <div
          v-for="attachment in attachments"
          :key="`pdf-${attachment.attachmentId}`"
          class="p-1"
        >
          <object
            v-if="attachment.convertedPdfUrl || attachment.fileExtension === 'pdf'"
            style="width: 100%; height: 100vh"
            :data="attachment.convertedPdfUrlLocal || attachment.urlLocal"
            type="application/pdf"
          >
            Installeer een pdf viewer in uw browser om document voorbeelden te kunnen zien.
          </object>
        </div>
      </template>
      <template>
        <h5>
          <strong>Documenten</strong>
        </h5>
        <div
          v-if="hasPermissionToUploadAttachment"
          class="w-100 d-flex align-items-start pt-3 pb-3"
        >
          <b-form-file
            v-validate="'size:10000'"
            data-vv-as="Bestand"
            v-model="selectedAttachment"
            name="attachment"
            class="ml-1"
            browse-text="Bladeren"
            placeholder="Geen bestand gekozen"
          />
          <b-btn
            class="ml-3"
            variant="primary"
            :disabled="!selectedAttachment"
            @click="$emit('uploadAttachments', selectedAttachment)"
            >Uploaden</b-btn
          >
        </div>
        <div v-show="errors.has('attachment')">
          {{ errors.first('attachment') }}
        </div>
        <div v-if="!hasAttachments">(Geen documenten gevonden)</div>
        <div v-if="hasAttachments" class="ml-1 d-flex flex-wrap">
          <div
            v-for="attachment in attachments"
            :key="`button-${attachment.attachmentId}`"
            class="mr-2 mb-2"
          >
            <b-btn
              variant="link"
              :href="attachment.urlLocal"
              target="_blank"
              class="border border-medium-gray bg-white"
              :class="{ 'border-right-0': hasPermissionToUploadAttachment }"
              download
              >{{ attachment.fileName }}.{{ attachment.fileExtension }}</b-btn
            >
            <b-btn
              v-if="hasPermissionToUploadAttachment"
              variant="white"
              @click="$emit('deleteAttachment', attachment)"
              class="border border-left-0 border-medium-gray bg-white"
            >
              <i class="fa fa-trash-o" />
            </b-btn>
          </div>
        </div>
      </template>
    </div>
  </b-card>
</template>
<script>
import isEmpty from 'lodash/isEmpty'
import { EDIT_SELECTION_COMMITEE_INTERFACE } from '../../constants/permissions'

export default {
  name: 'prospect-detail-documents',
  props: {
    attachments: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selectedAttachment: null
    }
  },
  computed: {
    hasAttachments () {
      return !isEmpty(this.attachments)
    },
    hasPermissionToUploadAttachment () {
      return (
        !this.hasPermission(EDIT_SELECTION_COMMITEE_INTERFACE) &&
        !this.$route.meta.noAuthorizationNeeded &&
        this.attachments.length < 4
      )
    }
  }
}
</script>
