<template>
  <b-card class="row p-5" no-body>
    <h2 class="pb-3">Vaardigheden en overige informatie</h2>
    <div
      v-if="currentProspect.languages && currentProspect.languages.length"
      class="border-bottom border-medium-gray"
    >
      <h4><strong>Talenkennis</strong></h4>
      <div
        v-for="(language, languageKey) in groupedLanguages"
        :key="languageKey"
        class="pb-3"
      >
        <strong>{{ languageKey }}: </strong>
        <div
          v-for="(level, levelKey) in language"
          :key="`${languageKey}-${levelKey}`"
        >
          <span v-for="(skill, index) in level" :key="index">
            {{ skill.skill | languageSkillFilter
            }}<span v-if="index < level.length - 1">, </span>
          </span>
          {{ levelKey | languageLevelFilter }}
        </div>
      </div>
    </div>
    <div
      v-if="showDrivingInformation"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h4><strong>Rijbewijs</strong></h4>
      <div v-if="currentProspect.driverLicenseType">
        In bezit van rijbewijs {{ currentProspect.driverLicenseType }}
      </div>
      <div v-if="currentProspect.driverLicenseRemark">
        {{ currentProspect.driverLicenseRemark }}
      </div>
      <div>
        {{
          currentProspect.haveACar
            ? "Kan beschikken over een auto"
            : "Kan niet beschikken over een auto"
        }}
      </div>
    </div>
    <div
      v-if="
        currentProspect.resumeAdditionalInfos &&
          currentProspect.resumeAdditionalInfos.length
      "
      class="pt-3 border-bottom border-medium-gray"
    >
      <div
        v-for="(category, key) in groupedResumeAdditionalInfo"
        :key="key"
        class="pb-3"
      >
        <h4>
          <strong>{{ key }} </strong>
        </h4>
        <div v-for="skill in category" :key="skill.id">
          {{ skill.description }}
        </div>
      </div>
    </div>
    <div
      v-if="hasCandidateWishes"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h4><strong>Wensen</strong></h4>
      <div class="container ml-0 pl-0">
        <div class="d-flex flex-column">
          <div
            v-if="
              currentProspect.candidateWishes.disciplines &&
                currentProspect.candidateWishes.disciplines.length
            "
            class="d-flex"
          >
            <div class="w-25">Vakgebied:</div>
            <div class="w-75">
              <span
                v-for="(discipline, index) in currentProspect.candidateWishes
                  .disciplines"
                :key="discipline"
              >
                {{ discipline | disciplineFilter
                }}<span
                  v-if="
                    index <
                      currentProspect.candidateWishes.disciplines.length - 1
                  "
                  >,
                </span>
              </span>
            </div>
          </div>
          <div
            v-if="
              currentProspect.candidateWishes.jobTypes &&
                currentProspect.candidateWishes.jobTypes.length
            "
            class="d-flex"
          >
            <div class="w-25">Dienstverband:</div>
            <div class="w-75">
              <span
                v-for="(type, index) in currentProspect.candidateWishes
                  .jobTypes"
                :key="type"
              >
                {{ type | jobTypeFilter
                }}<span
                  v-if="
                    index < currentProspect.candidateWishes.jobTypes.length - 1
                  "
                  >,
                </span>
              </span>
            </div>
          </div>
          <div
            v-if="
              currentProspect.candidateWishes.jobCategories &&
                currentProspect.candidateWishes.jobCategories.length
            "
            class="d-flex"
          >
            <div class="w-25">Functiecategorie:</div>
            <div class="w-75">
              <span
                v-for="(category, index) in currentProspect.candidateWishes
                  .jobCategories"
                :key="category"
              >
                {{ category | jobCategoryFilter
                }}<span
                  v-if="
                    index <
                      currentProspect.candidateWishes.jobCategories.length - 1
                  "
                  >,
                </span>
              </span>
            </div>
          </div>
          <div
            v-if="
              currentProspect.candidateWishes.regions &&
                currentProspect.candidateWishes.regions.length
            "
            class="d-flex"
          >
            <div class="w-25">Regio's:</div>
            <div class="w-75">
              <span
                v-for="(region, index) in currentProspect.candidateWishes
                  .regions"
                :key="region"
              >
                {{ region
                }}<span
                  v-if="
                    index < currentProspect.candidateWishes.regions.length - 1
                  "
                  >,
                </span>
              </span>
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.workingHoursPerWeek"
            class="d-flex"
          >
            <div class="w-25">Aantal uren per week:</div>
            <div class="w-75">
              {{ currentProspect.candidateWishes.workingHoursPerWeek }}
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.maxDistanceFromWorkPlace"
            class="d-flex"
          >
            <div class="w-25">Afstand woning-werk:</div>
            <div class="w-75">
              <span>
                {{ currentProspect.candidateWishes.maxDistanceFromWorkPlace
                }}{{
                  currentProspect.candidateWishes.isPossibleToRelocateForNewJob
                    ? ", het is voor mij mogelijk om voor een nieuwe baan te verhuizen"
                    : ", het is voor mij niet mogelijk om voor een nieuwe baan te verhuizen"
                }}
              </span>
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.organizationSize"
            class="d-flex"
          >
            <div class="w-25">Omvang van de organisatie:</div>
            <div class="w-75">
              {{ currentProspect.candidateWishes.organizationSize }}
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.perfectOrganisation"
            class="d-flex"
          >
            <div class="w-25">Gewenste type organisatie:</div>
            <div class="w-75">
              {{ currentProspect.candidateWishes.perfectOrganisation }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="currentProspect.candidateWishes.perfectJobDescription"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h4><strong>Omschrijving perfecte baan</strong></h4>
      <div>{{ currentProspect.candidateWishes.perfectJobDescription }}</div>
    </div>
    <div
      v-if="showWorkingConditions"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h4><strong>Arbeidsvoorwaarden</strong></h4>
      <div class="container ml-0 pl-0">
        <div class="d-flex flex-column">
          <div v-if="currentProspect.candidateWishes.lastSalary" class="d-flex">
            <div class="w-25">Huidig salaris:</div>
            <div class="w-75">
              &euro; {{ currentProspect.candidateWishes.lastSalary }},00
              {{
                currentProspect.candidateWishes.lastSalaryPeriod
                  | salaryPeriodFilter
              }}
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.desiredSalary"
            class="d-flex"
          >
            <div class="w-25">Gewenst salaris:</div>
            <div class="w-75">
              &euro; {{ currentProspect.candidateWishes.desiredSalary }},00
              {{
                currentProspect.candidateWishes.desiredSalaryPeriod
                  | salaryPeriodFilter
              }}
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.jobBenefits"
            class="d-flex"
          >
            <div class="w-25">Secundaire arbeidsvoorwaarden:</div>
            <div class="w-75">
              {{ currentProspect.candidateWishes.jobBenefits }}
            </div>
          </div>
          <div
            v-if="currentProspect.candidateWishes.jobBenefitsRemark"
            class="d-flex"
          >
            <div class="w-25">Opmerking:</div>
            <div class="w-75">
              {{ currentProspect.candidateWishes.jobBenefitsRemark }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="
        hasCandidateWishes && currentProspect.candidateWishes.otherPreferences
      "
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h3>Overige wensen</h3>
      <div>{{ currentProspect.candidateWishes.otherPreferences }}</div>
    </div>
    <div
      v-if="hasCandidateWishes && currentProspect.candidateWishes.availableFrom"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h3>Beschikbaarheid</h3>
      <div v-if="currentProspect.candidateWishes.lastSalary" class="d-flex">
        <div class="w-25">Beschikbaar per:</div>
        <div class="w-75">
          {{
            currentProspect.candidateWishes.availableFrom
              | availablePeriodFilter
          }}
        </div>
      </div>
    </div>
    <div
      v-if="currentProspect.competences && currentProspect.competences.length"
      class="pt-3 pb-3 border-bottom border-medium-gray"
    >
      <h3>Competenties</h3>
      <div v-if="currentProspect.competences.length" class="d-flex">
        <div class="w-75">
          <span
            v-for="(competence, index) in currentProspect.competences"
            :key="competence"
          >
            {{ competence | disciplineFilter
            }}<span v-if="index < currentProspect.competences.length - 1"
              >,
            </span>
          </span>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import disciplineLabels from '../../constants/labels/disciplines'
import jobTypeLabels from '../../constants/labels/job-types'
import jobCategoryLabels from '../../constants/labels/job-categories'
import salaryPeriodLabels from '../../constants/labels/salary-period'
import availablePeriodLabels from '../../constants/labels/available-period'
import languageSkillLabels from '../../constants/labels/language-skills'
import languageLevelLabels from '../../constants/labels/language-level'

export default {
  props: {
    currentProspect: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    groupedLanguages () {
      return mapValues(groupBy(this.currentProspect.languages, 'name'), (language) => groupBy(language, 'level'))
    },
    groupedResumeAdditionalInfo () {
      return groupBy(this.currentProspect.resumeAdditionalInfos, 'title')
    },
    hasCandidateWishes () {
      return some(omit(this.currentProspect.candidateWishes, 'id'), (value) => {
        if (value instanceof Array) {
          return !isEmpty(value)
        }
        return !!value
      })
    },
    showDrivingInformation () {
      return (
        this.currentProspect.haveDriverLicense || this.currentProspect.driverLicenseRemark || this.currentProspect.driverLicenseType || this.currentProspect.haveACar
      )
    },
    showWorkingConditions () {
      return (
        this.hasCandidateWishes && (this.currentProspect.candidateWishes.lastSalary || this.currentProspect.candidateWishes.desiredSalary || this.currentProspect.candidateWishes.jobBenefits || this.currentProspect.candidateWishes.jobBenefitsRemark)
      )
    }
  },
  filters: {
    languageSkillFilter (level) {
      return languageSkillLabels[level] || ''
    },
    languageLevelFilter (skill) {
      return languageLevelLabels[skill] || ''
    },
    salaryPeriodFilter (period) {
      return salaryPeriodLabels[period]
    },
    availablePeriodFilter (period) {
      return availablePeriodLabels[period]
    },
    disciplineFilter (discipline) {
      return disciplineLabels[discipline] || ''
    },
    jobTypeFilter (type) {
      return jobTypeLabels[type] || ''
    },
    jobCategoryFilter (category) {
      return jobCategoryLabels[category] || ''
    }
  }
}
</script>
