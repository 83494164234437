<template>
  <div
    class="px-4 py-4 d-flex flex-column alternating-color-list border-left border-right border-bottom border-light-gray"
  >
    <div class="d-flex align-items-center w-100">
      <b-form-checkbox
        v-if="!viewerIsExternal && isLatestRound"
        v-model="isChecked"
        :value="true"
        :unselected-value="false"
        @input="$emit('input', isChecked)"
        plain
      ></b-form-checkbox>
      <div class="mr-4 ml-2 d-none d-md-block prospect-avatar__container">
        <b-link
          :key="prospect.prospectId"
          :to="{
            name: 'vacancyProspectDetail',
            params: {
              vacancyId: this.vacancy.vacancyId,
              prospectId: this.prospect.prospectId,
              roundId: this.$route.params.roundId || this.vacancy.latestRoundId
            }
          }"
          @click="$emit('setRatingTypeForProspectNavigation')"
          class="d-flex flex-column avatar-link"
        >
          <avatar v-if="!prospect.avatarUrl" :fullname="fullname" />
          <div
            v-if="prospect.avatarUrl"
            class="d-flex align-items-center justify-content-around"
            style="width: 96px; height: 96px; overflow: hidden;"
          >
            <img
              v-if="prospect.avatarUrl"
              :src="prospect.avatarUrlLocal"
              class="img-thumbnail p-0 border-0 bg-transparent"
            />
          </div>
          <div class="avatar-link--underline" />
        </b-link>
      </div>
      <div class="d-flex flex-column ml-3 ml-md-0 prospect-details__container">
        <div v-if="!viewerIsExternal" class="d-flex align-items-center">
          <div>
            <h5>
              <strong>
                <b-link
                  :to="{
                    name: 'vacancyProspectDetail',
                    params: {
                      vacancyId: this.vacancy.vacancyId,
                      prospectId: this.prospect.prospectId,
                      roundId:
                        this.$route.params.roundId || this.vacancy.latestRoundId
                    }
                  }"
                  @click="$emit('setRatingTypeForProspectNavigation')"
                >
                  {{ fullname }}
                </b-link>
              </strong>
              <small class="ml-1" v-if="prospectIsNew">
                <i class="fa fa-circle text-new" />
              </small>
            </h5>
          </div>
          <b-btn
            v-if="isLatestRound"
            :id="`popover${tabDetails.title}${prospect.prospectId}`"
            size="lg"
            variant="link"
            class="text-black mb-1 p-0 ml-2"
          >
            <i class="fa fa-pencil-square-o text-medium-gray" />
          </b-btn>
          <b-popover
            v-if="isLatestRound"
            :target="`popover${tabDetails.title}${prospect.prospectId}`"
            triggers="focus"
            placement="bottom"
          >
            <template slot="title">
              Notities &amp; to-do's
            </template>
            <template v-for="notification in prospectNotifications">
              <notification-list-item
                style="width: 200px;"
                v-if="notification.title"
                :key="notification.entityId"
                :notification="notification"
                :speechBubble="false"
              />
            </template>
            <div
              v-if="
                !prospect.notifications || prospect.notifications.length === 0
              "
            >
              Op dit moment nog geen activiteiten.
            </div>
          </b-popover>
        </div>
        <div v-if="candidateSource">{{ candidateSource }}</div>
        <div v-if="applicationDate">{{ applicationDate }}</div>
        <div class="d-flex text-medium-gray">
          <b-link
            v-if="isLatestRound"
            @click="
              $emit('sendEmail', 'general', {
                to: fullname,
                prospectId: prospect.prospectId,
                noSelectedNeeded: true
              })
            "
            class="text-medium-gray mr-2"
            variant="link"
          >
            <i class="fa fa-envelope" />
          </b-link>
          <div
            v-if="
              isLatestRound && (prospect.phoneNumber || prospect.mobileNumber)
            "
          >
            |
          </div>
          <b-link
            class="text-medium-gray"
            :class="{ 'ml-2': isLatestRound }"
            :href="
              `tel:${
                prospect.phoneNumber
                  ? prospect.phoneNumber
                  : prospect.mobileNumber
              }`
            "
          >
            <strong>
              {{
                prospect.phoneNumber
                  ? prospect.phoneNumber
                  : prospect.mobileNumber
              }}
            </strong>
          </b-link>
        </div>
      </div>
      <div
        v-if="prospectJudgements.length"
        class="d-none d-lg-flex mr-4 ml-4 flex-column justify-content-between prospect-judgements__wrapper"
      >
        <span
          ><strong>Selectiecommissie: </strong
          ><b-link @click.prevent="showProspectJudgementsExplanations"
            >toelichtingen</b-link
          ></span
        >
        <div class="prospect-judgements__container">
          <div
            v-for="(judgement, index) in prospectJudgements"
            :key="index"
            class="p-2"
            :class="
              index < prospectJudgements.length - 1
                ? 'border-bottom border-medium-light'
                : ''
            "
          >
            <div class="d-flex justify-content-between">
              <div>
                <strong>{{ judgement.fullname }}</strong>
              </div>
              <div><i :class="icons[judgement.score]" /></div>
            </div>
            {{ judgement.comment }}
          </div>
        </div>
      </div>
      <div v-else class="prospect-judgements__placeholder"></div>
      <div
        class="ml-auto d-flex justify-content-between prospect-status__container"
        :class="{ 'ml-auto': !prospectJudgements.length }"
      >
        <div
          v-if="prospectJudgements.length"
          class="d-none d-md-flex d-lg-none ml-3 mr-4 flex-column justify-content-center"
        >
          <strong class="text-center">Selectiecommissie</strong>
          <b-btn
            @click="showProspectJudgementsExplanations"
            variant="medium-gray"
            class="mb-3 border border-medium-gray bg-white"
            >toelichtingen</b-btn
          >
        </div>
        <div class="ml-2" v-if="tabDetails.options">
          <label for="status">
            <strong>Status</strong>
          </label>
          <b-form-select
            @input="
              $emit('saveNewStatus', {
                status,
                prospectId: prospect.prospectId
              })
            "
            name="status"
            v-model="status"
            class="mb-3"
          >
            <template v-for="(option, key) in tabDetails.options">
              <option
                v-if="option.displayForApplicants || isCandidate"
                :key="`${key} - ${option.value}`"
                :value="option.value"
                :disabled="option.disabled"
                >{{ option.text }}</option
              >
            </template>
          </b-form-select>
        </div>
        <div
          class="ml-2"
          v-if="!tabDetails.displayRatingButtons && !tabDetails.options"
        >
          <div>Laatste beoordeling:</div>
          <div>
            <strong>{{ prospect.recruiterRating | formatRating }}</strong>
          </div>
          <div>Laatste status:</div>
          <div>
            <strong>{{ prospect.recruiterStatus | formatStatus }}</strong>
          </div>
        </div>
        <div
          class="ml-auto"
          v-if="tabDetails.displayRatingButtons && isLatestRound"
        >
          <prospect-rating-buttons
            @changeRating="changeRating"
            :ratingButtons="ratingButtons"
            direction="column"
            class="flex-column"
            :class="prospectJudgements.length ? 'flex-xl-row' : 'flex-lg-row'"
            style="width: max-content;"
          />
        </div>
      </div>
    </div>
    <div v-if="prospectJudgements.length" class="d-block d-sm-none mt-2 ml-4">
      <strong>Selectiecommissie:</strong>
      <b-link @click.prevent="showProspectJudgementsExplanations">
        toelichtingen</b-link
      >
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Avatar from '../../avatar'
import NotificationListItem from '../../notification-list/notification'
import ProspectRatingButtons from '../../prospect-details-components/prospect-rating-buttons'
import { CANDIDATETYPE_CANDIDATE } from '../../../constants/candidate-type'
import {
  PROSPRATING_UNRATED,
  PROSPRATING_SELECTED,
  PROSPRATING_RESERVED,
  PROSPRATING_DECLINED
} from '../../../constants/prospect/prospect-recruiter-rating'
import {
  PROPRECSTATUS_NOT_INTERESTED,
  PROPRECSTATUS_INVITED,
  PROPRECSTATUS_WITHDRAWN,
  PROPRECSTATUS_REJECTED,
  PROPRECSTATUS_INTERESTED,
  PROPRECSTATUS_RESERVED,
  PROPRECSTATUS_HIRED
} from '../../../constants/prospect/prospect-recruiter-status'
import { VIEW_EXTERNALLY } from '../../../constants/permissions'
import modal from '../../../services/modal'
import ProspectJudgementsModal from '../../prospect-details-components/prospect-judgements-modal'
import map from 'lodash/map'
import find from 'lodash/find'

export default {
  components: {
    Avatar,
    NotificationListItem,
    ProspectRatingButtons
  },
  props: {
    prospect: {
      type: Object,
      default: () => ({})
    },
    tabDetails: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Boolean,
      default: false
    },
    vacancy: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    },
    users: {
      type: Array,
      default: () => []
    },
    isLatestRound: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      status: this.prospect.recruiterStatus,
      isChecked: this.value
    }
  },
  computed: {
    fullname () {
      const { firstName, middleName, lastName } = this.prospect
      let name = firstName
      if (middleName) name += ` ${middleName}`
      return `${name} ${lastName}`
    },
    applicationDate () {
      return moment(this.prospect.createDate).format('DD MMM YYYY')
    },
    candidateSource () {
      const map = {
        CHANNEL_CAT_HIDDEN: 'ONE sollicitant',
        CHANNEL_CAT_EXTERNAL: 'Externe sollicitant',
        CHANNEL_CAT_REGIONAL: 'Regionale sollicitant',
        CHANNEL_CAT_INTERNAL: 'Interne sollicitant'
      }
      return map[(this.prospect.publishChannel || {}).channelCat] || ''
    },
    isCandidate () {
      return this.prospect.prospectType === CANDIDATETYPE_CANDIDATE
    },
    viewerIsExternal () {
      return this.hasPermission(VIEW_EXTERNALLY)
    },
    prospectIsNew () {
      return !this.viewerIsExternal && !this.prospect.isViewed
    },
    prospectNotifications () {
      return (
        this.prospect &&
        map(this.prospect.notifications, notification => {
          const userInfo = find(
            this.users,
            user => user.username === notification.selectorName
          )
          return { ...notification, selectorName: (userInfo || {}).fullName }
        })
      )
    },
    prospectJudgements () {
      return (
        this.prospect &&
        map(this.prospect.judgements, judgement => {
          const userInfo = find(
            this.users,
            user => user.username === judgement.selectionCommitteeMember
          )
          return {
            fullname: (userInfo || {}).fullName,
            comment: judgement.comment,
            score: judgement.currentJudgementScore
          }
        })
      )
    },
    icons () {
      return {
        1: 'fa fa-check text-success',
        0: 'fa fa-question text-dark',
        '-1': 'fa fa-times text-danger'
      }
    },
    ratingButtons () {
      return {
        [PROSPRATING_SELECTED]: {
          variant: 'link',
          name: 'Door',
          icon: 'fa fa-check-square-o',
          classes: 'text-left mb-1 bg-transparent border-0',
          value: 'selected',
          size: 'md'
        },
        [PROSPRATING_RESERVED]: {
          variant: 'link',
          name: 'Reserve',
          icon: 'fa fa-clock-o',
          classes: 'text-left mb-1 bg-transparent border-0',
          value: 'reserved',
          size: 'md'
        },
        [PROSPRATING_DECLINED]: {
          variant: 'link',
          name: 'Niet door',
          icon: 'fa fa-times',
          classes: 'text-left mb-1 bg-transparent border-0',
          value: 'declined',
          size: 'md'
        }
      }
    }
  },
  watch: {
    value () {
      this.isChecked = this.value
    },
    prospect: {
      handler: function () {
        this.status = this.prospect.recruiterStatus
      },
      deep: true
    }
  },
  methods: {
    changeRating (value) {
      this.$emit('changeRating', [this.prospect.prospectId], value)
    },
    showProspectJudgementsExplanations () {
      modal(ProspectJudgementsModal, {
        title: `Selectiecommissie toelichtingen voor ${this.fullname}`,
        icons: this.icons,
        judgements: this.prospectJudgements
      })
    }
  },
  filters: {
    formatRating (rating) {
      const map = {
        [PROSPRATING_UNRATED]: 'Nog niet beoordeeld',
        [PROSPRATING_DECLINED]: 'Niet door',
        [PROSPRATING_RESERVED]: 'Reserve',
        [PROSPRATING_SELECTED]: 'Door'
      }
      return map[rating]
    },
    formatStatus (status) {
      const map = {
        [PROPRECSTATUS_NOT_INTERESTED]: 'Niet geïnteresseerd',
        [PROPRECSTATUS_INVITED]: 'Uitgenodigd',
        [PROPRECSTATUS_WITHDRAWN]: 'Teruggetrokken',
        [PROPRECSTATUS_REJECTED]: 'Afgewezen',
        [PROPRECSTATUS_INTERESTED]: 'Geïnteresseerd',
        [PROPRECSTATUS_RESERVED]: 'In reserve gehouden',
        [PROPRECSTATUS_HIRED]: 'Aangesteld'
      }
      return map[status] || 'Geen'
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-link--underline {
  display: none;
  position: relative;
  margin-top: -2px;
  width: 100%;
  height: 2px;
  background-color: #1591a1;
  z-index: 1;
}
.v a.avatar-link:hover {
  text-decoration: none;
  .avatar-link--underline {
    display: block;
  }
}
.prospect- {
  &details__container {
    flex-basis: 20%;
  }
  &status__container {
    // flex-basis: 30%;
  }
  &judgements__wrapper {
  width: 400px;
  height: 130px;
  flex-grow: 1;
  .prospect-judgements__container {
    height: 100%;
    overflow: auto;
    background-color: #f1f5f9; // Tailwind'slate-100
  }
}
}
</style>
