<template>
  <b-input-group class="mb-3 mr-1">
    <b-input-group-prepend>
      <div
        class="d-flex align-items-center justify-content-center"
        style="background-color: #fff; border: 1px solid #ced4da; border-right: none; min-width: 40px;"
      >
        <i class="fa fa-search" style="color: #bfbfbf" />
      </div>
    </b-input-group-prepend>
    <b-form-input
      style="border-right: none; border-left: none"
      v-model="privateValue"
    />
    <b-input-group-append>
      <b-btn
        style="background-color: #fff; border: 1px solid #ced4da; border-left: none;"
        @click="privateValue = ''"
      >
        <i class="fa fa-times" style="color: #bfbfbf" />
      </b-btn>
    </b-input-group-append>
  </b-input-group>
</template>
<script>
export default {
  props: {
    value: String
  },
  data () {
    return {
      privateValue: this.value
    }
  },
  watch: {
    privateValue () {
      this.$emit('input', this.privateValue)
    },
    value () {
      this.privateValue = this.value
    }
  },
  methods: {
    reset () {
      this.privateValue = ''
      this.$refs.input.focus()
    }
  }
}
</script>
