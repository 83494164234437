<template>
  <b-modal
    centered
    title="Bewerken sollicitant"
    :visible="true"
    :ok-disabled="false"
    @ok="save"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    ok-title="Opslaan"
    size="lg"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div class="d-flex justify-content-between pb-2">
      <label for="firstname">Voornaam*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('prospectFirstname') }"
          v-validate="'required'"
          data-vv-as="Voornaam"
          v-model="firstname"
          type="text"
          name="firstname"
        />
        <div v-show="errors.has('firstname')" class="text-error">
          {{ errors.first("firstname") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="middleName">Tussenvoegels</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          v-model="middleName"
          type="text"
          name="prospectInitials"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="lastname">Achternaam*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('lastname') }"
          v-validate="'required'"
          data-vv-as="Achternaam"
          v-model="lastname"
          type="text"
          name="lastname"
        />
        <div v-show="errors.has('lastname')" class="text-error">
          {{ errors.first("lastname") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="dateOfBirth">Geboortedatum</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <datepicker
          v-model="dateOfBirth"
          placeholder="DD-MM-YYYY"
          :language="datepickerLanguage.nl"
          name="dateOfBirth"
          :ref="dateOfBirth"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="gender">Geslacht*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-radio-group
          v-model="gender"
          name="gender"
          v-validate="'required'"
          dataa-vv-as="Geslacht"
        >
          <b-form-radio value="GENDER_M">man</b-form-radio>
          <b-form-radio value="GENDER_F">vrouw</b-form-radio>
        </b-form-radio-group>
        <div v-show="errors.has('gender')" class="text-error">
          {{ errors.first("gender") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="address">Straat en huisnr.</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          v-model="address"
          type="text"
          name="address"
        />
      </div>
    </div>

    <div class="d-flex pb-2 align-items-center justify-content-between">
      <div class="d-flex flex-column align-items-start w-20">
        <label class="mb-0" for="postcode">Postcode en &nbsp;</label>
        <label for="city">plaats</label>
      </div>
      <div class="w-75 d-flex align-items-start">
        <input
          class="form-control w-20 mr-3"
          v-model="postcode"
          type="text"
          name="postcode"
        />
        <input class="form-control" v-model="city" type="text" name="city" />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="emailAddress">E-mail*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          :class="{ 'is-invalid': errors.has('emailAddress') }"
          v-validate="'required|email'"
          data-vv-as="E-mail"
          v-model="emailAddress"
          type="text"
          name="emailAddress"
        />
        <div v-show="errors.has('emailAddress')" class="text-error">
          {{ errors.first("emailAddress") }}
        </div>
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="email">Telefoon</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <input
          class="form-control"
          data-vv-as="Telefoon"
          v-model="phoneNumber"
          type="text"
          name="phoneNumber"
        />
      </div>
    </div>

    <div class="d-flex pb-2 justify-content-between">
      <label for="applicantTypeId">Sollicitant type</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-select
          name="applicantTypeId"
          v-model="applicantTypeId"
          class="mb-2"
        >
          <option
            v-for="type in applicantTypes"
            :key="type.id"
            :value="type.id"
            >{{ type.name }}</option
          >
        </b-form-select>
      </div>
    </div>

    <div
      class="d-flex justify-content-between"
      :class="source === 'SOURCE_OTHER' ? 'pb-3' : 'pb-2'"
    >
      <label for="gender">Bron*</label>
      <div class="w-75 d-flex flex-column align-items-start">
        <b-form-radio-group
          v-model="source"
          name="source"
          v-validate="'required'"
          data-vv-as="Bron"
        >
          <b-form-radio value="SOURCE_EMAIL">E-mail</b-form-radio>
          <b-form-radio value="SOURCE_OTHER">
            Anders
            <span v-if="source === 'SOURCE_OTHER'">, namelijk:</span>
          </b-form-radio>
        </b-form-radio-group>
        <b-form-input
          v-if="source === 'SOURCE_OTHER'"
          v-model="sourceText"
          name="sourceText"
          v-validate="'required'"
          data-vv-as="Bron uitleg"
        ></b-form-input>
        <div v-show="errors.has('source')" class="text-error">
          {{ errors.first("source") }}
        </div>
        <div v-show="errors.has('sourceText')" class="text-error">
          {{ errors.first("sourceText") }}
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'
import find from 'lodash/find'
import defaults from 'lodash/defaults'
import { CURRENT_PROSPECT } from '../../../store/keys-getters'

export default {
  name: 'EditProspectModal',
  components: {
    Datepicker
  },
  props: {
    applicantTypes: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      // avatarUrl: null,
      firstname: null,
      middleName: null,
      lastname: null,
      dateOfBirth: null,
      gender: null,
      address: null,
      postcode: null,
      city: null,
      emailAddress: null,
      phoneNumber: null,
      applicantTypeId: null,
      source: null,
      // attachments: null,
      sourceText: null
      // lCandidateType: null,
      // prospectId: null,
      // personID: null
    }
  },
  computed: {
    datepickerLanguage () {
      return {
        nl: nl
      }
    },
    defaultProspect () {
      return {
        applicantId: null,
        attachments: null,
        prospectId: null,
        firstname: null,
        lastname: null,
        gender: null,
        vacancyId: null,
        city: null,
        phoneNumber: null,
        emailAddress: null,
        middleName: null,
        dateOfBirth: null,
        address: null,
        postcode: null,
        cvAttachment: null,
        motivationAttachment: null,
        publishChannel: null,
        isSelected: false,
        age: null,
        motivation: null,
        currentJob: null,
        cvDescription: null,
        education: null,
        highestEducation: null,
        avatarUrl: null,
        lProspectType: null,
        matchingPcent: null,
        matchScore: null,
        applicationDate: null,
        prospectNotifications: null,
        nationality: null,
        maritalStatus: null,
        source: null,
        sourceText: null,
        lCandidateType: null,
        applicantTypeId: null
      }
    },
    applicantType () {
      const currentType = find(this.applicantTypes, ['id', this.applicantTypeId])
      return {
        applicantTypeId: String(currentType.id),
        applicantType: currentType.name
      }
    },
    currentProspect () {
      return this.$store.getters[CURRENT_PROSPECT] || {}
    },
    prospect () {
      return {
        ...this.defaultProspect,
        firstname: this.firstname,
        middleName: this.middleName,
        lastname: this.lastname,
        dateOfBirth: this.dateOfBirth,
        gender: this.gender,
        address: this.address,
        postcode: this.postcode,
        city: this.city,
        emailAddress: this.emailAddress,
        phoneNumber: this.phoneNumber,
        source: this.source,
        sourceText: this.sourceText,
        applicantTypeId: this.applicantTypeId,
        attachments: this.currentProspect.attachments,
        lCandidateType: this.currentProspect.lCandidateType,
        prospectId: this.currentProspect.prospectId,
        personID: this.currentProspect.personID
      }
    }
  },
  watch: {
    currentProspect () {
      this.assignCurrentProspectData()
    }
  },
  methods: {
    clearFiles (ref) {
      this.$refs[ref].reset()
    },
    save (evt) {
      evt.preventDefault()
      this.$validator.validate().then((isValid) => {
        if (!isValid) {
          return
        }
        this.$emit('resolve', defaults(this.applicantType, this.prospect))
        this.$emit('remove')
      })
    },
    assignCurrentProspectData () {
      this.firstname = this.currentProspect.firstname
      this.middleName = this.currentProspect.middleName
      this.lastname = this.currentProspect.lastname
      this.dateOfBirth = this.currentProspect.dateOfBirth
      this.gender = this.currentProspect.gender
      this.address = this.currentProspect.address
      this.postcode = this.currentProspect.postcode
      this.city = this.currentProspect.city
      this.emailAddress = this.currentProspect.emailAddress
      this.phoneNumber = this.currentProspect.phoneNumber
      this.applicantTypeId = this.currentProspect.applicantTypeId
      this.source = this.currentProspect.source
      this.sourceText = this.currentProspect.sourceText
    }
  },
  mounted () {
    this.assignCurrentProspectData()
  }
}
</script>
