<template>
  <b-modal
    centered
    size="lg"
    title="Nieuwe email"
    :visible="true"
    @ok="send"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    ok-title="E-mail versturen"
    cancel-title="Annuleren"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div class="pb-3 strong">
      Vraag om toestemming aan {{ to.length }}
      {{ to.length > 1 ? "sollicitanten" : "sollicitant" }}
    </div>

    <b-form-group label="Aan:">
      <b-form-input name="to" :disabled="true" :value="nameList">
      </b-form-input>
    </b-form-group>

    <b-form-group label="Onderwerp:">
      <b-form-input v-model="subject"> </b-form-input>
    </b-form-group>

    <!-- <b-form-group label="Text:">
      <p class="small text-muted">
        De woorden die in hoofdletters staan worden na versturen van deze e-mail
        <strong>automatisch ingevuld</strong> met de gegevens van de kandidaat,
        de link voor toestemming, jouw eigen naam en je handtekening.
      </p>
      <markdown-editor v-model="body" />
    </b-form-group> -->
  </b-modal>
</template>

<script>
// Note: Ewoud, 2021-01-31: Removed the markdown editor since it wasn't working on production for a long time anyways.
// Now consent emails can't be modified. This has the advantage of not having Markdown editor dependency.

import reduce from 'lodash/reduce'
import replace from 'lodash/replace'

const defaultBody = `Beste VOLLEDIGE_NAAM_KANDIDAAT,

Je hebt gesolliciteerd op een vacature bij onze organisatie. Graag zouden wij je gegevens langer willen bewaren. Hier hebben we jouw toestemming voor nodig.
Klik [hier](TOESTEMMING_LINK) om de bewaartermijn van jouw gegevens te beheren.

Vriendelijke groet,
VOLLEDIGE_NAAM_GEBRUIKER

GEBRUIKER_STANDAARD_HANDTEKENING`

export default {
  components: {
    // markdownEditor
  },
  props: {
    to: {
      required: true,
      type: Array
    }
  },
  data () {
    return {
      subject: 'Mogen wij je gegevens langer bewaren?',
      body: defaultBody
    }
  },
  computed: {
    nameList () {
      return this.to.join(', ')
    }
  },
  methods: {
    send () {
      const replaceMap = {
        TOESTEMMING_LINK: '${GIVE_CONSENT_LINK}', // eslint-disable-line no-template-curly-in-string
        VOLLEDIGE_NAAM_KANDIDAAT: '${FULLNAME_APPLICANT}', // eslint-disable-line no-template-curly-in-string
        VOLLEDIGE_NAAM_GEBRUIKER: '${FULLNAME_USER}', // eslint-disable-line no-template-curly-in-string
        GEBRUIKER_STANDAARD_HANDTEKENING: '${USER_DEFAULT_SIGNATURE}' // eslint-disable-line no-template-curly-in-string
      }

      const body = reduce(replaceMap, (result, repl, search) => replace(result, search, repl), this.body)
      this.$emit('resolve', {
        body,
        subject: this.subject
      })
    }
  }
}
</script>
