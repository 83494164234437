import Vue from 'vue'
import {
  LOAD_MIMIR_CHANNELS_DROPDOWNS,
  LOAD_PUBLICATION_CHANNELS,
  LOAD_PUBLICATION_CHANNELS_JOB_STRATEGIES,
  PUBLISH_VACANCY
} from '../../keys-actions'
import {
  MIMIR_CHANNELS_DROPDOWNS,
  PUBLICATION_CHANNELS,
  PUBLICATION_CHANNELS_JOB_STRATEGIES,
  VACANCY
} from '../../keys-getters'
import apiRequest from '../../../services/api-request'
import { stripHostFromAttachment } from '../../../services/attachment-data-cleaner'
import {
  SET_MIMIR_CHANNELS_DROPDOWNS,
  SET_PUBLICATION_CHANNELS,
  SET_PUBLICATION_CHANNELS_JOB_STRATEGIES
} from '../../keys-mutations'
import cloneDeep from 'lodash/cloneDeep'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import map from 'lodash/map'

export default {
  state: {
    publicationChannels: [],
    publicationChannelsJobStrategies: {},
    mimirDropdowns: {}
  },
  mutations: {
    [SET_PUBLICATION_CHANNELS]: (state, channels) =>
      (state.publicationChannels = cloneDeep(channels)),
    [SET_PUBLICATION_CHANNELS_JOB_STRATEGIES]: (
      state,
      { channelId, strategies }
    ) =>
      Vue.set(
        state.publicationChannelsJobStrategies,
        `${channelId}`,
        strategies
      ),
    [SET_MIMIR_CHANNELS_DROPDOWNS]: (
      state,
      { channelId, branch, functionName }
    ) =>
      Vue.set(state.mimirDropdowns, `${channelId}`, { branch, functionName })
  },
  getters: {
    [PUBLICATION_CHANNELS]: state =>
      groupBy(state.publicationChannels, channel => channel.channelCat),
    [PUBLICATION_CHANNELS_JOB_STRATEGIES]: state =>
      state.publicationChannelsJobStrategies,
    [MIMIR_CHANNELS_DROPDOWNS]: state => state.mimirDropdowns
  },
  actions: {
    [LOAD_PUBLICATION_CHANNELS]: (
      { dispatch, commit, getters },
      singleDispatch
    ) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/channels`
      }).then(data => {
        forEach(data, channel => {
          stripHostFromAttachment(channel, 'logoUrl')
        })

        commit(SET_PUBLICATION_CHANNELS, data)
        if (!singleDispatch) {
          dispatch(LOAD_PUBLICATION_CHANNELS_JOB_STRATEGIES, data)
          dispatch(LOAD_MIMIR_CHANNELS_DROPDOWNS, data)
        }
      })
    },
    [LOAD_PUBLICATION_CHANNELS_JOB_STRATEGIES]: (
      { commit, getters },
      channels
    ) => {
      const { vacancyId } = getters[VACANCY]

      forEach(channels, ({ isCustomCategories, channelId, lStrategy }) => {
        if (!isCustomCategories) {
          return
        }
        return apiRequest({
          method: 'GET',
          url: `/api/erecruiter-web-api/vacancies/${vacancyId}/channels/${channelId}/${lStrategy}/jobBoardCategories`
        }).then(strategies => {
          commit(SET_PUBLICATION_CHANNELS_JOB_STRATEGIES, {
            channelId,
            strategies
          })
        })
      })
    },
    [LOAD_MIMIR_CHANNELS_DROPDOWNS]: ({ commit }, channels) => {
      forEach(channels, ({ lStrategy, mimirChannelId, channelId }) => {
        if (lStrategy !== 'STRATEGY_MIMIR') {
          return
        }
        Promise.all([
          apiRequest({
            method: 'GET',
            url: `/api/erecruiter-web-api/mimirKeyType/JOB_PUBLISH_BRANCH/board/${mimirChannelId}/parent/null`
          }),
          apiRequest({
            method: 'GET',
            url: `/api/erecruiter-web-api/mimirKeyType/JOB_PUBLISH_FUNCTION_NAME/board/${mimirChannelId}/parent/null`
          })
        ]).then(([branch, functionName]) => {
          commit(SET_MIMIR_CHANNELS_DROPDOWNS, {
            channelId,
            branch,
            functionName
          })
        })
      })
    },
    [PUBLISH_VACANCY]: ({ dispatch, getters }, vacancies) => {
      const { vacancyId } = getters[VACANCY]
      const requests = map(vacancies, vacancy => {
        return apiRequest({
          method: 'PUT',
          url: `/api/erecruiter-web-api/vacancies/${vacancyId}/channels/${
            vacancy.channelId
          }`,
          // Remove the props added for displaying attachments before sending
          data: { ...vacancy, logoUrlLocal: undefined }
        }).catch(err => {
          throw new Error(err)
        })
      })
      Promise.all(requests)
        .then(() =>
          apiRequest({
            method: 'POST',
            url: `/api/erecruiter-web-api/vacancies/${vacancyId}?action=publish`
          })
        )
        .then(() => {
          Vue.notify({
            type: 'success',
            text: 'De vacature is gepubliceerd.'
          })
          dispatch(LOAD_PUBLICATION_CHANNELS)
          return Promise.resolve() // TODO this looks unfinished, to review
        })
        .catch(err => {
          Vue.notify({
            type: 'warning',
            text: `de vacature konde niet worden gepubliceerd voor alle kanalen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              err.message
            }`
          })
        })
    }
  }
}
