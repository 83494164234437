import Vue from 'vue'
import {
  SET_CURRENT_PROSPECT,
  SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION
} from '../../../keys-mutations'
import {
  CURRENT_PROSPECT,
  VACANCY,
  RATING_TYPE_FOR_PROSPECT_NAVIGATION
} from '../../../keys-getters'
import {
  DELETE_APPLICANT_PHOTO,
  DELETE_PROSPECT,
  DELETE_PROSPECT_ATTACHMENT,
  LOAD_CURRENT_PROSPECT,
  MOVE_PROSPECT_TO_VACANCY,
  UPDATE_APPLICANT_PHOTO,
  UPLOAD_PROSPECT_ATTACHMENT,
  LOAD_VACANCY_PROSPECTS
} from '../../../keys-actions'
import apiRequest from '../../../../services/api-request'
import saveAttachment from '../../../../services/save-attachment'
import { stripHostFromAttachment } from '../../../../services/attachment-data-cleaner'
import isNull from 'lodash/isNull'
import each from 'lodash/each'

export default {
  state: {
    currentProspect: {},
    prospectTypeForNavigation: null
  },
  mutations: {
    [SET_CURRENT_PROSPECT]: (state, prospect) =>
      (state.currentProspect = prospect),
    [SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION]: (state, type) => {
      if (isNull(type)) {
        state.prospectTypeForNavigation = type
        return
      }
      if (state.prospectTypeForNavigation) {
        return
      }
      state.prospectTypeForNavigation = type
    }
  },
  getters: {
    [CURRENT_PROSPECT]: state => state.currentProspect,
    [RATING_TYPE_FOR_PROSPECT_NAVIGATION]: state =>
      state.prospectTypeForNavigation
  },
  actions: {
    [LOAD_CURRENT_PROSPECT]: async (
      { getters, commit },
      { prospectId, roundId }
    ) => {
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        const data = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectsFull/${prospectId}/selectionRounds/${round}`
        })

        stripHostFromAttachment(data.avatarUrl, 'url')
        each(data.attachments, attachment => {
          stripHostFromAttachment(attachment, 'url')
          stripHostFromAttachment(attachment, 'convertedPdfUrl')
        })

        commit(SET_CURRENT_PROSPECT, data)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De sollicitant kon op dit moment niet worden geladen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [MOVE_PROSPECT_TO_VACANCY]: async ({ getters }, newVacancyId) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      try {
        const data = await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/prospects/${prospectId}/moveToVacancy/${newVacancyId}`
        })
        Vue.notify({
          type: 'success',
          text: `De kandidaat is succesvol toegevoegd aan de actieve selectieronde van de vacature ${
            data.vacancyTitle
          } (${data.vacancyId})`
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De kandidaat kon niet worden toegevoegd aan de vacature. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [DELETE_PROSPECT]: async ({ getters, dispatch }) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/applicant/${prospectId}`
        })
        Vue.notify({
          type: 'success',
          text: 'De sollicitant is verwijderd.'
        })
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De sollicitant kon niet verwijderd worden. Neem contact op met de supportdesk. ${
            err.message
          }`
        })
        return err
      }
    },
    [UPDATE_APPLICANT_PHOTO]: async (
      { getters, dispatch },
      { image, roundId }
    ) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      const { latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        await saveAttachment(
          image,
          `api/erecruiter-web-api/applicants/${prospectId}/photo`
        )
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
      }
    },
    [DELETE_APPLICANT_PHOTO]: async ({ getters, dispatch }, { roundId }) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      const { latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/applicants/${prospectId}/photo`
        })
        Vue.notify({
          type: 'success',
          text: 'De foto is verwijderd.'
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De foto kan niet worden verwijderd. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [UPLOAD_PROSPECT_ATTACHMENT]: async (
      { getters, dispatch },
      { attachment, roundId }
    ) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      const { latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        await saveAttachment(
          attachment,
          `api/erecruiter-web-api/applicants/${prospectId}/profileAttachment`
        )
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
        Vue.notify({
          type: 'success',
          text: 'Het bestand is toegevoegd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bestand kon niet worden toegevoegd. ${err.message}`
        })
      }
    },
    [DELETE_PROSPECT_ATTACHMENT]: async (
      { getters, dispatch },
      { attachmentId, roundId }
    ) => {
      const { prospectId } = getters[CURRENT_PROSPECT]
      const { latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/applicants/${prospectId}/profileAttachment/${attachmentId}`
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
        Vue.notify({
          type: 'success',
          text: 'Het bestand is verwijderd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bestand kan niet worden verwijderd. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    }
  }
}
