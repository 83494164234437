export default {
  RSMEDULVLTYPE_WO: 'WO',
  RSMEDULVLTYPE_HBOPLUS: 'HBO+',
  RSMEDULVLTYPE_HBO: 'HBO',
  RSMEDULVLTYPE_MBOPLUS: 'MBO+',
  RSMEDULVLTYPE_MBO: 'MBO',
  RSMEDULVLTYPE_VWO: 'VWO',
  RSMEDULVLTYPE_HAVO: 'HAVO',
  RSMEDULVLTYPE_MAVO: 'MAVO',
  RSMEDULVLTYPE_VMBO_LBO: 'VMBO/LBO',
  RSMEDULVLTYPE_ANDERS: 'Anders',
  EDUCOMPLETED_YES: 'diploma behaald',
  EDUCOMPLETED_NO: 'diploma niet behaald',
  EDUCOMPLETED_NOT_YET: 'diploma nog niet behaald'
}
