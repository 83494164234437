<template>
  <!-- TODO This should probably be converted into a b-nav https://bootstrap-vue.js.org/docs/components/nav -->
  <div class="ml-auto d-flex align-items-center">
    <div class="pr-3 pl-3 py-1 task-item">
      <i class="fa fa-exclamation-triangle mr-2 text-new" />{{
        stats.numberOfActiveTasks
      }}
      {{ stats.numberOfActiveTasks > 1 ? "taken" : "taak" }}
    </div>
    <b-btn
      variant="link"
      class="text-dark pr-3 pl-3 py-1 task-item"
      @click="openNotification({ type: notificationType.note }, true)"
      ><i class="fa fa-pencil-square-o mr-2" />{{ stats.numberOfNotes }}
      {{ stats.numberOfNotes > 1 ? "notities" : "notitie" }}</b-btn
    >
    <b-btn
      variant="link"
      class="text-dark pr-3 pl-3 py-1 task-item"
      @click="openNotification({ type: notificationType.todo }, true)"
      ><i class="fa fa fa-check-square-o mr-2" />{{
        stats.numberOfActiveVacancyTodos + stats.numberOfActiveProspectTodos
      }}
      {{
        stats.numberOfActiveVacancyTodos + stats.numberOfActiveProspectTodos !=
        1
          ? "To-Dos"
          : "To-Do"
      }}</b-btn
    >
    <b-link
    v-if="showReporting"
      target="_blank"
      :href="reportUrl"
      class="text-dark pr-3 pl-3 py-1 task-item"
      ><i class="fa fa-file-excel-o mr-2" />rapport</b-link
    >
  </div>
</template>
<script>
import modal from '../../../services/modal'
import NotificationModal from '../../notification-list/notification-modal'
import { CREATE_VACANCY_NOTE, CREATE_VACANCY_TODO, COMPLETE_PROSPECT_TODO } from '../../../store/keys-actions'
import { NOTIFTYPE_NOTE, NOTIFTYPE_TODO } from '../../../constants/prospect/prospect-notification-type'
import { VIEW_REPORTING } from '../../../constants/permissions'

const actionName = {
  [NOTIFTYPE_NOTE]: {
    create: CREATE_VACANCY_NOTE
  },
  [NOTIFTYPE_TODO]: {
    create: CREATE_VACANCY_TODO
  }
}

export default {
  name: 'VacancyHeaderTasks',
  props: {
    latestRoundIndex: {
      type: Number,
      default: null
    },
    stats: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    notificationType () {
      return {
        note: NOTIFTYPE_NOTE,
        todo: NOTIFTYPE_TODO
      }
    },
    reportUrl () {
      return `api/erecruiter-web-api/temp/reporting/export/vacancy/${this.$route.params.vacancyId}`
    },
    showReporting () {
      // TODO ONE-288 A specific permission should handle access to vacancy report
      return this.hasPermission(VIEW_REPORTING)
    }
  },
  methods: {
    saveNotificationChanges ({ actionName, type, id }) {
      modal(NotificationModal, { type, id }).then(({ notification }) => {
        if (!notification) {
          return
        }
        return this.$store.dispatch(actionName, { ...notification, selectionRoundIndex: this.latestRoundIndex })
      })
    },
    openNotification ({ type, id }, create) {
      const actionType = create ? 'create' : 'edit'
      this.saveNotificationChanges({ actionName: actionName[type][actionType], type, id })
    },
    completeTodo ({ isDone, id }) {
      return this.$store.dispatch(COMPLETE_PROSPECT_TODO, { isDone, id })
    }
  }
}
</script>

<style lang="scss" scoped>
.task-item {
  min-width: 130px;
  min-height: 35px;
  text-align: center;
}
</style>
