<template>
  <page>
    <div slot="main-content" >
      <div v-if="isLoggedIn" class="mb-2">Ga terug naar je ONE sessie:
        <b-button @click.prevent="returnToSession">Terugkeer</b-button>
      </div>
      <div class="alert alert-warning" role="alert">
        Deze sollicitanten zijn met u gedeeld door een collega. Deze link is twee weken geldig.
      </div>
      <div v-if="isLoading" class="text-center">
        <i class="fa fa-spinner fa-spin" />
      </div>
      <div v-else>
        <prospect
          v-for="(prospect, index) in prospectsData.list"
          :key="prospect.prospectId"
          :prospect="prospect"
          :vacancy="vacancy"
          :index="index"
        />
      </div>
    </div>
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import Prospect from './prospect'
import {
  LOAD_TOKEN_PROSPECT_DATA
} from '../../store/keys-actions'
import {
  TOKEN_PROSPECT_DATA,
  USER_AUTHENTICATED,
  TOKEN_PROSPECT_LOADING_STATE
} from '../../store/keys-getters'

export default {
  components: {
    Page,
    Prospect
  },
  data () {
    return {
      info: null,
      list: null,
      vacancy: null
    }
  },
  computed: {
    prospectsData () {
      return this.$store.getters[TOKEN_PROSPECT_DATA]
    },
    isLoading () {
      return this.$store.getters[TOKEN_PROSPECT_LOADING_STATE]
    },
    isLoggedIn () {
      return this.$store.getters[USER_AUTHENTICATED]
    }
  },
  methods: {
    returnToSession () {
      this.$cookies.remove('external-login')
      this.$router.push({ name: 'home', params: { reload: true } })
    }
  },
  mounted () {
    if (!this.$cookies.get('external-login')) {
      this.$router.push({ name: 'home' })
    }
    this.$store.dispatch(LOAD_TOKEN_PROSPECT_DATA)
  }
}
</script>
