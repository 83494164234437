/* eslint-disable no-empty-pattern */
import emailTemplateParser from '../../services/email-template-parser'
import Vue from 'vue'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import assign from 'lodash/assign'
import {
  SEND_GENERAL_EMAIL,
  SEND_KEEP_IN_RESERVE_EMAIL,
  LOAD_VACANCY_PROSPECTS,
  SEND_REJECT_EMAIL,
  DELETE_EMAIL_TEMPLATE,
  LOAD_EMAIL_TEMPLATES,
  LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE,
  SEND_SHARE_PROSPECTS_EMAIL,
  LOAD_PROSPECT_EMAIL,
  SAVE_EMAIL_TEMPLATE
} from '../keys-actions'
import {
  SET_DEFAULT_SHARE_PER_EMAIL_TEMPLATE,
  SET_CURRENT_NOTIFICATION
} from '../keys-mutations'
import { DEFAULT_SHARE_PER_EMAIL_TEMPLATE, VACANCY } from '../keys-getters'
import {
  EMAILTYPE_NORMAL,
  EMAILTYPE_BULK_BACKUP,
  EMAILTYPE_BULK_REJECT
} from '../../constants/email/email-type'
import { EMAILTEMPLATE_PLAIN } from '../../constants/email/email-template'
import apiRequest from '../../services/api-request'

import saveAttachment from '../../services/save-attachment'
const { parseContent } = emailTemplateParser

const date = new Date().toISOString()
const emailToType = 'EMAILTOTYPE_ONEPROSPECT' // don't change. default value required by backend
const lPriority = 'EMAILPRIORITY_NORMAL' // don't change. default value required by backend

const createFileUploadRequests = async array =>
  Promise.all(
    map(array, attachment =>
      saveAttachment(attachment, '/api/erecruiter-web-api/emails/attachments')
    )
  )

const notifyEmailSent = () =>
  Vue.notify({
    type: 'success',
    text: 'De email word gestuurd.'
  })
const notifyEmailNotSent = () =>
  Vue.notify({
    type: 'warning',
    text: 'De email is niet verzonden.'
  })

const emailPayloadDefaults = {
  emailToType,
  lPriority,
  date,
  emailId: null,
  scharedProspects: null, // scharedProspects isn't a typo. The property is stored with that spelling in the backend
  sender: null,
  toAsNames: null,
  tokenAuthorizationId: null
}

export default {
  state: {
    defaultSharePerEmailTemplate: {}
  },
  mutations: {
    [SET_DEFAULT_SHARE_PER_EMAIL_TEMPLATE]: (state, template) =>
      (state.defaultSharePerEmailTemplate = template)
  },
  getters: {
    [DEFAULT_SHARE_PER_EMAIL_TEMPLATE]: state =>
      state.defaultSharePerEmailTemplate
  },
  actions: {
    [SEND_GENERAL_EMAIL]: async ({}, email) => {
      try {
        const attachments = !isEmpty(email.attachments)
          ? await createFileUploadRequests(email.attachments)
          : null
        await apiRequest({
          method: 'POST',
          url: 'api/erecruiter-web-api/emails',
          data: assign(emailPayloadDefaults, {
            attachments,
            bcc: email.bcc,
            cc: email.cc,
            message: email.message,
            subject: email.subject,
            to: email.to,
            lEmailType: EMAILTYPE_NORMAL,
            lEmailTemplate: EMAILTEMPLATE_PLAIN
          })
        })
        notifyEmailSent()
      } catch (err) {
        notifyEmailNotSent()
      }
    },
    [SEND_KEEP_IN_RESERVE_EMAIL]: async ({ getters, dispatch }, email) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const attachments = !isEmpty(email.attachments)
          ? await createFileUploadRequests(email.attachments)
          : null
        await apiRequest({
          method: 'POST',
          url: `/api/erecruiter-web-api/emails/bulkbackup/${vacancyId}`,
          data: assign(emailPayloadDefaults, {
            attachments,
            bcc: email.bcc,
            cc: email.cc,
            message: email.message,
            subject: email.subject,
            to: email.to,
            lEmailType: EMAILTYPE_BULK_BACKUP,
            lEmailTemplate: EMAILTEMPLATE_PLAIN
          })
        })
        notifyEmailSent()
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        notifyEmailNotSent()
      }
    },
    [SEND_REJECT_EMAIL]: async ({ getters, dispatch }, email) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const attachments = !isEmpty(email.attachments)
          ? await createFileUploadRequests(email.attachments)
          : null
        await apiRequest({
          method: 'POST',
          url: `/api/erecruiter-web-api/emails/bulkreject/${vacancyId}`,
          data: assign(emailPayloadDefaults, {
            attachments,
            bcc: email.bcc,
            cc: email.cc,
            message: email.message,
            subject: email.subject,
            to: email.to,
            lEmailType: EMAILTYPE_BULK_REJECT,
            tokenAuthorizationId: email.tokenAuthorizationId,
            lEmailTemplate: EMAILTEMPLATE_PLAIN
          })
        })
        notifyEmailSent()
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        notifyEmailNotSent()
      }
    },
    [SEND_SHARE_PROSPECTS_EMAIL]: async ({}, email) => {
      try {
        const attachments = !isEmpty(email.attachments)
          ? await createFileUploadRequests(email.attachments)
          : null
        await apiRequest({
          method: 'POST',
          url: 'api/erecruiter-web-api/emails/shareProspects',
          data: assign(emailPayloadDefaults, {
            attachments,
            bcc: email.bcc,
            cc: email.cc,
            message: email.message,
            subject: email.subject,
            to: email.to,
            lEmailType: EMAILTYPE_BULK_REJECT,
            scharedProspects: email.sharedProspects, // scharedProspects isn't a typo. The property is stored with that spelling in the backend
            tokenAuthorizationId: email.tokenAuthorizationId
          })
        })
        notifyEmailSent()
      } catch (err) {
        notifyEmailNotSent()
      }
    },
    [DELETE_EMAIL_TEMPLATE]: async ({ dispatch }, templateId) => {
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/templates/${templateId}`
        })
        dispatch(LOAD_EMAIL_TEMPLATES)
        Vue.notify({
          type: 'success',
          text: 'De template is verwijderd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De template kon niet worden verwijderd. ${err.message}`
        })
      }
    },
    [SAVE_EMAIL_TEMPLATE]: async (
      { dispatch },
      { subject, body }
    ) => /* eslint-disable-line no-empty-pattern */ {
      try {
        await apiRequest({
          method: 'POST',
          url: 'api/erecruiter-web-api/templates',
          data: {
            subject,
            body
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De nieuwe email template is opgeslagen'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De email template is niet opgeslagen. ${err.message}`
        })
      }
    },
    [LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE]: async (
      { commit, getters },
      sharedProspectIds
    ) => {
      const { vacancyId } = getters[VACANCY]
      const sharedProspects = sharedProspectIds.join()
      try {
        let data = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/emails/templates/EMAILTYPE_SHARE_PROSPECTS?vacancyId=${vacancyId}&scharedProspects=${sharedProspects}`
        })
        data = parseContent({ template: data })
        commit(SET_DEFAULT_SHARE_PER_EMAIL_TEMPLATE, data)
      } catch (err) {
        return err
      }
    },
    [LOAD_PROSPECT_EMAIL]: async ({ commit }, emailId) => {
      try {
        const email = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/emails/${emailId}`
        })
        commit(SET_CURRENT_NOTIFICATION, email)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bericht is niet beschikbaar. Probeer later nog eens of neem contact op met Jobsrepublic. ${err.message}`
        })
      }
    }
  }
}
