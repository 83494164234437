var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pb-5"},[_vm._v(" Publiceer de vacature op de gewenste kanalen ")]),(_vm.disableChannelsPublication)?_c('notification-block',{staticClass:"mb-4",attrs:{"variation":"warn"}},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_c('i',{staticClass:"fa fa-exclamation-triangle mr-2"}),_c('span',[_vm._v("Publiceren naar Gemeentebanen.nl, Zorgbanen.nl en werkenbij-sites is momenteel niet mogelijk door onderhoud")])])]):_vm._e(),_vm._l((_vm.validChannelCategoryTitles),function(channelCategory){return _c('div',{key:channelCategory.key,staticClass:"pb-5"},[_c('h5',[_vm._v(_vm._s(channelCategory.title))]),(channelCategory.displayDates)?_c('div',{staticClass:"d-flex mb-2 align-items-center"},[_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: _vm.validateDate[channelCategory.key],
          date_format: 'yyyy-MM-dd',
        }),expression:"{\n          required: validateDate[channelCategory.key],\n          date_format: 'yyyy-MM-dd',\n        }"}],ref:((channelCategory.key) + "StartDate"),refInFor:true,staticClass:"mr-2",class:_vm.errors.has(((channelCategory.key) + "StartDate"))
            ? 'border border-error'
            : '',attrs:{"placeholder":"startdatum","language":_vm.datepickerLanguage.nl,"disabled-dates":_vm.disabledDates,"name":((channelCategory.key) + "StartDate"),"data-vv-as":"startdatum"},model:{value:(_vm.channelCategoryDates[channelCategory.key].start),callback:function ($$v) {_vm.$set(_vm.channelCategoryDates[channelCategory.key], "start", $$v)},expression:"channelCategoryDates[channelCategory.key].start"}}),_c('div',[_vm._v("tot en met")]),_c('datepicker',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: _vm.validateDate[channelCategory.key],
          date_format: 'yyyy-MM-dd',
          after: ((channelCategory.key) + "StartDate"),
        }),expression:"{\n          required: validateDate[channelCategory.key],\n          date_format: 'yyyy-MM-dd',\n          after: `${channelCategory.key}StartDate`,\n        }"}],staticClass:"ml-2",class:_vm.errors.has(((channelCategory.key) + "EndDate"))
            ? 'border border-error'
            : '',attrs:{"placeholder":"Einddatum","language":_vm.datepickerLanguage.nl,"disabled-dates":_vm.disabledDates,"name":((channelCategory.key) + "EndDate"),"data-vv-as":"einddatum"},model:{value:(_vm.channelCategoryDates[channelCategory.key].end),callback:function ($$v) {_vm.$set(_vm.channelCategoryDates[channelCategory.key], "end", $$v)},expression:"channelCategoryDates[channelCategory.key].end"}})],1):_vm._e(),(_vm.errors.has(((channelCategory.key) + "StartDate")))?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.errors.first(((channelCategory.key) + "StartDate")))+" ")]):_vm._e(),(_vm.errors.has(((channelCategory.key) + "EndDate")))?_c('div',{staticClass:"text-error"},[_vm._v(" "+_vm._s(_vm.errors.first(((channelCategory.key) + "EndDate")))+" ")]):_vm._e(),(channelCategory.description)?_c('div',{staticClass:"pb-2",domProps:{"innerHTML":_vm._s(channelCategory.description)}}):_vm._e(),_vm._l((_vm.privateValues[channelCategory.key]),function(channel,index){return _c('div',{key:index},[_c('channel',{attrs:{"jobCategories":_vm.jobStrategies[channel.channelId],"mimirOptions":_vm.mimir[channel.channelId],"disabled":_vm.disableChannelsPublication},model:{value:(_vm.privateValues[channelCategory.key][index]),callback:function ($$v) {_vm.$set(_vm.privateValues[channelCategory.key], index, $$v)},expression:"privateValues[channelCategory.key][index]"}})],1)})],2)}),_c('div',{staticClass:"col-12 d-flex pt-5"},[_c('b-btn',{staticClass:"mr-auto",attrs:{"variant":"primary"},on:{"click":_vm.previous}},[_c('i',{staticClass:"fa fa-chevron-left"}),_vm._v(" Vorige stap ")]),_c('b-btn',{attrs:{"variant":"primary"},on:{"click":_vm.next}},[_vm._v(" Opslaan "),(_vm.loading)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e()])],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }