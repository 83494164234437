import logLevel from 'loglevel'

const log = logLevel.getLogger('default')

const level =
  process.env.NODE_ENV === 'development'
    ? logLevel.levels.TRACE
    : logLevel.levels.WARN

log.setLevel(level)

export const trace = log.trace
export const debug = log.debug
export const info = log.info
export const warn = log.warn
export const error = log.error
