/* eslint no-unused-vars: ["error", { "ignoreRestSiblings": true }] */

import Vue from 'vue'
import {
  COMPLETE_PROSPECT_TODO,
  CREATE_PROSPECT_NOTE,
  CREATE_PROSPECT_TODO,
  DELETE_PROSPECT_NOTIFICATION_ATTACHMENT,
  EDIT_PROSPECT_NOTE,
  EDIT_PROSPECT_TODO,
  LOAD_CURRENT_PROSPECT,
  LOAD_PROSPECT_NOTE,
  LOAD_PROSPECT_TODO
} from '../../../keys-actions'
import {
  CURRENT_NOTIFICATION,
  CURRENT_PROSPECT,
  VACANCY
} from '../../../keys-getters'
import { PROSPECT_APPLICANT } from '../../../../constants/prospect/prospect-types'
import {
  ENTITYTYPE_APPLICANT,
  ENTITYTYPE_CANDIDATE
} from '../../../../constants/entity-types'
import apiRequest from '../../../../services/api-request'
import saveAttachment from '../../../../services/save-attachment'
import { stripHostFromAttachment } from '../../../../services/attachment-data-cleaner'
import { SET_CURRENT_NOTIFICATION } from '../../../keys-mutations'
import {
  NOTIFTYPE_NOTE,
  NOTIFTYPE_TODO
} from '../../../../constants/prospect/prospect-notification-type'

export default {
  state: {
    currentNotification: {}
  },
  mutations: {
    [SET_CURRENT_NOTIFICATION]: (state, notification) =>
      (state.currentNotification = notification)
  },
  getters: {
    [CURRENT_NOTIFICATION]: state => state.currentNotification
  },
  actions: {
    [CREATE_PROSPECT_NOTE]: async (
      { getters, dispatch },
      { notification, roundId }
    ) => {
      const info = notification
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      const { prospectId, lProspectType } = getters[CURRENT_PROSPECT]
      const { lSharingType, description, attachment, createDate } = info
      const lEntityType =
        lProspectType === PROSPECT_APPLICANT
          ? ENTITYTYPE_APPLICANT
          : ENTITYTYPE_CANDIDATE

      try {
        const file = attachment
          ? await saveAttachment(
            attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectNotes`,
          data: {
            attachment: file,
            lSharingType,
            description,
            createDate,
            entityId: prospectId,
            vacancyId,
            lEntityType
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De notitie wordt aangemaakt'
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
      }
    },
    [CREATE_PROSPECT_TODO]: async (
      { getters, dispatch },
      { notification, roundId }
    ) => {
      const info = notification
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      const { prospectId, lProspectType } = getters[CURRENT_PROSPECT]
      const {
        lSharingType,
        description,
        attachment,
        createDate,
        dueDate
      } = info

      const lEntityType =
        lProspectType === PROSPECT_APPLICANT
          ? ENTITYTYPE_APPLICANT
          : ENTITYTYPE_CANDIDATE
      try {
        const file = attachment
          ? await saveAttachment(
            attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectTodos`,
          data: {
            attachment: file,
            lSharingType,
            description,
            createDate,
            entityId: prospectId,
            lEntityType,
            dueDate
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De To-Do wordt aangemaakt'
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
      }
    },
    [EDIT_PROSPECT_NOTE]: async (
      { getters, dispatch },
      { notification, roundId }
    ) => {
      const note = notification
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      const { prospectId } = getters[CURRENT_PROSPECT]

      try {
        const file = note.attachment
          ? await saveAttachment(
            note.attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectNotes/${note.noteId}`,
          data: { ...note, attachment: file }
        })
        Vue.notify({
          type: 'success',
          text: 'De notitie is veranderd'
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
      }
    },
    [EDIT_PROSPECT_TODO]: async (
      { getters, dispatch },
      { notification, roundId }
    ) => {
      const todo = notification
      const { latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      const { prospectId } = getters[CURRENT_PROSPECT]

      try {
        const file = todo.attachment
          ? await saveAttachment(
            todo.attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        const { isDone, selectedSharingType, vacancyId, ...notification } = {
          ...todo,
          attachment: file
        }
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectTodos/${todo.todoId}`,
          data: notification
        })
        Vue.notify({
          type: 'success',
          text: 'De todo is veranderd'
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
        return err
      }
    },
    [LOAD_PROSPECT_NOTE]: async ({ getters, commit }, noteId) => {
      const { vacancyId } = getters[VACANCY]
      try {
        // TODO this request is returning 400
        const notification = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectNotes/${noteId}`
        })
        stripHostFromAttachment(notification.attachment, 'url')
        commit(SET_CURRENT_NOTIFICATION, notification)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bericht is niet beschikbaar. Probeer later nog eens of neem contact op met Jobsrepublic. ${err.message}`
        })
      }
    },
    [LOAD_PROSPECT_TODO]: async ({ getters, commit }, todoId) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const notification = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectTodos/${todoId}`
        })
        stripHostFromAttachment(notification.attachment, 'url')
        commit(SET_CURRENT_NOTIFICATION, notification)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bericht is niet beschikbaar. Probeer later nog eens of neem contact op met Jobsrepublic. ${err.message}`
        })
      }
    },
    [DELETE_PROSPECT_NOTIFICATION_ATTACHMENT]: async (
      { getters, dispatch },
      { notificationType, notificationId }
    ) => {
      const { attachment } = getters[CURRENT_NOTIFICATION]
      const actions = {
        [NOTIFTYPE_NOTE]: LOAD_PROSPECT_NOTE,
        [NOTIFTYPE_TODO]: LOAD_PROSPECT_TODO
      }
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/notes/attachments/${attachment.attachmentId}`
        })
        dispatch(actions[notificationType], notificationId)
        Vue.notify({
          type: 'success',
          text: 'Het bestand is verwijderd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bestand kon niet verwijderd worden. Neem contact op met de supportdesk. ${err.message}`
        })
      }
    },
    [COMPLETE_PROSPECT_TODO]: async ({ dispatch, getters }, { id, isDone }) => {
      const { vacancyId } = getters[VACANCY]
      const { prospectId } = getters[CURRENT_PROSPECT]
      try {
        await dispatch(LOAD_PROSPECT_TODO, id)
        const todo = { ...getters[CURRENT_NOTIFICATION], isDone }
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospectTodos/${id}`,
          data: todo
        })
        dispatch(LOAD_CURRENT_PROSPECT, { prospectId })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de sollicitant niet laden. ${err.message}`
        })
      }
    }
  }
}
