import Vue from 'vue'
import {
  ARCHIVE_VACANCY,
  LOAD_VACANCY_SUBPROCESSES,
  LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS,
  LOAD_VACANCY_PUBLICATION_SUBPROCESS_INFO,
  LOAD_PUBLICATION_CHANNELS,
  LOAD_VACANCY_STATS
} from '../../keys-actions'
import {
  VACANCY,
  VACANCY_SUBPROCESS_NOTIFICATIONS,
  VACANCY_PUBLICATION_SUBPROCESS_INFO,
  VACANCY_ROUNDS,
  VACANCY_STATS
} from '../../keys-getters'
import {
  SET_VACANCY,
  SET_VACANCY_SUBPROCESS_NOTIFICATIONS,
  SET_VACANCY_PUBLICATION_SUBPROCESS_INFO,
  SET_VACANCY_STATS
} from '../../keys-mutations'
import { SUBPROCESS_PUBLISH } from '../../../constants/vacancy-subprocesses'
import apiRequest from '../../../services/api-request'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'

export default {
  state: {
    subprocesses: {},
    publicationSubprocessInfo: {},
    vacancyStats: {}
  },
  mutations: {
    [SET_VACANCY_SUBPROCESS_NOTIFICATIONS]: (state, info) => {
      if (isEmpty(info)) {
        state.subprocesses = info
        return
      }
      const { notifications, name, round } = info
      if (!round) {
        state.subprocesses = { ...state.subprocesses, [name]: notifications }
        return
      }
      state.subprocesses = {
        ...state.subprocesses,
        [name]: { ...state.subprocesses[name], [round]: notifications }
      }
    },
    [SET_VACANCY_PUBLICATION_SUBPROCESS_INFO]: (state, info) =>
      (state.publicationSubprocessInfo = info),
    [SET_VACANCY_STATS]: (state, stats) => (state.vacancyStats = stats)
  },
  getters: {
    [VACANCY_SUBPROCESS_NOTIFICATIONS]: state => state.subprocesses,
    [VACANCY_PUBLICATION_SUBPROCESS_INFO]: state =>
      state.publicationSubprocessInfo,
    [VACANCY_STATS]: state => state.vacancyStats
  },
  actions: {
    [ARCHIVE_VACANCY]: async ({ getters, commit }) => {
      const vacancy = getters[VACANCY]
      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${
            vacancy.vacancyId
          }?action=close`,
          data: vacancy
        })
        commit(SET_VACANCY, {})
        Vue.notify({
          type: 'success',
          text: 'Vacature afgesloten en gearchiveerd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De vacature kon niet worden afgesloten en gearchiveerd. ${
            err.message
          }`
        })
      }
    },
    [LOAD_VACANCY_SUBPROCESSES]: async ({ getters, dispatch }) => {
      const { vacancyId } = getters[VACANCY]
      const rounds = getters[VACANCY_ROUNDS]
      try {
        const subprocesses = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/subprocesses`
        })
        map(subprocesses, async subprocess => {
          if (subprocess.name === 'CANDIDATEBANK') {
            return
          }
          if (subprocess.name === 'SELECT') {
            forEach(rounds, round =>
              dispatch(LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS, {
                key: subprocess.lSubprocess,
                name: subprocess.name,
                round: round.roundId
              })
            )
            return
          }
          dispatch(LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS, {
            key: subprocess.lSubprocess,
            name: subprocess.name
          })
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature informatie niet laden. ${err.message}`
        })
      }
    },
    [LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS]: async (
      { commit, getters, dispatch },
      { key, name, round }
    ) => {
      const { vacancyId } = getters[VACANCY]

      const lowerCaseName = name.toLowerCase()
      const url = round
        ? `api/erecruiter-web-api/vacancies/${vacancyId}/notifications/${key}/round/${round}`
        : `api/erecruiter-web-api/vacancies/${vacancyId}/notifications/${key}`
      try {
        const notifications = await apiRequest({
          method: 'GET',
          url
        })
        commit(SET_VACANCY_SUBPROCESS_NOTIFICATIONS, {
          notifications,
          name: lowerCaseName,
          round
        })
        if (key === SUBPROCESS_PUBLISH) {
          dispatch(LOAD_VACANCY_PUBLICATION_SUBPROCESS_INFO)
          dispatch(LOAD_PUBLICATION_CHANNELS, true)
        }
        dispatch(LOAD_VACANCY_STATS)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature notities niet laden. ${err.message}`
        })
      }
    },
    [LOAD_VACANCY_PUBLICATION_SUBPROCESS_INFO]: async ({ commit, getters }) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const info = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/publicationSubprocessesInfo`
        })
        commit(SET_VACANCY_PUBLICATION_SUBPROCESS_INFO, info)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature notities niet laden. ${err.message}`
        })
      }
    },
    [LOAD_VACANCY_STATS]: async ({ getters, commit }) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const stats = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/smallInfo`
        })
        commit(SET_VACANCY_STATS, stats)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature niet laden. ${err.message}`
        })
      }
    }
  }
}
