<template>
  <div>
    <div class="d-flex flex-column pb-4 justify-content-between">
      <p v-for="line in titleDescription" :key="line">
        {{ line }}
      </p>

      <div class="d-flex justify-content-between pt-2 pb-4 align-items-center">
        <div><strong>Filters: </strong></div>
        <b-form-checkbox
          name="filterVacancyIsArchived"
          plain
          v-model="filterVacancyIsArchived"
          class="ml-2"
        >
          Vacature
          <b-badge class="" pill variant="light" style="border: 1px solid #ccc"
            >Gearchiveerd</b-badge
          >
        </b-form-checkbox>

        <b-form-checkbox
          name="filterApplicantIsDeclined"
          plain
          v-model="filterApplicantIsDeclined"
          class="ml-2"
        >
          Sollicitant
          <b-badge class="" pill variant="danger">Niet door</b-badge>
        </b-form-checkbox>
        <div class="d-flex align-items-center">
          <b-form-checkbox
            name="filterApplyDateEnabled"
            plain
            :disabled="filterApplyDate === null"
            v-model="filterApplyDateEnabled"
            @change="removeFilterApplyDate"
            class="ml-2"
            >Sollicitatiedatum voor</b-form-checkbox
          >
          <datepicker
            v-model="filterApplyDate"
            placeholder="DD-MM-YYYY"
            :language="datepickerLanguage.nl"
            name="filterApplyDate"
            :ref="filterApplyDate"
            class="ml-2"
            @selected="filterApplyDateChanged"
          />
        </div>
      </div>

      <div class="d-flex align-items-center">
        <b-form-checkbox
          :disabled="!applicantIdListPerVacancyLength > 0"
          name="selectAll"
          plain
          @change="toggleAll"
          :checked="allSelected && selectedApplicantsTotal > 0"
        >
          {{
            allSelected && selectedApplicantsTotal > 0
              ? 'Alles deselecteren'
              : 'Alles selecteren'
          }}
        </b-form-checkbox>
        <div class="ml-auto p-1">
          <b-btn
            class="mr-2"
            variant="secondary"
            @click="reconsentApplicants"
            :disabled="selectedApplicantsTotal < 1"
          >
            <i v-if="loading" class="fa fa-spinner fa-spin" />
            Verleng bewaartermijn
            <i class="mr-2 fa fa-envelope-o" />
            <b-badge variant="light" v-if="selectedApplicantsTotal > 0">{{
              selectedApplicantsTotal
            }}</b-badge>
          </b-btn>
          <b-btn
            variant="danger"
            @click="anonymizeApplicants"
            :disabled="selectedApplicantsTotal < 1"
          >
            <i v-if="loading" class="fa fa-spinner fa-spin"></i>
            Anonimiseer
            <b-badge variant="light" v-if="selectedApplicantsTotal > 0">{{
              selectedApplicantsTotal
            }}</b-badge>
          </b-btn>
        </div>
      </div>
    </div>

    <b-card v-if="gdprVacancies.length === 0 && !loading" class="text-center">
      <div>Er zijn geen vacatures of sollicitanten gevonden.</div>
    </b-card>

    <div v-for="vacancy in gdprVacancies" :key="vacancy.vacancyId">
      <vacancy
        :title="vacancy.vacancyTitle"
        :status="vacancy.status"
        :isCurrentlyPublished="vacancy.isCurrentlyPublished"
        :applicants="vacancy.applicants"
        :vacancy-id="vacancy.vacancyId"
        :dateCreated="vacancy.dateCreated"
        :showVacancyLink="vacancy.showVacancyLink"
        :isArchived="vacancy.isArchived"
        v-model="selectedPerVacancy[vacancy.vacancyId]"
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import modal from '../../services/modal'
import Vacancy from './vacancy'
import ConfirmModal from './confirm-modal'
import RequestConsentModal from './request-consent-modal'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import {
  ANONYMIZE_APPLICANTS,
  REQUEST_APPLICANT_CONSENT
} from '../../store/keys-actions'
import {
  LOADING,
  ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER,
  CURRENT_USER
} from '../../store/keys-getters'
import { nl } from 'vuejs-datepicker/dist/locale'

export default {
  name: 'gdprPage',
  props: {
    title: {
      type: String,
      required: true
    },
    getter: {
      type: String,
      required: true
    }
  },
  components: {
    Vacancy,
    Datepicker
  },
  data () {
    return {
      selectedPerVacancy: {},
      vacanciesFilteredByCurrentUser: false,
      filterMyVacancies: false,
      filterVacancyIsArchived: false,
      filterApplicantIsDeclined: false,
      filterApplyDateEnabled: false,
      filterApplyDate: null
    }
  },
  computed: {
    datepickerLanguage () {
      return {
        nl: nl
      }
    },
    currentUser () {
      return this.$store.getters[CURRENT_USER]
    },
    gdprVacancies () {
      const vacancies = this.$store.getters[this.getter]

      return (
        vacancies
          // Add showVacancyLink and format date
          .map((vacancy) => ({
            ...vacancy,
            showVacancyLink: this.currentUserVacancyIdList.includes(
              vacancy.vacancyId
            ),
            dateCreated: `${moment.unix(vacancy.vacancyCreationDate)}`
          }))
          // Filter Archived vacancies
          .filter(
            (vacancy) => !this.filterVacancyIsArchived || vacancy.isArchived
          )
          // Filter applicants
          .map((vacancy) => {
            return {
              ...vacancy,
              applicants: vacancy.applicants
                // Parse applicationDate
                .map((applicant) => ({
                  ...applicant,
                  applicationDate: moment.unix(applicant.applicationDate)
                }))
                // Filter "is Declined"
                .filter(
                  (applicant) =>
                    !this.filterApplicantIsDeclined ||
                    applicant.isRatingDeclined
                )
                // Filter apply date before
                .filter(
                  (applicant) =>
                    !this.filterApplyDateEnabled ||
                    applicant.applicationDate.isBefore(this.filterApplyDate)
                )
            }
          })
          // Only include vacancies with applicants
          .filter((vacancy) => vacancy.applicants.length > 0)
      )
    },
    currentUserVacancyIdList () {
      const vacancies =
        this.$store.getters[ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]
      return _.map(vacancies, 'vacancyId')
    },
    applicantIdListPerVacancy () {
      return this.gdprVacancies.reduce((list, vacancy) => {
        list[vacancy.vacancyId] = _.map(vacancy.applicants, 'applicantId')
        return list
      }, {})
    },
    applicantIdListPerVacancyLength () {
      return _.flatMapDeep(this.applicantIdListPerVacancy).length
    },
    selectedApplicantsList () {
      return _.flatMapDeep(this.selectedPerVacancy)
    },
    selectedApplicantsTotal () {
      return this.selectedApplicantsList.length
    },
    loading () {
      return this.$store.getters[LOADING]
    },
    titleDescription () {
      return this.title.split('\n')
    },
    allSelected () {
      return (
        this.applicantIdListPerVacancyLength === this.selectedApplicantsTotal &&
        this.selectedApplicantsTotal > 0
      )
    },
    allSelectedNames () {
      const getFullNames = (applicants) => {
        return _.chain(applicants)
          .filter((applicant) =>
            this.selectedApplicantsList.includes(applicant.applicantId)
          )
          .map((applicant) =>
            [applicant.firstname, applicant.middlename, applicant.lastname]
              .filter((part) => !!part)
              .join(' ')
          )
          .value()
      }

      return _.chain(this.gdprVacancies)
        .reduce((result, vacancy) => {
          result[vacancy.vacancyId] = getFullNames(vacancy.applicants)
          return result
        }, {})
        .flatMapDeep()
        .value()
    }
  },
  watch: {
    gdprVacancies () {
      this.selectedPerVacancy = this.gdprVacancies.reduce((map, vacancy) => {
        map[vacancy.vacancyId] =
          this.selectedPerVacancy[vacancy.vacancyId] || []
        return map
      }, {})
    }
  },
  methods: {
    filterApplyDateChanged (newValue) {
      this.filterApplyDateEnabled = true
    },
    removeFilterApplyDate () {
      if (this.filterApplyDate !== null) {
        this.filterApplyDate = null
      }
    },
    toggleAll () {
      if (this.allSelected) {
        this.deselectAllApplicants()
        return
      }
      Object.keys(this.selectedPerVacancy).forEach(
        (vacancyId) =>
          (this.selectedPerVacancy[vacancyId] =
            this.applicantIdListPerVacancy[vacancyId])
      )
    },
    deselectAllApplicants () {
      Object.keys(this.selectedPerVacancy).forEach(
        (vacancyId) => (this.selectedPerVacancy[vacancyId] = [])
      )
    },
    anonymizeApplicants () {
      modal(ConfirmModal, {
        total: this.selectedApplicantsTotal
      }).then((ok) => {
        if (!ok) {
          return
        }
        return this.$store.dispatch(
          ANONYMIZE_APPLICANTS,
          this.selectedApplicantsList
        )
      })
    },
    reconsentApplicants () {
      modal(RequestConsentModal, {
        to: this.allSelectedNames
      }).then((result) => {
        if (!result) {
          return
        }
        return this.$store.dispatch(REQUEST_APPLICANT_CONSENT, {
          applicantIdList: this.selectedApplicantsList,
          subject: result.subject,
          body: result.body
        })
      })
    }
  }
}
</script>
