<template>
  <div
    class="px-4 py-4 d-flex align-items-start bg-white border-left border-right border-bottom border-light-gray"
  >
    <div class="mx-3">
      <b-link
        :key="prospect.prospectId"
        :to="{
          name: 'tokenProspectsDetail',
          params: { id: this.index }
        }"
        class="avatar-link"
      >
        <avatar v-if="!prospect.avatarUrl" :fullname="fullname" />
        <div
          v-if="prospect.avatarUrl"
          class="d-flex align-items-center justify-content-around"
          style="width: 96px; height: 96px; overflow: hidden;"
        >
          <img
            v-if="prospect.avatarUrl"
            :src="prospect.avatarUrl.urlLocal"
            class="img-thumbnail p-0 border-0 bg-transparent"
          />
        </div>
        <div class="avatar-link--underline" />
      </b-link>
    </div>
    <div class="d-flex flex-column ml-2">
      <div class="d-flex align-items-center">
        <div>
          <h5>
            <strong>
              <b-link
                :to="{
                  name: 'tokenProspectsDetail',
                  params: { id: this.index }
                }"
              >
                {{ fullname }}
              </b-link>
            </strong>
          </h5>
        </div>
      </div>
      <div v-if="candidateSource">{{ candidateSource }}</div>
      <div v-if="applicationDate">{{ applicationDate }}</div>
      <div class="d-flex text-medium-gray">
        <b-link
          v-if="prospect.emailAddress"
          class="text-medium-gray mr-2"
          variant="link"
          :href="`mailto:${prospect.emailAddress}`"
        >
          <i class="fa fa-envelope" />
        </b-link>
        <div
          v-if="
            prospect.emailAddress &&
              (prospect.phoneNumber || prospect.mobileNumber)
          "
        >
          |&nbsp;
        </div>
        <b-link
          class="text-medium-gray"
          :href="`tel:${prospect.phoneNumber ? prospect.phoneNumber : prospect.mobileNumber}`"
        >
          <strong>{{
            prospect.phoneNumber ? prospect.phoneNumber : prospect.mobileNumber
          }}</strong>
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Avatar from '../avatar'
import { VIEW_EXTERNALLY } from '../../constants/permissions'

export default {
  components: {
    Avatar
  },
  props: {
    prospect: {
      type: Object,
      default: () => ({})
    },
    vacancy: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      status: this.prospect.recruiterStatus,
      isChecked: this.value
    }
  },
  computed: {
    fullname () {
      return `${this.prospect.firstname} ${this.prospect.lastname}`
    },
    applicationDate () {
      return moment(this.prospect.createDate).format('DD MMM YYYY')
    },
    candidateSource () {
      const map = {
        CHANNEL_CAT_HIDDEN: 'ONE sollicitant',
        CHANNEL_CAT_EXTERNAL: 'Externe sollicitant',
        CHANNEL_CAT_REGIONAL: 'Regionale sollicitant',
        CHANNEL_CAT_INTERNAL: 'Interne sollicitant'
      }
      return map[(this.prospect.publishChannel || {}).channelCat] || ''
    },
    viewerIsExternal () {
      return this.hasPermission(VIEW_EXTERNALLY)
    },
    prospectIsNew () {
      return !this.viewerIsExternal && !this.prospect.isViewed
    },
    icons () {
      return {
        1: 'fa fa-check text-success',
        0: 'fa fa-question text-dark',
        '-1': 'fa fa-times text-danger'
      }
    }
  },
  watch: {
    value () {
      this.isChecked = this.value
    },
    prospect: {
      handler: function () {
        this.status = this.prospect.recruiterStatus
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-link--underline {
  display: none;
  position: relative;
  margin-top: -2px;
  width: 100%;
  height: 2px;
  background-color: #1591a1;
  z-index: 1;
}
.v a.avatar-link:hover {
  text-decoration: none;
  .avatar-link--underline {
    display: block;
  }
}
</style>
