<template>
  <div class="d-flex flex-column">
    <b-dropdown
      id="ddown1"
      :class="dynamicClasses"
      class="multiselect"
      no-flip
      boundary="scrollParent"
      variant="white"
    >
     <template #button-content>
      <div
        class="d-flex justify-content-between align-items-center"
      >
        <div>
          {{
            selected && selected.length > 0
              ? `${selected.length} geselecteerd`
              : `Selecteer ${label.toLowerCase()}`
          }}
        </div>
        <div><i class="fa fa-caret-down" /></div>
      </div>
     </template>
      <div class="container">
        <b-form-checkbox-group
          plain
          :name="name"
          :id="name"
          v-validate="validationRules"
          :data-vv-as="label"
          :class="{
            'is-invalid':
              fields[name] && fields[name].validated && errors.has(name)
          }"
          v-model="selected"
          class="p-3 d-flex flex-column"
        >
          <b-form-checkbox
            :class="{ 'is-invalid': errors.has(name) }"
            v-for="option in options"
            :key="option.lConst"
            :value="option.lConst"
            class="p-1 text-nowrap"
          >
            {{ option.value }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </div>
    </b-dropdown>
    <div
      v-if="fields[name] && fields[name].validated && errors.has(name)"
      class="text-right text-error"
    >
      {{ errors.first(name) }}
    </div>
  </div>
</template>

<script>
import isEqual from 'lodash/isEqual'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'

export default {
  inject: {
    $validator: '$validator'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: 'option'
    },
    label: {
      type: String,
      default: 'opties'
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    max: {
      type: Number,
      default: null
    },
    min: {
      type: Number,
      default: null
    },
    align: {
      type: String,
      default: 'right'
    }
  },
  data () {
    return {
      selected: cloneDeep(this.value) || []
    }
  },
  computed: {
    validationBorderClass () {
      return this.fields[this.name] && this.fields[this.name].validated && this.errors.has(this.name)
        ? 'border border-error'
        : 'border border-medium-gray'
    },
    alignmentClass () {
      return this.align === 'left' ? 'mr-auto' : 'ml-auto'
    },
    dynamicClasses () {
      return `${this.alignmentClass} ${this.validationBorderClass}`
    },
    validationRules () {
      const rules = {}
      if (this.required) {
        rules.required = true
      }
      if (this.max) {
        rules.maxArrayLength = this.max
      }
      if (this.min) {
        rules.minArrayLength = this.min
      }
      return isEmpty(rules) ? null : rules
    }
  },
  watch: {
    selected: {
      handler: function () {
        if (isEqual(this.selected, this.value)) {
          return
        }
        this.$emit('input', this.selected)
      },
      deep: true
    },
    value: {
      handler: function () {
        this.selected = cloneDeep(this.value)
      },
      deep: true
    }
  }
}
</script>
