<template>
  <div>
    <notification-list
      :list="currentProspect.prospectNotifications"
    ></notification-list>
  </div>
</template>

<script>
import NotificationList from '../../notification-list/list'

export default {
  components: {
    NotificationList
  },
  props: {
    currentProspect: {
      type: Object,
      default: null
    }
  }
}
</script>
