<template>
  <b-card class="row p-5" no-body>
    <div class="w-75 d-flex justify-content-between">
      <div v-if="phoneNumber">
        <i class="fa fa-phone mr-3 text-medium-gray" />
        <span v-if="!isSelectionCommitee"
          ><a :href="`tel:${phoneNumber}`">{{ phoneNumber }}</a></span
        >
        <span v-if="isSelectionCommitee">{{ phoneNumber }}</span>
      </div>
      <div v-if="emailAddress">
        <i class="fa fa-envelope mr-3 text-medium-gray" />
        <span v-if="!isSelectionCommitee"
          ><b-link @click="$emit('sendEmail')">{{ emailAddress }}</b-link></span
        >
        <span v-if="isSelectionCommitee">{{ emailAddress }}</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { EDIT_SELECTION_COMMITEE_INTERFACE } from '../../constants/permissions'

export default {
  name: 'prospect-detail-contact-info',
  props: {
    phoneNumber: {
      type: String,
      default: ''
    },
    emailAddress: {
      type: String,
      default: ''
    }
  },
  computed: {
    isSelectionCommitee () {
      return this.hasPermission(EDIT_SELECTION_COMMITEE_INTERFACE)
    }
  }
}
</script>
