<template>
  <div>
    <h5>Team</h5>
    <div class="pb-4">
      In het team voeg je alle collega's toe die betrokken zijn bij het
      wervings- en selectieproces van de vacature.
    </div>
    <h6><strong>Teamlid toevoegen</strong></h6>
    <v-autocomplete
      class="m-auto"
      :items="optionList"
      ref="autocomplete"
      :min-len="0"
      :input-attrs="configurationAutocomplete"
      v-model="selected"
      :auto-select-one-item="false"
      :get-label="getLabel"
      :component-item="autocompleteOptions"
      @update-items="search"
    />
    <autocomplete-selected
      v-if="userIsSelected"
      :selected="selected"
      @removeSelected="removeSelected"
    />
    <div class="pt-5">
      <h6><strong>Teamleden</strong></h6>
      <div v-for="member in team" :key="member.user.username">
        <team-member :current-user="currentUser" :member="member"></team-member>
      </div>
      <div class="d-flex justify-content-between p-3">
        <b-btn
          variant="primary"
          :to="{
            name: `${previousStepRoute}`,
            params: { vacancyId: vacancy.vacancyId }
          }"
          ><i class="fa fa-angle-left" /> Vorige stap</b-btn
        >
        <b-btn
          variant="primary"
          :to="{
            name: 'vacancyPublishChannels',
            params: { vacancyId: vacancy.vacancyId }
          }"
          >Volgende stap <i class="fa fa-angle-right"
        /></b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import map from 'lodash/map'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { LOAD_VACANCY_TEAM_MEMBERS, LOAD_USERS } from '../../../store/keys-actions'
import { CURRENT_USER, USERS, VACANCY, VACANCY_TEAM } from '../../../store/keys-getters'
import { EDIT_FAVORITE_TEMPLATE } from '../../../constants/permissions'
import TeamMember from './team-member'
import autocompleteOptions from './autocomplete-option'
import AutocompleteSelected from './autocomplete-selected'

export default {
  components: {
    TeamMember,
    AutocompleteSelected
  },
  data () {
    return {
      selected: null,
      autocompleteOptions: autocompleteOptions,
      items: [],
      configurationAutocomplete: {
        placeholder: 'Zoek gebruiker op naam'
      }
    }
  },
  computed: {
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    users () {
      return this.$store.getters[USERS]
    },
    team () {
      return this.$store.getters[VACANCY_TEAM]
    },
    currentUser () {
      return this.$store.getters[CURRENT_USER]
    },
    availableUsersForNewTeamMembers () {
      // TODO Code refactoring
      const teamMembers = map(this.team, (member) => member.user.username)
      const allowedUserRoleIds = [1, 2, 7]

      return this.users.reduce((acc, user) => {
        if (user.roles.length === 0) return acc
        if (teamMembers.includes(user.username)) return acc
        const filteredRoles = user.roles.filter((role) => allowedUserRoleIds.includes(role.id))
        if (filteredRoles.length === 0) return acc
        return acc.concat(Object.assign(user, { roles: filteredRoles }))
      }, [])
    },
    optionList () {
      return isEmpty(this.items) ? this.availableUsersForNewTeamMembers : this.items
    },
    userIsSelected () {
      return !isEmpty(this.selected)
    },
    canViewCustomizableFormPage () {
      return this.hasPermission(EDIT_FAVORITE_TEMPLATE)
    },
    previousStepRoute () {
      if (this.canViewCustomizableFormPage) {
        return 'vacancyForm'
      }
      return 'vacancyDescription'
    }
  },
  methods: {
    getLabel (item) {
      if (!item) {
        return
      }
      return item.fullName
    },
    search (text) {
      if (!text) {
        this.items = this.availableUsersForNewTeamMembers
        return
      }
      this.items = filter(this.availableUsersForNewTeamMembers, (user) => {
        const fullname = user.fullName.toUpperCase()
        const searchString = text.toUpperCase()
        return fullname.includes(searchString)
      })
    },
    removeSelected () {
      this.selected = null
      this.items = []
    }
  },
  mounted () {
    return Promise.all([this.$store.dispatch(LOAD_VACANCY_TEAM_MEMBERS), this.$store.dispatch(LOAD_USERS)])
  }
}
</script>
