export default {
  state: {
    top: [],
    vacancy: [],
    vacancySide: []
  },
  mutations: {},
  getters: {},
  actions: {}
}
