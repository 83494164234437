<template>
  <page>
    <merge-jobs-detail slot="main-content" />
  </page>
</template>

<script>
import Page from '../page-types/full-page'
import MergeJobsDetail from './merge-jobs-detail'

export default {
  name: 'MergeJobsPage',
  components: {
    Page,
    MergeJobsDetail
  }
}
</script>
