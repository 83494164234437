<template>
  <b-card :style="styles">
    <slot name="message" />
  </b-card>
</template>

<script>
export default {
  name: 'NotificationBlock',
  props: {
    variation: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'warn', 'danger', 'success'].includes(value)
      }
    }
  },
  computed: {
    bgColor () {
      const mapColor = {
        default: '#e6ecf2',
        warn: '#F9F7CD',
        danger: '#F6E7DC',
        success: '#D3F3D2'
      }
      return mapColor[this.variation]
    },
    color () {
      const mapColor = {
        default: '#2f353d',
        warn: '#775807',
        danger: '#661B0F',
        success: '#145B35'
      }
      return mapColor[this.variation]
    },
    styles () {
      return {
        backgroundColor: this.bgColor,
        color: this.color
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-wrapper {
}
</style>
