import {
  ADD_MODAL,
  REMOVE_MODAL,
  INCREMENT_HTTP_REQUESTS
} from '../keys-mutations'
import { LOADING, MODALS } from '../keys-getters'

export default {
  state: {
    modals: [],
    httpRequestCount: 0,
    debug: false
  },
  mutations: {
    [ADD_MODAL]: (state, modal) => {
      state.modals.push(modal)
    },
    [REMOVE_MODAL]: (state, modal) => {
      const index = state.modals.indexOf(modal)
      if (index < 0) {
        return
      }
      state.modals.splice(index, 1)
    },
    [INCREMENT_HTTP_REQUESTS]: (state, increment) => {
      state.httpRequestCount += increment
    }
  },
  getters: {
    [LOADING]: state => state.httpRequestCount > 0,
    [MODALS]: state => state.modals,
    getDebugStatus: state => state.debug
  }
}
