<template>
  <page>
    <vacancy-nav-header slot="header" />
    <prospect slot="main-content" />
    <prospect-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../../page-types/wide-aside-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header'
import ProspectAside from './prospect-aside.vue'
import Prospect from './prospect'
import { LOAD_VACANCY_PROSPECTS, LOAD_CURRENT_PROSPECT } from '../../../store/keys-actions'
import { SET_CURRENT_PROSPECT, SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION } from '../../../store/keys-mutations'

export default {
  name: 'prospectDetail',
  components: {
    Page,
    VacancyNavHeader,
    ProspectAside,
    Prospect
  },
  mounted () {
    this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
      prospectId: this.$route.params.prospectId,
      roundId: +this.$route.params.roundId
    })
    this.$store.dispatch(LOAD_VACANCY_PROSPECTS, this.$route.params.roundId)
  },
  beforeRouteUpdate (to, from, next) {
    this.$store
      .dispatch(LOAD_CURRENT_PROSPECT, { prospectId: to.params.prospectId, roundId: +to.params.roundId })
      .then(() => next())
      .catch(() => next(false))
  },
  beforeRouteLeave (to, from, next) {
    next()
    this.$store.commit(SET_CURRENT_PROSPECT, {})
    this.$store.commit(SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION, null)
  }
}
</script>
