import Vue from 'vue'
import {
  LOAD_VACANCY_COMPETENCES,
  SAVE_VACANCY_COMPETENCES
} from '../../keys-actions'
import { VACANCY, VACANCY_COMPETENCES } from '../../keys-getters'
import apiRequest from '../../../services/api-request'
import { SET_VACANCY_COMPETENCES } from '../../keys-mutations'

export default {
  state: {
    competences: []
  },
  mutations: {
    [SET_VACANCY_COMPETENCES]: (state, competences) =>
      (state.competences = competences)
  },
  getters: {
    [VACANCY_COMPETENCES]: state => state.competences
  },
  actions: {
    [LOAD_VACANCY_COMPETENCES]: ({ commit, getters }) => {
      const { vacancyId } = getters[VACANCY]
      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/vacancy/${vacancyId}/competences`
      })
        .then(data => commit(SET_VACANCY_COMPETENCES, data))
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `Oops! Er is iets fout gegaan. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              error.message
            }`
          })
          return null
        })
    },
    [SAVE_VACANCY_COMPETENCES]: ({ dispatch, getters }, competences) => {
      const { vacancyId } = getters[VACANCY]
      return apiRequest({
        method: 'POST',
        url: `/api/erecruiter-web-api/vacancy/${vacancyId}/competences`,
        data: competences
      })
        .then(() => dispatch(LOAD_VACANCY_COMPETENCES))
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `De competenties konden niet worden opgeslagen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              error.message
            }`
          })
          return null
        })
    }
  }
}
