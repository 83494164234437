<template>
  <div class="list-wrapper" v-show="attachments && attachments.length > 0">
    <b-list-group-item
      variant="medium-gray"
      class="text-uppercase font-weight-bold"
      >documenten</b-list-group-item
    >
    <b-link
      v-for="{
        fileName,
        fileExtension,
        attachmentId,
        url,
        convertedPdfUrl
      } in attachments"
      :key="attachmentId"
      :href="convertedPdfUrl || url"
      target="_blank"
    >
      <b-list-group-item>
        <span class="font-weight-bold">{{
          `${fileName}.${fileExtension}`
        }}</span>
      </b-list-group-item>
    </b-link>
    <b-link v-show="attachments.length === 4 && !searched && !loading">
      <b-list-group-item @click="$emit('extend-search', 'attachments')">
        <span> <i class="fa fa-eye mr-2" />Meer </span>
      </b-list-group-item>
    </b-link>
    <b-list-group-item v-show="loading">
      <div v-show="loading" class="text-center">
        <i class="fa fa-spinner fa-spin" />
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import { GET_SEARCH_ATTACHMENTS, GET_EXTENDED_SEARCH_STATE } from '../../store/keys-getters'

export default {
  computed: {
    attachments () {
      return this.$store.getters[GET_SEARCH_ATTACHMENTS]
    },
    searchState () {
      return this.$store.getters[GET_EXTENDED_SEARCH_STATE]('attachments')
    },
    searched () {
      return this.searchState.searched || false
    },
    loading () {
      return this.searchState.loading || false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../style/variables.scss";

.list-group-item {
  border-top-width: 0;
}
a {
  text-decoration: none !important;
  & > .list-group-item {
    & {
      border-top-width: 1px;
    }
    color: #2f353d !important;
    i {
      color: rgb(134, 134, 134) !important;
    }
    &:hover {
      background-color: $primary;
      color: white !important;
      i {
        color: white !important;
      }
    }
  }
}
</style>
