<template>
  <b-modal
    centered
    title="Openstaande To-Dos"
    :visible="true"
    @hidden="$emit('remove')"
    header-border-variant="light"
    size="lg"
    hide-footer
    body-class="p-0"
  >
    <div v-if="!todos.length">
      <div class="py-5 text-center">
        <span class="h5">Er zijn geen openstaande To-Dos voor uw vacatures.</span>
      </div>
    </div>
    <div v-if="todos.length">
      <b-list-group flush>
        <b-list-group-item
          v-for="todo in todos"
          :key="todo.entityId"
          class="alternating-color-list px-4 px-md-5 py-3"
        >
          <div class="w-100 h-100">
            <div class="small">
              <span class="mr-1 text-black-50"
                >Vacature:
                <b-link @click.prevent="navigateToVacancy(todo.vacancyId)">{{
                  todo.vacancyTitle
                }}</b-link
                >&nbsp;&nbsp;-&nbsp;</span
              ><small class="text-black-50">{{ todo.vacancyId }}</small>
            </div>
            <div class="d-flex justify-content-between align-items-center my-2">
              <div class="todo-item__todo">
                <h5 class="mb-1">{{ todo.title }}</h5>
                <small class="text-uppercase text-muted">{{
                  todo.lSharingType | parseSharingType
                }}</small>
              </div>
              <div class="d-flex align-items-baseline flex-column flex-lg-row">
                <small class="text-black-50 mr-1">Deadline:</small>
                <div class="due-date__date">
                  <time :datetime="todo.dueDate" class="mr-1">{{
                    todo.dueDate | formatDate
                  }}</time>
                  <i
                    v-if="Date.parse(todo.dueDate) < Date.now()"
                    class="fa fa-exclamation-circle text-danger"
                  ></i>
                </div>
              </div>
            </div>
            <div>
              <small class="text-black-50"
                >Aangemaakt op:
                <time :datetime="todo.createDate">{{
                  todo.createDate | formatDate
                }}</time></small
              >
            </div>
          </div>
        </b-list-group-item>
      </b-list-group>
    </div>
  </b-modal>
</template>

<script>
import moment from 'moment'

export default {
  name: 'PendingTodosModal',
  props: {
    todos: {
      type: Array,
      required: true
    }
  },
  filters: {
    formatDate (date) {
      return moment
        .utc(date)
        .local()
        .format('L')
    },
    parseSharingType (lSharingType) {
      if (lSharingType === 'PUBLIC') {
        return 'iedereen'
      }
      return 'mijzelf'
    }
  },
  methods: {
    navigateToVacancy (vacancyId) {
      this.$emit('remove')
      this.$router.push({
        name: 'vacancy',
        params: { vacancyId }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.due-date__date {
  white-space: nowrap;
}
</style>
