var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row p-4 border-bottom border-medium-gray"},[_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('b-link',{attrs:{"to":{
          name: 'prospectJudgements',
          params: {
            vacancyId: _vm.vacancy.vacancyId,
            roundId: _vm.vacancy.latestRoundId
          }
        }}},[_c('div',[_c('i',{staticClass:"fa fa-angle-left"}),_vm._v(" terug naar sollitantenoverzicht ")])]),_c('div',[_c('b-btn',{staticClass:"mr-2 border border-medium-gray",attrs:{"variant":"white","size":"sm","disabled":!(_vm.prospectsOrder[_vm.currentProspect.prospectId] || {}).prev,"to":{
            name: 'prospectJudgementDetail',
            params: {
              vacancyId: _vm.vacancy.vacancyId,
              index: (_vm.prospectsOrder[_vm.currentProspect.prospectId] || {}).prev,
              roundId: _vm.vacancy.latestRoundId
            }
          }}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('b-btn',{staticClass:"border border-medium-gray",attrs:{"variant":"white","size":"sm","disabled":!(_vm.prospectsOrder[_vm.currentProspect.prospectId] || {}).next,"to":{
            name: 'prospectJudgementDetail',
            params: {
              vacancyId: _vm.vacancy.vacancyId,
              index: (_vm.prospectsOrder[_vm.currentProspect.prospectId] || {}).next,
              roundId: _vm.vacancy.latestRoundId
            }
          }}},[_c('i',{staticClass:"fa fa-chevron-right"})])],1)],1)]),_c('prospect-detail-header',{attrs:{"candidate-source":_vm.candidateSource,"publishChannel":_vm.currentProspect.publishChannel,"fullname":_vm.fullname}}),_c('prospect-detail-contact-info',{attrs:{"phoneNumber":_vm.currentProspect.phoneNumber,"emailAddress":_vm.currentProspect.emailAddress}}),_c('prospect-detail-motivation',{attrs:{"motivation":_vm.currentProspect.motivation}}),_c('prospect-detail-documents',{attrs:{"attachments":_vm.currentProspect.attachments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }