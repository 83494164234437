var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-4 d-flex flex-column alternating-color-list border-left border-right border-bottom border-light-gray"},[_c('div',{staticClass:"d-flex align-items-center w-100"},[(!_vm.viewerIsExternal && _vm.isLatestRound)?_c('b-form-checkbox',{attrs:{"value":true,"unselected-value":false,"plain":""},on:{"input":function($event){return _vm.$emit('input', _vm.isChecked)}},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}):_vm._e(),_c('div',{staticClass:"mr-4 ml-2 d-none d-md-block prospect-avatar__container"},[_c('b-link',{key:_vm.prospect.prospectId,staticClass:"d-flex flex-column avatar-link",attrs:{"to":{
          name: 'vacancyProspectDetail',
          params: {
            vacancyId: this.vacancy.vacancyId,
            prospectId: this.prospect.prospectId,
            roundId: this.$route.params.roundId || this.vacancy.latestRoundId
          }
        }},on:{"click":function($event){return _vm.$emit('setRatingTypeForProspectNavigation')}}},[(!_vm.prospect.avatarUrl)?_c('avatar',{attrs:{"fullname":_vm.fullname}}):_vm._e(),(_vm.prospect.avatarUrl)?_c('div',{staticClass:"d-flex align-items-center justify-content-around",staticStyle:{"width":"96px","height":"96px","overflow":"hidden"}},[(_vm.prospect.avatarUrl)?_c('img',{staticClass:"img-thumbnail p-0 border-0 bg-transparent",attrs:{"src":_vm.prospect.avatarUrlLocal}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"avatar-link--underline"})],1)],1),_c('div',{staticClass:"d-flex flex-column ml-3 ml-md-0 prospect-details__container"},[(!_vm.viewerIsExternal)?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('h5',[_c('strong',[_c('b-link',{attrs:{"to":{
                  name: 'vacancyProspectDetail',
                  params: {
                    vacancyId: this.vacancy.vacancyId,
                    prospectId: this.prospect.prospectId,
                    roundId:
                      this.$route.params.roundId || this.vacancy.latestRoundId
                  }
                }},on:{"click":function($event){return _vm.$emit('setRatingTypeForProspectNavigation')}}},[_vm._v(" "+_vm._s(_vm.fullname)+" ")])],1),(_vm.prospectIsNew)?_c('small',{staticClass:"ml-1"},[_c('i',{staticClass:"fa fa-circle text-new"})]):_vm._e()])]),(_vm.isLatestRound)?_c('b-btn',{staticClass:"text-black mb-1 p-0 ml-2",attrs:{"id":("popover" + (_vm.tabDetails.title) + (_vm.prospect.prospectId)),"size":"lg","variant":"link"}},[_c('i',{staticClass:"fa fa-pencil-square-o text-medium-gray"})]):_vm._e(),(_vm.isLatestRound)?_c('b-popover',{attrs:{"target":("popover" + (_vm.tabDetails.title) + (_vm.prospect.prospectId)),"triggers":"focus","placement":"bottom"}},[_c('template',{slot:"title"},[_vm._v(" Notities & to-do's ")]),_vm._l((_vm.prospectNotifications),function(notification){return [(notification.title)?_c('notification-list-item',{key:notification.entityId,staticStyle:{"width":"200px"},attrs:{"notification":notification,"speechBubble":false}}):_vm._e()]}),(
              !_vm.prospect.notifications || _vm.prospect.notifications.length === 0
            )?_c('div',[_vm._v(" Op dit moment nog geen activiteiten. ")]):_vm._e()],2):_vm._e()],1):_vm._e(),(_vm.candidateSource)?_c('div',[_vm._v(_vm._s(_vm.candidateSource))]):_vm._e(),(_vm.applicationDate)?_c('div',[_vm._v(_vm._s(_vm.applicationDate))]):_vm._e(),_c('div',{staticClass:"d-flex text-medium-gray"},[(_vm.isLatestRound)?_c('b-link',{staticClass:"text-medium-gray mr-2",attrs:{"variant":"link"},on:{"click":function($event){return _vm.$emit('sendEmail', 'general', {
              to: _vm.fullname,
              prospectId: _vm.prospect.prospectId,
              noSelectedNeeded: true
            })}}},[_c('i',{staticClass:"fa fa-envelope"})]):_vm._e(),(
            _vm.isLatestRound && (_vm.prospect.phoneNumber || _vm.prospect.mobileNumber)
          )?_c('div',[_vm._v(" | ")]):_vm._e(),_c('b-link',{staticClass:"text-medium-gray",class:{ 'ml-2': _vm.isLatestRound },attrs:{"href":("tel:" + (_vm.prospect.phoneNumber
                ? _vm.prospect.phoneNumber
                : _vm.prospect.mobileNumber))}},[_c('strong',[_vm._v(" "+_vm._s(_vm.prospect.phoneNumber ? _vm.prospect.phoneNumber : _vm.prospect.mobileNumber)+" ")])])],1)]),(_vm.prospectJudgements.length)?_c('div',{staticClass:"d-none d-lg-flex mr-4 ml-4 flex-column justify-content-between prospect-judgements__wrapper"},[_c('span',[_c('strong',[_vm._v("Selectiecommissie: ")]),_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.showProspectJudgementsExplanations.apply(null, arguments)}}},[_vm._v("toelichtingen")])],1),_c('div',{staticClass:"prospect-judgements__container"},_vm._l((_vm.prospectJudgements),function(judgement,index){return _c('div',{key:index,staticClass:"p-2",class:index < _vm.prospectJudgements.length - 1
              ? 'border-bottom border-medium-light'
              : ''},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('strong',[_vm._v(_vm._s(judgement.fullname))])]),_c('div',[_c('i',{class:_vm.icons[judgement.score]})])]),_vm._v(" "+_vm._s(judgement.comment)+" ")])}),0)]):_c('div',{staticClass:"prospect-judgements__placeholder"}),_c('div',{staticClass:"ml-auto d-flex justify-content-between prospect-status__container",class:{ 'ml-auto': !_vm.prospectJudgements.length }},[(_vm.prospectJudgements.length)?_c('div',{staticClass:"d-none d-md-flex d-lg-none ml-3 mr-4 flex-column justify-content-center"},[_c('strong',{staticClass:"text-center"},[_vm._v("Selectiecommissie")]),_c('b-btn',{staticClass:"mb-3 border border-medium-gray bg-white",attrs:{"variant":"medium-gray"},on:{"click":_vm.showProspectJudgementsExplanations}},[_vm._v("toelichtingen")])],1):_vm._e(),(_vm.tabDetails.options)?_c('div',{staticClass:"ml-2"},[_vm._m(0),_c('b-form-select',{staticClass:"mb-3",attrs:{"name":"status"},on:{"input":function($event){return _vm.$emit('saveNewStatus', {
              status: _vm.status,
              prospectId: _vm.prospect.prospectId
            })}},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._l((_vm.tabDetails.options),function(option,key){return [(option.displayForApplicants || _vm.isCandidate)?_c('option',{key:(key + " - " + (option.value)),attrs:{"disabled":option.disabled},domProps:{"value":option.value}},[_vm._v(_vm._s(option.text))]):_vm._e()]})],2)],1):_vm._e(),(!_vm.tabDetails.displayRatingButtons && !_vm.tabDetails.options)?_c('div',{staticClass:"ml-2"},[_c('div',[_vm._v("Laatste beoordeling:")]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm._f("formatRating")(_vm.prospect.recruiterRating)))])]),_c('div',[_vm._v("Laatste status:")]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm._f("formatStatus")(_vm.prospect.recruiterStatus)))])])]):_vm._e(),(_vm.tabDetails.displayRatingButtons && _vm.isLatestRound)?_c('div',{staticClass:"ml-auto"},[_c('prospect-rating-buttons',{staticClass:"flex-column",class:_vm.prospectJudgements.length ? 'flex-xl-row' : 'flex-lg-row',staticStyle:{"width":"max-content"},attrs:{"ratingButtons":_vm.ratingButtons,"direction":"column"},on:{"changeRating":_vm.changeRating}})],1):_vm._e()])],1),(_vm.prospectJudgements.length)?_c('div',{staticClass:"d-block d-sm-none mt-2 ml-4"},[_c('strong',[_vm._v("Selectiecommissie:")]),_c('b-link',{on:{"click":function($event){$event.preventDefault();return _vm.showProspectJudgementsExplanations.apply(null, arguments)}}},[_vm._v(" toelichtingen")])],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{attrs:{"for":"status"}},[_c('strong',[_vm._v("Status")])])}]

export { render, staticRenderFns }