<template>
  <i class="px-0 mr-0" :class="publishedIconClass" />
</template>
<script>
export default {
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  },
  computed: {
    publishedIconClass () {
      return (this.vacancy.isPublished || this.vacancy.isCurrentlyPublished)
        ? 'fa fa-circle text-published'
        : 'fa fa-circle-thin text-muted'
    }
  }
}
</script>
