import Vue from 'vue'
import VeeValidate, { Validator } from 'vee-validate'
import defaultsDeep from 'lodash/defaultsDeep'

import nl from 'vee-validate/dist/locale/nl'
import customNL from './custom-dictionaries/nl'

import maxArrayLength from './custom-validators/max-array-length'
import minArrayLength from './custom-validators/min-array-length'
import urlOrMailto from './custom-validators/url-or-mailto'

Validator.extend('maxArrayLength', maxArrayLength)
Validator.extend('minArrayLength', minArrayLength)
Validator.extend('urlOrMailto', urlOrMailto)

Vue.use(VeeValidate)

defaultsDeep(nl, customNL)
Validator.localize('nl', nl)
