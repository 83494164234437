<template>
  <div>
    <div>
      <iframe style="width: 100%; height: 900px" :src="mimirUrl"></iframe>
    </div>
    <div class="col-12 d-flex pt-5">
      <b-btn variant="primary" class="mr-auto" @click="previous">
        <i class="fa fa-chevron-left" />
        Vorige stap
      </b-btn>
    </div>
  </div>
</template>

<script>
import { CURRENT_TENANT } from '../../../store/keys-getters'

export default {
  computed: {
    currentTenant () {
      return this.$store.getters[CURRENT_TENANT]
    },
    mimirUrl () {
      return `https://www.mmimir.nu/mmimir/smart?consultant=${this.currentTenant.vacancylistAccessToken}&id=${this.$route.params.vacancyId}&action=embed`
    }
  },
  methods: {
    previous () {
      this.$router.push({ name: 'vacancyTeam' })
    }
  }
}
</script>
