<template>
  <div>
    <b-nav>
      <b-nav-item
        :active="this.$route.name === 'vacancy'"
        :to="{ name: 'vacancy' }"
        exact
        >overzicht</b-nav-item
      >

      <span class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item
        :active="this.$route.name === 'vacancyDescription'"
        :to="{ name: 'vacancyDescription' }"
        >opstellen</b-nav-item
      >

      <span
        v-if="showFormLink"
        class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item
        id="prospect-form"
        :disabled="disableFormLink"
        :active="this.$route.name === 'vacancyForm'"
        v-if="showFormLink"
        :to="{ name: 'vacancyForm' }"
        >sollicitatie formulier</b-nav-item
      >

      <b-tooltip
        target="prospect-form"
        :disabled="!disableFormLink"
        triggers="hover"
        placement="bottom"
        custom-class="tooltip"
      >
        Selecteer "Jobsrepublic One" onder Opstellen > Solliciteren om dit in te
        schakelen en klik dan op "Tussentijds opslaan" of "Volgende Stap".
      </b-tooltip>

      <span
        v-if="showTeamLink"
        class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item
        :active="this.$route.name === 'vacancyTeam'"
        v-if="showTeamLink"
        :to="{ name: 'vacancyTeam' }"
        >team</b-nav-item
      >

      <span
        v-if="showPublicationLink"
        class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item
        :active="this.$route.name === 'vacancyPublishChannels'"
        v-if="showPublicationLink"
        :to="{ name: 'vacancyPublishChannels' }"
        >publicatie</b-nav-item
      >

      <span
        v-if="showApplicantsLink"
        class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item
        :active="this.$route.name === 'vacancySelectionRound'"
        v-if="showApplicantsLink"
        :to="{
          name: 'vacancySelectionRound',
          params: { roundId: vacancy.latestRoundId }
        }"
        >sollicitanten
      </b-nav-item>
      <span
        v-if="showMimirLink"
        class="mt-auto mb-auto pr-1 font-weight-light nav-item__separator"
        >|</span
      >
      <b-nav-item v-if="showMimirLink" :to="{ name: 'vacancyPublishMimir' }"
        >mimir</b-nav-item
      >
    </b-nav>
  </div>
</template>
<script>
export default {
  name: 'VacancyHeaderNavigation',
  props: {
    showApplicantsLink: {
      type: Boolean,
      default: false
    },
    showFormLink: {
      type: Boolean,
      default: false
    },
    disableFormLink: {
      type: Boolean,
      default: false
    },
    showPublicationLink: {
      type: Boolean,
      default: false
    },
    showTeamLink: {
      type: Boolean,
      default: false
    },
    showMimirLink: {
      type: Boolean,
      default: false
    },
    vacancy: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../../style/variables.scss";

</style>
