<template>
  <div class="row pt-4 pb-3" style="min-height: 150px;">
    <div class="col-12">
      <div class="d-flex flex-column no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3 pb-3">
          AVG manager
        </h1>
      </div>
      <div class="row no-gutter">
        <b-nav>
          <b-nav-item :to="{ name: 'gdprExpiredUsers' }" exact exact-active-class="active">
            Bewaartermijn verlopen
            <b-badge variant="light"
              ><i class="fa fa-user" /> {{ expiredCount }}</b-badge
            >
          </b-nav-item>

          <span class="mt-auto mb-auto pr-1 font-weight-light">|</span>

          <b-nav-item :to="{ name: 'gdprAlmostExpiredUsers' }" exact exact-active-class="active">
            Verloopt &lt; 30 dagen
            <b-badge variant="light"
              ><i class="fa fa-user" /> {{ expiresSoonCount }}</b-badge
            >
          </b-nav-item>

          <span class="mt-auto mb-auto pr-1 font-weight-light">|</span>

          <b-nav-item :to="{ name: 'gdprActiveUsers' }" exact exact-active-class="active">
            Nog > 30 dagen
            <b-badge variant="light"
              ><i class="fa fa-user" /> {{ activeCount }}</b-badge
            >
          </b-nav-item>

          <span class="mt-auto mb-auto pr-1 font-weight-light">|</span>

          <b-nav-item :to="{ name: 'gdprNoConsentUsers' }" exact exact-active-class="active">
            Bewaartermijn onbekend
            <b-badge variant="light"
              ><i class="fa fa-user" /> {{ noConsentCount }}</b-badge
            >
          </b-nav-item>
        </b-nav>
      </div>
    </div>
  </div>
</template>

<script>
import sumBy from 'lodash/sumBy'
import {
  GDPR_EXPIRED_APPLICANTS,
  GDPR_NO_CONSENT_APPLICANTS,
  GDPR_NOT_SOON_EXPIRING_APPLICANTS,
  GDPR_SOON_EXPIRING_APPLICANTS
} from '../../store/keys-getters'

export default {
  name: 'GdprNavHeader',
  computed: {
    expiredCount () {
      return sumBy(this.$store.getters[GDPR_EXPIRED_APPLICANTS], (vacancy) => vacancy.applicants.length)
    },
    noConsentCount () {
      return sumBy(this.$store.getters[GDPR_NO_CONSENT_APPLICANTS], (vacancy) => vacancy.applicants.length)
    },
    activeCount () {
      return sumBy(this.$store.getters[GDPR_NOT_SOON_EXPIRING_APPLICANTS], (vacancy) => vacancy.applicants.length)
    },
    expiresSoonCount () {
      return sumBy(this.$store.getters[GDPR_SOON_EXPIRING_APPLICANTS], (vacancy) => vacancy.applicants.length)
    }
  }
}
</script>
