<template>
  <b-modal
    centered
    id="email-modal"
    :visible="true"
    @ok="validateEmail"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    ok-title="E-mail versturen"
    size="lg"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div class="container">
      <div class="row mb-1">
        <label class="col-2" for="email-to">Aan</label>
        <b-form-input
          size="sm"
          class="col-7 col-lg-8"
          id="email-to"
          type="text"
          :disabled="!this.enableTo"
          v-model="to"
        />
        <div class="col-3 col-lg-2 d-flex">
          <b-btn
            v-if="!displayCC"
            size="sm"
            variant="link"
            class="text-black"
            @click="displayCC = true"
            >CC</b-btn
          >
          <b-btn
            v-if="!displayBCC"
            size="sm"
            variant="link"
            class="text-black"
            @click="displayBCC = true"
            >BCC</b-btn
          >
        </div>
      </div>
      <div v-if="displayCC" class="row mb-1">
        <label class="col-2" for="cc">CC</label>
        <b-form-input
          size="sm"
          id="cc"
          class="col-10"
          type="text"
          v-model="cc"
        />
      </div>
      <div v-if="displayBCC" class="row mb-1">
        <label class="col-2" for="bcc">BCC</label>
        <b-form-input
          size="sm"
          id="bcc"
          class="col-10"
          type="text"
          v-model="bcc"
        />
      </div>
      <div class="row mb-1">
        <label class="col-3 col-lg-2" for="subject">Onderwerp</label>
        <div class="col-9 col-lg-10 p-0 mb-0">
          <b-form-input
            size="sm"
            id="subject"
            name="subject"
            type="text"
            v-model="subject"
            placeholder="Onderwerp"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('subject') }"
            data-vv-as="Onderwerp"
          />
          <div v-show="errors.has('subject')" class="invalid-feedback">
            {{ errors.first("subject") }}
          </div>
        </div>
      </div>
      <div class="mt-3 mb-3 d-flex">
        <b-dropdown
          v-if="viewTemplates"
          id="ddown1"
          class="border border-medium-gray mr-1 scrollable-dropdown"
          variant="medium-light"
        >
          <template slot="button-content">
            Voeg template in
            <i class="fa fa-caret-down" />
          </template>
          <b-dropdown-item
            class="d-flex justify-content-between pr-0"
            v-for="template in emailTemplates"
            :key="template.templateId"
            @click="useTemplate(template)"
          >
            <div
              class="d-flex flex-row justify-content-between align-items-center"
            >
              <div>{{ template.subject }}</div>
              <b-btn variant="link" @click="deleteTemplate(template)">
                <i class="fa fa-trash-o" />
              </b-btn>
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown
          size="sm"
          class="border border-medium-gray"
          variant="medium-light"
        >
          <template slot="button-content">
            Selecteer placeholder
            <i class="fa fa-caret-down" />
          </template>
          <b-dropdown-item
            v-for="placeholder in emailPlaceholders"
            @click="insertPlaceholder(placeholder.value)"
            :value="placeholder"
            :key="placeholder.lConst"
          >
            <div>{{ placeholder.value }}</div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <vue-editor-with-image-upload
        :private-details="{ description: emailText }"
        @input="handleInput"
      />
      <div v-show="errors.has('body')" class="text-error">
        {{ errors.first("body") }}
      </div>
      <b-form-file
        size="sm"
        class="mt-1"
        multiple
        v-model="files"
        browse-text="Bladeren"
        placeholder="Geen bestand gekozen"
      ></b-form-file>
      <div
        v-if="editTemplates"
        class="d-flex justify-content-end align-items-center mt-3"
      >
        <b-form-checkbox
          class="justify-self-end mr-1"
          plain
          :disabled="templateExists"
          v-model="saveNewTemplate"
        >
          <small>Opslaan als nieuwe template</small>
        </b-form-checkbox>
        <div>
          <small>
            <i
              v-b-tooltip.hover
              title="Wanneer je een template opslaat kan deze voor elke e-mail worden hergebruikt"
              class="fa fa-info-circle"
            />
          </small>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import {
  LOAD_EMAIL_TEMPLATES,
  LOAD_EMAIL_PLACEHOLDERS,
  DELETE_EMAIL_TEMPLATE,
  SAVE_EMAIL_TEMPLATE
} from '../store/keys-actions'
import {
  EMAIL_TEMPLATES,
  EMAIL_PLACEHOLDERS,
  CURRENT_USER
} from '../store/keys-getters'
import {
  VIEW_FAVORITE_TEMPLATE,
  EDIT_FAVORITE_TEMPLATE
} from '../constants/permissions'
import some from 'lodash/some'
import flattenDeep from 'lodash/flattenDeep'
import VueEditorWithImageUpload from './vue-editor-with-image-upload'
import bus from '../event-bus'

export default {
  name: 'EmailModal',
  components: {
    VueEditorWithImageUpload
  },
  props: {
    defaults: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      displayCC: !!this.defaults.cc,
      displayBCC: !!this.defaults.bcc,
      to: this.defaults.to,
      cc: this.defaults.cc || '',
      bcc: this.defaults.bcc || '',
      subject: this.defaults.subject || '',
      emailText: this.makeDefaultEmailText(
        this.defaults.message,
        this.$store.getters[CURRENT_USER].emailSignature
      ),
      files: this.defaults.files || null,
      enableTo: !this.defaults.to,
      customEditorToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ header: [1, 2, 3, 4] }],
        [{ color: [] }, { background: [] }],
        [{ align: [] }],
        ['link']
      ],
      saveNewTemplate: false
    }
  },
  computed: {
    emailTemplates () {
      return this.$store.getters[EMAIL_TEMPLATES]
    },
    templateExists () {
      return some(this.emailTemplates, ['subject', this.subject])
    },
    emailPlaceholders () {
      return this.$store.getters[EMAIL_PLACEHOLDERS]
    },
    ccArray () {
      if (!this.cc) return []
      const splitNames = this.cc.split(',').map(el => el.split(';'))
      return flattenDeep(splitNames).map(el => el.trim())
    },
    bccArray () {
      if (!this.bcc) return []
      const splitNames = this.bcc.split(',').map(el => el.split(';'))
      return flattenDeep(splitNames).map(el => el.trim())
    },
    toArray () {
      if (!this.to) return []
      const splitNames = this.to.split(',').map(el => el.split(';'))
      return flattenDeep(splitNames).map(el => el.trim())
    },
    viewTemplates () {
      return this.hasPermission(VIEW_FAVORITE_TEMPLATE)
    },
    editTemplates () {
      return this.hasPermission(EDIT_FAVORITE_TEMPLATE)
    },
    isSingleProspect () {
      return !!this.defaults.prospectId
    }
  },
  methods: {
    validateEmail (e) {
      e.preventDefault()
      this.$validator.validate().then(isValid => {
        if (!isValid) {
          return
        }
        if (this.saveNewTemplate) {
          this.$store.dispatch(SAVE_EMAIL_TEMPLATE, {
            subject: this.subject,
            body: this.emailText
          })
        }
        this.$emit('resolve', {
          attachments: this.files,
          to: this.toArray,
          bcc: this.bccArray,
          cc: this.ccArray,
          message: this.emailText,
          subject: this.subject
        })
        this.$emit('remove')
      })
    },
    useTemplate (template) {
      this.emailText = template.body
      this.subject = template.subject
    },
    insertPlaceholder (placeholder) {
      bus.$emit('insert-placeholder', placeholder)
    },
    deleteTemplate ({ subject, templateId }) {
      const confirmed = confirm(`Wil je de template "${subject}" verwijderen?`)
      if (!confirmed) {
        return
      }
      this.$store.dispatch(DELETE_EMAIL_TEMPLATE, templateId)
    },
    handleInput (textContent) {
      this.emailText = textContent
    },
    makeDefaultEmailText (message, signature) {
      if (this.defaults.noSignature) signature = null
      if (message && !signature) return message
      if (!message && signature) return '<br/><br/><br/>' + signature
      if (message && signature) return message + signature
      return ''
    }
  },
  mounted () {
    if (this.viewTemplates) {
      this.$store.dispatch(LOAD_EMAIL_TEMPLATES)
    }
    this.$store.dispatch(LOAD_EMAIL_PLACEHOLDERS)
  }
}
</script>

<style lang="scss">
.scrollable-dropdown > ul {
  max-height: 100vh;
  overflow-y: auto;
}
</style>
