<template>
  <page>
    <vacancy-nav-header slot="header" />
    <vacancy-team slot="main-content" />
    <vacancy-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../../page-types/default-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header.vue'
import VacancyTeam from './team.vue'
import VacancyAside from '../vacancy-aside.vue'

export default {
  name: 'VacancyTeamPage',
  components: {
    Page,
    VacancyNavHeader,
    VacancyTeam,
    VacancyAside
  }
}
</script>
