import Vue from 'vue'
import Vuex from 'vuex'
import each from 'lodash/each'
import { warn, debug } from '../services/log'

import main from './modules/main'
import authorization from './modules/authorization'
import vacancy from './modules/vacancy/'
import navigation from './modules/navigation'
import gdpr from './modules/gdpr'
import tenants from './modules/tenants'
import mergeJobs from './modules/merge-jobs'
import users from './modules/users'
import vacancies from './modules/vacancies'
import selectionCommittee from './modules/selection-committee'
import tokenProspects from './modules/token-prospects'
import lookups from './modules/lookups'
import emails from './modules/emails'
import globalSearch from './modules/global-search'
import * as getters from './keys-getters'
import * as mutations from './keys-mutations'
import * as actions from './keys-actions'

if (process.env.NODE_ENV === 'development') {
  // check the format of the store keys

  const uniqueKeys = {}
  const keys = {
    actions,
    getters,
    mutations
  }
  each(keys, keyGroup => {
    each(keyGroup, (val, key) => {
      if (val !== key) {
        warn(
          `The key '${key}' should have the same value as the key. Now it is '${val}'`
        )
      }
      if (key.toUpperCase() !== key) {
        warn(`The key '${key}' should be upper case`)
      }
      if (uniqueKeys[key]) {
        warn(`The key '${key}' is not unique`)
      }
      uniqueKeys[key] = val
    })
  })
}

Vue.use(Vuex)

const strict = process.env.NODE_ENV === 'development'
const store = new Vuex.Store({
  strict,
  modules: {
    main,
    authorization,
    vacancy,
    navigation,
    gdpr,
    vacancies,
    selectionCommittee,
    tenants,
    users,
    tokenProspects,
    lookups,
    emails,
    mergeJobs,
    globalSearch
  }
})

debug(`Created Vuex store${strict ? ' in strict mode' : ''}`)

export default store
