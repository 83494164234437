<template>
  <div>
    <div class="row p-4 border-bottom border-medium-gray">
      <div class="w-100 d-flex justify-content-between">
        <b-link
          :to="{
            name: 'tokenProspects'
          }"
        >
          <div>
            <i class="fa fa-angle-left" /> terug naar sollitantenoverzicht
          </div>
        </b-link>
        <div>
          <b-btn
            variant="white"
            class="mr-2 border border-medium-gray"
            size="sm"
            :disabled="nextAndPreviousNavigation.previous <= 0"
            :to="{
              name: 'tokenProspectsDetail',
              params: { id: nextAndPreviousNavigation.previous }
            }"
          >
            <i class="fa fa-chevron-left" />
          </b-btn>
          <b-btn
            variant="white"
            class="border border-medium-gray"
            size="sm"
            :disabled="nextAndPreviousNavigation.next >= prospectMaxIndex"
            :to="{
              name: 'tokenProspectsDetail',
              params: { id: nextAndPreviousNavigation.next }
            }"
          >
            <i class="fa fa-chevron-right" />
          </b-btn>
        </div>
      </div>
    </div>
    <prospect-detail-header
      :loading="isLoading"
      :candidate-source="candidateSource"
      :avatarUrl="currentProspect.avatarUrl"
      :publishChannel="currentProspect.publishChannel"
      :fullname="fullname"
      :recruiterRating="currentProspect.lRecruiterRating"
      :prospectId="currentProspect.prospectId"
      :actionsDropdown="actionsDropdownList"
    />
    <prospect-detail-contact-info
      :phoneNumber="currentProspect.phoneNumber || currentProspect.mobileNumber"
      :emailAddress="currentProspect.emailAddress"
    />
    <prospect-work-experience
      v-if="prospectIsCandidate"
      :work-experience="currentProspect.experienceLst"
    />
    <prospect-work-experience-other
      v-if="
        prospectIsCandidate &&
          currentProspect.otherExperienceLst &&
          currentProspect.otherExperienceLst.length
      "
      :other-work-experience="currentProspect.otherExperienceLst"
    />
    <prospect-education
      v-if="prospectIsCandidate"
      :education="currentProspect.educationLst"
      :highest-level="currentProspect.highestEducation"
    />
    <prospect-courses
      v-if="prospectIsCandidate"
      :education="currentProspect.coursesLst"
    />
    <prospect-wishes-and-information
      v-if="prospectIsCandidate"
      :current-prospect="currentProspect"
    />
    <prospect-detail-motivation
      v-if="!prospectIsCandidate"
      :motivation="currentProspect.motivation"
    />
    <prospect-detail-documents :attachments="currentProspect.attachments" />
  </div>
</template>

<script>
import { VACANCY, OPEN_VACANCIES, LOADING, RATING_TYPE_FOR_PROSPECT_NAVIGATION } from '../../../store/keys-getters'
import { SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE, SHARE_SELECTION } from '../../../constants/permissions'
import { PROSPECT_CANDIDATE } from '../../../constants/prospect/prospect-types'
import ProspectDetailHeader from '../../prospect-details-components/prospect-detail-header'
import ProspectDetailContactInfo from '../../prospect-details-components/prospect-detail-contact-info'
import ProspectDetailMotivation from '../../prospect-details-components/prospect-detail-motivation'
import ProspectDetailDocuments from '../../prospect-details-components/prospect-detail-documents'
import ProspectWorkExperience from '../../prospect-details-components/prospect-work-experience'
import ProspectWorkExperienceOther from '../../prospect-details-components/prospect-work-experience-other'
import ProspectEducation from '../../prospect-details-components/prospect-education'
import ProspectCourses from '../../prospect-details-components/prospect-courses'
import ProspectWishesAndInformation from '../../prospect-details-components/prospect-wishes-and-information'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'

export default {
  components: {
    ProspectDetailDocuments,
    ProspectDetailMotivation,
    ProspectDetailContactInfo,
    ProspectDetailHeader,
    ProspectWorkExperience,
    ProspectWorkExperienceOther,
    ProspectEducation,
    ProspectCourses,
    ProspectWishesAndInformation
  },
  props: {
    prospectMaxIndex: {
      type: Number,
      required: true
    },
    currentIndex: {
      type: Number,
      required: true
    },
    currentProspect: {
      type: Object,
      default: null
    }
  },
  computed: {
    isLoading () {
      return this.$store.getters[LOADING]
    },

    vacancy () {
      return this.$store.getters[VACANCY]
    },
    candidateSource () {
      const map = {
        CHANNEL_CAT_HIDDEN: 'ONE sollicitant',
        CHANNEL_CAT_EXTERNAL: 'Externe sollicitant',
        CHANNEL_CAT_REGIONAL: 'Regionale sollicitant',
        CHANNEL_CAT_INTERNAL: 'Interne sollicitant'
      }
      return map[(this.currentProspect.publishChannel || {}).channelCat] || ''
    },
    fullname () {
      return isEmpty(this.currentProspect) ? '' : `${this.currentProspect.firstname} ${this.currentProspect.middleName ? this.currentProspect.middleName : ''} ${this.currentProspect.lastname}`
    },
    actionsDropdownList () {
      return [
        { key: 'print', label: 'Print', display: true, method: this.print },
        {
          key: 'copyToOtherVacancy',
          label: 'Kopieer naar andere vacature',
          display: true
        },
        { key: 'deleteProspect', label: 'Verwijder sollicitant', display: true },
        { key: 'anonymizeProspect', label: 'Anonimiseer sollicitant', display: true },
        {
          key: 'sendToSelectionCommittee',
          label: 'Voordragen aan selectiecommissie',
          display: this.hasPermission(SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE)
        },
        { key: 'sharePerEmail', label: 'Delen per e-mail', display: this.hasPermission(SHARE_SELECTION) }
      ]
    },
    openStartedVacancies () {
      const vacancies = this.$store.getters[OPEN_VACANCIES]
      return filter(
        vacancies,
        (vacancy) =>
          vacancy.status !== 'CREATED' && vacancy.status !== 'SUBMITTED' && vacancy.vacancyId !== this.vacancy.vacancyId
      )
    },
    prospectIsCandidate () {
      return this.currentProspect.lProspectType === PROSPECT_CANDIDATE
    },
    prospectTypeForNavigation () {
      return this.$store.getters[RATING_TYPE_FOR_PROSPECT_NAVIGATION]
    },
    nextAndPreviousNavigation () {
      return {
        next: this.currentIndex + 1,
        previous: this.currentIndex - 1
      }
    }
  },
  methods: {
    print () {
      window.open(`/api/erecruiter-web-api/prospect/${this.currentProspect.prospectId}/print.pdf`, '_blank')
    }
  }
}
</script>
