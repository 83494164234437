import axios from 'axios'
import router from '../router'
import store from '../store'
import { CHECK_TOKEN } from '../store/keys-actions'
import { INCREMENT_HTTP_REQUESTS } from '../store/keys-mutations'

const axiosClient = axios.create({
  timeout: 60 * 1000,
  headers: { 'x-client': 'erecruiter-ui' }
})

const axiosMethod = methodName => (...args) => {
  return store.dispatch(CHECK_TOKEN)
    .then(() => {
      store.commit(INCREMENT_HTTP_REQUESTS, 1)
      return axiosClient[methodName](...args)
    })
    .then(response => {
      store.commit(INCREMENT_HTTP_REQUESTS, -1)
      return response.data
    })
    .catch(er => {
      store.commit(INCREMENT_HTTP_REQUESTS, -1)

      if (er.response.status === 401 || er.response.status === 403) {
        router.push({ name: 'userPermission' })
      }

      throw er
    })
}

export default axiosMethod('request')

export const post = axiosMethod('post')
export const get = axiosMethod('get')
