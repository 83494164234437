import store from '../store/index'
import { ADD_MODAL, REMOVE_MODAL } from '../store/keys-mutations'

/*
the modal component should start with:
* <b-modal :visible="true" @ok="$emit('resolve', question)" @cancel="$emit('resolve')" @hidden="$emit('remove')">
*   hello world!
* </b-modal>
*/
export default (component, props) => {
  const modal = { component, props }
  return new Promise(resolve => {
    modal.resolve = value => {
      resolve(value)
    }
    modal.remove = () => {
      store.commit(REMOVE_MODAL, modal)
    }
    store.commit(ADD_MODAL, Object.freeze(modal))
  })
}
