import Vue from 'vue'
import {
  ADD_VACANCY_TEAM_MEMBER,
  DELETE_VACANCY_TEAM_MEMBER,
  LOAD_VACANCY_TEAM_MEMBERS
} from '../../keys-actions'
import { VACANCY, VACANCY_TEAM } from '../../keys-getters'
import apiRequest from '../../../services/api-request'
import { SET_VACANCY_TEAM } from '../../keys-mutations'

export default {
  state: {
    team: []
  },
  mutations: {
    [SET_VACANCY_TEAM]: (state, team) => (state.team = team)
  },
  getters: {
    [VACANCY_TEAM]: state => state.team
  },
  actions: {
    [LOAD_VACANCY_TEAM_MEMBERS]: ({ commit, getters }) => {
      const { vacancyId } = getters[VACANCY]
      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/team`
      }).then(data => {
        commit(SET_VACANCY_TEAM, data.teamMembers)
      })
    },
    [ADD_VACANCY_TEAM_MEMBER]: ({ dispatch, getters }, teamMember) => {
      const { vacancyId } = getters[VACANCY]
      return apiRequest({
        method: 'PUT',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/team`,
        data: teamMember
      }).then(() => dispatch(LOAD_VACANCY_TEAM_MEMBERS))
    },
    [DELETE_VACANCY_TEAM_MEMBER]: ({ dispatch, getters }, teamMemberId) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'DELETE',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/team/${teamMemberId}`
      })
        .then(() => dispatch(LOAD_VACANCY_TEAM_MEMBERS))
        .catch(err => {
          Vue.notify({
            type: 'warning',
            text: `Het teamlid kan niet worden verwijderd. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              err.message
            }`
          })
          return null
        })
    }
  }
}
