<template>
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          Gearchiveerde vacatures
        </h1>
        <div>
          <small>
            <span>{{ archivedCount }} gearchiveerd, </span>
            <b-link
              style="color: #000; text-decoration: underline;"
              :to="{ name: 'home' }"
              >{{ openCount }} open</b-link
            >
          </small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ARCHIVED_VACANCY_COUNT, OPEN_VACANCY_COUNT } from '../../store/keys-getters'

export default {
  name: 'VacancyNavHeader',
  computed: {
    openCount () {
      return this.$store.getters[OPEN_VACANCY_COUNT]
    },
    archivedCount () {
      return this.$store.getters[ARCHIVED_VACANCY_COUNT]
    }
  }
}
</script>
