export const CANDIDATETYPE_MANDATORY = 'CANDIDATETYPE_MANDATORY'
export const CANDIDATETYPE_REPOSITIONABLE = 'CANDIDATETYPE_REPOSITIONABLE'
export const CANDIDATETYPE_PREPLACED = 'CANDIDATETYPE_PREPLACED'
export const CANDIDATETYPE_MOBILITY = 'CANDIDATETYPE_MOBILITY'
export const CANDIDATETYPE_CANDIDATE = 'CANDIDATETYPE_CANDIDATE'
export const CANDIDATETYPE_INTERNAL_CANDIDATE =
  'CANDIDATETYPE_INTERNAL_CANDIDATE'
export const CANDIDATETYPE_REGIONAL_CANDIDATE =
  'CANDIDATETYPE_REGIONAL_CANDIDATE'
export const CANDIDATETYPE_EXTERNAL_CANDIDATE =
  'CANDIDATETYPE_EXTERNAL_CANDIDATE'
export const CANDIDATETYPE_EXTERNAL_APPLICANT =
  'CANDIDATETYPE_EXTERNAL_APPLICANT'
