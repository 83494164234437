<template>
  <b-card class="row" no-body>
    <div class="p-5">
      {{ motivation ? motivation : "(Geen motivatie ingevuld)" }}
    </div>
  </b-card>
</template>
<script>
export default {
  name: 'prospect-detail-motivation',
  props: {
    motivation: {
      type: String,
      default: ''
    }
  }
}
</script>
