import apiRequest from '../../services/api-request'
import emailTemplateParser from '../../services/email-template-parser'
import {
  LOAD_EMAIL_TEMPLATES,
  LOAD_VACANCY_COMPETENCES_OPTIONS,
  LOAD_EMAIL_PLACEHOLDERS,
  LOAD_APPLICANT_TYPES
} from '../keys-actions'
import {
  SET_EMAIL_TEMPLATES,
  SET_VACANCY_COMPETENCES_OPTIONS,
  SET_EMAIL_PLACEHOLDERS,
  SET_APPLICANT_TYPES
} from '../keys-mutations'
import {
  EMAIL_TEMPLATES,
  VACANCY_COMPETENCES_OPTIONS,
  EMAIL_PLACEHOLDERS,
  APPLICANT_TYPES
} from '../keys-getters'
const { parseContent } = emailTemplateParser

export default {
  state: {
    competences: [],
    emailPlaceholders: [],
    emailTemplates: [],
    applicantTypes: []
  },
  mutations: {
    [SET_VACANCY_COMPETENCES_OPTIONS]: (state, options) =>
      (state.competences = options),
    [SET_EMAIL_TEMPLATES]: (state, templates) =>
      (state.emailTemplates = templates),
    [SET_EMAIL_PLACEHOLDERS]: (state, placeholders) =>
      (state.emailPlaceholders = placeholders),
    [SET_APPLICANT_TYPES]: (state, types) => (state.applicantTypes = types)
  },
  getters: {
    [VACANCY_COMPETENCES_OPTIONS]: state => state.competences,
    [EMAIL_TEMPLATES]: state => state.emailTemplates,
    [EMAIL_PLACEHOLDERS]: state => state.emailPlaceholders,
    [APPLICANT_TYPES]: state => state.applicantTypes
  },
  actions: {
    [LOAD_VACANCY_COMPETENCES_OPTIONS]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/lookups/competence'
      })
        .then(data => commit(SET_VACANCY_COMPETENCES_OPTIONS, data))
        .catch(() => {
          return null
        })
    },
    [LOAD_EMAIL_TEMPLATES]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: 'api/erecruiter-web-api/templates'
      }).then(data => {
        data = data.map(template => parseContent({ template }))
        return commit(SET_EMAIL_TEMPLATES, data)
      })
    },
    [LOAD_EMAIL_PLACEHOLDERS]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: 'api/erecruiter-web-api/lookups/emailPlaceholder'
      }).then(data => commit(SET_EMAIL_PLACEHOLDERS, data))
    },
    [LOAD_APPLICANT_TYPES]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: 'api/erecruiter-web-api/applicantTypes'
      }).then(data => commit(SET_APPLICANT_TYPES, data))
    }
  }
}
