var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column p-3 justify-content-start bg-judgment-card"},[_c('div',{staticClass:"d-flex flex-column"},[_c('b-form-group',{attrs:{"label":"Geef een beoordeling"}},[_c('b-form-radio-group',{attrs:{"buttons":"","stacked":"","button-variant":"link","id":"radios2","name":"judgementScorescore","size":"sm"},model:{value:(
          (_vm.privateProspect.prospectJudgementForCurrentUser || {})
            .judgementScore
        ),callback:function ($$v) {_vm.$set((_vm.privateProspect.prospectJudgementForCurrentUser || {})
            , "judgementScore", $$v)},expression:"\n          (privateProspect.prospectJudgementForCurrentUser || {})\n            .judgementScore\n        "}},[_c('b-form-radio',{staticClass:"pl-0 text-left",class:(_vm.privateProspect.prospectJudgementForCurrentUser || {})
              .judgementScore === 1
              ? 'text-success'
              : '',attrs:{"value":1}},[_c('i',{staticClass:"fa fa-check",staticStyle:{"width":"16px"}}),_vm._v("Positief advies ")]),_c('b-form-radio',{staticClass:"pl-0 text-left",class:(_vm.privateProspect.prospectJudgementForCurrentUser || {})
              .judgementScore === 0
              ? 'text-dark'
              : '',attrs:{"value":0}},[_c('i',{staticClass:"fa fa-question",staticStyle:{"width":"16px"}}),_vm._v("Neutraal ")]),_c('b-form-radio',{staticClass:"pl-0 text-left",class:(_vm.privateProspect.prospectJudgementForCurrentUser || {})
              .judgementScore === -1
              ? 'text-danger'
              : '',attrs:{"value":-1}},[_c('i',{staticClass:"fa fa-times",staticStyle:{"width":"16px"}}),_vm._v("Negatief advies ")])],1),_c('b-form-textarea',{attrs:{"rows":"3","placeholder":"Geef een toelichting (optioneel)"},model:{value:(
          (_vm.privateProspect.prospectJudgementForCurrentUser || {}).comment
        ),callback:function ($$v) {_vm.$set((_vm.privateProspect.prospectJudgementForCurrentUser || {}), "comment", $$v)},expression:"\n          (privateProspect.prospectJudgementForCurrentUser || {}).comment\n        "}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }