<template>
  <!-- TODO this component is a good candidate to split into multiple components because that would make it much easier to read, e.g.: bubble container, bubble, bubble content... -->
  <div class="row border-top border-light-gray">
    <div class="col-4 col-md-3 pl-4 pt-4 pb-5 bg-background">
      <h5 class="ml-2 font-weight-bold">Publicatie</h5>
    </div>
    <div class="col-8 col-md-9">
      <div class="row">
        <!-- Left block -->
        <div class="col-2 col-md-6 border-right border-light-gray">
          <div class="d-none d-md-block py-4">
            <div class="d-flex flex-column align-items-start mr-3">
              <div class="w-100 mt-2 p-2 notification speech-bubble right">
                <div class="d-flex justify-content-between">
                  <strong class="text-dark">
                    <i
                      class="fa fa-circle small"
                      :class="
                        oneChannelInfo.oneChannelVacancyLinkActive
                          ? 'text-success'
                          : 'text-light-gray'
                      "
                    />
                    Sollicitatieformulier
                    <span v-if="!oneChannelInfo.oneChannelVacancyLinkActive"
                      >niet</span
                    >
                    actief
                  </strong>
                  <b-link
                    class="small"
                    :to="{
                      name: 'vacancyPublishChannels',
                      params: { vacancyId }
                    }"
                    >bewerken</b-link
                  >
                </div>
                <div class="d-flex flex-column">
                  <div
                    v-for="category in publicationChannelsInfo"
                    :key="category.title"
                  >
                    <div>
                      {{ category.title }} ({{ category.channels.length }})
                    </div>
                    <img
                      v-for="channel in category.channels"
                      width="210"
                      :key="channel.channelId"
                      :src="`${channel.logoUrlLocal}`"
                    />
                  </div>
                </div>
              </div>
              <div class="w-100 mt-2 p-2 notification speech-bubble right">
                <div class="d-flex justify-content-between">
                  <strong class="text-dark">
                    <i
                      class="fa fa-circle small"
                      :class="
                        oneChannelInfo.oneChannelVacancyLinkActive
                          ? 'text-success'
                          : 'text-light-gray'
                      "
                    />
                    Sollicitatieformulier
                    <span v-if="!oneChannelInfo.oneChannelVacancyLinkActive"
                      >niet</span
                    >
                    actief
                  </strong>
                  <b-link
                    class="small"
                    :to="{
                      name: 'vacancyPublishChannels',
                      params: { vacancyId }
                    }"
                    >bewerken</b-link
                  >
                </div>
                <div
                  v-if="oneChannelInfo.oneChannelVacancyLinkActive"
                  class="d-flex justify-content-between align-items-baseline flex-wrap overflow-hidden"
                >
                  <small>
                    Link naar vacaturetekst:
                    <b-link
                      :href="oneChannelInfo.vacancyApplicationUrl.url"
                      target="_blank"
                    >
                      {{ oneChannelInfo.vacancyApplicationUrl.url }}
                    </b-link>
                  </small>
                  <small>
                    Link naar sollicitatie pagina:
                    <b-link
                      :href="
                        `${oneChannelInfo.vacancyApplicationUrl.url}/solliciteren`
                      "
                      target="_blank"
                      >{{
                        oneChannelInfo.vacancyApplicationUrl.url
                      }}/solliciteren</b-link
                    >
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Right block -->
        <div class="col-10 col-md-6">
          <div class="py-4">
            <div class="ml-2">
              <div
                class="d-flex d-md-none flex-column ml-auto p-2 mb-1 notification speech-bubble left"
              >
                <div class="d-flex justify-content-between">
                  <strong class="text-dark">
                    <i
                      class="fa fa-circle small"
                      :class="
                        oneChannelInfo.oneChannelVacancyLinkActive
                          ? 'text-success'
                          : 'text-light-gray'
                      "
                    />
                    Sollicitatieformulier
                    <span v-if="!oneChannelInfo.oneChannelVacancyLinkActive"
                      >niet</span
                    >
                    actief
                  </strong>
                  <b-link
                    class="small"
                    :to="{
                      name: 'vacancyPublishChannels',
                      params: { vacancyId }
                    }"
                    >bewerken</b-link
                  >
                </div>
                <div
                  v-if="oneChannelInfo.oneChannelVacancyLinkActive"
                  class="d-flex flex-column"
                >
                  <small>
                    Link naar vacaturetekst:
                    <b-link
                      :href="oneChannelInfo.vacancyApplicationUrl.url"
                      target="_blank"
                    >
                      {{ oneChannelInfo.vacancyApplicationUrl.url }}
                    </b-link>
                  </small>
                  <small>
                    Link naar sollicitatie pagina:
                    <b-link
                      :href="
                        `${oneChannelInfo.vacancyApplicationUrl.url}/solliciteren`
                      "
                      target="_blank"
                      >{{
                        oneChannelInfo.vacancyApplicationUrl.url
                      }}/solliciteren</b-link
                    >
                  </small>
                </div>
              </div>
              <div
                class="d-flex d-md-none flex-column ml-auto p-2 mb-1 notification speech-bubble left"
              >
                <div class="d-flex justify-content-between">
                  <strong class="text-dark">
                    <i
                      class="fa fa-circle small"
                      :class="
                        oneChannelInfo.oneChannelVacancyLinkActive
                          ? 'text-success'
                          : 'text-light-gray'
                      "
                    />
                    Sollicitatieformulier
                    <span v-if="!oneChannelInfo.oneChannelVacancyLinkActive"
                      >niet</span
                    >
                    actief
                  </strong>
                  <b-link
                    class="small"
                    :to="{
                      name: 'vacancyPublishChannels',
                      params: { vacancyId }
                    }"
                    >bewerken</b-link
                  >
                </div>
                <div class="d-flex flex-column">
                  <div
                    v-for="category in publicationChannelsInfo"
                    :key="category.title"
                  >
                    <div>
                      {{ category.title }} ({{ category.channels.length }})
                    </div>
                    <img
                      v-for="channel in category.channels"
                      :key="channel.channelId"
                      :src="`${channel.logoUrlLocal}`"
                      width="210"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="notifications.length">
              <notification
                class="ml-2"
                v-for="item in notifications"
                :key="item.entityId"
                :notification="item"
                :main-content-background="true"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '../../notification-list/notification'
import {
  CHANNEL_CAT_INTERNAL,
  CHANNEL_CAT_EXTERNAL,
  CHANNEL_CAT_REGIONAL,
  CHANNEL_CAT_MOBILITY
} from '../../../constants/channel-category'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
export default {
  components: {
    Notification
  },
  props: {
    vacancyId: {
      required: true
    },
    notifications: {
      type: Array,
      default: () => []
    },
    oneChannelInfo: {
      type: Object,
      default: () => ({})
    },
    publicationChannels: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    publicationChannelsAreLoaded () {
      return isEmpty(this.publicationChannels)
    },
    publicationChannelsInfo () {
      return [
        {
          title: 'Extern',
          channels: filter(this.publicationChannels[CHANNEL_CAT_EXTERNAL], (channel) => channel.isPublished)
        },
        {
          title: 'Regionaal',
          channels: filter(this.publicationChannels[CHANNEL_CAT_REGIONAL], (channel) => channel.isPublished)
        },
        {
          title: 'Intern',
          channels: filter(this.publicationChannels[CHANNEL_CAT_INTERNAL], (channel) => channel.isPublished)
        },
        {
          title: 'Mobility',
          channels: filter(this.publicationChannels[CHANNEL_CAT_MOBILITY], (channel) => channel.isPublished)
        }
      ].filter((el) => el.channels.length !== 0)
    }
  }
}
</script>
