<template>
  <page>
    <release-notes-header slot="header" />
    <release-notes-list slot="main-content" />
    <release-notes-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import ReleaseNotesHeader from './release-notes-header'
import ReleaseNotesList from './release-notes-list'
import ReleaseNotesAside from '../aside/aside-content.vue'

export default {
  name: 'ReleaseNotesPage',
  components: {
    Page,
    ReleaseNotesHeader,
    ReleaseNotesList,
    ReleaseNotesAside
  }
}
</script>
