import Vue from 'vue'
import {
  CREATE_VACANCY_NOTE,
  CREATE_VACANCY_TODO,
  EDIT_VACANCY_NOTE,
  EDIT_VACANCY_TODO,
  LOAD_VACANCY_NOTE,
  COMPLETE_VACANCY_TODO,
  LOAD_VACANCY_SUBPROCESSES,
  LOAD_VACANCY_TODO,
  DELETE_VACANCY_NOTIFICATION_ATTACHMENT
} from '../../keys-actions'
import { CURRENT_NOTIFICATION, VACANCY } from '../../keys-getters'
import { ENTITYTYPE_VACANCY } from '../../../constants/entity-types'
import saveAttachment from '../../../services/save-attachment'
import { stripHostFromAttachment } from '../../../services/attachment-data-cleaner'
import apiRequest from '../../../services/api-request'
import { SET_CURRENT_NOTIFICATION } from '../../keys-mutations'
import {
  NOTIFTYPE_NOTE,
  NOTIFTYPE_TODO
} from '../../../constants/prospect/prospect-notification-type'

export default {
  actions: {
    [CREATE_VACANCY_NOTE]: async ({ getters, dispatch }, info) => {
      const { vacancyId } = getters[VACANCY]
      const {
        lSharingType,
        description,
        attachment,
        createDate,
        selectionRoundIndex
      } = info
      const lEntityType = ENTITYTYPE_VACANCY

      try {
        const file = info.attachment
          ? await saveAttachment(
            attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyNotes`,
          data: {
            attachment: file,
            lSharingType,
            description,
            createDate,
            entityId: vacancyId,
            vacancyId,
            lEntityType,
            selectionRoundIndex
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De notitie wordt aangemaakt'
        })
        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden bij het aanmaken van de vacature notitie. ${err.message}`
        })
      }
    },
    [CREATE_VACANCY_TODO]: async ({ getters, dispatch }, info) => {
      const { vacancyId } = getters[VACANCY]
      const {
        lSharingType,
        description,
        attachment,
        createDate,
        dueDate,
        selectionRoundIndex
      } = info
      const lEntityType = ENTITYTYPE_VACANCY

      try {
        const file = info.attachment
          ? await saveAttachment(
            attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyTodos`,
          data: {
            attachment: file,
            lSharingType,
            description,
            createDate,
            entityId: vacancyId,
            lEntityType,
            dueDate,
            selectionRoundIndex
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De todo wordt aangemaakt'
        })
        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden bij de vacature todo. ${err.message}`
        })
      }
    },
    [LOAD_VACANCY_NOTE]: async ({ getters, commit }, noteId) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const notification = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyNotes/${noteId}`
        })
        stripHostFromAttachment(notification.attachment, 'url')
        commit(SET_CURRENT_NOTIFICATION, notification)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature notities niet laden. ${err.message}`
        })
      }
    },
    [LOAD_VACANCY_TODO]: async ({ getters, commit }, todoId) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const notification = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyTodos/${todoId}`
        })
        stripHostFromAttachment(notification.attachment, 'url')
        commit(SET_CURRENT_NOTIFICATION, notification)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature To-Dos niet laden. ${err.message}`
        })
      }
    },
    [EDIT_VACANCY_TODO]: async ({ getters, dispatch }, todo) => {
      const { vacancyId } = getters[VACANCY]
      try {
        /* eslint-disable indent */
        const file = todo.attachment
          ? await saveAttachment(
              todo.attachment,
              'api/erecruiter-web-api/notes/attachments'
            )
          : null
        /* eslint-enable indent */
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyTodos/${todo.todoId}`,
          data: { ...todo, attachment: file }
        })
        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden. ${err.message}`
        })
      }
    },
    [EDIT_VACANCY_NOTE]: async ({ getters, dispatch }, note) => {
      const { vacancyId } = getters[VACANCY]
      try {
        const file = note.attachment
          ? await saveAttachment(
            note.attachment,
            'api/erecruiter-web-api/notes/attachments'
          )
          : null
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyNotes/${note.noteId}`,
          data: { ...note, attachment: file }
        })
        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden. ${err.message}`
        })
      }
    },
    [DELETE_VACANCY_NOTIFICATION_ATTACHMENT]: async (
      { getters, dispatch },
      { notificationType, notificationId }
    ) => {
      const { attachment } = getters[CURRENT_NOTIFICATION]
      const actions = {
        [NOTIFTYPE_NOTE]: LOAD_VACANCY_NOTE,
        [NOTIFTYPE_TODO]: LOAD_VACANCY_TODO
      }
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/notes/attachments/${attachment.attachmentId}`
        })
        dispatch(actions[notificationType], notificationId)
        Vue.notify({
          type: 'success',
          text: 'Het bestand is verwijderd.'
        })

        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bestand kon niet verwijderd worden. Neem contact op met de supportdesk. ${err.message}`
        })
      }
    },
    [COMPLETE_VACANCY_TODO]: async ({ getters, dispatch }, { id, isDone }) => {
      const { vacancyId } = getters[VACANCY]
      try {
        await dispatch(LOAD_VACANCY_TODO, id)
        const todo = { ...getters[CURRENT_NOTIFICATION], isDone }
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/vacancyTodos/${id}`,
          data: todo
        })
        dispatch(LOAD_VACANCY_SUBPROCESSES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden. ${err.message}`
        })
      }
    }
  }
}
