import moment from 'moment'
import Vue from 'vue'
import App from './app'
import './bootstrap.js'
import './mixin'
import './vee-validate/vee-validate'
import './autocomplete'
import './moment'
import './gtm'
import store from './store/index.js'
import router from './router.js'
import './style/app.scss'
import './sentry'
import VueCookies from 'vue-cookies'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
moment.locale('nl')

library.add(faUserFriends)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueCookies)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
