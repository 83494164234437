<template>
  <div>
    <div class="p-4">
      <h3><strong>Persoonlijke gegevens</strong></h3>
      <div class="pt-4 pb-4">
        <label for="photo">
          Profielfoto
          <div
            class="d-flex flex-column align-items-center p-2"
            style="background-color: #fff; border: 1px solid #ced4da;"
          >
            <avatar
              key="avatar"
              :fullname="privateDetails.fullName || '?'"
              class="m-1"
            />
          </div>
        </label>
      </div>

      <div class="d-flex">
        <label class="w-50 pr-2" for="firstname">
          Voornaam *
          <b-form-input
            v-validate="'required'"
            data-vv-as="Voornaam"
            :class="{ 'is-invalid': errors.has('firstname') }"
            id="firstname"
            name="firstname"
            v-model="privateDetails.firstname"
          />
          <div class="invalid-feedback">
            {{ errors.first("firstname") }}
          </div>
        </label>

        <label class="w-25" for="middleName"
          >Tussenvoegsel
          <b-form-input id="middleName" v-model="privateDetails.middleName" />
        </label>
      </div>
      <label class="w-75" for="lastname"
        >Achternaam *
        <b-form-input
          v-validate="'required'"
          data-vv-as="Achternaam"
          :class="{ 'is-invalid': errors.has('lastname') }"
          id="lastname"
          name="lastname"
          v-model="privateDetails.lastname"
        />
        <div class="invalid-feedback">
          {{ errors.first("lastname") }}
        </div>
      </label>

      <div>
        <b-form-group label="Geslacht">
          <b-form-radio-group
            plain
            id="gender"
            :options="gender"
            v-model="privateDetails.gender"
          />
        </b-form-group>
      </div>

      <div>
        <label class="w-75" for="title">
          Functie *
          <b-form-input
            v-validate="'required'"
            data-vv-as="Functie"
            :class="{ 'is-invalid': errors.has('title') }"
            id="title"
            name="title"
            v-model="privateDetails.title"
          />
          <div class="invalid-feedback">
            {{ errors.first("title") }}
          </div>
        </label>
      </div>

      <div>
        <label class="w-75" for="address">
          Straat en huisnummer
          <b-form-input id="address" v-model="privateDetails.address" />
        </label>
        <div>
          <label class="w-25 pr-2" for="postcode">
            Postcode
            <b-form-input id="postcode" v-model="privateDetails.postcode" />
          </label>
          <label class="w-50" for="city">
            Plaats
            <b-form-input id="city" v-model="privateDetails.city" />
          </label>
        </div>
      </div>

      <label class="w-75" for="telephone">
        Telefoon *
        <b-form-input
          v-validate="'required'"
          data-vv-as="Phone"
          :class="{ 'is-invalid': errors.has('PhoneNumber') }"
          type="tel"
          name="PhoneNumber"
          id="telephone"
          v-model="privateDetails.telephone"
        />
      </label>
      <label class="w-75" for="emailAddress">
        E-mail *
        <b-form-input
          v-validate="'required|email'"
          data-vv-as="E-mail"
          :class="{ 'is-invalid': errors.has('emailAddress') }"
          type="email"
          name="emailAddress"
          id="emailAddress"
          v-model="privateDetails.emailAddress"
        />
        <div class="invalid-feedback">
          {{ errors.first("emailAddress") }}
        </div>
      </label>
    </div>

<div class="p-4">
      <h3><strong>Wachtwoord</strong></h3>
      <p class="w-75">Gebruik de “wachtwoord  vergeten” link in het inlogscherm om een nieuw wachtwoord in te stellen voor uw account.</p>
    </div>
    <div class="p-4">
      <h3><strong>Email</strong></h3>
      <b-form-group
        label="Als ik vanuit ONE een e-mail verstuur, is de afzender"
      >
        <b-form-radio-group
          class="d-flex flex-column"
          plain
          id="email"
          v-model="privateDetails.altFromEmailType"
        >
          <div v-for="option in emailSender" :key="option.value">
            <b-form-radio :value="option.value" v-if="!!option.text">{{
              option.text
            }}</b-form-radio>
          </div>
          <b-form-select
            v-model="privateDetails.altFromEmailUsername"
            v-show="privateDetails.altFromEmailType === 'ALT_EMAIL_OTHER_USER'"
            class="w-75 mb-3"
          >
            <option
              v-for="user in users"
              :key="user.username"
              :value="user.username"
            >
              {{ user.fullName }} &lt;{{ user.emailAddress }}&gt;
            </option>
          </b-form-select>
        </b-form-radio-group>
      </b-form-group>
      <div class="pt-2">
        <label for="emailSignature">Standaard e-mail handtekeing</label>
        <vue-editor-with-image-upload-signature
          id="emailSignature"
          :private-details="privateDetails"
          class="w-75"
        />
      </div>
    </div>
    <div class="p-4">
      <h3><strong>Notificaties</strong></h3>
      <b-form-group
        label="Als er gesolliciteerd wordt op één van mijn vacatures wil ik hierover:"
      >
        <b-form-radio-group
          plain
          class="d-flex flex-column"
          id="lappEmailNotiftype"
          :options="vacancyNotificationFrequency"
          v-model="privateDetails.lappEmailNotiftype"
        />
      </b-form-group>
      <b-form-group
        label="Als de selectiecommissie beoordelingen indient wil ik hierover:"
      >
        <b-form-radio-group
          plain
          class="d-flex flex-column"
          id="selComEmailNotiftype"
          :options="selectionCommitteeNotificationTypes"
          v-model="privateDetails.selComEmailNotiftype"
        />
      </b-form-group>
    </div>
    <div class="d-flex justify-content-end align-items-center">
      <b-link class="p-4 " @click="discardChanges">Annuleren</b-link>
      <b-btn class="" variant="primary" @click="saveChanges">Bijwerken</b-btn>
    </div>
    <div v-if="errors.any()" class="text-danger text-right">
      <small>* Niet alle verplichte velden zijn ingevuld</small>
    </div>
  </div>
</template>

<script>
import {
  LOAD_CURRENT_USER_DETAILS,
  UPDATE_CURRENT_USER_DETAILS,
  LOAD_USERS
} from '../../store/keys-actions'
import {
  CURRENT_USER,
  USERNAME,
  USERS,
  CURRENT_TENANT
} from '../../store/keys-getters'
import {
  SELCOMMAILNOTIFTYPE_DIRECT,
  SELCOMMAILNOTIFTYPE_NONE,
  APPMAILNOTIFTYPE_APPLICANT,
  APPMAILNOTIFTYPE_DAILY,
  APPMAILNOTIFTYPE_NONE,
  APPMAILNOTIFTYPE_WEEKLY
} from '../../constants/notifications'
import {
  ALT_EMAIL_ORIGINAL,
  ALT_EMAIL_TENANT,
  ALT_EMAIL_OTHER_USER
} from '../../constants/email/email-sender'
import { GENDER_F, GENDER_M } from '../../constants/gender'
import VueEditorWithImageUploadSignature from '../vue-editor-with-image-upload-signature'
import ConfirmYesNoModal from '../confirm-yes-no-modal'
import cloneDeep from 'lodash/cloneDeep'
import modal from '../../services/modal'
import Avatar from '../avatar'
import router from '../../router'

export default {
  components: {
    Avatar,
    VueEditorWithImageUploadSignature
  },
  data () {
    return {
      privateDetails: {}
    }
  },
  watch: {
    currentUser () {
      this.privateDetails = cloneDeep(this.currentUser)
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters[CURRENT_USER]
    },
    username () {
      return this.$store.getters[USERNAME]
    },
    users () {
      return this.$store.getters[USERS]
    },
    currentTenant () {
      return this.$store.getters[CURRENT_TENANT]
    },
    selectionCommitteeNotificationTypes () {
      return [
        {
          value: SELCOMMAILNOTIFTYPE_NONE,
          text: 'geen e-mail ontvangen'
        },
        {
          value: SELCOMMAILNOTIFTYPE_DIRECT,
          text: 'direct een notificatie per e-mail ontvangen'
        }
      ]
    },
    vacancyNotificationFrequency () {
      return [
        {
          value: APPMAILNOTIFTYPE_APPLICANT,
          text: 'direct een notificatie per e-mail ontvangen'
        },
        {
          value: APPMAILNOTIFTYPE_DAILY,
          text:
            'dagelijks een e-mail ontvangen met de sollicitaties van de vorige dag'
        },
        {
          value: APPMAILNOTIFTYPE_NONE,
          text: 'geen e-mail ontvangen'
        },
        {
          value: APPMAILNOTIFTYPE_WEEKLY,
          text:
            'wekelijks een e-mail ontvangen met de sollicitaties van de vorige week'
        }
      ]
    },
    emailSender () {
      return [
        {
          value: ALT_EMAIL_ORIGINAL,
          text: 'Mijn eigen e-mailadres'
        },
        {
          value: ALT_EMAIL_TENANT,
          text: this.currentTenant.email
        },
        {
          value: ALT_EMAIL_OTHER_USER,
          text: 'Een ander e-mailadres, nl.'
        }
      ]
    },
    gender () {
      return [
        {
          value: GENDER_M,
          text: 'man'
        },
        {
          value: GENDER_F,
          text: 'vrouw'
        }
      ]
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_CURRENT_USER_DETAILS, this.username)
    this.$store.dispatch(LOAD_USERS)
  },
  methods: {
    saveChanges () {
      return this.$validator.validate().then((isValid) => {
        if (!isValid || this.errors.any()) {
          return
        }

        this.$store
          .dispatch(UPDATE_CURRENT_USER_DETAILS, {
            currentUser: this.privateDetails,
            username: this.username
          })
          .then(() => router.push({ name: 'home' }))
      })
    },
    discardChanges () {
      modal(ConfirmYesNoModal, {
        question:
          'Alle veranderingen zullen verloren gaan. Wil je de pagina verlaten?'
      }).then((ok) => {
        if (!ok) {
          return
        }
        this.$router.push({ name: 'home' })
      })
    }
  }
}
</script>
