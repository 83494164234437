<template>
  <div>
    <div class="row p-4 border-bottom border-medium-gray">
      <div class="w-100 d-flex justify-content-between">
        <b-link
          :to="{
            name: 'vacancySelectionRound',
            params: {
              vacancyId: vacancy.vacancyId,
              roundId: $route.params.roundId,
            },
          }"
        >
          <div>
            <i class="fa fa-angle-left" /> terug naar sollitantenoverzicht
          </div>
        </b-link>
        <div>
          <b-btn
            variant="white"
            class="mr-2 border border-medium-gray"
            size="sm"
            :disabled="
              !prospectNavigationOrder || !nextAndPreviousNavigation.previous
            "
            :to="{
              name: 'vacancyProspectDetail',
              params: {
                vacancyId: currentProspect.vacancyId,
                prospectId: nextAndPreviousNavigation.previous,
                roundId: $route.params.roundId,
              },
            }"
          >
            <i class="fa fa-chevron-left" />
          </b-btn>
          <b-btn
            variant="white"
            class="border border-medium-gray"
            size="sm"
            :disabled="
              !prospectNavigationOrder || !nextAndPreviousNavigation.next
            "
            :to="{
              name: 'vacancyProspectDetail',
              params: {
                vacancyId: currentProspect.vacancyId,
                prospectId: nextAndPreviousNavigation.next,
                roundId: $route.params.roundId,
              },
            }"
          >
            <i class="fa fa-chevron-right" />
          </b-btn>
        </div>
      </div>
    </div>
    <prospect-detail-header
      :loading="isLoading"
      :candidate-source="candidateSource"
      :avatarUrl="currentProspect.avatarUrl"
      :publishChannel="currentProspect.publishChannel"
      :fullname="fullname"
      :recruiterRating="currentProspect.lRecruiterRating"
      :prospectId="currentProspect.prospectId"
      :actionsDropdown="actionsDropdownList"
      @print="print"
      @copyToOtherVacancy="copyToOtherVacancy"
      @deleteProspect="deleteProspect"
      @editProspect="editProspect"
      @anonymizeProspect="anonymizeProspect"
      @sendToSelectionCommittee="sendToSelectionCommitee"
      @sharePerEmail="sharePerEmail"
    />
    <prospect-detail-contact-info
      :phoneNumber="currentProspect.phoneNumber || currentProspect.mobileNumber"
      :emailAddress="currentProspect.emailAddress"
      @sendEmail="sendEmail"
    />
    <prospect-work-experience
      v-if="prospectIsCandidate"
      :work-experience="currentProspect.experienceLst"
    />
    <prospect-work-experience-other
      v-if="
        prospectIsCandidate &&
          currentProspect.otherExperienceLst &&
          currentProspect.otherExperienceLst.length
      "
      :other-work-experience="currentProspect.otherExperienceLst"
    />
    <prospect-education
      v-if="prospectIsCandidate"
      :education="currentProspect.educationLst"
      :highest-level="currentProspect.highestEducation"
    />
    <prospect-courses
      v-if="prospectIsCandidate"
      :education="currentProspect.coursesLst"
    />
    <prospect-wishes-and-information
      v-if="prospectIsCandidate"
      :current-prospect="currentProspect"
    />
    <prospect-detail-motivation
      v-if="!prospectIsCandidate"
      :motivation="currentProspect.motivation"
    />
    <prospect-detail-documents
      @uploadAttachments="uploadAttachments"
      @deleteAttachment="deleteAttachment"
      :attachments="currentProspect.attachments"
    />
  </div>
</template>

<script>
import {
  CURRENT_PROSPECT,
  VACANCY,
  OPEN_VACANCIES,
  VACANCY_TEAM,
  DEFAULT_SHARE_PER_EMAIL_TEMPLATE,
  LOADING,
  VACANCY_PROSPECTS,
  RATING_TYPE_FOR_PROSPECT_NAVIGATION,
  APPLICANT_TYPES
} from '../../../store/keys-getters'
import {
  SEND_GENERAL_EMAIL,
  MOVE_PROSPECT_TO_VACANCY,
  DELETE_PROSPECT,
  ANONYMIZE_APPLICANTS,
  LOAD_VACANCY_TEAM_MEMBERS,
  SEND_PROSPECTS_TO_SELECTION_COMMITTEE,
  LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE,
  SEND_SHARE_PROSPECTS_EMAIL,
  DELETE_PROSPECT_ATTACHMENT,
  UPLOAD_PROSPECT_ATTACHMENT,
  LOAD_CURRENT_PROSPECT,
  LOAD_VACANCY_LIST,
  EDIT_PROSPECT
} from '../../../store/keys-actions'
import { SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION } from '../../../store/keys-mutations'
import {
  SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE,
  SHARE_SELECTION
} from '../../../constants/permissions'
import { PROSPECT_CANDIDATE } from '../../../constants/prospect/prospect-types'
import ProspectDetailHeader from '../../prospect-details-components/prospect-detail-header'
import ProspectDetailContactInfo from '../../prospect-details-components/prospect-detail-contact-info'
import ProspectDetailMotivation from '../../prospect-details-components/prospect-detail-motivation'
import ProspectDetailDocuments from '../../prospect-details-components/prospect-detail-documents'
import ProspectWorkExperience from '../../prospect-details-components/prospect-work-experience'
import ProspectWorkExperienceOther from '../../prospect-details-components/prospect-work-experience-other'
import ProspectEducation from '../../prospect-details-components/prospect-education'
import ProspectCourses from '../../prospect-details-components/prospect-courses'
import ProspectWishesAndInformation from '../../prospect-details-components/prospect-wishes-and-information'
import CopyToOtherVacancyModal from './copy-to-other-vacancy-modal'
import ConfirmYesNoModal from '../../confirm-yes-no-modal'
import EditProspectModal from '../vacancy-prospects/edit-prospect-modal'
import SendToSelectionCommitteeModal from '../vacancy-prospects/send-to-selection-committee-modal'
import modal from '../../../services/modal'
import EmailModal from '../../email-modal'
import assign from 'lodash/assign'
import map from 'lodash/map'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import filter from 'lodash/filter'
import defaults from 'lodash/defaults'

export default {
  components: {
    ProspectDetailDocuments,
    ProspectDetailMotivation,
    ProspectDetailContactInfo,
    ProspectDetailHeader,
    ProspectWorkExperience,
    ProspectWorkExperienceOther,
    ProspectEducation,
    ProspectCourses,
    ProspectWishesAndInformation
  },
  computed: {
    isLoading () {
      return this.$store.getters[LOADING]
    },
    currentProspect () {
      return this.$store.getters[CURRENT_PROSPECT]
    },
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    candidateSource () {
      const map = {
        CHANNEL_CAT_HIDDEN: 'ONE sollicitant',
        CHANNEL_CAT_EXTERNAL: 'Externe sollicitant',
        CHANNEL_CAT_REGIONAL: 'Regionale sollicitant',
        CHANNEL_CAT_INTERNAL: 'Interne sollicitant'
      }
      return map[(this.currentProspect.publishChannel || {}).channelCat] || ''
    },
    fullname () {
      return isEmpty(this.currentProspect)
        ? ''
        : `${this.currentProspect.firstname} ${
            this.currentProspect.middleName
              ? this.currentProspect.middleName + ' '
              : ''
          }${this.currentProspect.lastname}`
    },
    actionsDropdownList () {
      return [
        { key: 'print', label: 'Print', display: true, method: this.print },
        {
          key: 'copyToOtherVacancy',
          label: 'Kopieer naar andere vacature',
          display: true
        },
        {
          key: 'deleteProspect',
          label: 'Verwijder sollicitant',
          display: true
        },
        { key: 'editProspect', label: 'Bewerken', display: true },
        {
          key: 'anonymizeProspect',
          label: 'Anonimiseer sollicitant',
          display: true
        },
        {
          key: 'sendToSelectionCommittee',
          label: 'Voordragen aan selectiecommissie',
          display: this.hasPermission(SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE)
        },
        {
          key: 'sharePerEmail',
          label: 'Delen per e-mail',
          display: this.hasPermission(SHARE_SELECTION)
        }
      ]
    },
    openStartedVacancies () {
      const vacancies = this.$store.getters[OPEN_VACANCIES]
      return filter(
        vacancies,
        vacancy =>
          vacancy.status !== 'CREATED' &&
          vacancy.status !== 'SUBMITTED' &&
          vacancy.vacancyId !== this.vacancy.vacancyId
      )
    },
    prospectIsCandidate () {
      return this.currentProspect.lProspectType === PROSPECT_CANDIDATE
    },
    prospectTypeForNavigation () {
      return this.$store.getters[RATING_TYPE_FOR_PROSPECT_NAVIGATION]
    },
    prospectNavigationOrder () {
      return map(
        this.$store.getters[VACANCY_PROSPECTS](this.$route.params.roundId)[
          this.prospectTypeForNavigation
        ],
        prospect => prospect.prospectId
      )
    },
    nextAndPreviousNavigation () {
      const currentProspectIndex = findIndex(
        this.prospectNavigationOrder,
        item => item === this.currentProspect.prospectId
      )
      return {
        next: this.prospectNavigationOrder[currentProspectIndex + 1],
        previous: this.prospectNavigationOrder[currentProspectIndex - 1]
      }
    },
    applicantTypes () {
      return this.$store.getters[APPLICANT_TYPES]
    }
  },
  watch: {
    currentProspect () {
      this.$store.commit(
        SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION,
        this.currentProspect.lRecruiterRating
      )
    }
  },
  methods: {
    sendEmail () {
      const defaults = {
        to: this.fullname,
        prospectId: this.currentProspect.prospectId
      }
      modal(EmailModal, { defaults }).then(info => {
        if (!info) {
          return
        }
        const email = assign(info, { to: [this.currentProspect.prospectId] })
        this.$store.dispatch(SEND_GENERAL_EMAIL, email).then(() => {
          this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
            prospectId: this.currentProspect.prospectId,
            roundId: +this.$route.params.roundId
          })
        })
      })
    },
    print () {
      window.open(
        `/api/erecruiter-web-api/prospect/${this.currentProspect.prospectId}/print.pdf`,
        '_blank'
      )
    },
    async copyToOtherVacancy () {
      await this.$store.dispatch(LOAD_VACANCY_LIST)
      modal(CopyToOtherVacancyModal, {
        vacancies: this.openStartedVacancies
      }).then(vacancyId => {
        if (!vacancyId) {
          return
        }
        this.$store.dispatch(MOVE_PROSPECT_TO_VACANCY, vacancyId)
      })
    },
    deleteProspect () {
      modal(ConfirmYesNoModal, {
        question: 'Weet u zeker dat u deze sollicitant wilt verwijderen?'
      }).then(ok => {
        if (!ok) {
          return
        }
        this.$store.dispatch(DELETE_PROSPECT).then(() =>
          this.$router.push({
            name: 'vacancySelectionRound',
            params: {
              vacancyId: this.vacancy.vacancyId,
              roundId: this.$route.params.roundId
            }
          })
        )
      })
    },
    editProspect () {
      modal(EditProspectModal, { applicantTypes: this.applicantTypes })
        .then(data =>
          this.$store.dispatch(
            EDIT_PROSPECT,
            defaults(
              {
                vacancyId: this.vacancy.vacancyId
              },
              data
            )
          )
        )
        .then(() => {
          this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
            prospectId: this.$route.params.prospectId,
            roundId: +this.$route.params.roundId
          })
        })
    },
    anonymizeProspect () {
      modal(ConfirmYesNoModal, {
        question: 'Weet u zeker dat u deze sollicitant wilt anonimiseren?'
      }).then(ok => {
        if (!ok) {
          return
        }
        return this.$store
          .dispatch(ANONYMIZE_APPLICANTS, [this.currentProspect.prospectId])
          .then(() =>
            this.$router.push({
              name: 'vacancySelectionRound',
              params: {
                vacancyId: this.vacancy.vacancyId,
                roundId: this.$route.params.roundId
              }
            })
          )
      })
    },
    sendToSelectionCommitee () {
      this.$store.dispatch(LOAD_VACANCY_TEAM_MEMBERS).then(() => {
        const selectionCommittee = filter(this.$store.getters[VACANCY_TEAM], [
          'lTeamRole',
          7
        ])
        modal(SendToSelectionCommitteeModal, { selectionCommittee }).then(
          selected => {
            if (!selected) {
              return
            }
            this.$store
              .dispatch(SEND_PROSPECTS_TO_SELECTION_COMMITTEE, {
                prospectIds: [this.currentProspect.prospectId],
                selectionCommittee: selected
              })
              .then(() => {
                return this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
                  prospectId: this.currentProspect.prospectId,
                  roundId: +this.$route.params.roundId
                })
              })
          }
        )
      })
    },
    sharePerEmail () {
      const id = this.currentProspect.prospectId
      this.$store
        .dispatch(LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE, [id])
        .then(() => {
          let defaults = this.$store.getters[DEFAULT_SHARE_PER_EMAIL_TEMPLATE]
          defaults = { ...defaults, enableTo: true }
          modal(EmailModal, { defaults }).then(info => {
            if (!info) {
              return
            }
            const email = assign(
              {
                sharedProspects: [id],
                tokenAuthorizationId: defaults.tokenAuthorizationId
              },
              info
            )
            this.$store.dispatch(SEND_SHARE_PROSPECTS_EMAIL, email).then(() => {
              this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
                prospectId: id,
                roundId: +this.$route.params.roundId
              })
            })
          })
        })
    },
    deleteAttachment (attachment) {
      const name = `${attachment.fileName}.${attachment.fileExtension}`
      modal(ConfirmYesNoModal, {
        question: `Weet u zeker dat u ${name} wilt verwijderen?`
      }).then(ok => {
        if (!ok) {
          return
        }
        this.$store.dispatch(DELETE_PROSPECT_ATTACHMENT, {
          attachmentId: attachment.attachmentId,
          roundId: +this.$route.params.roundId
        })
      })
    },
    uploadAttachments (attachment) {
      this.$store.dispatch(UPLOAD_PROSPECT_ATTACHMENT, {
        attachment,
        roundId: +this.$route.params.roundId
      })
    }
  }
}
</script>
