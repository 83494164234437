<template>
  <div>
    <b-list-group>
      <b-list-group-item
        class="users-list"
        v-for="user in users"
        :key="user.username"
      >
        <users-list-item :user="user" />
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { LOAD_USERS } from '../../store/keys-actions'
import { USERS } from '../../store/keys-getters'
import UsersListItem from './users-list-item'

export default {
  components: {
    UsersListItem
  },
  computed: {
    users () {
      return this.$store.getters[USERS]
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_USERS)
  }
}
</script>

<style scoped>
.users-list:nth-child(even) {
  background-color: #fbfbfb;
}
</style>
