<template>
  <div class="row pt-4 pb-3">
    <h1 class="vacancy-title font-weight-light text-dark pr-3">
      Inzichten
    </h1>
  </div>
</template>

<script>
export default {
  name: 'ReportsHeader'
}
</script>
