<template>
  <div class="d-flex flex-column border-bottom border-medium-gray pt-4 pb-4">
    <div class="d-flex">
      <div class="pr-2">
        <avatar size="sm" :fullname="selected.fullName"></avatar>
      </div>
      <div class="d-flex flex-column">
        <small
          ><strong>{{ selected.fullName }}</strong></small
        >
        <div>
          <small>{{ selected.title }}</small>
        </div>
      </div>
      <div v-if="!displayInfoOnly" class="ml-auto align-self-center">
        <b-form-select v-model="chosenRole" :options="roles" class="mb-3" />
      </div>
    </div>
    <div v-if="!displayInfoOnly" class="ml-auto">
      <b-btn variant="link" @click="$emit('removeSelected')">Annuleren</b-btn>
      <b-btn variant="primary" @click="save">Opslaan</b-btn>
    </div>
    <div v-if="displayInfoOnly" class="ml-auto">
      <b-btn variant="link" @click="$emit('removeSelected')">Verwijderen</b-btn>
    </div>
  </div>
</template>

<script>
import map from 'lodash/map'
import omit from 'lodash/omit'
import Avatar from '../../avatar'
import { ADD_VACANCY_TEAM_MEMBER } from '../../../store/keys-actions'

export default {
  components: {
    Avatar
  },
  props: {
    selected: {
      type: Object
    },
    displayInfoOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      chosenRole: this.selected.roles[0].id
    }
  },
  computed: {
    roles () {
      return map(this.selected.roles, (role) => {
        return { value: role.id, text: this.roleNames[role.id] }
      })
    },
    roleNames () {
      return {
        1: 'Recruiter',
        2: 'Manager',
        7: 'Selectie commissie'
      }
    },
    newTeamMember () {
      return { lTeamRole: this.chosenRole, teamMemberId: null, user: omit(this.selected, 'roles') }
    }
  },
  methods: {
    save () {
      this.$store.dispatch(ADD_VACANCY_TEAM_MEMBER, [this.newTeamMember])
      this.$emit('removeSelected')
    }
  }
}
</script>
