<template>
  <div v-if="!isSelectionCommittee" class="d-flex flex-wrap justify-content-end">
    <b-btn
      v-for="(button, key) in ratingButtons"
      :key="key"
      :variant="recruiterRating === key ? button.variant : 'white'"
      :class="[{ 'text-primary': recruiterRating !== key }, button.classes]"
      @click="$emit('changeRating', button.value)"
      :size="button.size"
      class="rating-button"
    >
      <i :class="button.icon" /> {{ button.name }}
    </b-btn>
  </div>
</template>
<script>
export default {
  name: 'ProspectRatingButtons',
  props: {
    isSelectionCommittee: {
      type: Boolean,
      default: false
    },
    ratingButtons: {
      type: Object,
      default: () => ({})
    },
    recruiterRating: {
      type: String,
      default: ''
    },
    direction: {
      type: String,
      default: 'row'
    }
  }
}
</script>

<style lang="scss" scoped>
.rating-button {
  padding: .375rem 0.75rem !important;

  &.fixed-width {
    width: 115px;
  }
}
</style>
