import {
  SET_ROLES,
  SET_USER_PERMISSIONS,
  SET_JWT,
  SET_USERNAME,
  SET_CURRENT_TENANT,
  SET_AVAILABLE_TENANTS,
  SET_AUTH_ERROR
} from '../keys-mutations'
import {
  LOAD_USER_DATA,
  LOAD_CURRENT_USER_DETAILS,
  LOGOUT,
  HANDLE_AUTH_CALLBACK,
  INIT_AUTH,
  CHECK_TOKEN
} from '../keys-actions'
import {
  USER_AUTHENTICATED,
  USER_DATA_LOADED,
  USERNAME,
  IS_ADMIN,
  USER_PERMISSIONS
} from '../keys-getters'
import cookie from 'js-cookie'
import apiRequest from '../../services/api-request'
import auth from '../../auth.js'

export default {
  state: {
    jwt: '',
    username: '',
    roles: [],
    userPermissions: [],
    error: null
  },
  mutations: {
    [SET_JWT]: (state, jwt) => {
      state.jwt = jwt
      if (jwt) {
        cookie.set('jwt', jwt, { path: '/api/', secure: process.env.NODE_ENV === 'production' })
      } else {
        cookie.remove('jwt', { path: '/api/' })
      }
    },
    [SET_USERNAME]: (state, username) => {
      state.username = username
      if (username) {
        sessionStorage.setItem('username', username)
      } else {
        sessionStorage.removeItem('username')
      }
    },
    [SET_ROLES]: (state, roles) => {
      state.roles = roles
    },
    [SET_USER_PERMISSIONS]: (state, userPermissions) => {
      state.userPermissions = userPermissions
    },
    [SET_AUTH_ERROR]: (state, error) => {
      state.error = error
    }
  },
  getters: {
    [USER_AUTHENTICATED]: state => !!state.jwt,
    [USER_DATA_LOADED]: state => !!state.username,
    [USERNAME]: state => {
      const impersonatedUser = localStorage.getItem('impersonatedUser')
      return impersonatedUser || state.username
    },
    [IS_ADMIN]: (state, getters) => !!state.roles.find(role => role.name === 'ADMINISTRATOR'),
    [USER_PERMISSIONS]: state => state.userPermissions
  },
  actions: {
    [CHECK_TOKEN]: async ({ commit }) => {
      try {
        commit(SET_JWT, await auth.getTokenSilently())
      } catch (error) {
        commit(SET_AUTH_ERROR, error.message)
      }
    },
    [INIT_AUTH]: async ({ commit, dispatch }, skipLogin) => {
      try {
        commit(SET_JWT, await auth.getTokenSilently())
        dispatch(LOAD_USER_DATA)
      } catch (error) {
        if (
          !skipLogin &&
          (error.error === 'login_required' ||
          error.error === 'consent_required')
        ) {
          auth.loginWithRedirect()
        } else {
          commit(SET_AUTH_ERROR, error.message)
        }
      }
    },
    [LOGOUT]: async ({ commit }) => {
      try {
        await auth.logout({ returnTo: window.location.origin })
        commit(SET_USERNAME, null)
        commit(SET_JWT, null)

        cookie.remove('one-user', { path: '/api/' })
        localStorage.removeItem('impersonatedUser')
        // Deprecated, but remove just in case
        localStorage.removeItem('jwt-payload')
      } catch (error) {
        commit(SET_AUTH_ERROR, error.message)
      }
    },
    [HANDLE_AUTH_CALLBACK]: async ({ commit, getters, dispatch }) => {
      try {
        await auth.handleRedirectCallback()
        commit(SET_JWT, await auth.getTokenSilently({ ignoreCache: true }))
        await dispatch(LOAD_USER_DATA)
      } catch (error) {
        if (error.error === 'consent_required') {
          auth.loginWithRedirect()
        } else {
          commit(SET_AUTH_ERROR, error.message)
          commit(SET_USERNAME, null)
        }
      }
    },
    [LOAD_USER_DATA]: async ({ commit, dispatch, getters }) => {
      try {
        let adminInfo = {}
        const impersonatedUser = localStorage.getItem('impersonatedUser')
        if (impersonatedUser) {
        // If we're impersonating a user, we need to get the admin's info first before fetching the impersonated user
          adminInfo = await apiRequest({ method: 'GET', url: '/api/erecruiter-web-api/me', headers: { 'x-ignore-impersonation': true } })
        }

        const userInfo = await apiRequest({ method: 'GET', url: '/api/erecruiter-web-api/me' })
        commit(SET_CURRENT_TENANT, userInfo.tenant)
        commit(SET_AVAILABLE_TENANTS, userInfo.availableTenants)
        commit(SET_USERNAME, adminInfo.username || userInfo.username)
        commit(SET_ROLES, adminInfo.roles || userInfo.roles)
        commit(SET_USER_PERMISSIONS, userInfo.permissions)

        const username = getters[USERNAME]
        if (username) {
          dispatch(LOAD_CURRENT_USER_DETAILS, username)
        }
      } catch (e) {
        // If you can't get the current user's info, log out the user
        dispatch(LOGOUT)
      }
    }
  }
}
