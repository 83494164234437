export default {
  discipline_1: 'Administratief / Secretarieel',
  discipline_2: 'Automatisering / ICT',
  discipline_3: 'Bestuurlijk',
  discipline_4: 'Binnendienst / Algemene dienst',
  discipline_5: 'Bouwkunde / Civiele techniek',
  discipline_6: 'Brandweer',
  discipline_7: 'Buitendienst / Groenvoorziening',
  discipline_8: 'Burger- / publiekzaken',
  discipline_9: 'Dienstverlening / facilitair',
  discipline_10: 'Financieel / economisch',
  discipline_11: 'Gezondheidszorg',
  discipline_12: 'Griffie',
  discipline_13: 'Juridisch',
  discipline_14: 'Onderwijs / wetenschap / cultuur',
  discipline_15: 'Openbare orde en veiligheid',
  discipline_16: 'Personeel / organisatie',
  discipline_17: 'Ruimtelijke ordening / milieu',
  discipline_18: 'Sociale zaken / werkgelegenheid',
  discipline_19: 'Sport / recreatie',
  discipline_20: 'Verkeer / vervoer / nautisch',
  discipline_21: 'Verkoop / inkoop',
  discipline_22: 'Voorlichting / communicatie',
  discipline_23: 'Welzijn',
  discipline_24: 'Administratie',
  discipline_25: 'Administratief/secretarieel/ondersteuning',
  discipline_26: 'Advocatuur',
  discipline_27: 'Agrarisch',
  discipline_28: 'Analyse / Testing',
  discipline_29: 'Applicatie ontwikkeling & programmering',
  discipline_30: 'Applicatiebeheer',
  discipline_31: 'Automatisering',
  discipline_32: 'Basisonderwijs',
  discipline_33: 'Bedrijfsleven',
  discipline_34: 'Beheer/onderhoud',
  discipline_35: 'Beleggen',
  discipline_36: 'Bouwkunde/architectuur',
  discipline_37: 'Burgerzaken/publieke dienstverlening',
  discipline_38: 'Calculatie',
  discipline_39: 'Chauffeur',
  discipline_40: 'Chemie / milieu',
  discipline_41: 'Commercieel',
  discipline_42: 'Commercieel/Inkoop',
  discipline_43: 'Communicatie/Marketing',
  discipline_44: 'Computer- en netwerkbeveiliging',
  discipline_45: 'Consulting',
  discipline_46: 'Customer Service',
  discipline_47: 'Database ontwikkeling en -administratie',
  discipline_48: 'Directie/Management',
  discipline_49: 'Documentatie & Informatievoorziening',
  discipline_50: 'Douane',
  discipline_51: 'Drogist',
  discipline_52: 'E-installatie',
  discipline_53: 'Engineering/constructie',
  discipline_54: 'Expediteur',
  discipline_55: 'Facilitair/civiel/huishoudelijk',
  discipline_56: 'Farmaceutisch',
  discipline_57: 'Fiscaal',
  discipline_58: 'Fiscaal-Juridisch',
  discipline_59: 'Gastvrouw/zorghulp',
  discipline_60: 'Geestelijke gezondheidszorg',
  discipline_61: 'Geodesie',
  discipline_62: 'Handhaving / Regelgeving',
  discipline_63: 'HBO / universitair onderwijs',
  discipline_64: 'Helpdesk en support',
  discipline_65: 'Helpende',
  discipline_66: 'Horeca',
  discipline_67: 'ICT',
  discipline_68: 'Inkoop',
  discipline_69: 'Inkoop & aanbesteding',
  discipline_70: 'Integratie/migratie',
  discipline_71: 'Internationaal',
  discipline_72: 'Jongeren',
  discipline_73: 'Juridische dienstverlening',
  discipline_74: 'KAM/kwaliteitscontrole/veiligheid',
  discipline_75: 'Kleuteronderwijs / Kinderopvang',
  discipline_76: 'Kopersbegeleiding',
  discipline_77: 'Kunst & Cultuur',
  discipline_78: 'Kwaliteitszorg',
  discipline_79: 'Laboratorium',
  discipline_80: 'Laboratorium/radiologie',
  discipline_81: 'Logistiek & Distributie',
  discipline_82: 'Maatschappelijk/sociaal cultureel',
  discipline_83: 'Magazijn/Loods',
  discipline_84: 'Marketing & Reclame',
  discipline_85: 'Marketing & Verkoop',
  discipline_86: 'Medisch',
  discipline_87: 'Milieu/natuur/water',
  discipline_88: 'Montage',
  discipline_89: 'Netwerk- en serverbeheer',
  discipline_90: 'Non-profit instellingen',
  discipline_91: 'Notariaat',
  discipline_92: 'Onderwijs & Onderzoek',
  discipline_93: 'Onderwijs, Wetenschap en Onderzoek',
  discipline_94: 'Ontwerp technisch',
  discipline_95: 'Ontwerp uitvoerend',
  discipline_96: 'Oogheelkunde / Opticien',
  discipline_97: 'Ouderen',
  discipline_98: 'Paramedisch / Therapeutisch ',
  discipline_99: 'Personeel/organisatie/arbeidsvoorwaarden',
  discipline_100: 'Planning & Control',
  discipline_101: 'Politiek',
  discipline_102: 'Portfoliomanagement',
  discipline_103: 'Projectontwikkeling',
  discipline_104: 'Psychologisch/psychiatrisch',
  discipline_105: 'Rampenbestrijding',
  discipline_106: 'Rechterlijke macht',
  discipline_107: 'Risk management',
  discipline_108: 'Ruimtelijke ordening/infrastructuur',
  discipline_109: 'Secretarieel',
  discipline_110: 'Slijterij',
  discipline_111: 'Sociaal-pedagogisch',
  discipline_112: 'Sociale dienstverlening',
  discipline_113: 'Software implementatie en consulting',
  discipline_114: 'Speciaal onderwijs',
  discipline_115: 'Stedenbouw',
  discipline_116: 'Supermarkt',
  discipline_117: 'Tandheelkunde',
  discipline_118: 'Tekenen/bestekschrijven',
  discipline_119: 'Telecommunicatie & mobiele systemen',
  discipline_120: 'Training en persoonlijke ontwikkeling',
  discipline_121: 'Transportplanning',
  discipline_122: 'Verplegend/Verzorgend',
  discipline_123: 'Voedingsleer',
  discipline_124: 'Volkshuisvesting',
  discipline_125: 'Voorlichting/informatie',
  discipline_126: 'Voortgezet / middelbaar onderwijs',
  discipline_127: 'W-installatie',
  discipline_128: 'Webdesign en contentbeheer',
  discipline_129: 'Werkvoorbereiding/planning',
  discipline_130: '',
  discipline_131: 'Overheid'
}
