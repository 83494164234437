// GLOBAL
export const VIEW_ARCHIVED_VACANCIES = 'PERM_VIEW_ARCHIVED_VACANCIES'
export const EDIT_VACANCY_PUBLICATION_MIMIR =
  'PERM_EDIT_VACANCY_PUBLICATION_MIMIR'
export const VIEW_FAVORITE_TEMPLATE = 'PERM_VIEW_FAVORITE_TEMPLATE'
export const EDIT_FAVORITE_TEMPLATE = 'PERM_EDIT_FAVORITE_TEMPLATE'
export const CREATE_NEW_VACANCY = 'PERM_CREATE_VACANCY'
export const CREATE_USER = 'PERM_CREATE_USER'
export const VIEW_REPORTING = 'PERM_VIEW_REPORTING'

// NON GLOBAL
export const VIEW_VACANCY_TEAM = 'PERM_VIEW_VACANCY_TEAM'
export const VIEW_VACANCY_PUBLICATION_CHANNELS =
  'PERM_VIEW_VACANCY_PUBLICATION_CHANNELS'
export const EDIT_VACANCY_ENTRY = 'PERM_EDIT_VACANCY_ENTRY'
export const EDIT_SELECTION_COMMITEE_INTERFACE =
  'PERM_EDIT_SELECTION_COMMITEE_INTERFACE'
export const VIEW_SELECTION_COMMITEE_INTERFACE =
  'PERM_VIEW_SELECTION_COMMITEE_INTERFACE'
export const SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE =
  'PERM_SUBMIT_PROSPECTS_TO_SELECTION_COMMITTEE'
export const SHARE_SELECTION = 'PERM_SHARE_SELECTION'
export const CREATE_VACANCY_PROSPECT_NOTE = 'PERM_CREATE_VACANCY_PROSPECT_NOTE'
export const CREATE_VACANCY_PROSPECT_TODO = 'PERM_CREATE_VACANCY_PROSPECT_TODO'
export const VIEW_EXTERNALLY = 'PERM_VIEW_EXTERNALLY'
export const FINISH_VACANCY_SELECTION = 'PERM_FINISH_VACANCY_SELECTION'
export const MULTIPLE_SELECTION_ROUNDS = 'PERM_MULTIPLE_SELECTION_ROUNDS'
export const MULTIPLE_SELECTION_ROUNDS_LABEL =
  'PERM_MULTIPLE_SELECTION_ROUNDS_LABEL'
export const CREATE_VACANCY_APPLICANT = 'PERM_CREATE_VACANCY_APPLICANT'
