export default {
  jobCategory_1: 'Uitvoerend / ondersteunend',
  jobCategory_2: 'Management / leidinggevend',
  jobCategory_3: 'Beleid / staf / advies / onderzoek',
  jobCategory_4: 'Directie',
  jobCategory_5: 'Projectleiding',
  jobCategory_6: 'Algemeen bestuur',
  jobCategory_7: 'Projectmedewerker',
  jobCategory_8: 'Handhavend / toezichthoudend',
  jobCategory_9: 'Specialist',
  jobCategory_10: 'Vaktechnisch/Administratief',
  jobCategory_11: 'Zelfstandig/franchise'
}
