<template>
  <div class="icon-box">
    <i class="fa fa-check-square-o stacked-icon checkbox" />
    <i class="fa fa-user stacked-icon user" />
  </div>
</template>

<script>
export default {
  name: 'TodoMyselfIcon'
}
</script>

<style lang="scss" scoped>

.icon-box {
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
  position: relative;
  padding: 2.3px;
  height: 17px;
  width: 23px;
  text-align: left;
  .stacked-icon {
    display: block;
    position: absolute;
    white-space: normal;

    top: 0;
    left: 0;
  }
  .checkbox {
    left: 5px;
    color: #bbbbbb;
  }
  .user {
    transform: scale(0.9);
    top: 4px;
    color: #9f9f9f;
  }
}
</style>
