<template>
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          Vacatures
        </h1>
        <div>
          <small>
            <span>{{ openCount }} open</span>
            <span v-if="showArchivedLink"
              >,
              <b-link
                style="color: #000; text-decoration: underline;"
                :to="{ name: 'archive' }"
              >
                <span v-if="archivedCount">{{ archivedCount }} gearchiveerd</span>
                <span v-else>gearchiveerde vacatures</span>
                </b-link
              >
            </span>
          </small>
        </div>
        <div class="ml-auto">
          <b-btn
            variant="outline-primary"
            v-if="showPendingTodosButton"
            class="mr-3 pending-todos-btn mb-2 mb-sm-0"
            @click="openPendingTodosModal"
          >
            <div :class="{ 'overdue-alert': hasTodosPastDueDate }"></div>
            <i class="fa fa-check-square-o" /> Zie openstaande To-Dos
          </b-btn>
          <b-btn class="mb-2 mb-sm-0" v-if="showNewVacancyButton" @click="createNewVacancy">
            <i class="fa fa-plus" style="color: #b6980a;" /> nieuwe vacature
          </b-btn>
        </div>
      </div>
      <div class="row no-gutter"></div>
    </div>
  </div>
</template>

<script>
import modal from '../../services/modal'
import CreateVacancyModal from './create-vacancy-modal'
import PendingTodosModal from './pending-todos-modal'
import { CREATE_VACANCY } from '../../store/keys-actions'
import {
  OPEN_VACANCY_COUNT,
  ARCHIVED_VACANCY_COUNT,
  PENDING_TODOS
} from '../../store/keys-getters'
import {
  VIEW_ARCHIVED_VACANCIES,
  CREATE_NEW_VACANCY
} from '../../constants/permissions'
export default {
  name: 'VacancyNavHeader',
  computed: {
    openCount () {
      return this.$store.getters[OPEN_VACANCY_COUNT]
    },
    pendingTodos () {
      return this.$store.getters[PENDING_TODOS]
    },
    archivedCount () {
      return this.$store.getters[ARCHIVED_VACANCY_COUNT]
    },
    showArchivedLink () {
      return this.hasPermission(VIEW_ARCHIVED_VACANCIES)
    },
    showNewVacancyButton () {
      return this.hasPermission(CREATE_NEW_VACANCY)
    },
    showPendingTodosButton () {
      return true
    },
    hasTodosPastDueDate () {
      return this.pendingTodos.filter(
        todo => Date.parse(todo.dueDate) < Date.now()
      ).length
    }
  },
  methods: {
    createNewVacancy () {
      modal(CreateVacancyModal, {}).then(vacancyTitle => {
        if (!vacancyTitle) {
          return
        }
        this.$store.dispatch(CREATE_VACANCY, vacancyTitle)
      })
    },
    openPendingTodosModal () {
      modal(PendingTodosModal, { todos: this.pendingTodos })
    }
  }
}
</script>

<style lang="scss" scoped>
$alert-size: 6px;

.pending-todos-btn {
  position: relative;
  .overdue-alert {
    position: absolute;
    top: 0.3rem;
    left: 0.3rem;
    width: $alert-size;
    height: $alert-size;
    background-color: #dc3545;
    border-radius: 50%;
  }
}
</style>
