<template>
  <page>
    <prospect-judgements-header slot="header" />
    <prospect slot="main-content" />
    <prospect-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import ProspectJudgementsHeader from './prospect-judgements-header'
import ProspectAside from './prospect-aside.vue'
import Prospect from './prospect'
import {
  LOAD_SELECTION_COMMITTEE_PROSPECTS,
  LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT
} from '../../store/keys-actions'
import { SET_CURRENT_SELECTION_COMMITTEE_PROSPECT } from '../../store/keys-mutations'

export default {
  name: 'prospectJudgementDetail',
  components: {
    Page,
    ProspectJudgementsHeader,
    ProspectAside,
    Prospect
  },
  mounted () {
    this.$store.dispatch(LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT, this.$route.params.index)
    this.$store.dispatch(LOAD_SELECTION_COMMITTEE_PROSPECTS)
  },
  beforeRouteUpdate (to, from, next) {
    this.$store
      .dispatch(LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT, to.params.index)
      .then(() => next())
      .catch(() => next(false))
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(SET_CURRENT_SELECTION_COMMITTEE_PROSPECT, {})
    next()
  }
}
</script>
