<template>
  <div>
    <div class="pb-5">
      Publiceer de vacature op de gewenste kanalen
    </div>
    <notification-block v-if="disableChannelsPublication" class="mb-4" variation="warn">
      <span slot="message">
        <i class="fa fa-exclamation-triangle mr-2" /><span
          >Publiceren naar Gemeentebanen.nl, Zorgbanen.nl en werkenbij-sites is
          momenteel niet mogelijk door onderhoud</span
        >
      </span>
    </notification-block>
    <div
      v-for="channelCategory in validChannelCategoryTitles"
      :key="channelCategory.key"
      class="pb-5"
    >
      <h5>{{ channelCategory.title }}</h5>
      <div
        class="d-flex mb-2 align-items-center"
        v-if="channelCategory.displayDates"
      >
        <!-- prettier-ignore-attribute :class -->
        <datepicker
          class="mr-2"
          v-model="channelCategoryDates[channelCategory.key].start"
          placeholder="startdatum"
          :language="datepickerLanguage.nl"
          :disabled-dates="disabledDates"
          :name="`${channelCategory.key}StartDate`"
          :ref="`${channelCategory.key}StartDate`"
          v-validate="{
            required: validateDate[channelCategory.key],
            date_format: 'yyyy-MM-dd',
          }"
          data-vv-as="startdatum"
          :class="errors.has(`${channelCategory.key}StartDate`)
              ? 'border border-error'
              : ''"
        ></datepicker>
        <div>tot en met</div>
        <!-- prettier-ignore-attribute :class -->
        <datepicker
          class="ml-2"
          v-model="channelCategoryDates[channelCategory.key].end"
          placeholder="Einddatum"
          :language="datepickerLanguage.nl"
          :disabled-dates="disabledDates"
          :name="`${channelCategory.key}EndDate`"
          v-validate="{
            required: validateDate[channelCategory.key],
            date_format: 'yyyy-MM-dd',
            after: `${channelCategory.key}StartDate`,
          }"
          data-vv-as="einddatum"
          :class="errors.has(`${channelCategory.key}EndDate`)
              ? 'border border-error'
              : ''"
        ></datepicker>
      </div>
      <div
        v-if="errors.has(`${channelCategory.key}StartDate`)"
        class="text-error"
      >
        {{ errors.first(`${channelCategory.key}StartDate`) }}
      </div>
      <div
        v-if="errors.has(`${channelCategory.key}EndDate`)"
        class="text-error"
      >
        {{ errors.first(`${channelCategory.key}EndDate`) }}
      </div>
      <div
        v-if="channelCategory.description"
        class="pb-2"
        v-html="channelCategory.description"
      />
      <div
        v-for="(channel, index) in privateValues[channelCategory.key]"
        :key="index"
      >
        <channel
          v-model="privateValues[channelCategory.key][index]"
          :jobCategories="jobStrategies[channel.channelId]"
          :mimirOptions="mimir[channel.channelId]"
          :disabled="disableChannelsPublication"
        ></channel>
      </div>
    </div>
    <div class="col-12 d-flex pt-5">
      <b-btn variant="primary" class="mr-auto" @click="previous">
        <i class="fa fa-chevron-left" />
        Vorige stap
      </b-btn>
      <b-btn variant="primary" @click="next">
        Opslaan
        <i v-if="loading" class="fa fa-spinner fa-spin" />
      </b-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'
import cloneDeep from 'lodash/cloneDeep'
import flatMap from 'lodash/flatMap'
import differenceWith from 'lodash/differenceWith'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import mapValues from 'lodash/mapValues'
import some from 'lodash/some'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import {
  PUBLICATION_CHANNELS,
  MIMIR_CHANNELS_DROPDOWNS,
  PUBLICATION_CHANNELS_JOB_STRATEGIES,
  LOADING
} from '../../../store/keys-getters'
import {
  LOAD_PUBLICATION_CHANNELS,
  PUBLISH_VACANCY
} from '../../../store/keys-actions'
import {
  CHANNEL_CAT_ADVERTISING,
  CHANNEL_CAT_EXTERNAL,
  CHANNEL_CAT_HIDDEN,
  CHANNEL_CAT_INTERNAL,
  CHANNEL_CAT_JOBMARKETING,
  CHANNEL_CAT_MOBILITY,
  CHANNEL_CAT_REGIONAL
} from '../../../constants/channel-category'
import Channel from './channel'
import NotificationBlock from '../../notification-block.vue'
import config from '../../../config.js'

const { DISABLE_CHANNELS_PUBLICATION } = config

export default {
  components: {
    Channel,
    Datepicker,
    NotificationBlock
  },
  data () {
    return {
      privateValues: {},
      channelCategoryDates: {
        CHANNEL_CAT_HIDDEN: {},
        CHANNEL_CAT_MOBILITY: {},
        CHANNEL_CAT_INTERNAL: {},
        CHANNEL_CAT_REGIONAL: {},
        CHANNEL_CAT_EXTERNAL: {},
        CHANNEL_CAT_ADVERTISING: {},
        CHANNEL_CAT_JOBMARKETING: {}
      },
      // This flag toggles publishing / de-publishing to all channels, to be used during TOO migration
      disableChannelsPublication: DISABLE_CHANNELS_PUBLICATION
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    channels () {
      return this.$store.getters[PUBLICATION_CHANNELS]
    },
    mimir () {
      return this.$store.getters[MIMIR_CHANNELS_DROPDOWNS]
    },
    jobStrategies () {
      return this.$store.getters[PUBLICATION_CHANNELS_JOB_STRATEGIES]
    },
    channelCategoryTitles () {
      return [
        {
          key: CHANNEL_CAT_HIDDEN,
          title: 'One sollicitatieformulier',
          description: '',
          displayDates: true
        },
        {
          key: CHANNEL_CAT_MOBILITY,
          title: 'Loopbaancentrum',
          description: '',
          displayDates: true
        },
        {
          key: CHANNEL_CAT_INTERNAL,
          title: 'Intern',
          description: '',
          displayDates: true
        },
        {
          key: CHANNEL_CAT_REGIONAL,
          title: 'Regionaal',
          description: '',
          displayDates: true
        },
        {
          key: CHANNEL_CAT_EXTERNAL,
          title: 'Extern',
          description: '',
          displayDates: true
        },
        {
          key: CHANNEL_CAT_ADVERTISING,
          title: 'Hypertargeted Social Recruitment',
          description:
            'Activeer Social Recruitment voor je vacature en bereik ook de passief werkzoekenden!<br/><br/> Klik op ' +
            'onderstaande button en laat je vacature scannen door ONE. De kenmerken van jouw vacature worden gebruikt om de ' +
            'optimale mix van social media kanalen te bepalen. Onze consultants bellen je dezelfde dag voor een passend Social ' +
            'Recruitment advies (vrijblijvend).',
          displayDates: false
        },
        {
          key: CHANNEL_CAT_JOBMARKETING,
          title: 'Jobmarketing',
          description:
            'Wil jij weten welke (niche)jobboards het meest effectief zijn voor jouw vacature? Onze consultants adviseren ' +
            'dagelijks over passende wervingskanalen en kunnen je vacature doorplaatsen voor een voordelig tarief. Klik op onderstaande ' +
            'knop en je wordt dezelfde dag gebeld voor een jobmarketing advies (vrijblijvend).',
          displayDates: false
        }
      ]
    },
    validateDate () {
      return mapValues(this.privateValues, (category) =>
        some(category, 'isSelected', true)
      )
    },
    datepickerLanguage () {
      return {
        nl: nl
      }
    },
    disabledDates () {
      const today = new Date()
      const yesterday = new Date(today.setDate(today.getDate() - 1))
      return {
        to: yesterday
      }
    },
    changes () {
      const newStatusChannels = flatMap(this.privateValues, (n) => n)
      const oldStatusChannels = flatMap(this.channels, (n) => n)
      return differenceWith(newStatusChannels, oldStatusChannels, isEqual)
    },
    validChannelCategoryTitles () {
      return this.channelCategoryTitles.filter(
        (channelCategory) =>
          this.channels[channelCategory.key] &&
          this.channels[channelCategory.key].length > 0
      )
    }
  },
  watch: {
    channels: function () {
      this.privateValues = cloneDeep(this.channels)
      return forEach(this.channels, (channelCategory, key) => {
        const channelWithDates = find(channelCategory, 'publishStartDate')
        this.$set(this.channelCategoryDates, key, {
          start: channelWithDates ? channelWithDates.publishStartDate : null,
          end: channelWithDates ? channelWithDates.publishEndDate : null
        })
      })
    },
    channelCategoryDates: {
      handler: function () {
        return forEach(this.privateValues, (channelCategory, key) =>
          forEach(channelCategory, (channel) => {
            if (this.channelCategoryDates[key].start) {
              this.$set(
                channel,
                'publishStartDate',
                this.customFormatter(this.channelCategoryDates[key].start)
              )
            }
            if (this.channelCategoryDates[key].end) {
              this.$set(
                channel,
                'publishEndDate',
                this.customFormatter(this.channelCategoryDates[key].end)
              )
            }
          })
        )
      },
      deep: true
    }
  },
  methods: {
    customFormatter (date) {
      return moment(date).format('YYYY-MM-DD')
    },
    previous () {
      this.$router.push({ name: 'vacancyTeam' })
    },
    save () {
      return this.$validator.validate().then((isValid) => {
        if (!isValid) {
          // TODO write a better text for this error
          return Promise.reject(
            new Error('Kan niet opslaan, corrigeer de fouten')
          )
        }
        if (isEmpty(this.changes)) {
          return Promise.resolve()
        }
        return this.$store.dispatch(PUBLISH_VACANCY, this.changes)
      })
    },
    next () {
      return this.save()
        .then(() => this.$router.push({ name: 'vacancy' }))
        .catch(() => window.scroll(0, 0))
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_PUBLICATION_CHANNELS)
  }
}
</script>
