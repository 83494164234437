<template>
  <div v-if="ready">
    <progress-bar :running="loading" />
    <modals :modals="modals" />
    <notifications />
    <main-header v-if="showHeader" />
    <info-bar v-if="showInfoBar" />
    <b-container fluid >
      <router-view />
    </b-container>
  </div>
</template>

<script>
import MainHeader from './components/main-header.vue'
import InfoBar from './components/info-bar.vue'
import Modals from './components/modals.vue'
import ProgressBar from './components/progress-bar'
import { LOADING, USER_DATA_LOADED, MODALS, IS_ADMIN } from './store/keys-getters'
import { HANDLE_AUTH_CALLBACK, INIT_AUTH } from './store/keys-actions'
import Notifications from './components/notifications'

export default {
  name: 'app',
  components: {
    MainHeader,
    InfoBar,
    Modals,
    ProgressBar,
    Notifications
  },
  data () {
    return {
      showInfoBar: false
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    modals () {
      return this.$store.getters[MODALS]
    },
    loggedIn () {
      return this.$store.getters[USER_DATA_LOADED]
    },
    showHeader () {
      const headerlessRouteNames = ['mergeJobs', 'login']
      return !headerlessRouteNames.includes(this.$route.name)
    },
    ready () {
      // Check if it's a page that doesn't need authorization (token prospects)
      return this.loggedIn || this.$route.path.includes('tokenProspects')
    }
  },
  created: async function () {
    if (window.location.pathname === '/auth_callback') {
      await this.$store.dispatch(HANDLE_AUTH_CALLBACK)
      // For admins, redirect to organizations list
      if (this.$store.getters[IS_ADMIN]) {
        document.location.replace('/admin/#organizations')
      } else {
        // Remove the auth_callback part from the URL without loading the page again
        history.replaceState(null, '', '/#/home')
      }
    } else {
      await this.$store.dispatch(INIT_AUTH, this.$route.path.includes('tokenProspects'))
    }
  }
}
</script>
