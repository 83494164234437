<template>
  <page>
    <vacancy-nav-header slot="header" />
    <description slot="main-content" />
    <vacancy-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../../page-types/default-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header.vue'
import VacancyAside from '../vacancy-aside.vue'
import Description from './description.vue'

const alertBeforeLeave = (e) => {
  // Cancel the event as stated by the standard.
  e.preventDefault()
  // Chrome requires returnValue to be set.
  e.returnValue = ''
}

export default {
  name: 'VacancyDescriptionPage',
  inject: {
    $validator: '$validator'
  },
  components: {
    Page,
    VacancyNavHeader,
    VacancyAside,
    Description
  },
  computed: {
    isFormChanged () {
      return Object.keys(this.fields).some((key) => this.fields[key].changed)
    }
  },
  beforeRouteLeave (to, from, next) {
    if (!this.isFormChanged) {
      next()
      return
    }
    const hasLeaveRoutePermission = confirm(
      'Let op! De inhoud van deze pagina wordt niet automatisch opgeslagen. Weet je zeker dat je de pagina wil verlaten?'
    )
    if (hasLeaveRoutePermission) {
      next()
    }
    next(false)
  },
  created () {
    window.addEventListener('beforeunload', alertBeforeLeave)
  },
  destroyed () {
    window.removeEventListener('beforeunload', alertBeforeLeave)
  }
}
</script>
