export default {
  getMessage () {
    return 'Value should be a mailto (e.g. mailto:john@doe.com) or a valid URL (e.g. www.my-domain.com/my-form)'
  },
  validate (value) {
    // re matches mailto:john@doe.com, https://www.domain.com, https://www.domain.com/pathname, http://www.domain.com/pathname, mailto:r.willemsen@rekenkamer.rotterdam.nl...
    const re = /^mailto:[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9-]+$|^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/\S*)?$/i

    return re.test(value)
  }
}
