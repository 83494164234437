<template>
  <div class="pt-2 pb-2 pl-3 pr-1">
    <div class="d-flex flex-column flex-sm-row" style="height:100px;">
      <div class="align-self-start align-self-sm-center" style="width:65%;">
        <div class="d-flex flex-column flex-sm-row align-items-baseline">
          <b-link
            :to="{ name: 'vacancy', params: { vacancyId: vacancy.vacancyId } }"
          >
            <h4 class="mb-0 pr-2">
              <strong>{{ vacancy.title }}</strong>
            </h4>
          </b-link>
          <div class=" pr-4" style="color: #f68b1e;">
            <small>GEARCHIVEERD</small>
          </div>
        </div>
        <div>
          {{ vacancy.status | formatStatus }}
        </div>
      </div>
      <div
        class="d-flex align-self-start align-self-sm-center mr-auto flex-row flex-sm-column"
      >
        <div class="pr-1">Aangemaakt op:</div>
        <div>{{ vacancy.dateCreated | formatDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ArchiveVacancyListItem',
  props: {
    vacancy: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatStatus (scope) {
      const map = {
        WITHDRAWN: 'Ingetrokken',
        CLOSED: 'Aangenomen',
        MIGRATED: 'Import'
      }
      return map[scope] || ''
    }
  }
}
</script>
