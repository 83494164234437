<template>
  <div class="row pt-4 pb-3">
    <div class="col-12 col-md-8 col-lg-10">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          {{ vacancy.title || "..." }}
        </h1>
        <div>
          <small>
            <vacancy-status-icon :vacancy="vacancy" /> vacature nr.
            {{ vacancy.vacancyId || "..." }}
          </small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-4 col-lg-2 text-right">
      <preview-button
        :privateDetails="vacancy"
        :dropdownOptions="options"
        size="md"
      />
    </div>
  </div>
</template>

<script>
import VacancyStatusIcon from '../vacancy/vacancy-status-icon'
import {
  VACANCY,
  VACANCY_DESCRIPTION_DROPDOWN_OPTIONS
} from '../../store/keys-getters'
import PreviewButton from '../vacancy-preview/preview-button.vue'

export default {
  name: 'prospectHeader',
  components: {
    VacancyStatusIcon,
    PreviewButton
  },
  computed: {
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    options () {
      return this.$store.getters[VACANCY_DESCRIPTION_DROPDOWN_OPTIONS]
    }
  }
}
</script>
