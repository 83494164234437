export const stripHostFromURL = attachmentURL => {
  // Use a local URL instead of an absolute one so it uses the proxy that adds the auth info to the request
  if (!attachmentURL) return ''

  const url = new URL(attachmentURL)
  return `/api${url.pathname}${url.search}`
}

export const stripHostFromAttachment = (attachment, propertyName) => {
  if (!attachment || !attachment[propertyName]) return

  attachment[`${propertyName}Local`] = stripHostFromURL(attachment[propertyName])
}
