var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-4 py-4 d-flex align-items-start bg-white border-left border-right border-bottom border-light-gray"},[_c('div',{staticClass:"mx-3"},[_c('b-link',{key:_vm.prospect.prospectId,staticClass:"avatar-link",attrs:{"to":{
        name: 'tokenProspectsDetail',
        params: { id: this.index }
      }}},[(!_vm.prospect.avatarUrl)?_c('avatar',{attrs:{"fullname":_vm.fullname}}):_vm._e(),(_vm.prospect.avatarUrl)?_c('div',{staticClass:"d-flex align-items-center justify-content-around",staticStyle:{"width":"96px","height":"96px","overflow":"hidden"}},[(_vm.prospect.avatarUrl)?_c('img',{staticClass:"img-thumbnail p-0 border-0 bg-transparent",attrs:{"src":_vm.prospect.avatarUrl.urlLocal}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"avatar-link--underline"})],1)],1),_c('div',{staticClass:"d-flex flex-column ml-2"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('h5',[_c('strong',[_c('b-link',{attrs:{"to":{
                name: 'tokenProspectsDetail',
                params: { id: this.index }
              }}},[_vm._v(" "+_vm._s(_vm.fullname)+" ")])],1)])])]),(_vm.candidateSource)?_c('div',[_vm._v(_vm._s(_vm.candidateSource))]):_vm._e(),(_vm.applicationDate)?_c('div',[_vm._v(_vm._s(_vm.applicationDate))]):_vm._e(),_c('div',{staticClass:"d-flex text-medium-gray"},[(_vm.prospect.emailAddress)?_c('b-link',{staticClass:"text-medium-gray mr-2",attrs:{"variant":"link","href":("mailto:" + (_vm.prospect.emailAddress))}},[_c('i',{staticClass:"fa fa-envelope"})]):_vm._e(),(
          _vm.prospect.emailAddress &&
            (_vm.prospect.phoneNumber || _vm.prospect.mobileNumber)
        )?_c('div',[_vm._v(" |  ")]):_vm._e(),_c('b-link',{staticClass:"text-medium-gray",attrs:{"href":("tel:" + (_vm.prospect.phoneNumber ? _vm.prospect.phoneNumber : _vm.prospect.mobileNumber))}},[_c('strong',[_vm._v(_vm._s(_vm.prospect.phoneNumber ? _vm.prospect.phoneNumber : _vm.prospect.mobileNumber))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }