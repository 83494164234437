<template>
  <div>
    <customer-support-card class="mb-3" />
    <release-notes-card v-if="showReleaseNotesCard" />
  </div>
</template>
<script>
import CustomerSupportCard from './customer-support-card'
import ReleaseNotesCard from './release-notes-card'

export default {
  name: 'AsideContent',
  components: {
    CustomerSupportCard,
    ReleaseNotesCard
  },
  data () {
    return {
      showReleaseNotesCard: false
    }
  }
}
</script>
