<template>
  <div>
    <div>
      <b-list-group>
        <b-list-group-item
          class="alternating-color-list"
          v-for="report in reports"
          :key="report.title"
        >
          <reports-list-item :report="report" />
        </b-list-group-item>
      </b-list-group>
      <div class="p-4">
        <div class="pb-3">
          Wil je graag de views voor jouw kanaal activeren of wil je dieper
          ingaan op bepaalde resultaten? Neem gerust contact op via
          <a href="tel:0202611260">020 - 26 11 26 0</a> of
          <a href="mailto:info@jobsrepublic.nl">info@jobsrepublic.nl</a>. Wij
          helpen je graag.
        </div>
        <div>
          NB: Het publicatie rapport meet de views sinds oktober 2017
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsListItem from './reports-list-item'
import moment from 'moment'

export default {
  name: 'ReportsList',
  components: {
    ReportsListItem
  },
  computed: {
    startDate () {
      return moment()
        .add(-2, 'months')
        .format('YYYY-MM-DDTHH:mm:ssZ')
    },
    endDate () {
      return moment().format('YYYY-MM-DDTHH:mm:ssZ')
    },
    dateUrlPart () {
      return `start=${encodeURIComponent(this.startDate)}&end=${encodeURIComponent(this.endDate)}`
    },
    reportsBaseUrl () {
      return '/api/erecruiter-web-api/temp/reporting/export'
    },
    reports () {
      return [
        {
          title: 'Sollicitanten rapport',
          description: 'Een overzicht van alle sollicitanten nodig? Dit rapport geeft je inzicht in alle sollicitaties per vacature.',
          icon: 'fa fa-users',
          url: `${this.reportsBaseUrl}/applicants?${this.dateUrlPart}`
        },
        {
          title: 'Gearchiveerde vacatures rapport',
          description: 'Bekijk hier de eindresultaten van gearchiveerde vacatures en krijg inzicht in o.a. doorlooptijden, aantal kandidaten en redenen voor archiveren.',
          icon: 'fa fa-folder-o',
          url: `${this.reportsBaseUrl}/archivedVacancies`
        },
        {
          title: 'Openstaande vacatures rapport',
          description: 'Bekijk hier het overzicht van openstaande vacatures en krijg inzicht in o.a. views en kandidaten status.',
          icon: 'fa fa-file-text-o',
          url: `${this.reportsBaseUrl}/openVacancies`
        }
        // This feature is hidden because the data it provides is outdated
        // {
        //   title: 'Publicatie rapport',
        //   description: 'Hoe effectief zijn jouw wervingskanalen? Download het publicatie rapport en evalueer de views en sollicitaties per kanaal.',
        //   icon: 'fa fa-bar-chart',
        //   url: `${this.reportsBaseUrl}/vacanciesStats?${this.dateUrlPart}`
        // }
      ]
    }
  }
}
</script>
