<template>
  <div
    class="row align-items-center justify-content-center justify-content-md-start"
  >
    <div
      class="col-12 col-sm-2 d-flex justify-content-center justify-content-sm-start align-self-md-middle"
    >
      <h1>
        <i
          :class="report.icon"
          class="p-4 text-white"
          style="background-color: #E6ECF2; width: 88px;"
        ></i>
      </h1>
    </div>

    <div class="col-sm-10 col-md-7 pb-3 pt-3">
      <div class="pr-2">
        <b-link :href="report.url">
          <h4 class="mb-0">
            <strong>{{ report.title }}</strong>
          </h4>
        </b-link>
      </div>
      <div>
        {{ report.description }}
      </div>
    </div>
    <div
      class="col-12 col-md-3 d-flex justify-content-center justify-content-sm-end"
    >
      <b-btn variant="primary" :href="report.url">
        <i class="fa fa-download" />
        Download
      </b-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReportsListItem',
  props: {
    report: {
      type: Object,
      required: true
    }
  }
}
</script>
