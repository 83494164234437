<template>
  <page>
    <user-profile-header slot="header" />
    <user-profile-details slot="main-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import UserProfileHeader from './user-profile-header'
import UserProfileDetails from './user-profile-details'

export default {
  name: 'UserProfilePage',
  components: {
    Page,
    UserProfileHeader,
    UserProfileDetails
  }
}
</script>
