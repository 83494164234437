import Vue from 'vue'
import VueGtm from '@gtm-support/vue2-gtm'
import router from './router'

const debug = process.env.NODE_ENV === 'development'

Vue.use(VueGtm, {
  id: 'GTM-W9H4GML',
  enabled: true,
  debug: debug,
  vueRouter: router
})
