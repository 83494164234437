import apiRequest from '../../services/api-request'
import assignIn from 'lodash/assignIn'
import each from 'lodash/each'
import {
  LOAD_CURRENT_USER_DETAILS,
  UPDATE_CURRENT_USER_DETAILS,
  LOAD_USERS,
  CREATE_NEW_USER
} from '../keys-actions'
import {
  SET_CURRENT_USER,
  UPDATE_CURRENT_USER,
  SET_USERS,
  SET_USER_PHOTO
} from '../keys-mutations'
import { CURRENT_USER, USERS } from '../keys-getters'
import Vue from 'vue'

export default {
  state: {
    currentUser: {},
    users: []
  },
  mutations: {
    [SET_CURRENT_USER]: (state, currentUser) => {
      state.currentUser = currentUser
    },
    [UPDATE_CURRENT_USER]: (state, { fieldName, change }) => {
      Object.assign(state.currentUser, { [fieldName]: change })
    },
    [SET_USER_PHOTO]: (state, data) => {
      assignIn(state.currentUser, { photo: data })
    },
    [SET_USERS]: (state, users) => {
      state.users = users
    }
  },
  getters: {
    [CURRENT_USER]: state => state.currentUser || {},
    [USERS]: state => state.users || []
  },
  actions: {
    [LOAD_CURRENT_USER_DETAILS]: ({ commit }, username) => {
      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/users/${username}`
      }).then(data => {
        commit(SET_CURRENT_USER, data)
      })
    },
    [UPDATE_CURRENT_USER_DETAILS]: (
      { commit, state },
      { currentUser, username }
    ) => {
      return apiRequest({
        method: 'PUT',
        url: `/api/erecruiter-web-api/users/${username}`,
        data: currentUser
      }).then(newUser =>
        commit(SET_CURRENT_USER, newUser)
      )
    },
    [LOAD_USERS]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/users'
      }).then(data => {
        each(data, user => {
        })
        commit(SET_USERS, data)
      })
    },
    [CREATE_NEW_USER]: ({ dispatch }, user) => {
      return apiRequest({
        method: 'POST',
        url: '/api/erecruiter-web-api/users/createUser',
        data: user
      })
        .then(() => dispatch(LOAD_USERS))
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `De gebruiker kon niet aangemaakt worden. Neem contact op met de supportdesk. ${
              error.message
            }`
          })
          throw error
        })
    }
  }
}
