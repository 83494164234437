<template>
  <!-- If an event needs to be emitten across multiple component levels, use $listeners instead of $emit, see: https://vuejs.org/v2/api/#vm-listeners -->
  <!-- TO-DO ONE-358 Refactor both vue-editor-with-image vue components into a single one -->
  <vue-editor
    class="bg-white"
    :value="' '"
    v-model="privateDetails.emailSignature"
    useCustomImageHandler
    @imageAdded="handleImageAdded"
    data-vv-name="emailSignature"
    data-vv-as="Vacatureomschrijving"
    :editorOptions="editorSettings"
    :editorToolbar="customToolbar"
    :id="vueEditorId"
  />
</template>
<script>
// The quill editor is actually not designed to be used in a scenario like it is used here. It is actually designed to
// use with the intermediate Delta format. Directly setting and getting HTML into the editor is not well supported.
// Compare issue https://github.com/quilljs/quill/issues/993 and https://github.com/quilljs/quill/issues/903
// for more background.
// However since it does have IE11 support we chose to keep using the editor until CKEditor5 is supported in IE11 or our users do not
// need IE11 anymore.
import forEach from 'lodash/forEach'
import apiRequest from '../services/api-request'
import { error } from '../services/log'
import { Quill, VueEditor } from 'vue2-editor'
import ImageResize from 'quill-image-resize-module'
import BlotFormatter from 'quill-blot-formatter'

var Clipboard = Quill.import('modules/clipboard')
var Delta = Quill.import('delta')
Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/blotFormatter', BlotFormatter)

class PlainClipboard extends Clipboard {
  convert () {
    // Pasting text from a PDF or DOC requires this parsing to reduce edition work required by the user
    const text = this.container.innerText
      .replace(/([A-Za-zÀ-ȕ0-9,])\n/g, '$1 ')
      .replace(/(-)\n/, '$1')
      .replace(/\n{3,}/g, '\n')
    this.container.innerHTML = ''
    return new Delta().insert(text)
  }
}

Quill.register('modules/clipboard', PlainClipboard, true)

// Use AlignStyle attributor instead of ql-align classes
const AlignStyle = Quill.import('attributors/style/align')
Quill.register(AlignStyle, true)

// Use custom indent style attributor copied from
// https://github.com/quilljs/quill/issues/1930 and https://codepen.io/anon/pen/LgqBxm
// instead of ql-indent classes.
const Parchment = Quill.import('parchment')
const levels = [1, 2, 3, 4, 5]
const multiplier = 2

class IndentAttributor extends Parchment.Attributor.Style {
  add (node, value) {
    return super.add(node, `${value * multiplier}em`)
  }

  value (node) {
    return parseFloat(super.value(node)) / multiplier || undefined // Don't return NaN
  }
}

const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: levels.map(value => `${value * multiplier}em`)
})
Quill.register(IndentStyle)

window.Quill = Quill

export default {
  name: 'VueEditorWithImageUploadSignature',
  components: {
    VueEditor
  },
  inject: {
    $validator: '$validator'
  },
  props: {
    privateDetails: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline', 'strike'],
        [
          { align: '' },
          { align: 'center' },
          { align: 'right' },
          { align: 'justify' }
        ],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
      editorSettings: {
        modules: {
          imageResize: {},
          blotFormatter: {}
        }
      },
      vueEditorId: 'email-signature-editor'
    }
  },
  methods: {
    handleImageAdded (file, Editor, cursorLocation, resetUploader) {
      if (file.size > 2000000) {
        this.$notify({
          type: 'warning',
          text:
            'De afbeelding moet kleiner dan 2MB zijn. Verklein je afbeelding bijvoorbeeld via tinypng.com of www.befunky.com/create/resize-image'
        })
        resetUploader()
        return
      }
      // TO-DO ONE-357 Signature images should be uploaded to a S3 bucket different than the one used for vacancy images
      return apiRequest({
        method: 'GET',
        url: `/api/s3-image-upload/vacancy?filename=${file.name}&content_type=${file.type}`,
        withCredentials: true
      })
        .then(result => {
          const formData = new FormData()
          forEach(result.params, (param, key) => formData.append(key, param))
          formData.append('file', file)
          const xhr = new XMLHttpRequest()
          xhr.open('POST', result.endpoint_url, true)
          xhr.onload = () => {
            if (xhr.status >= 400) {
              resetUploader()
              this.$notify({
                type: 'warning',
                text:
                  'De afbeelding is niet opgeslagen. Probeer later nog een keer of neem contact op met onze Supportdesk.'
              })
              return
            }
            const parser = new DOMParser()
            const parsedXML = parser.parseFromString(xhr.response, 'text/xml')
            const url = parsedXML.getElementsByTagName('Location').item(0)
              .textContent // IE acceptable way to iterate through XML nodes.
            Editor.insertEmbed(cursorLocation, 'image', url)
            resetUploader()
          }
          xhr.send(formData)
        })
        .catch(err => {
          error(err)
        })
    }
  }
}
</script>
