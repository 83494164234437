import { render, staticRenderFns } from "./todo-myself-icon.vue?vue&type=template&id=502f454e&scoped=true&"
import script from "./todo-myself-icon.vue?vue&type=script&lang=js&"
export * from "./todo-myself-icon.vue?vue&type=script&lang=js&"
import style0 from "./todo-myself-icon.vue?vue&type=style&index=0&id=502f454e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "502f454e",
  null
  
)

export default component.exports