var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row border-top border-light-gray"},[_c('div',{staticClass:"col-4 col-md-3 pl-4 pt-4 pb-5 bg-background"},[(_vm.seeMultipleRounds)?_c('h5',{staticClass:"ml-2 font-weight-bold"},[_vm._v(" Selectie - ronde "+_vm._s(_vm.round.index)+" ")]):_c('h5',{staticClass:"ml-2 font-weight-bold"},[_vm._v(" Selectie ")])]),_c('div',{staticClass:"col-8 col-md-9"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0"},[_c('div',{staticClass:"d-flex flex-column p-4 bg-white"},[_c('div',{staticClass:"mb-4"},[_c('b-link',{staticClass:"d-inline-block",on:{"click":function($event){return _vm.setCurrentTabProspectsOverview(_vm.prospectsInfo.unrated.key)}}},[_c('h4',{staticClass:"font-weight-bold mb-0"},[_vm._v(" Nog niet beoordeeld ("+_vm._s(_vm.prospectsInfo.unrated.count)+") ")])]),_c('div',{staticClass:"mb-3"},[_c('small',{staticClass:"mr-1 font-weight-bold",staticStyle:{"font-size":"75%"}},[_vm._v(_vm._s(_vm.prospectsInfo.unrated.count)+" sollicitanten")]),_c('b-badge',{attrs:{"pill":""}},[_vm._v(_vm._s(_vm.prospectsInfo.unrated.newCount)+" nieuw")])],1),(_vm.prospects[_vm.prospectsInfo.unrated.key])?_c('div',{staticClass:"d-flex"},_vm._l((_vm.prospects[
                  _vm.prospectsInfo.unrated.key
                ].filter(function (el, i) { return i < 4; })),function(prospect){return _c('b-link',{key:prospect.prospectId,staticClass:"mr-3 avatar-link",attrs:{"to":{
                  name: 'vacancyProspectDetail',
                  params: {
                    vacancyId: _vm.vacancyId,
                    prospectId: prospect.prospectId,
                    roundId: _vm.round.roundId
                  }
                }}},[(!prospect.avatarUrl)?_c('avatar',{staticStyle:{"width":"80px","height":"80px"},attrs:{"fullname":((prospect.firstName) + " " + (prospect.lastName))}}):_vm._e(),(prospect.avatarUrl)?_c('div',{staticClass:"d-flex align-items-center justify-content-around",staticStyle:{"width":"80px","height":"80px"}},[(prospect.avatarUrl)?_c('img',{staticClass:"img-thumbnail p-0 border-0 bg-transparent",attrs:{"src":prospect.avatarUrlLocal}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"avatar-link--underline"})],1)}),1):_vm._e()],1),_c('div',{staticClass:"mb-4"},[_c('b-link',{staticClass:"d-inline-block",on:{"click":function($event){return _vm.setCurrentTabProspectsOverview(_vm.prospectsInfo.selected.key)}}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" Door ("+_vm._s(_vm.prospectsInfo.selected.count)+") ")])]),(_vm.prospects[_vm.prospectsInfo.selected.key])?_c('div',{staticClass:"d-flex"},_vm._l((_vm.prospects[
                  _vm.prospectsInfo.selected.key
                ].filter(function (el, i) { return i < 4; })),function(prospect){return _c('b-link',{key:prospect.prospectId,staticClass:"mr-3 avatar-link",attrs:{"to":{
                  name: 'vacancyProspectDetail',
                  params: {
                    vacancyId: _vm.vacancyId,
                    prospectId: prospect.prospectId,
                    roundId: _vm.round.roundId
                  }
                }}},[(!prospect.avatarUrl)?_c('avatar',{staticStyle:{"width":"80px","height":"80px"},attrs:{"fullname":((prospect.firstName) + " " + (prospect.lastName))}}):_vm._e(),(prospect.avatarUrl)?_c('div',{staticClass:"d-flex align-items-center justify-content-around",staticStyle:{"width":"80px","height":"80px"}},[(prospect.avatarUrl)?_c('img',{staticClass:"img-thumbnail p-0 border-0 bg-transparent",attrs:{"src":prospect.avatarUrlLocal}}):_vm._e()]):_vm._e(),_c('div',{staticClass:"avatar-link--underline"})],1)}),1):_vm._e()],1),_c('div',{staticClass:"d-flex"},[_c('b-link',{on:{"click":function($event){return _vm.setCurrentTabProspectsOverview(_vm.prospectsInfo.reserved.key)}}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" Reserve ("+_vm._s(_vm.prospectsInfo.reserved.count)+") ")])]),_c('b-link',{staticClass:"ml-3",on:{"click":function($event){return _vm.setCurrentTabProspectsOverview(_vm.prospectsInfo.declined.key)}}},[_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" Niet door ("+_vm._s(_vm.prospectsInfo.declined.count)+") ")])])],1)])]),_c('div',{staticClass:"col-2 col-md-6 border-right border-light-gray"}),_c('div',{staticClass:"col-10 col-md-6"},[_c('div',{staticClass:"py-4",staticStyle:{"margin-left":"-7px"}},[(_vm.notifications.length)?_c('div',{staticClass:"ml-2"},_vm._l((_vm.notifications),function(item){return _c('notification',{key:item.entityId,staticClass:"ml-2",attrs:{"notification":item,"main-content-background":true},on:{"openNotification":_vm.openNotification,"completeTodo":_vm.completeTodo}})}),1):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }