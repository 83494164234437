<template>
  <div>
    <judgement-score-box
      :private-prospect="privateProspect"
    ></judgement-score-box>
    <div class="d-flex w-100 p-2 mb-5 bg-judgment-card">
      <b-btn
        variant="primary"
        :disabled="!isJudgementEditor"
        class=" ml-auto"
        @click="saveJudgement"
        >Indienen</b-btn
      >
    </div>

    <notification-list
      :list="privateProspect.prospectNotifications"
    ></notification-list>
  </div>
</template>
<script>
import { CURRENT_SELECTION_COMMITTEE_PROSPECT } from '../../store/keys-getters'
import { SAVE_SELECTION_COMMITEE_JUDGEMENTS } from '../../store/keys-actions'
import JudgementScoreBox from '../judgement-score-box'
import NotificationList from '../notification-list/list'
import cloneDeep from 'lodash/cloneDeep'
import { EDIT_SELECTION_COMMITEE_INTERFACE } from '../../constants/permissions'

export default {
  components: {
    JudgementScoreBox,
    NotificationList
  },
  data () {
    return {
      privateProspect: {}
    }
  },
  watch: {
    currentProspect () {
      this.privateProspect = cloneDeep(this.currentProspect)
    }
  },
  computed: {
    currentProspect () {
      return this.$store.getters[CURRENT_SELECTION_COMMITTEE_PROSPECT]
    },
    isJudgementEditor () {
      return this.hasPermission(EDIT_SELECTION_COMMITEE_INTERFACE)
    }
  },
  methods: {
    saveJudgement () {
      this.$store.dispatch(SAVE_SELECTION_COMMITEE_JUDGEMENTS, [
        {
          prospectId: this.privateProspect.prospectId,
          prospectJudgementForCurrentUser: this.privateProspect.prospectJudgementForCurrentUser
        }
      ])
    }
  }
}
</script>
