<template>
  <div class="row border-top border-light-gray">
    <div class="col-4 col-md-3 pl-4 pt-4 pb-5 bg-background">
      <h5 v-if="seeMultipleRounds" class="ml-2 font-weight-bold">
        Selectie - ronde {{ round.index }}
      </h5>
      <h5 v-else class="ml-2 font-weight-bold">
        Selectie
      </h5>
    </div>
    <div class="col-8 col-md-9">
      <div class="row">
        <div class="col-12 p-0">
          <div class="d-flex flex-column p-4 bg-white">
            <div class="mb-4">
              <b-link
                class="d-inline-block"
                @click="
                  setCurrentTabProspectsOverview(prospectsInfo.unrated.key)
                "
              >
                <h4 class="font-weight-bold mb-0">
                  Nog niet beoordeeld ({{ prospectsInfo.unrated.count }})
                </h4>
              </b-link>
              <div class="mb-3">
                <small class="mr-1 font-weight-bold" style="font-size: 75%"
                  >{{ prospectsInfo.unrated.count }} sollicitanten</small
                >
                <b-badge pill
                  >{{ prospectsInfo.unrated.newCount }} nieuw</b-badge
                >
              </div>
              <div class="d-flex" v-if="prospects[prospectsInfo.unrated.key]">
                <b-link
                  v-for="prospect in prospects[
                    prospectsInfo.unrated.key
                  ].filter((el, i) => i < 4)"
                  :key="prospect.prospectId"
                  :to="{
                    name: 'vacancyProspectDetail',
                    params: {
                      vacancyId,
                      prospectId: prospect.prospectId,
                      roundId: round.roundId
                    }
                  }"
                  class="mr-3 avatar-link"
                >
                  <avatar
                    v-if="!prospect.avatarUrl"
                    :fullname="`${prospect.firstName} ${prospect.lastName}`"
                    style="width: 80px; height: 80px;"
                  />
                  <div
                    v-if="prospect.avatarUrl"
                    class="d-flex align-items-center justify-content-around"
                    style="width: 80px; height: 80px;"
                  >
                    <img
                      v-if="prospect.avatarUrl"
                      :src="prospect.avatarUrlLocal"
                      class="img-thumbnail p-0 border-0 bg-transparent"
                    />
                  </div>
                  <div class="avatar-link--underline" />
                </b-link>
              </div>
            </div>
            <div class="mb-4">
              <b-link
                class="d-inline-block"
                @click="
                  setCurrentTabProspectsOverview(prospectsInfo.selected.key)
                "
              >
                <h4 class="font-weight-bold">
                  Door ({{ prospectsInfo.selected.count }})
                </h4>
              </b-link>
              <div class="d-flex" v-if="prospects[prospectsInfo.selected.key]">
                <b-link
                  v-for="prospect in prospects[
                    prospectsInfo.selected.key
                  ].filter((el, i) => i < 4)"
                  :key="prospect.prospectId"
                  :to="{
                    name: 'vacancyProspectDetail',
                    params: {
                      vacancyId,
                      prospectId: prospect.prospectId,
                      roundId: round.roundId
                    }
                  }"
                  class="mr-3 avatar-link"
                >
                  <avatar
                    v-if="!prospect.avatarUrl"
                    :fullname="`${prospect.firstName} ${prospect.lastName}`"
                    style="width: 80px; height: 80px;"
                  />
                  <div
                    v-if="prospect.avatarUrl"
                    class="d-flex align-items-center justify-content-around"
                    style="width: 80px; height: 80px;"
                  >
                    <img
                      v-if="prospect.avatarUrl"
                      :src="prospect.avatarUrlLocal"
                      class="img-thumbnail p-0 border-0 bg-transparent"
                    />
                  </div>
                  <div class="avatar-link--underline" />
                </b-link>
              </div>
            </div>
            <div class="d-flex">
              <b-link
                @click="
                  setCurrentTabProspectsOverview(prospectsInfo.reserved.key)
                "
              >
                <h4 class="font-weight-bold">
                  Reserve ({{ prospectsInfo.reserved.count }})
                </h4>
              </b-link>
              <b-link
                class="ml-3"
                @click="
                  setCurrentTabProspectsOverview(prospectsInfo.declined.key)
                "
              >
                <h4 class="font-weight-bold">
                  Niet door ({{ prospectsInfo.declined.count }})
                </h4>
              </b-link>
            </div>
          </div>
        </div>
        <div class="col-2 col-md-6 border-right border-light-gray"></div>
        <div class="col-10 col-md-6">
          <div class="py-4" style="margin-left: -7px;">
            <div v-if="notifications.length" class="ml-2">
              <notification
                class="ml-2"
                v-for="item in notifications"
                :key="item.entityId"
                :notification="item"
                :main-content-background="true"
                @openNotification="openNotification"
                @completeTodo="completeTodo"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Notification from '../../notification-list/notification'
import NotificationModal from '../../notification-list/notification-modal'
import {
  PROSPRATING_UNRATED,
  PROSPRATING_DECLINED,
  PROSPRATING_RESERVED,
  PROSPRATING_SELECTED
} from '../../../constants/prospect/prospect-recruiter-rating'
import { NOTIFTYPE_TODO } from '../../../constants/prospect/prospect-notification-type'
import { SET_CURRENT_TAB_PROSPECTS_OVERVIEW } from '../../../store/keys-mutations'
import { EDIT_VACANCY_TODO, EDIT_VACANCY_NOTE, COMPLETE_VACANCY_TODO } from '../../../store/keys-actions'
import Avatar from '../../avatar'
import modal from '../../../services/modal'
import { MULTIPLE_SELECTION_ROUNDS_LABEL } from '../../../constants/permissions'

export default {
  components: {
    Notification,
    Avatar
  },
  props: {
    vacancyId: {
      required: true
    },
    notifications: {
      type: Array,
      default: () => []
    },
    prospects: {
      type: Object,
      default: () => ({})
    },
    round: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    prospectsInfo () {
      return {
        unrated: {
          count: (this.prospects[PROSPRATING_UNRATED] || []).length,
          newCount: (this.prospects[PROSPRATING_UNRATED] || []).filter((prospect) => !prospect.isViewed).length,
          key: PROSPRATING_UNRATED
        },
        selected: {
          count: (this.prospects[PROSPRATING_SELECTED] || []).length,
          key: PROSPRATING_SELECTED
        },
        declined: {
          count: (this.prospects[PROSPRATING_DECLINED] || []).length,
          key: PROSPRATING_DECLINED
        },
        reserved: {
          count: (this.prospects[PROSPRATING_RESERVED] || []).length,
          key: PROSPRATING_RESERVED
        }
      }
    },
    seeMultipleRounds () {
      return this.hasPermission(MULTIPLE_SELECTION_ROUNDS_LABEL)
    }
  },
  methods: {
    setCurrentTabProspectsOverview (tab) {
      this.$store.commit(SET_CURRENT_TAB_PROSPECTS_OVERVIEW, tab)
      this.$router.push({
        name: 'vacancySelectionRound',
        params: { vacancyId: this.vacancyId, roundId: this.round.roundId }
      })
    },
    openNotification ({ type, id }) {
      modal(NotificationModal, { type, id, vacancyLevelNotification: true }).then(({ notification }) => {
        if (!notification) {
          return
        }
        const action = type === NOTIFTYPE_TODO ? EDIT_VACANCY_TODO : EDIT_VACANCY_NOTE
        this.$store.dispatch(action, notification)
      })
    },
    completeTodo (info) {
      this.$store.dispatch(COMPLETE_VACANCY_TODO, info)
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar-link--underline {
  display: none;
  position: relative;
  margin-top: -2px;
  width: 100%;
  height: 2px;
  background-color: #1591a1;
  z-index: 1;
}
.v a.avatar-link:hover {
  text-decoration: none;
  .avatar-link--underline {
    display: block;
  }
}
</style>
