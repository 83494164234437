<template>
  <div class="progress-bar-container" v-show="running">
    <b-progress height="2px" :value="progress" />
  </div>
</template>

<script>
import interval from '../services/interval'
import noop from 'lodash/noop'

export default {
  name: 'progressBar',
  props: { running: Boolean },
  data () {
    return {
      progress: 0,
      clearInterval: noop
    }
  },
  watch: {
    running: {
      handler () {
        if (this.running) {
          this.start()
          return
        }
        this.stop()
      },
      immediate: true
    }
  },
  methods: {
    start () {
      this.progress = 0
      this.clearInterval = interval(() => this.increase(), 300)
    },
    increase () {
      const increase = Math.random() * ((100 - this.progress) / 9)
      this.progress += Math.min(this.progress + increase, 99.9)
    },
    stop () {
      this.clearInterval()
      this.progress = 100
    }
  }
}
</script>
