<template>
  <b-modal
    centered
    :title="title"
    :visible="true"
    @ok="$emit('resolve', true)"
    @hidden="$emit('remove')"
    ok-variant="medium-light"
    cancel-title="close"
    ok-only
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div
      v-for="(judgement, index) in judgements"
      :key="index"
      class="p-3"
      :class="
        index < judgements.length - 1 ? 'border-bottom border-medium-light' : ''
      "
    >
      <div class="d-flex justify-content-between">
        <div>
          <strong>{{ judgement.fullname }}</strong>
        </div>
        <div><i :class="icons[judgement.score]" /></div>
      </div>
      {{ judgement.comment }}
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'ProspectJudgementsModal',
  props: {
    judgements: {
      type: Array,
      default: () => []
    },
    icons: {
      type: Object,
      default: () => ({})
    },
    title: {
      type: String,
      default: 'Selectiecommissie toelichtingen'
    }
  }
}
</script>
