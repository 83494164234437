<template>
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          Gebruikers
        </h1>
        <b-btn v-if="showNewUserButton" class="ml-auto" @click="createUser">
          <i class="fa fa-plus" style="color: #b6980a;" /> nieuwe gebruiker
        </b-btn>
      </div>
      <div class="row no-gutter"></div>
    </div>
  </div>
</template>
<script>
import modal from '../../services/modal'
import CreateUserModal from './create-user-modal'
import { CREATE_NEW_USER } from '../../store/keys-actions'
import { CREATE_USER } from '../../constants/permissions'

export default {
  components: {
  },
  computed: {
    showNewUserButton () {
      return this.hasPermission(CREATE_USER)
    }
  },
  methods: {
    createUser () {
      modal(CreateUserModal, {}).then((user) => {
        if (!user) {
          return
        }
        this.$store.dispatch(CREATE_NEW_USER, user).then(() => {
          this.$notify({
            type: 'success',
            title: 'Gebruiker toegevoegd',
            text: 'De nieuwe gebruiker is toegevoegd en ontvangt een uitnodiging via e-mail.'
          })
        }).catch(err => {
          this.$notify({
            type: 'warning',
            text: err.message
          })
          return null
        })
      })
    }
  }
}
</script>
