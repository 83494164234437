import Vue from 'vue'
import {
  VACANCY,
  VACANCY_PROSPECTS,
  CURRENT_TAB_PROSPECTS_OVERVIEW
} from '../../../keys-getters'
import {
  LOAD_VACANCY_PROSPECTS,
  CHANGE_PROSPECT_RATING,
  CHANGE_PROSPECT_STATUS,
  SEND_PROSPECTS_TO_SELECTION_COMMITTEE,
  ADD_NEW_APPLICANT,
  LOAD_CURRENT_PROSPECT,
  EDIT_PROSPECT
} from '../../../keys-actions'
import {
  SET_VACANCY_PROSPECTS,
  SET_CURRENT_TAB_PROSPECTS_OVERVIEW
} from '../../../keys-mutations'
import apiRequest from '../../../../services/api-request'
import saveAttachment from '../../../../services/save-attachment'
import { stripHostFromAttachment } from '../../../../services/attachment-data-cleaner'
import groupBy from 'lodash/groupBy'
import assignIn from 'lodash/assignIn'
import map from 'lodash/map'
import each from 'lodash/each'
import isEmpty from 'lodash/isEmpty'
import defaults from 'lodash/defaults'

export default {
  state: {
    prospects: {},
    currentTab: null
  },
  mutations: {
    [SET_VACANCY_PROSPECTS]: (state, { prospects, roundId }) =>
      (state.prospects = { ...state.prospects, [roundId]: prospects }),
    [SET_CURRENT_TAB_PROSPECTS_OVERVIEW]: (state, currentTab) =>
      (state.currentTab = currentTab)
  },
  getters: {
    [VACANCY_PROSPECTS]: state => roundId =>
      assignIn(
        { PROSPRATING_TOTAL: state.prospects[roundId] },
        groupBy(state.prospects[roundId], prospect => prospect.recruiterRating)
      ),
    [CURRENT_TAB_PROSPECTS_OVERVIEW]: state => state.currentTab
  },
  actions: {
    [LOAD_VACANCY_PROSPECTS]: async ({ commit, getters }, roundId) => {
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const round = roundId || latestRoundId
      try {
        let data = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospects/v2?selectionroundid=${round}`
        })
        data = data.reverse()

        each(data, (prospect) => {
          stripHostFromAttachment(prospect, 'avatarUrl')
        })

        commit(SET_VACANCY_PROSPECTS, { prospects: data, roundId: round })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Kon sollicitanten niet laden. ${err.message}`
        })
      }
    },
    [CHANGE_PROSPECT_RATING]: async (
      { getters, dispatch },
      { ratingType, prospectIds, individualProspect }
    ) => {
      const { vacancyId } = getters[VACANCY]
      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospects`,
          data: { ratingType, prospectIds }
        })
        dispatch(LOAD_VACANCY_PROSPECTS)
        if (individualProspect) {
          dispatch(LOAD_CURRENT_PROSPECT, { prospectId: prospectIds[0] }) // prospectIds has to be an array for the /prospects endpoint. However, there's only one element in the array and the prospectId is necessary for reloading the currentProspect
          return
        }
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Kon de sollicitant beoordeling niet wijzigen. ${err.message}`
        })
      }
    },
    [CHANGE_PROSPECT_STATUS]: async (
      { getters, dispatch },
      { status, prospectId }
    ) => {
      const { vacancyId } = getters[VACANCY]
      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospects/${prospectId}`,
          data: {
            lRecruiterStatus: status
          }
        })
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Kon de sollicitant status niet wijzigen. ${err.message}`
        })
      }
    },
    [SEND_PROSPECTS_TO_SELECTION_COMMITTEE]: async (
      { getters },
      { prospectIds, selectionCommittee }
    ) => {
      const { vacancyId } = getters[VACANCY]

      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospects/submitToJudgement`,
          data: {
            prospectIdsSubmittedToJudgement: prospectIds,
            selectionCommitteeUsernames: selectionCommittee
          }
        })
        Vue.notify({
          type: 'success',
          text:
            'De geselecteerde sollicitanten worden gedeeld met de selectiecommissieleden.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De geselecteerde sollicitanten konden niet worden gedeeld met de selectiecommissieleden. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [ADD_NEW_APPLICANT]: async ({ getters, dispatch }, applicant) => {
      const { vacancyId } = getters[VACANCY]
      let avatarUrl
      let fetchedAttachments

      try {
        try {
          avatarUrl = applicant.avatarUrl
            ? await saveAttachment(
              applicant.avatarUrl,
              'api/erecruiter-web-api/applicants/attachments/photo'
            )
            : null
          fetchedAttachments = !isEmpty(applicant.attachments)
            ? await Promise.all(
              map(applicant.attachments, attachment =>
                saveAttachment(
                  attachment,
                  'api/erecruiter-web-api/applicants/attachments'
                )
              )
            )
            : []
        } catch (err) {
          Vue.notify({
            type: 'warning',
            text: `Niet alle bijlagen zijn opgeslagen. Probeer de missende bestanden opnieuw op te slaan. ${
              err.message
            }`
          })
        }
        const applicantWithAttachments = defaults(
          { avatarUrl, attachments: fetchedAttachments },
          applicant
        )
        await apiRequest({
          method: 'POST',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/applicants`,
          data: applicantWithAttachments
        })
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er kan geen nieuwe sollicitant worden aangemaakt. ${
            err.message
          }`
        })
      }
    },
    [EDIT_PROSPECT]: async ({ getters, dispatch }, prospect) => {
      const { vacancyId } = getters[VACANCY]
      const { prospectId } = prospect

      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/applicants/${prospectId}`,
          // Remove the props added for displaying attachments before sending
          data: { ...prospect, avatarUrlLocal: undefined }
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er kan geen nieuwe sollicitant worden aangemaakt. ${
            err.message
          }`
        })
      }
    }
  }
}
