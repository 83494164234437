import Vue from 'vue'
import {
  LOAD_SELECTION_COMMITTEE_PROSPECTS,
  SAVE_SELECTION_COMMITEE_JUDGEMENTS,
  LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT
} from '../keys-actions'
import {
  SET_SELECTION_COMMITTEE_PROSPECTS,
  SET_CURRENT_SELECTION_COMMITTEE_PROSPECT
} from '../keys-mutations'
import {
  VACANCY,
  SELECTION_COMMITTEE_PROSPECTS,
  CURRENT_SELECTION_COMMITTEE_PROSPECT,
  SELECTION_COMMITTEE_PROSPECTS_ORDER
} from '../keys-getters'
import apiRequest from '../../services/api-request'
import { stripHostFromAttachment } from '../../services/attachment-data-cleaner'
import forEach from 'lodash/forEach'

export default {
  state: {
    prospects: [],
    prospect: {}
  },
  mutations: {
    [SET_SELECTION_COMMITTEE_PROSPECTS]: (state, prospects) => {
      state.prospects = prospects
    },
    [SET_CURRENT_SELECTION_COMMITTEE_PROSPECT]: (state, prospect) => {
      state.prospect = prospect
    }
  },
  getters: {
    [SELECTION_COMMITTEE_PROSPECTS]: state => state.prospects,
    [CURRENT_SELECTION_COMMITTEE_PROSPECT]: state => state.prospect,
    [SELECTION_COMMITTEE_PROSPECTS_ORDER]: state => {
      const order = {}
      forEach(state.prospects, (value, index) => {
        const previous = index - 1 >= 0 ? index - 1 : null
        const next = index + 1 < state.prospects.length ? index + 1 : null
        const { prospectId } = value
        order[prospectId] = {
          prev:
            previous || previous === 0
              ? state.prospects[previous].prospectId
              : null,
          next: next ? state.prospects[next].prospectId : null
        }
      })
      return order
    }
  },
  actions: {
    [LOAD_SELECTION_COMMITTEE_PROSPECTS]: ({ commit, getters }) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/prospectJudgements/v2`,
        headers: { 'Content-Type': 'application/json' }
      }).then(data => commit(SET_SELECTION_COMMITTEE_PROSPECTS, data))
    },
    [SAVE_SELECTION_COMMITEE_JUDGEMENTS]: (
      { dispatch, getters },
      prospects
    ) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'PUT',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/prospectJudgements`,
        data: prospects
      })
        .then(() => {
          Vue.notify({
            type: 'success',
            text: 'Uw beoordeling is ingediend.'
          })
          dispatch(LOAD_SELECTION_COMMITTEE_PROSPECTS, vacancyId)
        })
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `De sollicitanten konden op dit moment niet worden geladen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              error.message
            }`
          })
          return null
        })
    },
    [LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT]: (
      { commit, getters },
      prospectId
    ) => {
      const { vacancyId, latestRoundId } = getters[VACANCY]

      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/vacancies/${vacancyId}/prospectsFull/${prospectId}/selectionRounds/${latestRoundId}`
      })
        .then(data => {
          stripHostFromAttachment(data.avatarUrl, 'url')
          forEach(data.attachments, attachment => {
            stripHostFromAttachment(attachment, 'url')
            stripHostFromAttachment(attachment, 'convertedPdfUrl')
          })
          commit(SET_CURRENT_SELECTION_COMMITTEE_PROSPECT, data)
        })
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `De prospect konde op dit moment niet worden geladen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
              error.message
            }`
          })
          return null
        })
    }
  }
}
