<template>
  <div>
    <div v-if="showVacancyProspects">
      <vacancy-prospects-section
        v-for="round in vacancyRounds"
        :key="round.roundId"
        :prospects="vacancyProspects[round.roundId]"
        :notifications="(subprocessNotifications.select || {})[round.roundId]"
        :round="round"
        :vacancy-id="vacancy.vacancyId"
      />
    </div>
    <vacancy-publication-section
      v-if="subprocessNotifications.publish"
      :vacancy-id="vacancy.vacancyId"
      :notifications="subprocessNotifications.publish"
      :one-channel-info="publicationSubprocessInfo"
      :publication-channels="publicationChannels"
    />
    <vacancy-creation-section
      v-if="subprocessNotifications.entry"
      :description="vacancy.description"
      :vacancy-id="vacancy.vacancyId"
      :title="vacancy.title"
      :notifications="subprocessNotifications.entry"
    />
  </div>
</template>
<script>
import {
  VACANCY,
  VACANCY_SUBPROCESS_NOTIFICATIONS,
  VACANCY_PUBLICATION_SUBPROCESS_INFO,
  PUBLICATION_CHANNELS,
  VACANCY_ROUNDS,
  VACANCY_PROSPECTS
} from '../../../store/keys-getters'
import {
  LOAD_VACANCY_SUBPROCESSES,
  LOAD_SELECTION_ROUND_STATS,
  LOAD_VACANCY_PROSPECTS
} from '../../../store/keys-actions'
import VacancyCreationSection from './vacancy-creation-section'
import VacancyPublicationSection from './vacancy-publication-section'
import VacancyProspectsSection from './vacancy-prospects-section'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
export default {
  components: {
    VacancyCreationSection,
    VacancyPublicationSection,
    VacancyProspectsSection
  },
  computed: {
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    vacancyRounds () {
      return this.$store.getters[VACANCY_ROUNDS]
    },
    subprocessNotifications () {
      return this.$store.getters[VACANCY_SUBPROCESS_NOTIFICATIONS]
    },
    publicationSubprocessInfo () {
      return this.$store.getters[VACANCY_PUBLICATION_SUBPROCESS_INFO]
    },
    publicationChannels () {
      return this.$store.getters[PUBLICATION_CHANNELS]
    },
    vacancyProspects () {
      const prospects = {}
      forEach(
        this.vacancyRounds,
        ({ roundId }) => (prospects[roundId] = this.$store.getters[VACANCY_PROSPECTS](roundId))
      )
      return prospects
    },
    showVacancyProspects () {
      return !isEmpty(this.vacancyProspects)
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_VACANCY_SUBPROCESSES)
    if (this.vacancyRounds) {
      this.vacancyRounds.map((round) => {
        this.$store.dispatch(LOAD_SELECTION_ROUND_STATS, round.roundId)
        this.$store.dispatch(LOAD_VACANCY_PROSPECTS, round.roundId)
      })
    }
  }
}
</script>
