<template>
  <div class="mb-4">
    <b-card
      @click="showCollapse = !showCollapse"
      :class="showCollapse ? 'collapsed' : 'null'"
      style="background-color: #fbfbfb"
      no-body
      :aria-expanded="showCollapse ? 'true' : 'false'"
    >
      <div
        class="
          d-flex
          flex-column
          justify-content-between
          align-items-center
          pt-3
          pl-3
          pr-3
          pb-1
        "
      >
        <div
          class="
            d-flex
            flex-row
            justify-content-between
            align-items-center
            w-100
          "
        >
          <div class="d-flex flex-column align-self-start">
            <div class="d-flex align-items-center">
              <h4 class="" style="margin-bottom: 0">
                {{ title }}
              </h4>
              <b-badge
                v-if="isCurrentlyPublished"
                class="ml-2"
                pill
                variant="success"
                >Gepubliceerd</b-badge
              >
              <b-badge v-if="isArchived" class="ml-2" pill variant="light"
                >Gearchiveerd</b-badge
              >
            </div>
            <div class="pt-1" v-if="showVacancyLink">
              <router-link
                style="max-width: 150px"
                :to="{ name: 'vacancy', params: { vacancyId } }"
              >
                <small>
                  Ga naar de vacature <i class="fa fa-arrow-circle-right"
                /></small>
              </router-link>
            </div>
          </div>

          <div v-if="dateCreated" class="align-self-center ml-auto order-md-3">
            <div class="pr-4 pl-2">
              <small>Aangemaakt op:</small><br />
              {{ dateCreated | formatDate }}
            </div>
          </div>
        </div>

        <div
          v-if="selected"
          class="w-100 pt-1 d-flex flex-column align-items-center"
          style="border-top: 1px solid #e5e5e5"
          :style="selected.length > 0 ? 'color: red;' : 'color: #bfbfbf;'"
        >
          <div v-if="selected.length === 0">
            <small
              >{{ applicants.length }}
              {{
                applicants.length === 1 ? 'Sollicitant' : 'Sollicitanten'
              }}</small
            >
          </div>
          <div v-if="selected.length > 0">
            <small
              >{{ selected.length }} / {{ applicants.length }} Geselecteerd
              sollicitanten</small
            >
          </div>
          <i :class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'" />
        </div>
      </div>
    </b-card>

    <b-collapse class="mb-2" v-model="showCollapse" id="collapse4">
      <b-list-group-item
        style="background-color: #fbfbfb"
        class="d-flex align-items-center"
      >
        <b-form-checkbox
          name="selectAll"
          plain
          @change="toggleAll"
          :checked="allSelected"
        >
          {{ allSelected ? 'Alles deselecteren' : 'Alles selecteren' }}
        </b-form-checkbox>
      </b-list-group-item>
      <b-list-group>
        <b-list-group-item
          v-for="applicant in applicants"
          :key="applicant.applicantId"
        >
          <b-form-checkbox-group
            class="d-flex align-items-center"
            plain
            id="checkboxes1"
            name="flavour1"
            v-model="selected"
            v-on:change="$emit('add-selected', selected)"
          >
            <b-form-checkbox :value="applicant.applicantId">
              <span class="pr-2">
                <strong
                  >{{ applicant.firstname }} {{ applicant.lastname }}</strong
                > </span
              >:

              <span>
                {{ applicant.timeRemaining | formatExpirationDate }}
              </span>
              <b-badge class="ml-2" pill variant="info">{{
                applicant.applicationDate.format('D-MM-YYYY')
              }}</b-badge>
              <b-badge
                v-if="applicant.isRatingDeclined"
                class="ml-2"
                pill
                variant="danger"
                >Niet door</b-badge
              >
            </b-form-checkbox>

            <b-button
              variant="link"
              size="sm"
              v-if="applicant.consents && applicant.consents.length"
              @click="showConsentsDetail(applicant.consents)"
            >
              <i class="fa fa-eye" /> bekijk toestemming
            </b-button>
          </b-form-checkbox-group>
        </b-list-group-item>
      </b-list-group>
    </b-collapse>
  </div>
</template>

<script>
import moment from 'moment'
import every from 'lodash/every'
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import filter from 'lodash/filter'
import modal from '../../services/modal'
import consentsDetailModal from './consents-detail-modal'

export default {
  props: {
    title: String,
    applicants: {
      type: Array,
      required: true
    },
    status: String,
    isCurrentlyPublished: {
      type: Boolean,
      required: true
    },
    isArchived: {
      type: Boolean,
      required: true
    },
    vacancyId: {},
    value: {
      type: Array,
      required: true
    },
    showVacancyLink: {
      type: Boolean,
      required: true
    },
    dateCreated: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      showCollapse: true,
      selected: cloneDeep(this.value)
    }
  },
  computed: {
    allSelected () {
      return every(this.applicantIdList, (applicantId) =>
        this.selected.includes(applicantId)
      )
    },
    applicantIdList () {
      return this.applicants.map((applicant) => applicant.applicantId)
    }
  },
  watch: {
    selected () {
      if (isEqual(this.selected, this.value)) {
        return
      }
      this.$emit('input', this.selected)
    },
    applicantIdList () {
      this.selected = filter(this.selected, (applicantId) =>
        this.applicantIdList.includes(applicantId)
      )
    },
    value () {
      this.selected = cloneDeep(this.value)
    }
  },
  methods: {
    toggleAll () {
      if (this.allSelected) {
        this.selected = []
        return
      }
      this.selected = this.applicantIdList
    },
    showConsentsDetail (consents) {
      modal(consentsDetailModal, {
        consents: consents
      })
    }
  },
  filters: {
    formatExpirationDate (timeRemaining) {
      if (timeRemaining === null) {
        return 'geen toestemming gevonden'
      }
      if (timeRemaining < 0) {
        return (
          'De toestemming is ' +
          moment.duration(timeRemaining, 'seconds').humanize() +
          ' verlopen'
        )
      }
      return (
        'Toestemming verloopt over ' +
        moment.duration(timeRemaining, 'seconds').humanize()
      )
    },
    formatDate (date) {
      return moment.utc(date).local().format('L')
    }
  }
}
</script>
