<template>
  <div>
    <b-alert show variant="warning" class="mt-4">
      <h2>
        404 - Page not found
      </h2>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>
