var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row p-4 border-bottom border-medium-gray"},[_c('div',{staticClass:"w-100 d-flex justify-content-between"},[_c('b-link',{attrs:{"to":{
          name: 'vacancySelectionRound',
          params: {
            vacancyId: _vm.vacancy.vacancyId,
            roundId: _vm.$route.params.roundId,
          },
        }}},[_c('div',[_c('i',{staticClass:"fa fa-angle-left"}),_vm._v(" terug naar sollitantenoverzicht ")])]),_c('div',[_c('b-btn',{staticClass:"mr-2 border border-medium-gray",attrs:{"variant":"white","size":"sm","disabled":!_vm.prospectNavigationOrder || !_vm.nextAndPreviousNavigation.previous,"to":{
            name: 'vacancyProspectDetail',
            params: {
              vacancyId: _vm.currentProspect.vacancyId,
              prospectId: _vm.nextAndPreviousNavigation.previous,
              roundId: _vm.$route.params.roundId,
            },
          }}},[_c('i',{staticClass:"fa fa-chevron-left"})]),_c('b-btn',{staticClass:"border border-medium-gray",attrs:{"variant":"white","size":"sm","disabled":!_vm.prospectNavigationOrder || !_vm.nextAndPreviousNavigation.next,"to":{
            name: 'vacancyProspectDetail',
            params: {
              vacancyId: _vm.currentProspect.vacancyId,
              prospectId: _vm.nextAndPreviousNavigation.next,
              roundId: _vm.$route.params.roundId,
            },
          }}},[_c('i',{staticClass:"fa fa-chevron-right"})])],1)],1)]),_c('prospect-detail-header',{attrs:{"loading":_vm.isLoading,"candidate-source":_vm.candidateSource,"avatarUrl":_vm.currentProspect.avatarUrl,"publishChannel":_vm.currentProspect.publishChannel,"fullname":_vm.fullname,"recruiterRating":_vm.currentProspect.lRecruiterRating,"prospectId":_vm.currentProspect.prospectId,"actionsDropdown":_vm.actionsDropdownList},on:{"print":_vm.print,"copyToOtherVacancy":_vm.copyToOtherVacancy,"deleteProspect":_vm.deleteProspect,"editProspect":_vm.editProspect,"anonymizeProspect":_vm.anonymizeProspect,"sendToSelectionCommittee":_vm.sendToSelectionCommitee,"sharePerEmail":_vm.sharePerEmail}}),_c('prospect-detail-contact-info',{attrs:{"phoneNumber":_vm.currentProspect.phoneNumber || _vm.currentProspect.mobileNumber,"emailAddress":_vm.currentProspect.emailAddress},on:{"sendEmail":_vm.sendEmail}}),(_vm.prospectIsCandidate)?_c('prospect-work-experience',{attrs:{"work-experience":_vm.currentProspect.experienceLst}}):_vm._e(),(
      _vm.prospectIsCandidate &&
        _vm.currentProspect.otherExperienceLst &&
        _vm.currentProspect.otherExperienceLst.length
    )?_c('prospect-work-experience-other',{attrs:{"other-work-experience":_vm.currentProspect.otherExperienceLst}}):_vm._e(),(_vm.prospectIsCandidate)?_c('prospect-education',{attrs:{"education":_vm.currentProspect.educationLst,"highest-level":_vm.currentProspect.highestEducation}}):_vm._e(),(_vm.prospectIsCandidate)?_c('prospect-courses',{attrs:{"education":_vm.currentProspect.coursesLst}}):_vm._e(),(_vm.prospectIsCandidate)?_c('prospect-wishes-and-information',{attrs:{"current-prospect":_vm.currentProspect}}):_vm._e(),(!_vm.prospectIsCandidate)?_c('prospect-detail-motivation',{attrs:{"motivation":_vm.currentProspect.motivation}}):_vm._e(),_c('prospect-detail-documents',{attrs:{"attachments":_vm.currentProspect.attachments},on:{"uploadAttachments":_vm.uploadAttachments,"deleteAttachment":_vm.deleteAttachment}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }