<template>
  <page>
    <archive-nav-header slot="header" />
    <archive-vacancy-list slot="main-content" />
    <archive-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import ArchiveNavHeader from './archive-header.vue'
import ArchiveAside from '../aside/aside-content.vue'
import ArchiveVacancyList from './archive-vacancy-list'

export default {
  name: 'ArchivedVacanciesPage',
  components: {
    Page,
    ArchiveNavHeader,
    ArchiveAside,
    ArchiveVacancyList
  }
}
</script>
