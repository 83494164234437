<template>
  <div class="list-wrapper" v-show="vacancies && vacancies.length > 0">
    <b-list-group-item
      variant="medium-gray"
      class="text-uppercase font-weight-bold"
      >vacatures</b-list-group-item
    >
    <b-link
      v-for="{ title, vacancyId } in vacancies"
      :key="vacancyId"
      :to="{ name: 'vacancy', params: { vacancyId } }"
    >
      <b-list-group-item>
        <span class="font-weight-bold">{{ title }}</span
        ><span> - Nr. {{ vacancyId }}</span>
      </b-list-group-item>
    </b-link>
    <b-link
      v-show="vacancies.length === 4 && !searched && !loading"
      @click="$emit('extend-search', 'vacancies')"
    >
      <b-list-group-item>
        <span> <i class="fa fa-eye mr-2" />Meer </span>
      </b-list-group-item>
    </b-link>
    <b-list-group-item v-show="loading">
      <div v-show="loading" class="text-center">
        <i class="fa fa-spinner fa-spin" />
      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import { GET_SEARCH_VACANCIES, GET_EXTENDED_SEARCH_STATE } from '../../store/keys-getters'

export default {
  computed: {
    vacancies () {
      return this.$store.getters[GET_SEARCH_VACANCIES]
    },
    searchState () {
      return this.$store.getters[GET_EXTENDED_SEARCH_STATE]('vacancies')
    },
    searched () {
      return this.searchState.searched || false
    },
    loading () {
      return this.searchState.loading || false
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../style/variables.scss";

.list-group-item {
  border-top-width: 0;
}
a {
  text-decoration: none !important;
  & > .list-group-item {
    & {
      border-top-width: 1px;
    }
    color: #2f353d !important;
    i {
      color: rgb(134, 134, 134) !important;
    }
    &:hover {
      background-color: $primary;
      color: white !important;
      i {
        color: white !important;
      }
    }
  }
}
</style>
