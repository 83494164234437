<template>
  <div class="bg-judgment-card d-flex flex-column p-3">
    <div class="ml-2"><strong>Selectiecommissie</strong></div>
    <div
      class="d-flex pb-1 justify-content-between"
      v-for="judgement in prospectJudgements"
      :key="judgement.comment"
    >
      <div class="ml-2">
        {{ judgement.selectionCommitteeMember.user.fullName }}
      </div>
      <div class="w-10 mr-2 d-flex justify-content-center align-items-center">
        <i :class="icons[judgement.judgementScore]" />
      </div>
    </div>
    <b-btn
      @click="showProspectJudgementsExplanations"
      size="sm"
      variant="medium-gray"
      class="align-self-end m-2 border border-medium-gray bg-white"
    >
      toelichtingen
    </b-btn>
  </div>
</template>
<script>
import modal from '../../services/modal'
import ProspectJudgementsModal from './prospect-judgements-modal'
import map from 'lodash/map'

export default {
  props: {
    prospectJudgements: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    icons () {
      return {
        1: 'fa fa-check text-success',
        0: 'fa fa-question text-dark',
        '-1': 'fa fa-times text-danger'
      }
    },
    judgements () {
      return map(this.prospectJudgements, (judgement) => ({
        fullname: judgement.selectionCommitteeMember.user.fullName,
        comment: judgement.comment,
        score: judgement.judgementScore
      }))
    }
  },
  methods: {
    showProspectJudgementsExplanations () {
      modal(ProspectJudgementsModal, { icons: this.icons, judgements: this.judgements })
    }
  }
}
</script>
