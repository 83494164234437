<template>
  <b-modal
    centered
    :title="title"
    :visible="true"
    :ok-disabled="confirmationWord !== userConfirmation"
    @ok="$emit('resolve', true)"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div>
      Weet je zeker dat je de geselecteerde sollicitanten wilt anonimiseren?
    </div>
    <div class="pt-3 pb-3">
      Type {{ confirmationWord }} in onderstaand veld en klik op OK om de actie
      te bevestigen.
    </div>
    <input
      class="form-control"
      :class="{ 'is-invalid': errors.has('user-confirmation') }"
      v-validate="{ required: true, is: confirmationWord }"
      data-vv-as="veld"
      v-model="userConfirmation"
      type="text"
      name="user-confirmation"
      id="user-confirmation"
    />
    <div class="invalid-feedback">
      {{ errors.first("user-confirmation") }}
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    total: {
      required: true,
      type: Number
    }
  },
  data () {
    return {
      userConfirmation: '',
      confirmationWord: 'ANONIMISEER'
    }
  },
  computed: {
    title () {
      return this.total === 1 ? `Anonimiseer ${this.total} sollicitant` : `Anonimiseer ${this.total} sollicitanten`
    }
  }
}
</script>
