import Vue from 'vue'
import Router from 'vue-router'

import VacancyFormPage from './components/vacancy/vacancy-form/page'
import VacancyTeamPage from './components/vacancy/vacancy-team/page'
import NotFoundPage from './components/not-found-page'
import GdprOverviewPage from './components/gdpr/page'
import GdprPage from './components/gdpr/subpage'
import OverviewVacanciesPage from './components/overview-vacancies/page'
import ProspectJudgementsPage from './components/prospect-judgements/page'
import ReportsPage from './components/reports/page'
import UserProfilePage from './components/user-profile/page'
import UsersPage from './components/users/page'
import MergeJobsPage from './components/merge-jobs/page'
import ArchivedVacanciesPage from './components/archive-vacancies/page'
import NoPermissionPage from './components/no-permission/page'
import TokenProspects from './components/token-prospects/page'
import TokenProspectsDetail from './components/token-prospects/detail/page'
import VacancyDescription from './components/vacancy/vacancy-description/page'
import VacancyPublicationChannelsPage from './components/vacancy/vacancy-publication-channels/page'
// import VacancyPublicationMimirPage from './components/vacancy-publication-mimir/page'
import VacancyMimirPage from './components/vacancy/vacancy-mimir/page'
import VacancyProspectsPage from './components/vacancy/vacancy-prospects/page'
import VacancyProspectDetailPage from './components/vacancy/vacancy-prospect-details/page'
import ProspectJudgementDetailPage from './components/prospect-judgement-details/page'
import VacancyOverviewPage from './components/vacancy/vacancy-overview/page'
import ReleaseNotesPage from './components/release-notes/page'
import includes from 'lodash/includes'

import store from './store'
import { LOAD_VACANCY } from './store/keys-actions'
import {
  GDPR_NO_CONSENT_APPLICANTS,
  GDPR_NOT_SOON_EXPIRING_APPLICANTS,
  GDPR_SOON_EXPIRING_APPLICANTS,
  GDPR_EXPIRED_APPLICANTS,
  VACANCY_TEAM_PERMISSIONS
} from './store/keys-getters'
import { SET_TEAM_PERMISSIONS } from './store/keys-mutations'
import {
  EDIT_SELECTION_COMMITEE_INTERFACE,
  VIEW_SELECTION_COMMITEE_INTERFACE
} from './constants/permissions'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(Router)

const hasONEPermission = permission => {
  if (!store.getters[VACANCY_TEAM_PERMISSIONS]) {
    store.dispatch(LOAD_VACANCY)
  }
  const { permissions } = store.getters[VACANCY_TEAM_PERMISSIONS]
  return includes(permissions, permission)
}

const routes = [
  {
    path: '/user-permission',
    name: 'userPermission',
    component: NoPermissionPage,
    meta: {
      noAuthorizationNeeded: true
    }
  },
  {
    path: '/tokenProspects',
    name: 'tokenProspects',
    component: TokenProspects,
    meta: {
      noAuthorizationNeeded: true
    }
  },
  {
    path: '/tokenProspects/:id',
    name: 'tokenProspectsDetail',
    component: TokenProspectsDetail,
    meta: {
      noAuthorizationNeeded: true
    }
  },
  {
    path: '/home',
    name: 'home',
    component: OverviewVacanciesPage
  },
  {
    path: '/vacancies/:vacancyId',
    name: 'vacancy',
    component: VacancyOverviewPage,
    beforeEnter: (to, from, next) => {
      if (hasONEPermission(VIEW_SELECTION_COMMITEE_INTERFACE)) {
        next({
          name: 'prospectJudgements',
          params: { vacancyId: to.params.vacancyId }
        })
        return
      }
      next()
    }
  },
  {
    path: '/vacancies/:vacancyId/edit',
    name: 'vacancyDescription',
    component: VacancyDescription
  },
  {
    path: '/vacancies/:vacancyId/form',
    name: 'vacancyForm',
    component: VacancyFormPage
  },
  {
    path: '/vacancies/:vacancyId/team',
    name: 'vacancyTeam',
    component: VacancyTeamPage
  },
  {
    path: '/vacancies/:vacancyId/publish',
    name: 'vacancyPublishChannels',
    component: VacancyPublicationChannelsPage
  },
  {
    path: '/vacancies/:vacancyId/mimir',
    name: 'vacancyPublishMimir',
    component: VacancyMimirPage
  },
  {
    path: '/vacancies/:vacancyId/select/rounds/:roundId?',
    name: 'vacancySelectionRound',
    component: VacancyProspectsPage,
    beforeEnter: (to, from, next) => {
      if (hasONEPermission(EDIT_SELECTION_COMMITEE_INTERFACE)) {
        next({ name: 'vacancy', params: { vacancyId: to.params.vacancyId } })
        return
      }
      next()
    }
  },
  {
    path: '/vacancies/:vacancyId/prospects/:prospectId',
    redirect: '/vacancies/:vacancyId/prospects/:prospectId/rounds/'
  },
  {
    path: '/vacancies/:vacancyId/prospects/:prospectId/rounds/:roundId?',
    name: 'vacancyProspectDetail',
    component: VacancyProspectDetailPage
  },
  {
    path: '/archive',
    name: 'archive',
    component: ArchivedVacanciesPage
  },
  {
    path: '/report',
    name: 'reports',
    component: ReportsPage
  },
  {
    path: '/vacancies/:vacancyId/profileJudgements',
    name: 'prospectJudgements',
    component: ProspectJudgementsPage
  },
  {
    path: '/vacancies/:vacancyId/profileJudgements/index/:index/:roundId',
    name: 'prospectJudgementDetail',
    component: ProspectJudgementDetailPage
  },
  {
    path: '/candidatesDatabase',
    name: 'candidatesDatabase',
    component: NotFoundPage
  },
  {
    path: '/AVG',
    name: 'gdprOverview',
    component: GdprOverviewPage,
    redirect: { name: 'gdprExpiredUsers' },
    children: [
      {
        path: 'expired',
        name: 'gdprExpiredUsers',
        component: GdprPage,
        props: {
          title:
            'De bewaartermijn van deze sollicitant-gegevens is verlopen. Je kunt de sollicitanten selecteren en anonimiseren. \nAlle privacy gevoelige data wordt dan verwijderd.',
          getter: GDPR_EXPIRED_APPLICANTS
        }
      },
      {
        path: 'almost-expired',
        name: 'gdprAlmostExpiredUsers',
        component: GdprPage,
        props: {
          title:
            'De bewaartermijn van deze sollicitant-gegevens verloopt binnen 30 dagen.',
          getter: GDPR_SOON_EXPIRING_APPLICANTS
        }
      },
      {
        path: 'active',
        name: 'gdprActiveUsers',
        component: GdprPage,
        props: {
          title:
            'Deze sollicitant-gegevens mogen nog meer dan 30 dagen bewaard worden.',
          getter: GDPR_NOT_SOON_EXPIRING_APPLICANTS
        }
      },
      {
        path: 'no-consent',
        name: 'gdprNoConsentUsers',
        component: GdprPage,
        props: {
          title: 'Voor deze sollicitanten is de bewaartermijn onbekend.',
          getter: GDPR_NO_CONSENT_APPLICANTS
        }
      }
    ]
  },
  {
    path: '/user',
    name: 'userProfile',
    component: UserProfilePage
  },
  {
    path: '/users',
    name: 'users',
    component: UsersPage
  },
  {
    path: '/mergeJobs/:prevUrl/vacancy/:vacancyViewedId/prospects/:prospectIds',
    name: 'mergeJobs',
    component: MergeJobsPage
  },
  {
    path: '/release-notes',
    name: 'releaseNotes',
    component: ReleaseNotesPage
  },
  { path: '*', redirect: 'home' }
]

const router = new Router({
  mode: 'hash',
  routes
})

// load vacancy data for any page that has a param vacancyId
router.beforeEach((to, from, next) => {
  if (!to.params || !to.params.vacancyId) {
    // Clear previous vacancy's team permissions
    store.commit(SET_TEAM_PERMISSIONS, [])
    next()
    return
  }

  store
    .dispatch(LOAD_VACANCY, to.params.vacancyId)
    .then(() => next())
    .catch(() => next())
})

// A reload is required to refesh the session, when navigating from tokenProspects to the home page
router.afterEach((to, from) => {
  if (to.params && to.params.reload) {
    window.location.assign('/')
  }
})

export default router
