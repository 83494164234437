<template>
    <div>
        <release-notes-list-item />
    </div>
</template>

<script>
import ReleaseNotesListItem from './release-notes-list-item'

export default {
  name: 'ReleaseNotesList',
  components: {
    ReleaseNotesListItem
  }
}
</script>
