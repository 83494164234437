<template>
  <div>
    <div class="pb-4">
      <div
        class="d-flex align-items-end justify-content-between mb-4 flex-column flex-sm-row"
      >
        <div class="title__container align-self-start w-100 order-1 order-sm-0">
          <h5>Vacature</h5>
          <div class="pr-2 mr-5">
            <label for="title"></label>
            <strong>Titel *</strong>
            <b-form-input
              v-validate="'required|max:150'"
              data-vv-as="Titel"
              :class="{ 'is-invalid': errors.has('title') }"
              id="title"
              name="title"
              v-model="privateDetails.title"
            />
            <div class="invalid-feedback">{{ errors.first("title") }}</div>
          </div>
        </div>
        <div class="order-0 order-sm-1">
          <div
            class="col-12 col-md-2 d-flex flex-column bg-white align-items-center align-items-md-start order-0 order-md-1 p-0"
            style="max-width: 300px;"
          >
            <preview-button
              :privateDetails="privateDetails"
              :dropdownOptions="dropdownOptions"
              :contactPerson="contactPerson"
              size="md"
            />
          </div>
        </div>
      </div>
      <div class="d-flex flex-column">
        <strong>Vacatureomschrijving *</strong>
        <div>
          Vul hier de vacatureomschrijving in. Vertel de kandidaat meer over je
          organisatie, het team, de cultuur en de functie. Maak je vacature
          extra aantrekkelijk door het invoegen van afbeeldingen en video
        </div>
        <div class="mt-3 mb-3">
          <b-dropdown
            v-if="canViewTemplates"
            id="ddown1"
            class="border border-medium-gray"
            variant="medium-light"
          >
            <template #button-content>
              Voeg template in
              <i class="fa fa-caret-down" />
            </template>
            <b-dropdown-item
              class="text-primary d-flex justify-content-between pr-0"
              v-for="template in templates"
              :key="template.templateId"
            >
              <b-btn
                variant="link"
                @click="privateDetails.description = template.description"
                >{{ template.title }}</b-btn
              >
              <b-btn variant="link" @click="deleteTemplate(template)">
                <i class="fa fa-trash-o" />
              </b-btn>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <vue-editor-with-image-upload :private-details="privateDetails" />
        <div v-show="errors.has('description')" class="text-error">
          {{ errors.first("description") }}
        </div>
        <div v-if="canEditTemplates" class="d-flex align-items-center ml-auto">
          <b-form-checkbox plain v-model="saveNewTemplate">
            <small>Opslaan als nieuwe template</small>
          </b-form-checkbox>
          <div>
            <small>
              <i
                v-b-tooltip.hover
                title="Wanneer u een template opslaat kan deze voor elke beschrijving vacature worden hergebruikt."
                class="fa fa-info-circle"
              />
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="pb-4 pt-4">
      <h5 :class="{ 'is-invalid': errors.has('title') }">Kenmerken</h5>
      <div class="d-flex justify-content-between pb-4 pr-2">
        <div>
          <label for="discipline">
            <strong>Vakgebied *</strong>
          </label>
          <multiselect-dropdown
            v-if="privateDetails.hasOwnProperty('discipline')"
            v-model="privateDetails.discipline"
            :align="'left'"
            name="discipline"
            :required="true"
            :max="3"
            label="Vakgebied"
            :options="dropdownOptions.discipline"
          />
        </div>
        <div>
          <label for="educationLevel">
            <strong>Opleidingsniveau *</strong>
          </label>
          <multiselect-dropdown
            v-if="privateDetails.hasOwnProperty('training')"
            v-model="privateDetails.training"
            name="educationLevel"
            :required="true"
            label="Opleidingsniveau"
            :options="dropdownOptions.educationLevel"
          />
        </div>
      </div>
      <div class="d-flex justify-content-between pb-4 pr-2">
        <div>
          <label for="jobCategory">
            <strong>Functiecategorie *</strong>
          </label>
          <multiselect-dropdown
            v-if="privateDetails.hasOwnProperty('jobCategory')"
            v-model="privateDetails.jobCategory"
            :align="'left'"
            name="jobCategory"
            :required="true"
            :max="3"
            label="Functiecategorie"
            :options="dropdownOptions.jobCategory"
          />
        </div>
        <div>
          <label for="relevantExperience">
            <strong>Werkervaring</strong>
          </label>
          <multiselect-dropdown
            v-if="privateDetails.hasOwnProperty('relevantExperience')"
            v-model="privateDetails.relevantExperience"
            name="relevantExperience"
            label="Werkervaring"
            :options="dropdownOptions.careerLevel"
          />
        </div>
      </div>
    </div>
    <div class="pb-4 pt-4">
      <h5>Locatie</h5>
      <div class="w-75 pr-2">
        <label for="workLocation"></label>
        <strong>Postcode werklocatie *</strong>
        <b-form-input
          v-validate="'required'"
          data-vv-as="workLocation"
          :class="{ 'is-invalid': errors.has('workLocation') }"
          class="w-50"
          id="workLocation"
          name="workLocation"
          v-model="privateDetails.workLocation"
        />
        <div class="invalid-feedback">{{ errors.first("workLocation") }}</div>
      </div>
    </div>
    <div class="pb-4 pt-4">
      <h5>Dienstverband</h5>
      <div class="d-flex flex-column justify-content-start pb-3 w-75 pr-2">
        <label for="employmentType">
          <strong>Aanstelling *</strong>
        </label>
        <div class="d-flex">
          <div class="mr-2">
            <multiselect-dropdown
              v-if="privateDetails.hasOwnProperty('employmentType')"
              v-model="privateDetails.employmentType"
              name="employmentType"
              :required="true"
              label="Aanstelling"
              :options="dropdownOptions.employmentType"
            />
          </div>
          <div class="d-flex flex-column">
            <div
              class="d-flex flex-row align-items-center"
              style="height: 40px"
            >
              <b-form-input
                v-validate="'required|decimal:2'"
                data-vv-as="FTE"
                class="w-50 h-100 0"
                id="employmentHours"
                name="employmentHours"
                style="height: 26px"
                :value="privateDetails.employmentHours"
                @input="inputChange"
              />
              <div class="ml-2">
                <strong>FTE *</strong>
              </div>
            </div>
            <div
              v-show="errors.has('employmentHours')"
              class="text-error small"
            >
              {{ errors.first("employmentHours") }}
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-column justify-content-start w-100 pr-2">
        <label for="employmentType">
          <strong>Aantal uren per week</strong>
        </label>
        <div class="d-flex">
          <div class="mr-2">
            <b-form-select
              v-model.number="privateDetails.hoursPerWeekType"
              class="mb-3"
            >
              <option :value="null" disabled>Selecteer een optie</option>
              <option
                v-for="option in dropdownOptions.hoursPerWeekType"
                :key="option.lConst"
                :value="option.lConst"
                >{{ option.value }}</option
              >
            </b-form-select>
          </div>
          <div class="d-flex flex-column">
            <div
              v-if="privateDetails.hoursPerWeekType === 'hoursPerWeekType_1'"
              class="d-flex flex-row align-items-center"
              style="height: 40px"
            >
              <b-form-input
                v-validate="'numeric'"
                type="number"
                class="w-25 h-100 mr-2"
                :class="{ 'is-invalid': errors.has('hoursPerWeek') }"
                data-vv-as="Uren per week"
                id="hoursPerWeek"
                name="hoursPerWeek"
                style="height: 26px"
                v-model.number="privateDetails.hoursPerWeek"
              />
              <div class="mr-2">
                <strong>uren per week</strong>
              </div>
            </div>
            <div
              v-if="privateDetails.hoursPerWeekType === 'hoursPerWeekType_2'"
              class="d-flex flex-row align-items-center"
              style="height: 40px"
            >
              <div class="ml-3 mr-1">
                <strong>min</strong>
              </div>
              <b-form-input
                v-validate="{
                  numeric: true,
                  max_value: privateDetails.hoursPerWeekMax,
                  is_not: privateDetails.hoursPerWeekMax
                }"
                class="w-25 h-100"
                :class="{ 'is-invalid': errors.has('hoursPerWeekMin') }"
                id="hoursPerWeekMin"
                data-vv-as="Min uren"
                type="number"
                name="hoursPerWeekMin"
                style="height: 26px"
                v-model.number="privateDetails.hoursPerWeekMin"
              />
              <div class="ml-3 mr-1">
                <strong>max</strong>
              </div>
              <b-form-input
                v-validate="'numeric'"
                class="w-25 h-100"
                :class="{ 'is-invalid': errors.has('hoursPerWeekMax') }"
                data-vv-as="Max uren"
                id="hoursPerWeekMax"
                type="number"
                name="hoursPerWeekMax"
                style="height: 26px"
                v-model.number="privateDetails.hoursPerWeekMax"
              />
              <div class="ml-2">
                <strong>uren per week.</strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="vacancy-description-error-row">
        <div
          v-if="
            privateDetails.hoursPerWeekType === 'hoursPerWeekType_1' &&
              errors.has('hoursPerWeek')
          "
          class="text-error small"
        >
          {{ errors.first("hoursPerWeek") }}
        </div>
        <div
          v-if="
            privateDetails.hoursPerWeekType === 'hoursPerWeekType_2' &&
              errors.has('hoursPerWeekMin')
          "
          class="text-error small"
        >
          {{ errors.first("hoursPerWeekMin") }}
        </div>
        <div
          v-if="
            privateDetails.hoursPerWeekType === 'hoursPerWeekType_2' &&
              errors.has('hoursPerWeekMax')
          "
          class="text-error small"
        >
          {{ errors.first("hoursPerWeekMax") }}
        </div>
      </div>
      <div
        class="d-flex flex-column justify-content-start w-100 pr-2 input-group"
      >
        <label for="employmentType">
          <strong>Salaris</strong>
        </label>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <div class="mr-2">schaal</div>
            <div class="mr-2">
              <multiselect-dropdown
                v-model="salary"
                name="salary"
                :options="dropdownOptions.salaryScale"
              />
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center w-30">
            <div
              class="d-flex flex-row align-items-center"
              style="height: 40px"
            >
              <b-form-input
                v-validate="
                  'numeric|max_value:' + String(privateDetails.salaryMax)
                "
                class="h-100 text-center"
                :class="{ 'is-invalid': errors.has('salaryMin') }"
                id="salaryMin"
                placeholder="€ min"
                type="number"
                name="salaryMin"
                style="height: 26px"
                v-model.number="privateDetails.salaryMin"
              />
            </div>
            <div class="mr-3 ml-3">
              <strong>-</strong>
            </div>
            <div
              class="d-flex flex-row align-items-center"
              style="height: 40px"
            >
              <b-form-input
                v-validate="'numeric'"
                class="h-100 text-center"
                :class="{ 'is-invalid': errors.has('salaryMin') }"
                id="salaryMax"
                type="number"
                placeholder="€ max"
                name="salaryMax"
                style="height: 26px"
                v-model.number="privateDetails.salaryMax"
              />
            </div>
          </div>
          <div>
            <b-form-select v-model="privateDetails.salaryForPeriod">
              <option
                v-for="option in dropdownOptions.salaryForPeriod"
                :key="option.lConst"
                :value="option.lConst"
                >{{ option.value }}</option
              >
            </b-form-select>
          </div>
        </div>
      </div>
    </div>
    <div
      class="vacancy-description-error-row text-danger small"
      v-if="errors.has('salaryMin')"
    >
      Het maximumsalaris mag niet minder zijn dan het minimumsalaris
    </div>
    <div class="pb-4 pt-4">
      <h5>Documenten</h5>
      <b-card class="mb-3" style="background-color: #e6ecf2">
        <small>
          Het toevoegen van documenten aan vacatures wordt niet meer ondersteund
          op vacature sites. Wanneer u toch bijlages bij uw vacature wilt
          opnemen kunt u een link in de vacature tekst plaatsen.
        </small>
      </b-card>
      <!-- <div class="d-flex align-items-center justify-content-between">
        <b-form-file
          multiple
          class="w-75"
          ref="files"
          :disabled="vacancyDetails.attachments.length >= 2"
          v-validate="{ size: 10000, maxArrayLength: 2 }"
          v-model="attachments"
          name="files"
          placeholder="Selecteer bestanden..."
        />
        <b-btn variant="primary" :disabled="!attachments" @click="uploadAttachments">Upload</b-btn>
      </div>
      <div v-if="errors.has('files')" class="text-error">{{ errors.first('files') }}</div>
      <div class="d-flex flex-wrap mt-4" v-if="vacancyDetails.attachments.length > 0">
        <div v-for="attachment in vacancyDetails.attachments" :key="attachment.attachmentId" class="mr-2">
          <b-btn variant="white" class="border border-right-0 border-medium-gray" :href="attachment.url" target="_blank"
            >{{ attachment.fileName }}.{{ attachment.fileExtension }}</b-btn
          >
          <b-btn variant="white" class="border border-left-0 border-medium-gray" @click="deleteAttachment(attachment)">
            <i class="fa fa-trash-o" />
          </b-btn>
        </div>
      </div> -->
    </div>
    <div class="pb-4 pt-4">
      <h5>Solliciteren</h5>
      <div>
        Selecteer op welke manier je de sollicitaties wil ontvangen. Gebruik je
        een extern sollicitatieformulier? Plak dan de link in het onderstaande
        veld.
      </div>
      <div>
        <div class="d-flex align-items-center mb-3">
          <label class="mb-0" for="sollicitaties">
            <strong>Sollicitaties ontvangen via:</strong>
          </label>
          <b-form-select
            v-model="privateDetails.appSrc"
            class="ml-2 w-25"
            name="sollicitaties"
            id="sollicitaties"
          >
            <option
              v-for="option in dropdownOptions.appSrc"
              :key="option.lConst"
              :value="option.lConst"
              >{{ option.value }}</option
            >
          </b-form-select>
        </div>
        <div v-show="!appSrcAddressIsDisabled">
          <b-input
            v-validate="`required:${!appSrcAddressIsDisabled}|urlOrMailto`"
            name="appSrcAddress"
            :class="{ 'is-invalid': errors.has('appSrcAddress') }"
            v-model.trim="privateDetails.appSrcAddress"
            :disabled="appSrcAddressIsDisabled"
          />
          <div class="invalid-feedback">
            {{ errors.first("appSrcAddress") }}
          </div>
        </div>
      </div>
    </div>
    <div class="pb-4 pt-4">
      <h5>Contactgegevens</h5>
      <div>
        <strong>Contactpersoon *</strong>
      </div>
      <div>
        Door een contactpersoon toe te voegen verschijnt onderaan de
        gepubliceerde vacature automatisch informatie over bij wie sollicitanten
        meer informatie kunnen verkrijgen over de vacature.
      </div>
      <v-autocomplete
        v-validate="{ required: appSrcAddressIsDisabled }"
        class="m-auto"
        :items="contactPersonOptionList"
        ref="autocomplete"
        :min-len="0"
        data-vv-as="Contactpersoon"
        name="contactPerson"
        :class="{ '--is-invalid': errors.has('contactPerson') }"
        :input-attrs="configurationAutocomplete"
        v-model="contactPerson"
        :auto-select-one-item="false"
        :get-label="getAutocompleteLabel"
        :component-item="autocompleteOptions"
        @update-items="searchAutocomplete"
      />
      <div v-if="errors.has('contactPerson')" class="vacancy-description-error-row text-danger small mt-1">
        Contactpersoon is verplicht wanneer u sollicitaties ontvangt via Jobsrepublic One
      </div>

      <autocomplete-selected
        v-if="contactPersonIsSelected"
        :selected="contactPerson"
        :displayInfoOnly="true"
        @removeSelected="removeAutocompleteSelected"
      />
    </div>
    <div v-if="showErrorMessage && errors.items.length > 0" class="text-error">
      Niet alle velden in het formulier zijn correct ingevuld. Controleer de
      velden hierboven met een asterisk(*) en/of een melding!
    </div>
    <div class="d-flex justify-content-end">
      <div
        class="d-flex flex-column bg-white align-items-center p-0"
        style="max-width: 300px;"
      >
        <preview-button
          :privateDetails="privateDetails"
          :dropdownOptions="dropdownOptions"
          :contactPerson="contactPerson"
          size="md"
        />
      </div>
      <b-btn variant="link" @click="save({ publish: false })"
        >Tussentijds opslaan</b-btn
      >
      <b-btn variant="primary" @click="save({ publish: true, nextRoute })">
        <div v-if="!loading">Volgende stap</div>
        <div v-if="loading">
          <i class="fa fa-spinner fa-spin" />
        </div>
      </b-btn>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import map from 'lodash/map'
import min from 'lodash/min'
import max from 'lodash/max'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import {
  LOADING,
  USERS,
  VACANCY,
  VACANCY_DESCRIPTION_DROPDOWN_OPTIONS,
  VACANCY_DESCRIPTION_TEMPLATES
} from '../../../store/keys-getters'
import {
  DELETE_VACANCY_ATTACHMENT,
  DELETE_VACANCY_DESCRIPTION_TEMPLATE,
  LOAD_USERS,
  LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS,
  LOAD_VACANCY_DESCRIPTION_TEMPLATES,
  SAVE_VACANCY_DESCRIPTION,
  SAVE_VACANCY_TEMPLATE,
  UPLOAD_VACANCY_ATTACHMENT
} from '../../../store/keys-actions'
import {
  VIEW_FAVORITE_TEMPLATE,
  EDIT_FAVORITE_TEMPLATE
} from '../../../constants/permissions'
import modal from '../../../services/modal'
import YesNoModal from '../../confirm-yes-no-modal'
import MultiselectDropdown from '../../multiselect-dropdown'
import autocompleteOptions from '../vacancy-team/autocomplete-option'
import AutocompleteSelected from '../vacancy-team/autocomplete-selected'
import VueEditorWithImageUpload from '../../vue-editor-with-image-upload'
import PreviewButton from '../../vacancy-preview/preview-button.vue'

export default {
  inject: {
    $validator: '$validator'
  },
  components: {
    VueEditorWithImageUpload,
    MultiselectDropdown,
    AutocompleteSelected,
    PreviewButton
  },
  data () {
    return {
      privateDetails: {},
      salary: [],
      attachments: null,
      saveNewTemplate: false,
      autocompleteOptions: autocompleteOptions,
      contactPerson: null,
      configurationAutocomplete: {
        placeholder: 'Zoek gebruiker op naam'
      },
      contactPersonItems: [],
      showErrorMessage: false
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    vacancyDetails () {
      return this.$store.getters[VACANCY]
    },
    templates () {
      return this.$store.getters[VACANCY_DESCRIPTION_TEMPLATES]
    },
    dropdownOptions () {
      return this.$store.getters[VACANCY_DESCRIPTION_DROPDOWN_OPTIONS]
    },
    users () {
      return this.$store.getters[USERS]
    },
    contactPersonOptionList () {
      return isEmpty(this.contactPersonItems)
        ? this.users
        : this.contactPersonItems
    },
    contactPersonIsSelected () {
      return !isEmpty(this.contactPerson)
    },
    contactPersonFromServer () {
      return this.users.find(
        user => user.username === this.privateDetails.contactPerson
      )
    },
    canViewTemplates () {
      return this.hasPermission(VIEW_FAVORITE_TEMPLATE)
    },
    canEditTemplates () {
      return this.hasPermission(EDIT_FAVORITE_TEMPLATE)
    },
    nextRoute () {
      if (this.canViewTemplates && this.appSrcAddressIsDisabled) {
        return 'vacancyForm'
      }
      return 'vacancyTeam'
    },
    appSrcAddressIsDisabled () {
      return this.privateDetails.appSrc !== 'appSrc_3'
    },
    appSrcAddress () {
      return this.privateDetails.appSrcAddress
    }
  },
  watch: {
    appSrcAddress: {
      handler () {
        this.prependProtocol()
      }
    },
    vacancyDetails: {
      immediate: true,
      handler () {
        if (!isEmpty(this.privateDetails)) {
          if (
            isEqual(
              this.privateDetails.attachments,
              this.vacancyDetails.attachments
            )
          ) {
            return
          }
          this.privateDetails.attachments = this.vacancyDetails.attachments
          return
        }
        this.privateDetails = cloneDeep(this.vacancyDetails)
        if (!this.privateDetails.salaryForPeriod) {
          this.privateDetails.salaryForPeriod = 'salaryForPeriod_2'
        }
        if (!this.privateDetails.appSrc) {
          this.privateDetails.appSrc = 'appSrc_1'
        }
        if (this.privateDetails.salaryScale) {
          this.salary = this.privateDetails.salaryScale
        }
        if (
          !this.privateDetails.description ||
          this.privateDetails.description === '<p>&nbsp;</p>'
        ) {
          this.privateDetails.description = ''
        }
      },
      deep: true
    },
    salary: {
      immediate: true,
      handler () {
        if (this.privateDetails.salaryScale === this.salary) {
          return
        }
        this.privateDetails.salaryScale = this.salary
        const selectedScales = filter(
          this.dropdownOptions.salaryScale,
          option => includes(this.salary, option.lConst)
        )
        this.privateDetails.salaryMin = min(map(selectedScales, 'minSalary'))
        this.privateDetails.salaryMax = max(map(selectedScales, 'maxSalary'))
      }
    },
    contactPerson () {
      this.privateDetails.contactPerson = this.contactPerson
        ? this.contactPerson.username
        : null
    },
    contactPersonFromServer () {
      this.contactPerson = this.contactPersonFromServer
    }
  },
  methods: {
    inputChange (e) {
      if (!e && e !== 0) {
        this.privateDetails.employmentHours = null
        return
      }
      if (typeof e === 'number') {
        this.privateDetails.employmentHours = e
        return
      }
      const value = parseFloat(e.replace(',', '.'))
      if (isNaN(value)) {
        this.privateDetails.employmentHours = e
        return
      }
      this.privateDetails.employmentHours = value
    },
    deleteTemplate ({ title, templateId }) {
      modal(YesNoModal, {
        question: `Wil je de template "${title}" verwijderen?`
      }).then(ok => {
        if (!ok) {
          return
        }
        this.$store.dispatch(DELETE_VACANCY_DESCRIPTION_TEMPLATE, templateId)
      })
    },
    deleteAttachment (attachment) {
      const name = `${attachment.fileName}.${attachment.fileExtension}`
      modal(YesNoModal, {
        question: `Weet u zeker dat u ${name} wilt verwijderen?`
      }).then(ok => {
        if (!ok) {
          return
        }
        this.$store.dispatch(
          DELETE_VACANCY_ATTACHMENT,
          attachment.attachmentId
        )
      })
    },
    uploadAttachments () {
      this.$store
        .dispatch(UPLOAD_VACANCY_ATTACHMENT, this.attachments)
        .then(() => this.$refs.files.reset())
    },
    getAutocompleteLabel (item) {
      if (!item) {
        return
      }
      return item.fullName
    },
    removeAutocompleteSelected () {
      this.contactPerson = null
      this.contactPersonItems = []
    },
    searchAutocomplete (text) {
      if (!text) {
        this.contactPersonItems = this.users
        return
      }
      this.contactPersonItems = filter(this.users, user => {
        const fullname = user.fullName.toUpperCase()
        const searchString = text.toUpperCase()
        return fullname.includes(searchString)
      })
    },
    save ({ publish, nextRoute }) {
      return this.$validator.validate().then(isValid => {
        if ((!isValid || this.errors.items.length > 0) && publish === true) {
          this.showErrorMessage = true
          return
        }
        if (this.saveNewTemplate) {
          this.$store.dispatch(SAVE_VACANCY_TEMPLATE, {
            title: this.privateDetails.title,
            description: this.privateDetails.description
          })
        }

        // removes the check about whether the user wants to leave the page while they have unsaved changes
        Object.keys(this.fields).forEach(key =>
          this.$validator.flag(key, { changed: false })
        )

        this.resetUnselectedHoursType()

        this.$store
          .dispatch(SAVE_VACANCY_DESCRIPTION, {
            vacancy: this.privateDetails,
            publish
          })
          .then(() => {
            if (!nextRoute) {
              return
            }
            this.$router.push({
              name: nextRoute,
              params: { vacancyId: this.vacancyDetails.vacancyId }
            })
          })
          .catch(() => {})
      })
    },
    prependProtocol () {
      if (
        this.privateDetails.appSrcAddress &&
        this.privateDetails.appSrcAddress &&
        this.privateDetails.appSrcAddress.match(/^w{3}\./)
      ) {
        this.privateDetails.appSrcAddress =
          'https://' + this.privateDetails.appSrcAddress
      }
    },
    resetUnselectedHoursType () {
      if (this.privateDetails.hoursPerWeekType === 'hoursPerWeekType_1') {
        this.privateDetails.hoursPerWeekMax = this.privateDetails.hoursPerWeekMin = null
        return
      }
      this.privateDetails.hoursPerWeek = null
    }
  },
  mounted () {
    const requests = [
      this.$store.dispatch(LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS),
      this.$store.dispatch(LOAD_USERS)
    ]
    if (this.canViewTemplates) {
      requests.push(this.$store.dispatch(LOAD_VACANCY_DESCRIPTION_TEMPLATES))
    }
    return Promise.all([requests])
  }
}
</script>

<style lang="scss" scoped>
.title__container {
  flex-basis: 70%;
}
.--is-invalid {
  &::v-deep .v-autocomplete-input {
    border-color: #dc3545 !important;
    &:focus {
      -webkit-box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
      box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25) !important;
    }
  }
}
</style>
