<template>
  <page>
    <users-header slot="header" />
    <users-list slot="main-content" />
    <users-aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import UsersHeader from './users-header'
import UsersList from './users-list'
import UsersAside from '../aside/aside-content.vue'

export default {
  name: 'UserProfilePage',
  components: {
    Page,
    UsersHeader,
    UsersList,
    UsersAside
  }
}
</script>
