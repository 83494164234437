<template>
  <b-modal
    centered
    title="Voordragen aan selectiecommissie"
    :visible="true"
    @ok="sendToSelectionCommitee"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    ok-title="Sturen"
    body-class="modal-scroll"
    :ok-disabled="!selectionCommittee || selectionCommittee.length < 1"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div>
      <div v-if="!selectionCommittee || selectionCommittee.length < 1">
        Er zijn geen selectie commissieleden in het team.
      </div>
      <b-form-group>
        <b-form-checkbox-group
          stacked
          v-model="selected"
          v-validate="'required'"
          :class="{ 'is-invalid': errors.has('selectionCommittee') }"
          name="selectionCommittee"
          data-vv-as="Selectiecommissie"
        >
          <b-form-checkbox
            v-for="member in selectionCommittee"
            :key="member.teamMemberId"
            :value="member.user.username"
            >{{ member.user.fullName }}</b-form-checkbox
          >
        </b-form-checkbox-group>
      </b-form-group>
      <div v-if="errors.has('selectionCommittee')" class="text-error">
        {{ errors.first("selectionCommittee") }}
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'SendToSelectionCommitee',
  props: {
    selectionCommittee: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selected: []
    }
  },
  methods: {
    sendToSelectionCommitee (e) {
      e.preventDefault()
      this.$validator.validate().then((isValid) => {
        if (!isValid) {
          return
        }
        this.$emit('resolve', this.selected)
        this.$emit('remove')
      })
    }
  }
}
</script>
