<template>
  <div>
    <search-input v-model="userSearch"></search-input>
    <div class="d-flex">
      <b-form-select v-model="sortMethod" class="mb-3 mr-3">
        <option :value="null" disabled>-- Sorteer op --</option>
        <option value="last">Laatst aangemaakt</option>
        <option value="alphabetical">A - Z</option>
      </b-form-select>
      <b-form-select v-model="filterCriteria" class="mb-4">
        <option :value="null" disabled>-- Kies filter --</option>
        <option value="published">Gepubliceerd</option>
        <option value="not-published">Niet gepubliceerd</option>
        <option value="all">Alle</option>
      </b-form-select>
    </div>
    <div>
      <b-list-group>
        <b-list-group-item
          class="alternating-color-list"
          v-for="vacancy in sortedVacancyList"
          :key="vacancy.vacancyId"
        >
          <overview-vacancy-list-item :vacancy="vacancy" />
        </b-list-group-item>
        <div class="p-3" v-if="sortedVacancyList.length < 1 && !loading">
          Er zijn geen vacatures gevonden binnen jouw filter.
        </div>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy'
import deburr from 'lodash/deburr'
import words from 'lodash/words'
import every from 'lodash/every'
import filter from 'lodash/filter'
import { OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER, LOADING } from '../../store/keys-getters'
import { LOAD_VACANCY_LIST } from '../../store/keys-actions'
import OverviewVacancyListItem from './overview-vacancy-list-item'
import SearchInput from '../search-input'

export default {
  name: 'overviewVacancyList',
  components: {
    OverviewVacancyListItem,
    SearchInput
  },
  data () {
    return {
      sortMethod: 'last',
      filterCriteria: 'all',
      userSearch: ''
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    allVacancies () {
      return this.$store.getters[OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]
    },
    publishedVacancies () {
      return this.allVacancies.filter((vacancy) => vacancy.isCurrentlyPublished)
    },
    notPublishedVacancies () {
      return this.allVacancies.filter((vacancy) => !vacancy.isCurrentlyPublished)
    },
    filteredVacancyList () {
      if (this.filterCriteria === 'published') {
        return this.publishedVacancies
      }
      if (this.filterCriteria === 'not-published') {
        return this.notPublishedVacancies
      }
      return this.allVacancies
    },
    deburredUserSearch () {
      return words(deburr(this.userSearch))
    },
    searchResults () {
      if (!this.deburredUserSearch) {
        return this.filteredVacancyList
      }
      return filter(this.filteredVacancyList, (vacancy) =>
        every(this.deburredUserSearch, (word) => vacancy.searchKey.includes(word))
      )
    },
    sortedVacancyList () {
      if (this.sortMethod === 'alphabetical') {
        return sortBy(this.searchResults, [(vacancy) => vacancy.uppercaseTitle])
      }
      if (this.sortMethod === 'last') {
        return sortBy(this.searchResults, ['dateCreated']).reverse()
      }
      return this.searchResults
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_VACANCY_LIST)
  }
}
</script>
