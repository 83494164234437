<template>
  <b-nav class="vacancy-nav-bar">
    <b-nav-item
      active-class="text-primary border-primary"
      type="dark"
      :to="{ name: 'vacancyDescription' }"
      exact
      >VACATURE</b-nav-item
    >
    <b-nav-item
      id="prospect-form-link"
      active-class="text-primary border-primary"
      v-if="showFormLink"
      type="dark"
      :to="{ name: 'vacancyForm' }"
      exact
      :disabled="disableFormLink"
      >SOLLICITATIE FORMULIER</b-nav-item
    >

    <b-tooltip
      target="prospect-form-link"
      :disabled="!disableFormLink"
      triggers="hover"
      placement="bottom"
      custom-class="tooltip"
    >
      Selecteer "Jobsrepublic One" onder Opstellen > Solliciteren om dit in te
      schakelen en klik dan op "Tussentijds opslaan" of "Volgende Stap".
    </b-tooltip>

    <b-nav-item
      active-class="text-primary border-primary"
      v-if="showTeamLink"
      type="dark"
      :to="{ name: 'vacancyTeam' }"
      exact
      >TEAM</b-nav-item
    >
    <b-nav-item
      active-class="text-primary border-primary"
      type="dark"
      :to="{ name: 'vacancyPublishChannels' }"
      exact
      >PUBLICATIE</b-nav-item
    >
    <b-nav-item
      v-if="showMimirLink"
      active-class="text-primary border-primary"
      type="dark"
      :to="{ name: 'vacancyPublishMimir' }"
      exact
      >MIMIR</b-nav-item
    >
  </b-nav>
</template>

<script>
import { VIEW_VACANCY_TEAM, EDIT_FAVORITE_TEMPLATE, EDIT_VACANCY_PUBLICATION_MIMIR } from '../../constants/permissions'
import { VACANCYSTATUS_PUBLISHED, VACANCYSTATUS_SELECTION_STARTED } from '../../constants/vacancy-status'
import { VACANCY } from '../../store/keys-getters'

export default {
  name: 'vacancyAside',
  computed: {
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    vacancyPublishedOrSelectionStarted () {
      return (
        this.vacancy.lStatus === VACANCYSTATUS_PUBLISHED || this.vacancy.lStatus === VACANCYSTATUS_SELECTION_STARTED
      )
    },
    showTeamLink () {
      return this.vacancyPublishedOrSelectionStarted && this.hasPermission(VIEW_VACANCY_TEAM, this.vacancy.vacancyId)
    },
    showFormLink () {
      return this.vacancyPublishedOrSelectionStarted && this.hasPermission(EDIT_FAVORITE_TEMPLATE)
    },
    showMimirLink () {
      return this.vacancyPublishedOrSelectionStarted && this.hasPermission(EDIT_VACANCY_PUBLICATION_MIMIR)
    },
    disableFormLink () {
      return this.$store.getters[VACANCY].appSrc === 'appSrc_3'
    }
  }
}
</script>

<style lang="scss">
.vacancy-nav-bar {
  .nav-link {
    &.disabled {
      color: #9da9b3 !important;
      border-left-color: #9da9b3 !important;
    }
  }
}
</style>
