<template>
  <div class="pl-lg-5 pr-lg-5">
    <div class="d-flex justify-content-between pb-5">
      <div>
        <h5>
          <strong v-if="seeMultipleRounds"
            >Selectie - Ronde {{ roundIndex }}</strong
          >
          <strong v-else>Selectie</strong>
        </h5>
      </div>
      <div>
        <b-btn
          v-if="isLatestRound && createMultipleRounds"
          variant="white"
          class="border border-light-gray"
          @click="startNewRound"
        >
          <div v-if="loading">
            <i class="fa fa-spinner fa-spin" />
          </div>
          <div v-if="!loading">Nieuwe ronde</div>
        </b-btn>
      </div>
    </div>
    <div>
      <b-tabs no-key-nav>
        <b-tab
          @click="changeTab(key)"
          v-for="(tab, key) in tabDetails"
          :active="key === currentTab"
          :key="key"
          :title="`${tab.title} (${(prospects[key] || []).length})`"
        >
          <div
            v-if="isLatestRound"
            class="d-flex align-items-center bg-white border-left border-right border-bottom border-light-gray px-4 py-3"
          >
            <b-form-checkbox
              id="checkbox1"
              v-model="isSelectAllChecked"
              @input="toggleAll"
              plain
              :disabled="loading || (prospects[key] || []).length === 0"
            />
            <b-dropdown
              variant="light-gray"
              id="ddown1"
              class="m-md-2 ml-2"
              :disabled="loading || (prospects[key] || []).length === 0"
            >
              <template slot="button-content">
                <div v-if="!loading">
                  Acties
                  <i class="fa fa-caret-down" />
                </div>
                <div v-if="loading">
                  <i class="fa fa-spinner fa-spin" />
                </div>
              </template>
              <b-dropdown-item
                v-if="key !== 'PROSPRATING_UNRATED'"
                @click="changeProspectRating('unrated')"
                >Plaats in 'Nog niet beoordeld'</b-dropdown-item
              >
              <b-dropdown-item
                v-if="key !== 'PROSPRATING_SELECTED'"
                @click="changeProspectRating('selected')"
                >Plaats in 'Door'</b-dropdown-item
              >
              <b-dropdown-item
                v-if="key !== 'PROSPRATING_RESERVED'"
                @click="changeProspectRating('reserved')"
                >Plaats in 'Reserve'</b-dropdown-item
              >
              <b-dropdown-item
                v-if="key !== 'PROSPRATING_DECLINED'"
                @click="changeProspectRating('declined')"
                >Plaats in 'Niet Door'</b-dropdown-item
              >
              <b-dropdown-item
                @click="sendMail('general', { to: 'geselecteerd' })"
                >Bulkmail | Algemeen</b-dropdown-item
              >
              <!-- prettier-ignore-attribute @click -->
              <b-dropdown-item
                v-if="key === 'PROSPRATING_RESERVED'"
                @click="sendMail('reserve', {
                    to: 'geselecteerd',
                    subject: 'Verzoek om uw CV in ons bestand te bewaren',
                  })"
                >Bulkmail | In reserve houden</b-dropdown-item
              >
              <!-- prettier-ignore-attribute @click -->
              <b-dropdown-item
                v-if="key === 'PROSPRATING_DECLINED'"
                @click="sendMail('reject', {
                    to: 'geselecteerd',
                    subject: `Status sollicitatie vacature ${vacancy.title}`,
                  })"
                >Bulkmail | Afwijzen</b-dropdown-item
              >
              <b-dropdown-item @click="sendToSelectionCommittee"
                >Voordragen aan selectiecommissie</b-dropdown-item
              >
              <b-dropdown-item @click="generateBulkPrint"
                >Bulk print profielen</b-dropdown-item
              >
              <b-dropdown-item v-if="showShareOption" @click="sharePerEmail()"
                >Delen per e-mail</b-dropdown-item
              >
            </b-dropdown>
          </div>
          <div class="bg-white">
            <!-- prettier-ignore-attribute @setRatingTypeForProspectNavigation -->
            <prospect
              @sendEmail="sendMail"
              @saveNewStatus="saveNewStatus"
              @setRatingTypeForProspectNavigation="setRatingTypeForProspectNavigation(key)"
              v-for="(prospect, index) in prospects[key]"
              @changeRating="changeRating"
              v-model="selected[prospect.prospectId]"
              :prospect="prospect"
              :users="users"
              :tabDetails="tabDetails[key]"
              :vacancy="vacancy"
              :index="index"
              :key="`${key}-${prospect.prospectId}`"
              :isLatestRound="isLatestRound"
            />
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {
  LOAD_VACANCY_PROSPECTS,
  CHANGE_PROSPECT_RATING,
  CHANGE_PROSPECT_STATUS,
  LOAD_VACANCY_TEAM_MEMBERS,
  SEND_PROSPECTS_TO_SELECTION_COMMITTEE,
  SEND_GENERAL_EMAIL,
  SEND_KEEP_IN_RESERVE_EMAIL,
  SEND_REJECT_EMAIL,
  START_NEW_SELECTION_ROUND,
  LOAD_SELECTION_ROUND_STATS,
  LOAD_USERS,
  LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE,
  SEND_SHARE_PROSPECTS_EMAIL
} from '../../../store/keys-actions'
import {
  VACANCY_PROSPECTS,
  VACANCY,
  LOADING,
  SELECTION_ROUND_STATS,
  VACANCY_TEAM,
  USERS,
  CURRENT_TAB_PROSPECTS_OVERVIEW,
  VACANCY_ROUNDS,
  DEFAULT_SHARE_PER_EMAIL_TEMPLATE
} from '../../../store/keys-getters'
import {
  SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION,
  SET_CURRENT_TAB_PROSPECTS_OVERVIEW
} from '../../../store/keys-mutations'
import {
  PROSPRATING_UNRATED,
  PROSPRATING_DECLINED,
  PROSPRATING_RESERVED,
  PROSPRATING_SELECTED,
  PROSPRATING_TOTAL
} from '../../../constants/prospect/prospect-recruiter-rating'
import {
  PROPRECSTATUS_NOT_INTERESTED,
  PROPRECSTATUS_HIRED,
  PROPRECSTATUS_INTERESTED,
  PROPRECSTATUS_INVITED,
  PROPRECSTATUS_REJECTED,
  PROPRECSTATUS_RESERVED,
  PROPRECSTATUS_WITHDRAWN,
  PROPRECSTATUS_WITHOUT_STATUS
} from '../../../constants/prospect/prospect-recruiter-status'
import Prospect from './prospect'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'
import filter from 'lodash/filter'
import modal from '../../../services/modal'
import SendToSelectionCommitteeModal from './send-to-selection-committee-modal'
import EmailModal from '../../email-modal'
import ConfirmYesNoModal from '../../confirm-yes-no-modal'
import {
  MULTIPLE_SELECTION_ROUNDS,
  MULTIPLE_SELECTION_ROUNDS_LABEL,
  SHARE_SELECTION
} from '../../../constants/permissions'

function isEmpty (value = null) {
  if (value === null) return true
  return !Object.entries(value).length
}

export default {
  name: 'prospects',
  components: {
    Prospect
  },
  data () {
    return {
      isSelectAllChecked: false,
      text: '',
      selected: {}
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    prospects () {
      return this.$store.getters[VACANCY_PROSPECTS](this.$route.params.roundId)
    },
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    users () {
      return this.$store.getters[USERS]
    },
    currentTab () {
      return this.$store.getters[CURRENT_TAB_PROSPECTS_OVERVIEW]
    },
    tabDetails () {
      return {
        [PROSPRATING_UNRATED]: {
          title: 'Nog niet beoordeeld',
          options: null,
          displayRatingButtons: true
        },
        [PROSPRATING_SELECTED]: {
          title: 'Door',
          options: [
            {
              value: PROPRECSTATUS_WITHOUT_STATUS,
              text: 'Geen status',
              displayForApplicants: true
              // disabled: true
            },
            {
              value: PROPRECSTATUS_INTERESTED,
              text: 'Geïnteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_NOT_INTERESTED,
              text: 'Niet geïnteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_INVITED,
              text: 'Uitgenodigd',
              displayForApplicants: true
            },
            {
              value: PROPRECSTATUS_WITHDRAWN,
              text: 'Teruggetrokken',
              displayForApplicants: true
            },
            {
              value: PROPRECSTATUS_HIRED,
              text: 'Aangesteld',
              displayForApplicants: true
            }
          ],
          displayRatingButtons: false
        },
        [PROSPRATING_RESERVED]: {
          title: 'Reserve',
          options: [
            {
              value: PROPRECSTATUS_WITHOUT_STATUS,
              text: 'Geen status',
              displayForApplicants: true
              // disabled: true
            },
            {
              value: PROPRECSTATUS_INTERESTED,
              text: 'Geïnteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_NOT_INTERESTED,
              text: 'Niet geinteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_RESERVED,
              text: 'In reserve gehouden',
              displayForApplicants: true
            },
            {
              value: PROPRECSTATUS_WITHDRAWN,
              text: 'Teruggetrokken',
              displayForApplicants: true
            }
          ],
          displayRatingButtons: false
        },
        [PROSPRATING_DECLINED]: {
          title: 'Niet door',
          options: [
            {
              value: PROPRECSTATUS_WITHOUT_STATUS,
              text: 'Geen status',
              displayForApplicants: true
              // disabled: true
            },
            {
              value: PROPRECSTATUS_INTERESTED,
              text: 'Geïnteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_NOT_INTERESTED,
              text: 'Niet geinteresseerd',
              displayForApplicants: false
            },
            {
              value: PROPRECSTATUS_WITHDRAWN,
              text: 'Teruggetrokken',
              displayForApplicants: true
            },
            {
              value: PROPRECSTATUS_REJECTED,
              text: 'Afgewezen',
              displayForApplicants: true
            }
          ],
          displayRatingButtons: false
        },
        [PROSPRATING_TOTAL]: {
          title: 'Totaal',
          options: null,
          displayRatingButtons: false
        }
      }
    },
    selectedProspectIds () {
      return Object.keys(
        pickBy(this.selected, (prospect) => prospect)
      ).map((id) => Number(id))
    },
    emailActionConstants () {
      return {
        general: SEND_GENERAL_EMAIL,
        reserve: SEND_KEEP_IN_RESERVE_EMAIL,
        reject: SEND_REJECT_EMAIL
      }
    },
    selectedProspectsLackStatus () {
      return this.prospects[this.currentTab]
        .filter((prospect) =>
          this.selectedProspectIds.includes(prospect.prospectId)
        )
        .every(
          (prospect) =>
            prospect.recruiterStatus === PROPRECSTATUS_WITHOUT_STATUS
        )
    },
    selectionRoundStats () {
      return this.$store.getters[SELECTION_ROUND_STATS]
    },
    vacancyRounds () {
      return this.$store.getters[VACANCY_ROUNDS]
    },
    roundIndex () {
      const round = this.vacancyRounds.find(
        (round) => round.roundId === +this.$route.params.roundId
      )
      if (round && typeof round.index !== 'undefined') return round.index
      return null
    },
    isLatestRound () {
      return this.vacancy.latestRoundId === +this.$route.params.roundId
    },
    allProspectsAreSelected () {
      if (isEmpty(this.selected)) return false
      return Object.values(this.selected).every((n) => n)
    },
    seeMultipleRounds () {
      return this.hasPermission(MULTIPLE_SELECTION_ROUNDS_LABEL)
    },
    createMultipleRounds () {
      return this.hasPermission(MULTIPLE_SELECTION_ROUNDS)
    },
    showShareOption () {
      return this.hasPermission(SHARE_SELECTION)
    },
    noProspectsSelected () {
      const selected = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      return !Object.values(selected).some(Boolean)
    }
  },
  watch: {
    // I suspect this watcher has something to do with the issue. The logic here is too complex, I need to refactor this component
    prospects: {
      handler: function () {
        this.setUpSelectedProspects()
      },
      deep: true
    },
    allProspectsAreSelected: function () {
      this.isSelectAllChecked = this.allProspectsAreSelected
    }
  },
  methods: {
    changeProspectRating (to) {
      if (this.noProspectsSelected) {
        this.$notify({
          type: 'warning',
          text: 'U hebt nog niemand geselecteerd.'
        })
        return
      }
      // ONE-376 Selecting all candidates is not reset when switching tabs
      // https://jobsrepublic-v2.atlassian.net/browse/ONE-376?atlOrigin=eyJpIjoiZDVjMTE0MTA2ODdmNGQ1YmI1Y2NjYjhkMDMyODk0YTgiLCJwIjoiaiJ9
      const selectedProspects = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      const selectedProspectIds = this.getProspectIds(selectedProspects)
      this.changeRating(selectedProspectIds, to)
      this.setUpSelectedProspects()
    },
    changeRating (prospectIds, rating) {
      this.$store.dispatch(CHANGE_PROSPECT_RATING, {
        ratingType: rating,
        prospectIds: prospectIds
      })
    },
    saveNewStatus (status) {
      this.$store.dispatch(CHANGE_PROSPECT_STATUS, { ...status })
    },
    changeTab (key) {
      this.$store.commit(SET_CURRENT_TAB_PROSPECTS_OVERVIEW, key)
      if (!this.prospects[this.currentTab]) {
        this.selected = {}
        return
      }
      this.resetCurrentProspects()
    },
    // Set selected prospects to false based on current tab
    setUpSelectedProspects () {
      const object = {}
      if (this.prospects[this.currentTab] && this.prospects[this.currentTab].length) {
        this.prospects[this.currentTab].forEach(
          (prospect) => (object[prospect.prospectId] = false)
        )
      }
      this.selected = object
    },
    toggleAll () {
      if (this.allProspectsAreSelected === this.isSelectAllChecked) {
        return
      }
      if (this.allProspectsAreSelected) {
        this.selected = mapValues(this.selected, (value) => !value)
        return
      }
      this.selected = mapValues(this.selected, () => true)
    },
    sendToSelectionCommittee () {
      if (this.noProspectsSelected) {
        this.$notify({
          type: 'warning',
          text: 'U hebt nog niemand geselecteerd.'
        })
        return
      }
      // ONE-376 Selecting all candidates is not reset when switching tabs
      // https://jobsrepublic-v2.atlassian.net/browse/ONE-376?atlOrigin=eyJpIjoiZDVjMTE0MTA2ODdmNGQ1YmI1Y2NjYjhkMDMyODk0YTgiLCJwIjoiaiJ9
      const selectedProspects = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      const selectedProspectIds = this.getProspectIds(selectedProspects)
      this.$store.dispatch(LOAD_VACANCY_TEAM_MEMBERS).then(() => {
        const selectionCommittee = filter(this.$store.getters[VACANCY_TEAM], [
          'lTeamRole',
          7
        ])
        modal(SendToSelectionCommitteeModal, { selectionCommittee }).then(
          (selected) => {
            if (!selected) {
              return
            }
            this.$store.dispatch(SEND_PROSPECTS_TO_SELECTION_COMMITTEE, {
              prospectIds: selectedProspectIds,
              selectionCommittee: selected
            })
          }
        )
      })
    },
    generateBulkPrint () {
      if (this.noProspectsSelected) {
        this.$notify({
          type: 'warning',
          text: 'U hebt nog niemand geselecteerd.'
        })
        return
      }
      // ONE-376 Selecting all candidates is not reset when switching tabs
      // https://jobsrepublic-v2.atlassian.net/browse/ONE-376?atlOrigin=eyJpIjoiZDVjMTE0MTA2ODdmNGQ1YmI1Y2NjYjhkMDMyODk0YTgiLCJwIjoiaiJ9
      const selectedProspects = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      const selectedProspectIds = this.getProspectIds(selectedProspects)
      modal(ConfirmYesNoModal, {
        question: 'Genereer uw bestand om te downloaden.'
      }).then((ok) => {
        if (!ok) {
          return
        }
        const selectedProspectsString = selectedProspectIds.join('_')
        const url = `/#mergeJobs/${encodeURIComponent(
          this.$route.path
        )}/vacancy/${
          this.$route.params.vacancyId
        }/prospects/${selectedProspectsString}`
        window.open(url, '_blank')
      })
    },
    sendMail (type, defaults) {
      const needSelectedProspects =
        typeof defaults.noSelectedNeeded === 'undefined'
      const selectedProspectsHaveStatus = !this.selectedProspectsLackStatus

      if (needSelectedProspects && this.noProspectsSelected) {
        this.$notify({
          type: 'warning',
          text: 'U hebt nog niemand geselecteerd.'
        })
        return
      }
      if (type !== 'general' && selectedProspectsHaveStatus) {
        this.$notify({
          type: 'warning',
          text: 'Selecteer sollicitanten zonder status'
        })
        return
      }

      // ONE-376 Selecting all candidates is not reset when switching tabs
      // https://jobsrepublic-v2.atlassian.net/browse/ONE-376?atlOrigin=eyJpIjoiZDVjMTE0MTA2ODdmNGQ1YmI1Y2NjYjhkMDMyODk0YTgiLCJwIjoiaiJ9
      // When switching tab, the prospects which where selected still show up in the selected property with value "false", when they shouldn't be included anymore in the selected property
      // The following prevents sending emails to the prospects that should not receive them in the form of a quick hotfix
      // A cleaner solution would be to refactor this component into smaller parts, to make it more manageable
      const selectedProspects = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      const selectedProspectIds = this.getProspectIds(selectedProspects)

      // defaults = {...defaults, to: }
      modal(EmailModal, { defaults }).then((info) => {
        if (!info) {
          return
        }
        const email = {
          ...info,
          to: defaults.prospectId ? [defaults.prospectId] : selectedProspectIds
        }
        this.$store.dispatch(this.emailActionConstants[type], email)
      })
    },
    sharePerEmail () {
      // ONE-376 Selecting all candidates is not reset when switching tabs
      // https://jobsrepublic-v2.atlassian.net/browse/ONE-376?atlOrigin=eyJpIjoiZDVjMTE0MTA2ODdmNGQ1YmI1Y2NjYjhkMDMyODk0YTgiLCJwIjoiaiJ9
      const selectedProspects = this.filterProspectsByTab(
        this.selected,
        this.currentTab
      )
      const ids = this.getProspectIds(selectedProspects)
      this.$store
        .dispatch(LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE, ids)
        .then(() => {
          let defaults = this.$store.getters[DEFAULT_SHARE_PER_EMAIL_TEMPLATE]
          defaults = { ...defaults, enableTo: true }
          modal(EmailModal, { defaults }).then((info) => {
            if (!info) {
              return
            }
            const email = {
              sharedProspects: ids,
              tokenAuthorizationId: defaults.tokenAuthorizationId,
              ...info
            }

            this.$store.dispatch(SEND_SHARE_PROSPECTS_EMAIL, email)
          })
        })
    },
    startNewRound () {
      this.$store.dispatch(LOAD_SELECTION_ROUND_STATS).then(() => {
        if (
          this.selectionRoundStats &&
          this.selectionRoundStats.toBeRejectedCount > 0
        ) {
          modal(ConfirmYesNoModal, {
            question:
              'Een of meerdere kandidaten in de tab niet door heeft geen eind-status. Mogelijk is deze ronde niet correct afgerond. Wenst u terug te gaan om de ronde correct af te sluiten?',
            okButtonText: 'Nee',
            cancelButtonText: 'Ja'
          }).then((ok) => {
            if (!ok) {
              return
            }
            this.$store.dispatch(START_NEW_SELECTION_ROUND).then(() =>
              this.$router.push({
                name: 'vacancy',
                params: { vacancyId: this.vacancy.vacancyId }
              })
            )
          })
          return
        }
        modal(ConfirmYesNoModal, {
          question:
            'Weet je zeker dat je een nieuwe selectieronde wilt starten?',
          okButtonText: 'ja',
          cancelButtonText: 'Nee'
        }).then((ok) => {
          if (!ok) {
            return
          }
          this.$store.dispatch(START_NEW_SELECTION_ROUND).then(() =>
            this.$router.push({
              name: 'vacancy',
              params: { vacancyId: this.vacancy.vacancyId }
            })
          )
        })
      })
    },
    setRatingTypeForProspectNavigation (key) {
      this.$store.commit(SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION, key)
    },
    deselectAllCurrentProspects () {
      this.selected = Object.keys(this.selected).reduce((acc, prospectId) => {
        acc[prospectId] = false
        return acc
      }, {})
    },
    resetCurrentProspects () {
      if (this.prospects[this.currentTab] && this.prospects[this.currentTab].length) {
        this.selected = this.prospects[this.currentTab].reduce(
          (acc, prospect) => {
            return (acc = { ...acc, [prospect.prospectId]: false })
          },
          {}
        )
      }
    },
    /**
     * unfilteredProspects expected object { prospectId : boolean }
     * returns object { prospectId : boolean }
     */
    filterProspectsByTab (unfilteredProspects, tab) {
      return this.prospects[tab]
        .filter((prospect) => unfilteredProspects[prospect.prospectId])
        .reduce((acc, prospect) => {
          return (acc = {
            ...acc,
            [prospect.prospectId]: unfilteredProspects[prospect.prospectId]
          })
        }, {})
    },
    /**
     * prospects expected object { prospectId : boolean }
     * returns Array of prospect ids
     */
    getProspectIds (prospects) {
      return Object.keys(pickBy(prospects, (prospect) => prospect)).map((id) =>
        Number(id)
      )
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_VACANCY_PROSPECTS, this.$route.params.roundId)
    this.$store.dispatch(LOAD_USERS)
    if (this.currentTab === null) {
      this.$store.commit(
        SET_CURRENT_TAB_PROSPECTS_OVERVIEW,
        PROSPRATING_UNRATED
      )
    }
    this.resetCurrentProspects()
  }
}
</script>
