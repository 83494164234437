<template>
  <b-card class="row p-5" no-body>
    <div class="pb-3">
      <h2 class="m-0">Opleiding</h2>
      <div v-if="highestLevel">Hoogst genoten opleiding</div>
      <div v-if="highestLevel">
        <strong>{{ highestLevel | formatEducationLevelAndStatus }}</strong>
      </div>
    </div>
    <div
      v-for="(degree, index) in orderedEducation"
      :key="degree.title"
      class="pb-3 pt-3"
      :class="
        orderedEducation.length > 1 && index !== orderedEducation.length - 1
          ? 'border-bottom border-medium-gray'
          : ''
      "
    >
      <div v-if="degree.schoolName">
        <strong>
          <span>{{ degree.schoolName }}</span>
          <span v-if="degree.educationLevel">
            ({{ degree.educationLevel | formatEducationLevelAndStatus }})</span
          >
        </strong>
      </div>
      <div v-if="degree.title">{{ degree.title }}</div>
      <div>
        <small class="font-weight-light">
          <span v-if="degree.startDate"
            >{{ degree.startDate | formatDate("MMM YYYY") }} -
          </span>
          <span v-if="degree.endDate">{{
            degree.endDate | formatDate("MMM YYYY")
          }}</span>
        </small>
      </div>
      <div>
        <small>{{ degree.completed | formatEducationLevelAndStatus }}</small>
      </div>
    </div>
  </b-card>
</template>

<script>
import orderBy from 'lodash/orderBy'

export default {
  props: {
    education: {
      type: Array,
      default: () => []
    },
    highestLevel: {
      type: String,
      default: ''
    }
  },
  computed: {
    orderedEducation () {
      return orderBy(this.education, ['endDate', 'startDate'], ['desc', 'desc'])
    }
  }
}
</script>
