import merge from 'lodash/merge'

import competences from './competences'
import description from './description'
import form from './form'
import prospects from './prospects/prospects'
import prospectNotifications from './prospects/notifications'
import currentProspect from './prospects/current-prospect'
import publicationChannels from './publication-channels'
import team from './team'
import vacancyNotifications from './notifications'
import overview from './overview'

export default merge(
  competences,
  description,
  form,
  publicationChannels,
  team,
  prospects,
  currentProspect,
  prospectNotifications,
  vacancyNotifications,
  overview
)
