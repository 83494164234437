<template>
  <b-modal
    v-if="notificationType"
    centered
    :title="notificationType.title"
    :visible="true"
    @ok="save"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    :cancel-title="cancelButtonText"
    :ok-title="okButtonText"
    size="lg"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div class="d-flex flex-column">
      <div class="d-flex justify-content-between">
        <b-form-group class="d-flex" label="Zichtbaar voor:">
          <b-form-radio-group
            plain
            id="radios1"
            v-model="selectedSharingType"
            :options="sharingOptions"
            name="radioOpenions"
          />
        </b-form-group>
        <div v-if="notificationType.showDueDate">
          <div>Herinner me op:</div>
          <datepicker
            v-model="dueDate"
            :language="datepickerLanguage.nl"
            name="dueDate"
            :disabled-dates="disabledDates"
          />
        </div>
      </div>
      <b-form-textarea
        :class="{ 'is-invalid': errors.has('description') }"
        v-model="description"
        name="description"
        v-validate="'required'"
        data-vv-as="Omschrijving"
        :rows="6"
        :max-rows="9"
      />
      <div class="invalid-feedback">{{ errors.first("description") }}</div>
      <div
        v-if="!hasAttachments"
        class="d-flex pb-2 pt-2 justify-content-between align-items-center"
      >
        <label for="attachment">Bijlage</label>
        <div class="w-100 d-flex align-items-start">
          <b-form-file
            ref="attachment"
            v-model="attachment"
            name="attachment"
            class="ml-1"
            browse-text="Bladeren"
            placeholder="Geen bestand gekozen"
          />
        </div>
      </div>
      <div v-if="hasAttachments" class="mt-2 d-flex flex-wrap">
        <b-btn
          variant="link"
          :href="currentNotification.attachment.urlLocal"
          target="_blank"
          class="border border-right-0 border-medium-gray bg-white"
          >{{ currentNotification.attachment.fileName }}.{{
            currentNotification.attachment.fileExtension
          }}</b-btn
        >
        <b-btn
          variant="white"
          @click="deleteAttachment"
          class="border border-left-0 border-medium-gray bg-white"
        >
          <i class="fa fa-trash-o" />
        </b-btn>
      </div>
      <div class="ml-auto">Datum: {{ date.day }}</div>
    </div>
  </b-modal>
</template>
<script>
import { SHARING_PRIVATE, SHARING_PUBLIC } from '../../constants/notification-sharing-type'
import { NOTIFTYPE_NOTE, NOTIFTYPE_TODO } from '../../constants/prospect/prospect-notification-type'
import {
  LOAD_PROSPECT_NOTE,
  LOAD_PROSPECT_TODO,
  DELETE_VACANCY_NOTIFICATION_ATTACHMENT,
  DELETE_PROSPECT_NOTIFICATION_ATTACHMENT,
  LOAD_VACANCY_NOTE,
  LOAD_VACANCY_TODO
} from '../../store/keys-actions'
import { CURRENT_NOTIFICATION } from '../../store/keys-getters'
import { SET_CURRENT_NOTIFICATION } from '../../store/keys-mutations'
import moment from 'moment'
import Datepicker from 'vuejs-datepicker'
import { nl } from 'vuejs-datepicker/dist/locale'
import isEmpty from 'lodash/isEmpty'

const now = moment()
const yesterday = now.clone().subtract(1, 'day')

export default {
  name: 'NotificationModal',
  components: {
    Datepicker
  },
  props: {
    type: {
      type: String,
      default: null
    },
    id: {
      type: Number
    },
    cancelButtonText: {
      type: String,
      default: 'Annuleren'
    },
    okButtonText: {
      type: String,
      default: 'Plaatsen'
    },
    vacancyLevelNotification: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedSharingType: SHARING_PUBLIC,
      description: null,
      attachment: null,
      dueDate: null
    }
  },
  computed: {
    sharingOptions () {
      return [{ text: 'iedereen', value: SHARING_PUBLIC }, { text: 'mijzelf', value: SHARING_PRIVATE }]
    },
    notificationType () {
      const map = {
        [NOTIFTYPE_TODO]: {
          title: this.id ? 'Edit ToDo' : 'Nieuwe ToDo', // TODO: check translation!
          loadAction: this.vacancyLevelNotification ? LOAD_VACANCY_TODO : LOAD_PROSPECT_TODO,
          deleteAction: this.vacancyLevelNotification ? DELETE_VACANCY_NOTIFICATION_ATTACHMENT : DELETE_PROSPECT_NOTIFICATION_ATTACHMENT,
          showDueDate: true
        },
        [NOTIFTYPE_NOTE]: {
          title: this.id ? 'Edit notitie' : 'Nieuwe notitie', // TODO: check translation!
          loadAction: this.vacancyLevelNotification ? LOAD_VACANCY_NOTE : LOAD_PROSPECT_NOTE,
          deleteAction: this.vacancyLevelNotification ? DELETE_VACANCY_NOTIFICATION_ATTACHMENT : DELETE_PROSPECT_NOTIFICATION_ATTACHMENT,
        }
      }
      return map[this.type]
    },
    currentNotification () {
      return this.$store.getters[CURRENT_NOTIFICATION] || {}
    },
    hasAttachments () {
      return !isEmpty(this.currentNotification) && !isEmpty(this.currentNotification.attachment)
    },
    date () {
      const date = this.currentNotification ? moment(this.currentNotification.createDate) : now
      return {
        day: date.format('DD-MM-YYYY'),
        seconds: date.format('YYYY-MM-DD[T]h:mm:ss')
      }
    },
    datepickerLanguage () {
      return {
        nl: nl
      }
    },
    disabledDates () {
      const yesterdayDate = new Date(yesterday)
      return {
        to: yesterdayDate
      }
    },
    notification () {
      return {
        ...this.currentNotification,
        ...{
          lSharingType: this.selectedSharingType,
          description: this.description,
          attachment: this.attachment,
          createDate: this.date.seconds,
          dueDate: this.dueDate
        }
      }
    }
  },
  watch: {
    currentNotification () {
      if (this.currentNotification.lSharingType) {
        this.selectedSharingType = this.currentNotification.lSharingType
      }
      this.description = this.currentNotification.description
      this.dueDate = this.currentNotification.dueDate
    }
  },
  methods: {
    clearFiles (ref) {
      this.$refs[ref].reset()
    },
    save (e) {
      e.preventDefault()
      this.$validator.validate().then((isValid) => {
        if (!isValid) {
          return
        }
        this.$emit('resolve', {
          notification: this.notification
        })
        this.$emit('remove')
      })
    },
    deleteAttachment () {
      const { attachment } = this.currentNotification
      const hasLeaveRoutePermission = confirm(`Weet u zeker dat u "${attachment.fileName}.${attachment.fileExtension}" wilt verwijderen?`)
      if (!hasLeaveRoutePermission) {
        return
      }
      this.$store.dispatch(this.notificationType.deleteAction, { notificationType: this.type, notificationId: this.id })
    }
  },
  mounted () {
    const date = now.add(2, 'day')
    this.dueDate = new Date(date)
    if (this.id) {
      const action = this.notificationType.loadAction
      this.$store.dispatch(action, this.id)
    }
  },
  destroyed () {
    this.$store.commit(SET_CURRENT_NOTIFICATION, {})
  }
}
</script>
