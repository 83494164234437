import Vue from 'vue'
import apiRequest from '../../services/api-request'
import {
  LOAD_GDPR_APPLICANTS,
  REQUEST_APPLICANT_CONSENT,
  ANONYMIZE_APPLICANTS
} from '../keys-actions'
import { SET_GDPR_APPLICANTS } from '../keys-mutations'
import {
  GDPR_EXPIRED_APPLICANTS,
  GDPR_NO_CONSENT_APPLICANTS,
  GDPR_NOT_SOON_EXPIRING_APPLICANTS,
  GDPR_SOON_EXPIRING_APPLICANTS
} from '../keys-getters'
import defaults from 'lodash/defaults'

const soon = 60 * 60 * 24 * 30
const gdprFilter = filter => {
  return state => {
    const vacancies = state.vacancies.map(vacancy => {
      const applicants = vacancy.applicants.filter(filter)
      return defaults({ applicants }, vacancy)
    })
    return vacancies.filter(vacancy => vacancy.applicants.length > 0)
  }
}

export default {
  state: {
    vacancies: []
  },
  mutations: {
    [SET_GDPR_APPLICANTS]: (state, applicants) => {
      state.vacancies = Object.freeze(applicants)
    }
  },
  getters: {
    [GDPR_NO_CONSENT_APPLICANTS]: gdprFilter(
      applicant => applicant.consentEndDate === null
    ),
    [GDPR_SOON_EXPIRING_APPLICANTS]: gdprFilter(
      applicant =>
        applicant.timeRemaining > 0 && applicant.timeRemaining <= soon
    ),
    [GDPR_NOT_SOON_EXPIRING_APPLICANTS]: gdprFilter(
      applicant => applicant.timeRemaining > 0 && applicant.timeRemaining > soon
    ),
    [GDPR_EXPIRED_APPLICANTS]: gdprFilter(
      applicant => applicant.timeRemaining && applicant.timeRemaining <= 0
    )
  },
  actions: {
    [LOAD_GDPR_APPLICANTS]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/applicants/gdpr'
      }).then(data => commit(SET_GDPR_APPLICANTS, data))
    },
    [ANONYMIZE_APPLICANTS]: ({ dispatch }, applicantIdList) => {
      const applicantString =
        applicantIdList.length > 1 ? 'sollicitanten' : 'sollicitant'

      // There is a 60 second request timeout on the loadbalancer. This means larger requests will timeout before they finish.
      // If there are more than 50 we immediately return a success message and don't await the response from the server.
      // This is a bit hacky, but it will be a lot less confusing to the end user.
      if (applicantIdList.length < 50) {
        return apiRequest({
          method: 'PUT',
          url: '/api/erecruiter-web-api/applicants/anonymize',
          data: applicantIdList
        })
          .then(() => {
            Vue.notify({
              type: 'success',
              text: `${applicantIdList.length} ${applicantString} geanonimiseerd`
            })
            return dispatch(LOAD_GDPR_APPLICANTS)
          })
          .catch(error => {
            Vue.notify({
              type: 'warning',
              text: `De sollicitanten konden niet geanonimiseerd worden. Neem contact op met de supportdesk. ${
                error.message
              }`
            })
            throw error
          })
      } else {
        apiRequest({
          method: 'PUT',
          url: '/api/erecruiter-web-api/applicants/anonymize',
          data: applicantIdList
        })
        Vue.notify({
          type: 'success',
          text: 'Sollicitanten worden op de achtergrond geanonimiseerd. Dit kan enkele minuten duren. Herlaad de pagina om het verloop te volgen.'
        })
        return Promise.resolve()
      }
    },
    [REQUEST_APPLICANT_CONSENT]: (
      { dispatch },
      { applicantIdList, body, subject }
    ) => {
      return apiRequest({
        method: 'POST',
        url: '/api/erecruiter-web-api/applicants/gdpr/askconsent',
        data: { applicantIds: applicantIdList, subject, body }
      })
        .then(() => {
          const emailString =
            applicantIdList.length > 1 ? 'berichten zijn' : 'bericht is'
          Vue.notify({
            type: 'success',
            text: `${applicantIdList.length} ${emailString} verstuurd`
          })
          return dispatch(LOAD_GDPR_APPLICANTS)
        })
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `Deze actie is niet gelukt. Neem contact op met de supportdesk. ${
              error.message
            }`
          })
          throw error
        })
    }
  }
}
