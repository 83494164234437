<template>
  <div
    class="d-flex justify-content-center align-items-center"
    :class="[{ circle }, size]"
  >
    <span class="text-white mb-0" :class="fontSize">{{ initials }}</span>
  </div>
</template>

<script>
import words from 'lodash/words'

export default {
  name: 'Avatar',
  props: {
    fullname: {
      type: String,
      default: '?'
    },
    circle: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    }
  },
  computed: {
    initials () {
      const separatedWords = words(this.fullname)
      const initials = separatedWords.map((word) => word[0].toUpperCase())

      if (initials.length > 1) {
        return initials[0] + initials.slice(-1)
      }
      if (initials.length > 0) {
        return initials[0]
      }
      return ''
    },
    fontSize () {
      if (this.size === 'sm') return 'h3'
      return 'h1'
    }
  }
}
</script>
