<template>
  <notifications position="bottom left" width="30em" :duration="10000">
    <template slot="body" slot-scope="props">
      <div class="pl-3">
        <b-alert
          show
          :variant="props.item.type"
          dismissible
          @dismissed="props.close"
        >
          <h4 v-if="props.item.title" class="alert-heading">
            {{ props.item.title }}
          </h4>
          {{props.item.text}}
        </b-alert>
      </div>
    </template>
  </notifications>
</template>

<script>
import Vue from 'vue'
import Notifications from 'vue-notification'

Vue.use(Notifications)

export default {}
</script>
