<template>
  <b-modal
    centered
    scrollable
    title="Voorbeeld"
    :visible="true"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    size="xl"
    header-border-variant="light"
    footer-border-variant="light"
    hide-footer
  >
    <div class="mx-4">
      <h4 class="px-3">{{ vacancyDetails.title }}</h4>
      <hr />
      <div class="d-flex wrap-nowrap justify-content-around px-3">
        <div v-if="hoursPerWeek" class="text-nowrap pr-2">
          <i class="fa fa-clock-o fa-lg mr-2 vacancy-icon"></i
          >{{ hoursPerWeek }}
        </div>
        <div>
          <i class="fa fa-graduation-cap  fa-lg mr-2 vacancy-icon"></i
          ><span
            v-for="(training, index) in vacancyDetails.training"
            :key="training"
            >{{ training }}
            <span v-if="index !== vacancyDetails.training.length - 1">, </span>
          </span>
        </div>
        <!-- ONE-301 Retrieve location for vacancy preview same way Gemeentebanen does -->
        <!-- <div>
          <i class="fa fa-map-marker fa-lg mr-2 vacancy-icon"></i
          >{{ currentTenant.location }}
        </div> -->
        <div v-if="salaryRange" class="text-nowrap pl-2">
          <i class="fa fa-euro fa-lg mr-2 vacancy-icon"></i
          >{{ salaryRange }}
        </div>
      </div>
      <hr />
      <div class="px-3">
        <h5 class="mb-3">Vacature</h5>
        <p class="px-3">
          <span v-html="vacancyDetails.description"></span>
        </p>
      </div>
      <div v-if="contactPerson">
        <hr />
        <div class="px-3">
          <h5 class="mb-3">Meer informatie over deze vacature?</h5>
          <p class="px-3">
            Neem contact op met {{ contactPerson.fullName }} van
            {{ currentTenant.companyDescription || currentTenant.companyName }}
            ({{ contactPerson.telephone }})
          </p>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { CURRENT_TENANT } from '../../store/keys-getters'

export default {
  name: 'PreviewVacancyModal',
  props: {
    vacancyDetails: {
      type: Object,
      required: true
    },
    contactPerson: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    currentTenant () {
      return this.$store.getters[CURRENT_TENANT]
    },
    hoursPerWeek () {
      const {
        hoursPerWeekType,
        hoursPerWeek,
        hoursPerWeekMin,
        hoursPerWeekMax
      } = this.vacancyDetails

      // hoursPerWeekType_1 == Exact (single digit) number of hours
      // hoursPerWeekType_2 == Mininum and maximum number of hours
      if (hoursPerWeekType === 'hoursPerWeekType_1') {
        return hoursPerWeek
      }
      if (hoursPerWeekMin !== '' && hoursPerWeekMax !== '') {
        return `${hoursPerWeekMin} - ${hoursPerWeekMax}`
      }
      return ''
    },
    salaryRange () {
      const { salaryMax, salaryMin } = this.vacancyDetails

      if (!salaryMax || !salaryMin) return ''

      return `${salaryMin} - ${salaryMax}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "../../style/variables.scss";

.vacancy-icon {
  color: $primary;
}
</style>
