<template>
  <div class="d-flex flex-column">
    <div>
      <strong>Berichten</strong>
    </div>
    <div class="d-flex justify-content-lg-between pt-2 pb-2">
      <b-btn
        v-if="!$route.meta.noAuthorizationNeeded"
        class="pl-0"
        variant="link"
        :class="!canCreateToDos ? 'ml-auto' : ''"
        :disabled="!canCreateNotes"
        @click="openNotification({ type: notificationType.note }, true)"
      >
        <i class="fa fa-pencil-square-o" />
        {{ notificationCount[notificationType.note] || 0 }} Notities
      </b-btn>
      <b-btn
        v-if="canCreateToDos"
        variant="link"
        class="align-self-end"
        :disabled="!canCreateToDos"
        @click="openNotification({ type: notificationType.todo }, true)"
      >
        <i class="fa fa-check-square-o" />
        {{ notificationCount[notificationType.todo] || 0 }} To Do's
      </b-btn>
    </div>
    <div class="w-100 pl-4 border-left border-medium-gray">
      <template v-if="list.length">
        <notification
          @completeTodo="completeTodo"
          @openNotification="openNotification"
          v-for="item in list"
          :key="item.entityId"
          :notification="item"
        />
      </template>
      <div v-if="!list.length">Op dit moment nog geen activiteiten.</div>
    </div>
  </div>
</template>

<script>
import Notification from './notification'
import { CREATE_VACANCY_PROSPECT_NOTE, CREATE_VACANCY_PROSPECT_TODO } from '../../constants/permissions'
import {
  CREATE_PROSPECT_NOTE,
  CREATE_PROSPECT_TODO,
  EDIT_PROSPECT_NOTE,
  EDIT_PROSPECT_TODO,
  COMPLETE_PROSPECT_TODO,
  LOAD_PROSPECT_EMAIL,
  SEND_GENERAL_EMAIL,
  LOAD_CURRENT_PROSPECT
} from '../../store/keys-actions'
import { CURRENT_PROSPECT, CURRENT_NOTIFICATION } from '../../store/keys-getters'
import { NOTIFTYPE_NOTE, NOTIFTYPE_TODO, NOTIFTYPE_EMAIL } from '../../constants/prospect/prospect-notification-type'
import NotificationModal from './notification-modal'
import EmailModal from '../email-modal'
import modal from '../../services/modal'
import countBy from 'lodash/countBy'
import isEmpty from 'lodash/isEmpty'

const actionName = {
  [NOTIFTYPE_NOTE]: {
    create: CREATE_PROSPECT_NOTE,
    edit: EDIT_PROSPECT_NOTE
  },
  [NOTIFTYPE_TODO]: {
    create: CREATE_PROSPECT_TODO,
    edit: EDIT_PROSPECT_TODO
  }
}

export default {
  components: {
    Notification
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    canCreateNotes () {
      return this.hasPermission(CREATE_VACANCY_PROSPECT_NOTE)
    },
    canCreateToDos () {
      return this.hasPermission(CREATE_VACANCY_PROSPECT_TODO)
    },
    notificationType () {
      return {
        note: NOTIFTYPE_NOTE,
        todo: NOTIFTYPE_TODO
      }
    },
    notificationCount () {
      return countBy(this.list, 'lNotificationType')
    },
    currentProspect () {
      return this.$store.getters[CURRENT_PROSPECT]
    },
    currentProspectFullname () {
      return isEmpty(this.currentProspect) ? '' : `${this.currentProspect.firstname} ${this.currentProspect.middleName ? this.currentProspect.middleName + ' ' : ''}${this.currentProspect.lastname}`
    },
    currentNotification () {
      return this.$store.getters[CURRENT_NOTIFICATION]
    }
  },
  methods: {
    saveNotificationChanges ({ actionName, type, id }) {
      modal(NotificationModal, { type, id }).then(({ notification }) => {
        if (!notification) {
          return
        }
        return this.$store.dispatch(actionName, { notification, roundId: +this.$route.params.roundId })
      })
    },
    async openNotification ({ type, id }, create) {
      if (type === NOTIFTYPE_EMAIL) {
        await this.$store.dispatch(LOAD_PROSPECT_EMAIL, id)
        const defaults = {
          ...this.parseEmailNotification(this.currentNotification),
          to: this.currentProspectFullname,
          prospectId: this.currentProspect.prospectId,
          noSignature: true
        }
        modal(EmailModal, { defaults }).then(
          (info) => {
            if (!info) {
              return
            }
            const email = Object.assign(info, { to: [this.currentProspect.prospectId] })
            this.$store.dispatch(SEND_GENERAL_EMAIL, email).then(() => {
              this.$store.dispatch(LOAD_CURRENT_PROSPECT, {
                prospectId: this.currentProspect.prospectId,
                roundId: +this.$route.params.roundId
              })
            })
          }
        )
        return
      }
      const actionType = create ? 'create' : 'edit'
      if (actionName[type] && actionName[type][actionType]) this.saveNotificationChanges({ actionName: actionName[type][actionType], type, id })
    },
    completeTodo ({ isDone, id }) {
      return this.$store.dispatch(COMPLETE_PROSPECT_TODO, { isDone, id })
    },
    parseEmailNotification (emailNotification) {
      return {
        ...emailNotification,
        to: emailNotification.to.join(', '),
        cc: emailNotification.cc.join(', '),
        bcc: emailNotification.bcc.join(', ')
      }
    }
  }
}
</script>
