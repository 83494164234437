import isEmpty from 'lodash/isEmpty'
export default (value, [min]) => {
  if (value === undefined || value === null) {
    return false
  }
  if (!Array.isArray(value) || isEmpty(value)) {
    return false
  }
  return value.length >= min
}
