import { render, staticRenderFns } from "./release-notes-list-item.vue?vue&type=template&id=48bca7ab&scoped=true&"
var script = {}
import style0 from "./release-notes-list-item.vue?vue&type=style&index=0&id=48bca7ab&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48bca7ab",
  null
  
)

export default component.exports