<template>
  <div class="row pt-4 pb-3">
    <div class="col-12">
      <div class="row no-gutters pb-2 align-items-baseline">
        <h1 class="vacancy-title font-weight-light text-dark pr-3">
          Release-opmerkingen
        </h1>
      </div>
      <div class="row no-gutter"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReleaseNotesHeader'
}
</script>
