<template>
  <div class="row">
    <h5 class="col-12">Ontwerp het sollicitatie formulier</h5>
    <p class="col-12">
      Kies de velden voor het sollicitatie formulier van je vacature.
    </p>

    <div class="col-12 flex-md-row" v-if="privateQuestions.length">
      <div class="row p-2 justify-content-between align-items-start">
        <table
          class="col-12 col-md-9 table table-bordered bg-white order-1 order-md-0"
          style="max-width: 550px;"
        >
          <tr
            v-for="(question, index) in privateQuestions"
            :key="question.name"
            :class="{ 'bg-light': !question.editable }"
          >
            <td style="vertical-align: middle;">{{ question.question }}</td>
            <td>
              <question v-model="privateQuestions[index]" />
            </td>
          </tr>
        </table>

        <div
          class="mb-3 d-flex flex-column bg-white align-items-center align-items-md-start order-0 order-md-1"
          style="max-width: 550px;"
        >
          <b-btn
            variant="outline-primary"
            class="text-center text-md-left px-4 py-3"
            @click="previewForm"
          >
            <span><i class="fa fa-eye" /> Voorbeeld</span>
          </b-btn>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex pt-5">
      <b-btn variant="primary" class="mr-auto" @click="previous">
        <i
          class="fa fa-fw"
          :class="{
            'fa-spinner fa-spin': loading,
            'fa-chevron-left': !loading
          }"
        />
        Vorige stap
      </b-btn>
      <b-link variant="link" class="p-2" @click="save"
        >Tussentijds opslaan</b-link
      >
      <b-btn variant="primary" @click="next">
        Volgende stap
        <i
          class="fa fa-fw"
          :class="{
            'fa-spinner fa-spin': loading,
            'fa-chevron-right': !loading
          }"
        />
      </b-btn>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import Question from './question.vue'
import PreviewFormModal from './preview-form-modal'
import { LOADING, VACANCY_FORM } from '../../../store/keys-getters'
import { LOAD_VACANCY_FORM, SAVE_VACANCY_FORM } from '../../../store/keys-actions'
import modal from '../../../services/modal'

export default {
  name: 'VacancyForm',
  components: {
    Question
  },
  data () {
    return {
      privateQuestions: []
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    questions () {
      return this.$store.getters[VACANCY_FORM].config
    }
  },
  watch: {
    questions () {
      this.privateQuestions = cloneDeep(this.questions)
    }
  },
  methods: {
    previewForm () {
      modal(PreviewFormModal, { questions: this.privateQuestions })
    },
    save () {
      if (isEqual(this.privateQuestions, this.questions)) {
        return Promise.resolve()
      }

      return this.$store.dispatch(SAVE_VACANCY_FORM, { config: this.privateQuestions })
    },
    next () {
      return this.save().then(() => this.$router.push({ name: 'vacancyTeam' }))
    },
    previous () {
      return this.save().then(() =>
        this.$router.push({
          name: 'vacancyDescription'
        })
      )
    }
  },
  created () {
    this.$store.dispatch(LOAD_VACANCY_FORM)
  }
}
</script>
