<template>
  <b-navbar toggleable="lg" type="dark" variant="dark">
    <div
      class="nav-wrapper d-flex flex-nowrap align-items-center justify-content-between"
    >
      <b-navbar-toggle target="nav_collapse" />

      <b-navbar-brand href="#" class="mr-0 mr-md-3">
        <router-link
          :to="!$route.meta.noAuthorizationNeeded ? { name: 'home' } : {}"
          exact
        >
          <img src="@/assets/images/Logo_ONE_RGB.svg" style="height: 40px;" />
        </router-link>
      </b-navbar-brand>
    </div>

    <b-collapse
      v-if="!$route.meta.noAuthorizationNeeded"
      is-nav
      id="nav_collapse"
    >
      <b-navbar-nav v-if="isLoggedIn">
        <b-nav-item :to="{ name: 'home' }" href="#" text-white
          >Vacatures</b-nav-item
        >
        <b-nav-item v-if="showInsights" :to="{ name: 'reports' }" text-white
          >Inzichten</b-nav-item
        >
        <b-nav-item :to="{ name: 'users' }" text-white>Gebruikers</b-nav-item>
        <b-nav-item v-if="showGdpr" :to="{ name: 'gdprOverview' }" text-white
          >AVG</b-nav-item
        >
      </b-navbar-nav>

      <hr class="d-lg-none border-darl-gray" />

      <b-navbar-nav class="ml-auto" v-if="isLoggedIn">
        <b-nav-item
          class="nav-item--separate border-darl-gray double-border"
          href="https://jobsrepublicone.zendesk.com/hc/nl"
          target="_blank"
          text-white
        >
          <i class="fa fa-question fa-lg mx-lg-2" />
        </b-nav-item>

        <hr class="d-lg-none border-darl-gray w-100" />

        <b-nav-item disabled right v-if="!otherTenants.length && tenantName">
          {{ tenantName }}
        </b-nav-item>

        <b-nav-item-dropdown
          v-if="otherTenants.length"
          class="nav-item--separate border-darl-gray"
        >
          <span slot="button-content" class="text-darl-gray mx-lg-2">
            {{ tenantName }}
            <i class="fa fa-caret-down" />
          </span>
          <b-dropdown-item
            v-for="tenant in otherTenants"
            :key="tenant.companyName"
            @click="switchTenant(tenant)"
          >
            {{ tenant.companyDescription }}
          </b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right >
          <span slot="button-content" class="mx-lg-2">
            {{ fullName || "User" | truncate(18) }}
            <i class="fa fa-caret-down" />
          </span>
          <b-dropdown-item :to="{ name: 'userProfile' }"
            >Profiel</b-dropdown-item
          >
          <b-dropdown-item v-if="isAdminUser" href="/admin/#organizations"
            >Terug naar Admin</b-dropdown-item
          >
          <b-dropdown-item @click="logout">Uitloggen</b-dropdown-item>
        </b-nav-item-dropdown>

      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
import { IS_ADMIN, CURRENT_USER } from '../store/keys-getters'
import { SWITCH_TENANT, LOGOUT } from '../store/keys-actions'
import { VIEW_REPORTING, CREATE_NEW_VACANCY } from '../constants/permissions'
import SearchInput from './main-search/search-input.vue'
import compact from 'lodash/compact'
import filter from 'lodash/filter'
import get from 'lodash/get'

export default {
  name: 'mainHeader',
  components: {
    SearchInput
  },
  computed: {
    user () {
      return this.$store.getters[CURRENT_USER] || {}
    },
    isLoggedIn () {
      return !!this.user.username
    },
    isAdminUser () {
      return this.$store.getters[IS_ADMIN]
    },
    fullName () {
      return compact([
        this.user.firstname,
        this.user.middleName,
        this.user.lastname
      ]).join(' ')
    },
    currentTenant () {
      return get(this.$store, 'state.tenants.currentTenant', {})
    },
    tenantName () {
      return this.currentTenant.companyDescription
    },
    otherTenants () {
      const currentTenantName = this.currentTenant.companyName
      const availableTenants = get(
        this.$store,
        'state.tenants.availableTenants'
      )
      return filter(
        availableTenants,
        (tenant) => tenant.companyName !== currentTenantName
      )
    },
    showInsights () {
      // TODO ONE-288 A specific permission should handle access to vacancy report
      return (
        this.hasPermission(VIEW_REPORTING) &&
        this.hasPermission(CREATE_NEW_VACANCY)
      )
    },
    showGdpr () {
      return this.hasPermission(CREATE_NEW_VACANCY)
    }
  },
  methods: {
    switchTenant (tenant) {
      this.$store.dispatch(SWITCH_TENANT, tenant).then(() => {
        this.$router.go(this.$router.currentRoute)
      })
    },
    logout () {
      this.$store.dispatch(LOGOUT)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.nav-item--separate {
  border: 0px;
  @include media-breakpoint-up(lg) {
    border-right: 1px solid;
  }
}
.nav-wrapper {
  width: 100%;
  @include media-breakpoint-up(lg) {
    width: initial;
  }
  @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
    margin-right: 50px;
  }
}
.search-input-wrapper {
  position: relative;
  .arrow-up {
    $arrow-size: 6px;
    position: relative;
    top: 1px;
    left: 229px;
    width: 0;
    height: 0;
    border-left: $arrow-size solid transparent;
    border-right: $arrow-size solid transparent;
    border-bottom: $arrow-size solid rgb(255, 255, 255);
    z-index: $zindex-popover + 1;
  }
  .search-input {
    min-width: 335px;
    position: absolute;
    z-index: $zindex-popover;
    @include media-breakpoint-up(lg) {
      top: 42px;
      right: -64px;
    }
  }
}
</style>
