<template>
  <b-modal
    centered
    title="Kopieër naar andere vacature"
    :visible="true"
    @ok="$emit('resolve', selected)"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    body-class="modal-scroll"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div>
      <b-form-radio-group
        stacked
        id="radios2"
        v-model="selected"
        name="radioSubComponent"
      >
        <b-form-radio
          v-for="vacancy in vacancies"
          :key="vacancy.vacancyId"
          :value="vacancy.vacancyId"
          >{{ vacancy.title }}({{ vacancy.vacancyId }})</b-form-radio
        >
      </b-form-radio-group>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'CopyToOtherVacancyModal',
  props: {
    vacancies: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      selected: null
    }
  }
}
</script>
