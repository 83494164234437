import store from './store'
import Vue from 'vue'
import {
  VACANCY,
  USER_PERMISSIONS,
  VACANCY_TEAM_PERMISSIONS
} from './store/keys-getters'
import { debug } from './services/log'
import {
  EDIT_VACANCY_ENTRY,
  EDIT_FAVORITE_TEMPLATE
} from './constants/permissions'
import educationLevelLabels from './constants/labels/education-level-and-status'
import { RECRUITER } from './constants/roles'
import { CONFIGURABLE_FORM } from './constants/modules'
import pickBy from 'lodash/pickBy'
import some from 'lodash/some'
import includes from 'lodash/includes'
import moment from 'moment'
import truncate from 'lodash/truncate'

Vue.mixin({
  methods: {
    hasPermission (permission) {
      const vacancy = store.getters[VACANCY]
      const permissions = [].concat(store.getters[USER_PERMISSIONS], store.getters[VACANCY_TEAM_PERMISSIONS].permissions || [])

      if (includes(permissions, permission)) {
        if (store.getters.getDebugStatus) {
          debug(
            `permission granted by team permissions for permission "${permission}" in vacancy ${
              vacancy.vacancyId
            }`
          )
        }
        return true
      }

      if (store.getters.getDebugStatus) {
        debug(
          `permission NOT granted for permission "${permission}" in vacancy "${
            vacancy.vacancyId
          }"`
        )
      }
      return false
    },
    hasRole (role) {
      return includes(this.roles, role)
    },
    hasSomeOfRoles (roles) {
      return some(roles, role => this.hasRole(role))
    }
  },
  computed: {
    roles () {
      // todo: create roles on the backend
      const fakeRolePermissions = {
        [RECRUITER]: () => this.hasRole(EDIT_VACANCY_ENTRY)
      }

      return Object.keys(
        pickBy(fakeRolePermissions, (permission, role) =>
          this.hasPermission(role)
        )
      )
    },
    modules () {
      // todo: create modules on the backend
      const fakeModulePermissions = {
        [CONFIGURABLE_FORM]: () => this.hasRole(EDIT_FAVORITE_TEMPLATE)
      }

      return Object.keys(
        pickBy(fakeModulePermissions, (permission, role) =>
          this.hasPermission(role)
        )
      )
    }
  },
  filters: {
    truncate (string = '', length = 20) {
      return truncate(string, { length, separator: ' ' })
    },
    formatDate (date, format = 'L') {
      if (!date) {
        return ''
      }
      return moment
        .utc(date)
        .local()
        .format(format)
    },
    formatEducationLevelAndStatus (level) {
      return educationLevelLabels[level] || ''
    }
  }
})
