import Vue from 'vue'
import {
  DELETE_VACANCY_ATTACHMENT,
  DELETE_VACANCY_DESCRIPTION_TEMPLATE,
  LOAD_SELECTION_ROUND_STATS,
  LOAD_VACANCY,
  LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS,
  LOAD_VACANCY_DESCRIPTION_TEMPLATES,
  LOAD_VACANCY_PROSPECTS,
  SAVE_VACANCY_DESCRIPTION,
  SAVE_VACANCY_TEMPLATE,
  START_NEW_SELECTION_ROUND,
  UPLOAD_VACANCY_ATTACHMENT
} from '../../keys-actions'
import {
  SET_SELECTION_ROUND_STATS,
  SET_TEAM_PERMISSIONS,
  SET_VACANCY,
  SET_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS,
  SET_VACANCY_ID,
  SET_VACANCY_ROUNDS
} from '../../keys-mutations'
import apiRequest from '../../../services/api-request'
import {
  SELECTION_ROUND_STATS,
  VACANCY,
  VACANCY_DESCRIPTION_DROPDOWN_OPTIONS,
  VACANCY_TEAM_PERMISSIONS,
  VACANCY_ROUNDS,
  IS_VACANCY_ARCHIVED
} from '../../keys-getters'
import map from 'lodash/map'
import head from 'lodash/head'
import saveAttachment from '../../../services/save-attachment'

export default {
  state: {
    vacancy: {
      vacancyId: null
    },
    teamPermissions: [],
    rounds: [],
    selectionRoundStats: {},
    dropdownOptions: {}
  },
  mutations: {
    [SET_VACANCY_ID]: (state, vacancyId) =>
      (state.vacancy.vacancyId = vacancyId),
    [SET_TEAM_PERMISSIONS]: (state, permissions) =>
      (state.teamPermissions = permissions),
    [SET_VACANCY]: (state, vacancy) => (state.vacancy = vacancy),
    [SET_VACANCY_ROUNDS]: (state, rounds) => (state.rounds = rounds),
    [SET_SELECTION_ROUND_STATS]: (state, stats) =>
      (state.selectionRoundStats = stats),
    [SET_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS]: (state, options) =>
      (state.dropdownOptions = options)
  },
  getters: {
    [VACANCY]: state => state.vacancy,
    [VACANCY_TEAM_PERMISSIONS]: state => state.teamPermissions,
    [SELECTION_ROUND_STATS]: state => state.selectionRoundStats,
    [VACANCY_DESCRIPTION_DROPDOWN_OPTIONS]: state => state.dropdownOptions,
    [VACANCY_ROUNDS]: state => state.rounds,
    [IS_VACANCY_ARCHIVED]: state =>
      [
        'VACANCYSTATUS_WITHDRAWN',
        'VACANCYSTATUS_CLOSED',
        'VACANCYSTATUS_MIGRATED'
      ].includes(state.vacancy.status)
  },
  actions: {
    [LOAD_VACANCY]: async ({ commit }, vacancyId) => {
      commit(SET_VACANCY_ID, vacancyId)
      try {
        const vacancy = await apiRequest({
          method: 'GET',
          url: `/api/erecruiter-web-api/vacancies/${vacancyId}`
        })
        vacancy.status = vacancy.lStatus
        const rounds = await apiRequest({
          method: 'GET',
          url: `/api/erecruiter-web-api/vacancies/${vacancyId}/selection/rounds`
        })
        const teamPermissions = await apiRequest({
          method: 'POST',
          url: '/api/erecruiter-web-api/teamPermissions',
          data: `"${vacancyId}"`,
          headers: { 'Content-type': 'application/json' }
        })
        vacancy.latestRoundId = (head(rounds) || {}).roundId

        commit(SET_VACANCY_ROUNDS, rounds)
        commit(SET_VACANCY, vacancy)
        commit(SET_TEAM_PERMISSIONS, teamPermissions)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de vacature niet laden. ${err.message}`
        })
      }
    },
    [DELETE_VACANCY_DESCRIPTION_TEMPLATE]: async ({ dispatch }, templateId) => {
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/vacancyTemplates/${templateId}`
        })
        Vue.notify({
          type: 'success',
          text: 'De template is verwijderd.'
        })
        dispatch(LOAD_VACANCY_DESCRIPTION_TEMPLATES)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De template kon op dit moment niet worden verwijderd. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS]: async ({ commit }) => {
      try {
        const dropdownOptions = await apiRequest({
          method: 'POST',
          url: 'api/erecruiter-web-api/lookups/lists',
          data: [
            'discipline',
            'jobCategory',
            'educationLevel',
            'careerLevel',
            'employmentType',
            'hoursPerWeekType',
            'salaryScale',
            'salaryForPeriod',
            'appSrc'
          ]
        })
        commit(SET_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS, dropdownOptions)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is iets fout gegaan. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [DELETE_VACANCY_ATTACHMENT]: async (
      { dispatch, getters },
      attachmentId
    ) => {
      const { vacancyId } = getters[VACANCY]
      try {
        await apiRequest({
          method: 'DELETE',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/attachments/${attachmentId}`
        })
        dispatch(LOAD_VACANCY, vacancyId)
        Vue.notify({
          type: 'success',
          text: 'Het bestand is verwijderd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Het bestand kan niet worden verwijderd. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [LOAD_SELECTION_ROUND_STATS]: async ({ getters, commit }, round) => {
      const { vacancyId, latestRoundId } = getters[VACANCY]
      const roundId = round || latestRoundId
      try {
        const selectionRoundStats = await apiRequest({
          method: 'GET',
          url: `api/erecruiter-web-api/vacancies/${vacancyId}/prospects/todoInfo/selectionRounds/${roundId}`
        })
        commit(SET_SELECTION_ROUND_STATS, selectionRoundStats)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `We konden de statistieken niet laden. ${err.message}`
        })
      }
    },
    [START_NEW_SELECTION_ROUND]: async ({ getters, dispatch }) => {
      const vacancy = getters[VACANCY]
      try {
        await apiRequest({
          method: 'PUT',
          url: `api/erecruiter-web-api/vacancies/${
            vacancy.vacancyId
          }?action=prepareFinishSelection&startNewRound=true`,
          data: vacancy
        })
        dispatch(LOAD_VACANCY_PROSPECTS)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Een nieuwe round kon niet worden gestaart. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
      }
    },
    [SAVE_VACANCY_TEMPLATE]: async (
      _,
      { title, description }
    ) => /* eslint-disable-line no-empty-pattern */ {
      try {
        await apiRequest({
          method: 'POST',
          url: 'api/erecruiter-web-api/vacancyTemplates',
          data: {
            title,
            description
          }
        })
        Vue.notify({
          type: 'success',
          text: 'De nieuwe template is opgeslagen'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De vacature template is niet opgeslagen. ${err.message}`
        })
      }
    },
    [UPLOAD_VACANCY_ATTACHMENT]: async ({ getters, dispatch }, documents) => {
      const { vacancyId } = getters[VACANCY]
      const requests = map(documents, item =>
        saveAttachment(
          item,
          `api/erecruiter-web-api/vacancies/${vacancyId}/attachments`
        )
      )
      try {
        await Promise.all(requests)
        dispatch(LOAD_VACANCY, vacancyId)
        Vue.notify({
          type: 'success',
          text:
            requests.length > 1
              ? 'De bestanden zijn toegevoegd'
              : 'Het bestand is toegevoegd.'
        })
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `Er is een fout opgetreden tijdens het uploaden van de bijlage. ${
            err.message
          }`
        })
      }
    },
    [SAVE_VACANCY_DESCRIPTION]: async (
      { getters, commit },
      { vacancy, publish }
    ) => {
      const { vacancyId } = getters[VACANCY]
      const url = `api/erecruiter-web-api/vacancies/${vacancyId}${
        publish ? '?action=edit' : ''
      }`
      try {
        const response = await apiRequest({
          method: 'PUT',
          url: url,
          data: vacancy
        })
        Vue.notify({
          type: 'success',
          text: 'De vacature is opgeslagen'
        })
        commit(SET_VACANCY, response)
      } catch (err) {
        Vue.notify({
          type: 'warning',
          text: `De vacature kon niet worden opgeslagen. Probeer later nog eens of neem contact op met Jobsrepublic. ${
            err.message
          }`
        })
        throw err
      }
    }
  }
}
