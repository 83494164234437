import apiRequest from '../../services/api-request'
import Vue from 'vue'
import router from '../../router'
import deburr from 'lodash/deburr'
import kebabCase from 'lodash/kebabCase'
import extend from 'lodash/extend'
import { error } from '../../services/log'
import {
  CREATE_VACANCY,
  LOAD_VACANCY_LIST,
  LOAD_ARCHIVED_VACANCIES,
  LOAD_VACANCY_DESCRIPTION_TEMPLATES
} from '../keys-actions'
import {
  SET_OPEN_VACANCIES,
  SET_ARCHIVED_VACANCIES,
  SET_VACANCY_DESCRIPTION_TEMPLATES
} from '../keys-mutations'
import {
  OPEN_VACANCIES,
  ARCHIVED_VACANCIES,
  OPEN_VACANCY_COUNT,
  ARCHIVED_VACANCY_COUNT,
  ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER,
  OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER,
  ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER,
  VACANCY_DESCRIPTION_TEMPLATES,
  PENDING_TODO_COUNT_BY_VACANCY_ID,
  PENDING_TODOS
} from '../keys-getters'

export default {
  state: {
    vacancyList: [],
    openVacancies: [],
    archivedVacancies: [],
    openCount: null,
    archivedCount: null,
    templates: []
  },
  mutations: {
    [SET_OPEN_VACANCIES]: (state, vacancies) => {
      const vacanciesWithSearchKeys = vacancies.map(vacancy => {
        const deburredName = deburr(vacancy.title)
        const deburredId = deburr(vacancy.vacancyId)
        const searchKey = kebabCase(deburredName.concat(deburredId))
        const uppercaseTitle = vacancy.title.toUpperCase()
        return extend({ searchKey, uppercaseTitle }, vacancy)
      })
      state.openVacancies = Object.freeze(vacanciesWithSearchKeys)
    },
    [SET_ARCHIVED_VACANCIES]: (state, vacancies) => {
      const vacanciesWithSearchKeys = vacancies.map(vacancy => {
        const deburredName = deburr(vacancy.title)
        const deburredId = deburr(vacancy.vacancyId)
        const searchKey = kebabCase(deburredName.concat(deburredId))
        const uppercaseTitle = vacancy.title.toUpperCase()
        return extend({ searchKey, uppercaseTitle }, vacancy)
      })
      state.archivedVacancies = Object.freeze(vacanciesWithSearchKeys)
    },
    [SET_VACANCY_DESCRIPTION_TEMPLATES]: (state, templates) => {
      state.templates = templates
    }
  },
  getters: {
    [OPEN_VACANCIES]: state => state.openVacancies,
    [ARCHIVED_VACANCIES]: state => state.archivedVacancies,
    [OPEN_VACANCY_COUNT]: (state, getters) =>
      getters[OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER].length,
    [ARCHIVED_VACANCY_COUNT]: (state, getters) =>
      getters[ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER].length,
    [OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]: (state, getters) =>
      getters[OPEN_VACANCIES].filter(vacancy => vacancy.userIsTeamMember),
    [ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]: (state, getters) =>
      getters[ARCHIVED_VACANCIES].filter(vacancy => vacancy.userIsTeamMember),
    [ALL_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]: (state, getters) =>
      getters[OPEN_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]
        .concat(getters[ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]),
    [VACANCY_DESCRIPTION_TEMPLATES]: state => state.templates,
    [PENDING_TODO_COUNT_BY_VACANCY_ID]: state =>
      state.openVacancies.reduce((acc, vacancy) => {
        const pendingTodoCount = {
          total: 0,
          public: {
            total: 0,
            pastDueDate: 0,
            withinDueDate: 0
          }
        }
        pendingTodoCount.private = { ...pendingTodoCount.public }

        vacancy.todos.forEach(todo => {
          if (todo.done) return

          pendingTodoCount.total++

          const isDueDatePast = Date.parse(todo.dueDate) > Date.now()

          if (todo.lSharingType === 'PUBLIC') {
            pendingTodoCount.public.total++
            if (isDueDatePast) {
              pendingTodoCount.public.pastDueDate++
            } else {
              pendingTodoCount.public.withinDueDate++
            }
          }

          if (todo.lSharingType === 'PRIVATE') {
            pendingTodoCount.private.total++
            if (isDueDatePast) {
              pendingTodoCount.private.pastDueDate++
            } else {
              pendingTodoCount.private.withinDueDate++
            }
          }
        })

        acc[vacancy.vacancyId] = pendingTodoCount

        return acc
      }, {}),
    [PENDING_TODOS]: state => {
      return state.openVacancies.reduce((todos, vacancy) => {
        if (!vacancy.todos.length) return todos
        todos.push(
          ...vacancy.todos
            // we need only the non done (pending) todos
            .filter(todo => !todo.done)
            // add vacancyId and vacancyTitle to the todo object
            .map(todo => ({
              ...todo,
              vacancyId: vacancy.vacancyId,
              vacancyTitle: vacancy.title
            }))
        )
        // Sort the todos from older to newer due date
        return todos.sort(
          (a, b) => Date.parse(a.dueDate) - Date.parse(b.dueDate)
        )
      }, [])
    }
  },
  actions: {
    [LOAD_VACANCY_LIST]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/vacancies/open/v2'
      })
        .then(vacancies => {
          commit(SET_OPEN_VACANCIES, vacancies)
        })
        .catch(() => {
          error('could not load /api/erecruiter-web-api/vacancies/v2')
        })
    },
    [LOAD_ARCHIVED_VACANCIES]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/vacancies/archived/v2'
      })
        .then(vacancies => {
          commit(SET_ARCHIVED_VACANCIES, vacancies)
        })
        .catch(() => {
          error('could not load /api/erecruiter-web-api/vacancies/v2')
        })
    },
    [CREATE_VACANCY]: ({ dispatch }, title) => {
      return apiRequest({
        method: 'POST',
        url: '/api/erecruiter-web-api/vacancies',
        data: { title }
      }).then(data =>
        dispatch(LOAD_VACANCY_LIST).then(() =>
          router.push({
            name: 'vacancyDescription',
            params: { vacancyId: data.vacancyId }
          })
        )
      )
    },
    [LOAD_VACANCY_DESCRIPTION_TEMPLATES]: ({ commit }) => {
      return apiRequest({
        method: 'GET',
        url: '/api/erecruiter-web-api/vacancyTemplates'
      })
        .then(data => {
          commit(SET_VACANCY_DESCRIPTION_TEMPLATES, data)
        })
        .catch(error => {
          Vue.notify({
            type: 'warning',
            text: `De omschrijving templates konden op dit moment niet worden geladen. Probeer later nog eens of neem contact op met Jobsrepublic. ${error.message}`
          })
          return null
        })
    }
  }
}
