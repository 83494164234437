<template>
  <div class="row justify-content-around align-items-center">
    <b-button-group class="m-2">
      <b-btn
        :disabled="!value.editable"
        :style="value.editable ? '' : { cursor: 'default' }"
        @click="privateValue.enabled = true"
        :variant="privateValue.enabled ? 'success' : 'outline-success'"
      >
        Aan
      </b-btn>
      <b-btn
        :disabled="!value.editable"
        :style="value.editable ? '' : { cursor: 'default' }"
        @click="privateValue.enabled = false"
        :variant="!privateValue.enabled ? 'danger' : 'outline-danger'"
      >
        Uit
      </b-btn>
    </b-button-group>
    <b-form-checkbox
      class="m-2"
      plain
      :disabled="!value.editable || !value.enabled"
      v-model="privateValue.validation.required"
      :value="true"
      :unchecked-value="false"
    >
      Verplicht veld
    </b-form-checkbox>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

export default {
  name: 'VacancyFormQuestion',
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      privateValue: cloneDeep(this.value)
    }
  },
  watch: {
    privateValue: {
      handler: function () {
        if (isEqual(this.privateValue, this.value)) {
          return
        }
        this.$emit('input', this.privateValue)
      },
      deep: true
    },
    value: {
      handler: function () {
        this.privateValue = cloneDeep(this.value)
      },
      deep: true
    }
  }
}
</script>
