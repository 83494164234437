export const NOTIFTYPE_TASK = 'NOTIFTYPE_TASK'
export const NOTIFTYPE_TIMESTAMP = 'NOTIFTYPE_TIMESTAMP'
export const NOTIFTYPE_EMAIL = 'NOTIFTYPE_EMAIL'
export const NOTIFTYPE_NOTE = 'NOTIFTYPE_NOTE'
export const NOTIFTYPE_TODO = 'NOTIFTYPE_TODO'
export const NOTIFTYPE_SOCIALMEDIA = 'NOTIFTYPE_SOCIALMEDIA'
export const NOTIFTYPE_TEAM_MEMBER_ADDED = 'NOTIFTYPE_TEAM_MEMBER_ADDED'
export const NOTIFTYPE_TEAM_MEMBER_REMOVED = 'NOTIFTYPE_TEAM_MEMBER_REMOVED'
export const NOTIFTYPE_ROUND_END = 'NOTIFTYPE_ROUND_END'
export const NOTIFTYPE_MOBILITY = 'NOTIFTYPE_MOBILITY'
