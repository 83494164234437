<template>
  <div>
    <search-input v-model="userSearch"></search-input>
    <div class="d-flex">
      <b-form-select v-model="sortMethod" class="mb-3 mr-3">
        <option :value="null" disabled>-- Sorteer op --</option>
        <option value="last">Laatst aangemaakt</option>
        <option value="alphabetical">A - Z</option>
      </b-form-select>
      <b-form-select
        :options="filterOptions"
        v-model="selectedFilter"
        class="mb-4 "
      />
    </div>
    <div>
      <b-list-group>
        <b-list-group-item
          class="alternating-color-list"
          v-for="vacancy in sortedVacancyList"
          :key="vacancy.vacancyId"
        >
          <archive-vacancy-list-item :vacancy="vacancy" />
        </b-list-group-item>
        <div class="p-3" v-if="sortedVacancyList.length < 1 && !loading">
          Er zijn geen vacatures gevonden binnen jouw filter.
        </div>
      </b-list-group>
    </div>
  </div>
</template>

<script>
import sortBy from 'lodash/sortBy'
import deburr from 'lodash/deburr'
import words from 'lodash/words'
import every from 'lodash/every'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { LOADING, ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER } from '../../store/keys-getters'
import { LOAD_ARCHIVED_VACANCIES } from '../../store/keys-actions'
import { VACANCYSTATUS_CLOSED, VACANCYSTATUS_MIGRATED, VACANCYSTATUS_WITHDRAWN } from '../../constants/vacancy-status'
import ArchiveVacancyListItem from './archive-vacancy-list-item'
import SearchInput from '../search-input'

export default {
  name: 'ArchiveVacancyList',
  components: {
    ArchiveVacancyListItem,
    SearchInput
  },
  data () {
    return {
      sortMethod: 'last',
      selectedFilter: 'all',
      userSearch: ''
    }
  },
  computed: {
    loading () {
      return this.$store.getters[LOADING]
    },
    allVacancies () {
      return this.$store.getters[ARCHIVED_VACANCIES_WITH_CURRENT_USER_AS_TEAM_MEMBER]
    },
    withdrawnVacancies () {
      return this.allVacancies.filter((vacancy) => vacancy.status === 'WITHDRAWN')
    },
    closedVacancies () {
      return this.allVacancies.filter((vacancy) => vacancy.status === 'CLOSED')
    },
    migratedVacancies () {
      return this.allVacancies.filter((vacancy) => vacancy.status === 'MIGRATED')
    },
    filterOptions () {
      return [
        {
          text: '-- Kies filter --',
          value: null,
          disabled: true
        },
        {
          text: 'Aangenomen',
          value: VACANCYSTATUS_CLOSED
        },
        {
          text: 'Ingetrokken',
          value: VACANCYSTATUS_WITHDRAWN
        },
        {
          text: 'Import',
          value: VACANCYSTATUS_MIGRATED
        },
        {
          text: 'Alle',
          value: 'all'
        }
      ]
    },
    filteredVacancyList () {
      const map = {
        VACANCYSTATUS_CLOSED: this.closedVacancies,
        VACANCYSTATUS_WITHDRAWN: this.withdrawnVacancies,
        VACANCYSTATUS_MIGRATED: this.migratedVacancies,
        all: this.allVacancies
      }
      return map[this.selectedFilter]
    },
    deburredUserSearch () {
      return words(deburr(this.userSearch))
    },
    searchResults () {
      if (isEmpty(this.deburredUserSearch)) {
        return this.filteredVacancyList
      }
      return filter(this.filteredVacancyList, (vacancy) =>
        every(this.deburredUserSearch, (word) => vacancy.searchKey.includes(word))
      )
    },
    sortedVacancyList () {
      if (this.sortMethod === 'alphabetical') {
        return sortBy(this.searchResults, [(vacancy) => vacancy.uppercaseTitle])
      }
      if (this.sortMethod === 'last') {
        return sortBy(this.searchResults, ['dateCreated']).reverse()
      }
      return this.searchResults
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_ARCHIVED_VACANCIES)
  }
}
</script>
