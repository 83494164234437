<template>
  <div class="d-flex pt-4 pb-4 mb-3">
    <div class="d-flex flex-column align-items-center mr-4 p-2 pb-0 ">
      <avatar
        v-if="!avatarUrl || !avatarUrl.url"
        :fullname="fullname"
        size="lg"
      ></avatar>
      <div
        v-if="avatarUrl && avatarUrl.url"
        class="d-flex align-items-center justify-content-around border border-medium-gray"
        style="width: 178px; height: 178px; overflow: hidden;"
      >
        <img
          v-if="avatarUrl && avatarUrl.url"
          :src="avatarUrl.urlLocal"
          class="img-thumbnail p-0 border-0 bg-transparent"
        />
      </div>
      <input
        accept="image/jpeg, image/png, image/gif"
        type="file"
        id="photo"
        ref="photoInput"
        name="photo"
        @change="updatePhoto()"
        size="60"
        class="d-none"
        :class="{ 'is-invalid': errors.has('photo') }"
      />
      <div
        v-if="isLoggedIn && !isSelectionCommittee"
        class="w-100 d-flex"
      >
        <b-btn @click="$refs.photoInput.click()" variant="primary" class="mt-1"
          >Update</b-btn
        >
        <b-btn
          v-if="avatarUrl && avatarUrl.url"
          @click="deletePhoto"
          variant="light-gray border-medium-gray"
          class="mt-1"
          >Verwijder</b-btn
        >
      </div>
    </div>
    <div class="d-flex flex-column w-100">
      <div
        v-if="!isSelectionCommittee && !$route.meta.noAuthorizationNeeded"
        class="ml-auto"
      >
        <b-dropdown
          variant="white"
          size="sm"
          text="Acties"
          right
          class="m-md-2"
        >
        <template #button-content>
          <div v-if="!loading" class="d-flex justify-content-between">
            <div class="mr-2">Acties</div>
            <div>
              <small><i class="fa fa-chevron-down"/></small>
            </div>
          </div>
          <div v-else class="d-flex justify-content-between">
            <i v-if="loading" class="fa fa-spinner fa-spin"></i>
          </div>
        </template>
          <b-dropdown-item
            v-for="action in actionsDropdown"
            v-show="action.display"
            :key="action.key"
            @click="$emit(action.key)"
            >{{ action.label }}</b-dropdown-item
          >
        </b-dropdown>
      </div>
      <div class="mb-1">
        <h1>{{fullname}}</h1>
        <div class="font-weight-light">{{ candidateSource }}</div>
        <div class="font-weight-light">
          <span>Bron: {{ publishChannel.title }}</span>
          <span
            ><i
              v-b-tooltip.hover
              title="Binnengekomen via de sollicitatie link of handmatig geüpload in het systeem"
              class="fa fa-info-circle text-darl-gray ml-2"
            ></i
          ></span>
        </div>
      </div>
      <ProspectRatingButtons
        v-if="!$route.meta.noAuthorizationNeeded"
        :is-selection-committee="isSelectionCommittee"
        :rating-buttons="ratingButtons"
        :recruiter-rating="recruiterRating"
        @changeRating="changeRating"
      />
    </div>
  </div>
</template>
<script>
// TODO this file is probably not being used
import Avatar from '../avatar'
import {
  EDIT_SELECTION_COMMITEE_INTERFACE
} from '../../constants/permissions'
import {
  PROSPRATING_DECLINED,
  PROSPRATING_RESERVED,
  PROSPRATING_SELECTED,
  PROSPRATING_UNRATED
} from '../../constants/prospect/prospect-recruiter-rating'
import {
  DELETE_APPLICANT_PHOTO,
  UPDATE_APPLICANT_PHOTO,
  CHANGE_PROSPECT_RATING
} from '../../store/keys-actions'
import { USER_AUTHENTICATED } from '../../store/keys-getters'
import modal from '../../services/modal'
import YesNoModal from '../confirm-yes-no-modal'
import ProspectRatingButtons from './prospect-rating-buttons'

export default {
  name: 'prospect-detail-header',
  components: { ProspectRatingButtons, Avatar },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    candidateSource: {
      type: String,
      default: ''
    },
    publishChannel: {
      type: Object,
      default: () => ({})
    },
    fullname: {
      type: String,
      required: true
    },
    recruiterRating: {
      type: String,
      default: PROSPRATING_UNRATED
    },
    actionsDropdown: {
      type: Array,
      default: () => []
    },
    avatarUrl: {
      type: Object,
      default: () => ({})
    },
    prospectId: {
      type: Number,
      default: null
    }
  },
  computed: {
    isSelectionCommittee () {
      return this.hasPermission(EDIT_SELECTION_COMMITEE_INTERFACE)
    },
    isLoggedIn () {
      return this.$store.getters[USER_AUTHENTICATED]
    },
    ratingButtons () {
      return {
        [PROSPRATING_SELECTED]: {
          variant: 'success',
          name: 'Door',
          icon: 'fa fa-check-square-o',
          classes: 'border border-medium-gray fixed-width',
          value: 'selected'
        },
        [PROSPRATING_RESERVED]: {
          variant: 'warning',
          name: 'Reserve',
          icon: 'fa fa-clock-o',
          classes: 'border border-medium-gray fixed-width',
          value: 'reserved'
        },
        [PROSPRATING_DECLINED]: {
          variant: 'error',
          name: 'Niet door',
          icon: 'fa fa-times',
          classes: 'border border-medium-gray fixed-width',
          value: 'declined'
        }
      }
    }
  },
  methods: {
    updatePhoto () {
      const photoInput = this.$refs.photoInput
      if (!photoInput.files || !photoInput.files[0]) {
        return
      }
      return this.$store.dispatch(UPDATE_APPLICANT_PHOTO, {
        image: photoInput.files[0],
        roundId: +this.$route.params.roundId
      })
    },
    deletePhoto () {
      modal(YesNoModal, {
        question: 'Weet u zeker dat deze foto wilt verwijderen?'
      }).then(confirmed => {
        if (!confirmed) {
          return
        }
        return this.$store.dispatch(DELETE_APPLICANT_PHOTO, {
          roundId: +this.$route.params.roundId
        })
      })
    },
    changeRating (value) {
      this.$store.dispatch(CHANGE_PROSPECT_RATING, {
        ratingType: value,
        prospectIds: [this.prospectId],
        individualProspect: true
      })
    }
  }
}
</script>
