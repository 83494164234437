<template>
  <page v-if="currentProspect">
    <prospect
      slot="main-content"
      :prospectMaxIndex="prospectMaxIndex"
      :currentIndex="$route.params.id"
      :currentProspect="currentProspect"
    />
    <prospect-aside :currentProspect="currentProspect" slot="side-content" />
  </page>
</template>

<script>
import Page from '../../page-types/wide-aside-page'
import ProspectAside from './prospect-aside.vue'
import Prospect from './prospect'
import { TOKEN_PROSPECT_DATA } from '../../../store/keys-getters'
import { LOAD_TOKEN_PROSPECT_DATA } from '../../../store/keys-actions'

export default {
  name: 'prospectDetail',
  components: {
    Page,
    ProspectAside,
    Prospect
  },
  computed: {
    prospectMaxIndex () {
      return this.prospects.length
    },
    currentProspect () {
      return this.prospects[this.$route.params.id]
    },
    prospects () {
      const prospects = this.$store.getters[TOKEN_PROSPECT_DATA]
      return prospects && prospects.list ? prospects.list : []
    }
  },
  created () {
    this.$store.dispatch(LOAD_TOKEN_PROSPECT_DATA)
  }
}
</script>
