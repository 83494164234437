<template>
  <b-modal
    centered
    :title="title"
    :visible="true"
    @ok="$emit('resolve', true)"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    :cancel-title="cancelButtonText"
    :ok-title="okButtonText"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div>{{ question }}</div>
  </b-modal>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Bevestig uw keuze'
    },
    cancelButtonText: {
      type: String,
      default: 'Annuleren'
    },
    okButtonText: {
      type: String,
      default: 'OK'
    },
    question: {
      type: String,
      required: true
    }
  }
}
</script>
