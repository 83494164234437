export const SWITCH_TENANT = 'SWITCH_TENANT'

// users
export const LOAD_USER_DATA = 'LOAD_USER_DATA'
export const LOAD_CURRENT_USER_DETAILS = 'LOAD_CURRENT_USER_DETAILS'
export const LOAD_CURRENT_TENANT_DETAILS = 'LOAD_CURRENT_TENANT_DETAILS'
export const UPDATE_CURRENT_USER_DETAILS = 'UPDATE_CURRENT_USER_DETAILS'
export const LOAD_USERS = 'LOAD_USERS'
export const CREATE_NEW_USER = 'CREATE_NEW_USER'
export const INIT_AUTH = 'INIT_AUTH'
export const HANDLE_AUTH_CALLBACK = 'HANDLE_AUTH_CALLBACK'
export const LOGOUT = 'LOGOUT'
export const CHECK_TOKEN = 'CHECK_TOKEN'

// vacancy
export const LOAD_VACANCY = 'LOAD_VACANCY'
export const CREATE_VACANCY = 'CREATE_VACANCY'
export const LOAD_VACANCY_LIST = 'LOAD_VACANCY_LIST'
export const LOAD_ARCHIVED_VACANCIES = 'LOAD_ARCHIVED_VACANCIES'
export const CREATE_VACANCY_NOTE = 'CREATE_VACANCY_NOTE'
export const CREATE_VACANCY_TODO = 'CREATE_VACANCY_TODO'
export const EDIT_VACANCY_NOTE = 'EDIT_VACANCY_NOTE'
export const EDIT_VACANCY_TODO = 'EDIT_VACANCY_TODO'
export const ARCHIVE_VACANCY = 'ARCHIVE_VACANCY'
export const LOAD_VACANCY_SUBPROCESSES = 'LOAD_VACANCY_SUBPROCESSES'
export const LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS =
  'LOAD_VACANCY_SUBPROCESS_NOTIFICATIONS'
export const LOAD_VACANCY_PUBLICATION_SUBPROCESS_INFO =
  'LOAD_VACANCY_PUBLICATION_SUBPROCESS_INFO'
export const LOAD_VACANCY_STATS = 'LOAD_VACANCY_STATS'
// vacancy - description
export const LOAD_VACANCY_DESCRIPTION_TEMPLATES =
  'LOAD_VACANCY_DESCRIPTION_TEMPLATES'
export const DELETE_VACANCY_DESCRIPTION_TEMPLATE =
  'DELETE_VACANCY_DESCRIPTION_TEMPLATE'
export const LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS =
  'LOAD_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS'
export const UPLOAD_VACANCY_ATTACHMENT = 'UPLOAD_VACANCY_ATTACHMENT'
export const SAVE_VACANCY_DESCRIPTION = 'SAVE_VACANCY_DESCRIPTION'
export const DELETE_VACANCY_ATTACHMENT = 'DELETE_VACANCY_ATTACHMENT'
export const DELETE_VACANCY_NOTIFICATION_ATTACHMENT = 'DELETE_VACANCY_NOTIFICATION_ATTACHMENT'
export const SAVE_VACANCY_TEMPLATE = 'SAVE_VACANCY_TEMPLATE'
// vacancy - team
export const LOAD_VACANCY_TEAM_MEMBERS = 'LOAD_VACANCY_TEAM_MEMBERS'
export const DELETE_VACANCY_TEAM_MEMBER = 'DELETE_VACANCY_TEAM_MEMBER'
export const ADD_VACANCY_TEAM_MEMBER = 'ADD_VACANCY_TEAM_MEMBER'
// vacancy - form
export const LOAD_VACANCY_FORM = 'LOAD_VACANCY_FORM'
export const SAVE_VACANCY_FORM = 'SAVE_VACANCY_FORM'
// vacancy - prospects
export const LOAD_TOKEN_PROSPECT_DATA = 'LOAD_TOKEN_PROSPECT_DATA'
export const LOAD_VACANCY_PROSPECTS = 'LOAD_VACANCY_PROSPECTS'
export const MOVE_PROSPECT_TO_VACANCY = 'MOVE_PROSPECT_TO_VACANCY'
export const CHANGE_PROSPECT_RATING = 'CHANGE_PROSPECT_RATING'
export const CHANGE_PROSPECT_STATUS = 'CHANGE_PROSPECT_STATUS'
export const SEND_PROSPECTS_TO_SELECTION_COMMITTEE =
  'SEND_PROSPECTS_TO_SELECTION_COMMITTEE'
export const START_NEW_SELECTION_ROUND = 'START_NEW_SELECTION_ROUND'
export const LOAD_SELECTION_ROUND_STATS = 'LOAD_SELECTION_ROUND_STATS'
export const LOAD_APPLICANT_TYPES = 'LOAD_APPLICANT_TYPES'
export const ADD_NEW_APPLICANT = 'ADD_NEW_APPLICANT'
export const EDIT_PROSPECT = 'EDIT_PROSPECT'
export const LOAD_CURRENT_PROSPECT = 'LOAD_CURRENT_PROSPECT'
export const DELETE_PROSPECT = 'DELETE_PROSPECT'
export const LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE =
  'LOAD_DEFAULT_SHARE_PER_EMAIL_TEMPLATE'
export const SEND_SHARE_PROSPECTS_EMAIL = 'SEND_SHARE_PROSPECTS_EMAIL'
export const CREATE_PROSPECT_NOTE = 'CREATE_PROSPECT_NOTE'
export const CREATE_PROSPECT_TODO = 'CREATE_PROSPECT_TODO'
export const LOAD_PROSPECT_NOTE = 'LOAD_PROSPECT_NOTE'
export const LOAD_PROSPECT_TODO = 'LOAD_PROSPECT_TODO'
export const EDIT_PROSPECT_NOTE = 'EDIT_PROSPECT_NOTE'
export const EDIT_PROSPECT_TODO = 'EDIT_PROSPECT_TODO'
export const COMPLETE_PROSPECT_TODO = 'COMPLETE_PROSPECT_TODO'
export const COMPLETE_VACANCY_TODO = 'COMPLETE_VACANCY_TODO'
export const LOAD_VACANCY_NOTE = 'LOAD_VACANCY_NOTE'
export const LOAD_VACANCY_TODO = 'LOAD_VACANCY_TODO'

export const DELETE_PROSPECT_NOTIFICATION_ATTACHMENT = 'DELETE_PROSPECT_NOTIFICATION_ATTACHMENT'
export const UPLOAD_PROSPECT_ATTACHMENT = 'UPLOAD_PROSPECT_ATTACHMENT'
export const DELETE_PROSPECT_ATTACHMENT = 'DELETE_PROSPECT_ATTACHMENT'
export const LOAD_PROSPECT_EMAIL = 'LOAD_PROSPECT_EMAIL'
export const UPDATE_APPLICANT_PHOTO = 'UPDATE_APPLICANT_PHOTO'
export const DELETE_APPLICANT_PHOTO = 'DELETE_APPLICANT_PHOTO'
// vacancy - competences
export const LOAD_VACANCY_COMPETENCES = 'LOAD_VACANCY_COMPETENCES'
export const SAVE_VACANCY_COMPETENCES = 'SAVE_VACANCY_COMPETENCES'
export const LOAD_VACANCY_COMPETENCES_OPTIONS =
  'LOAD_VACANCY_COMPETENCES_OPTIONS'
// vacancy - publication
export const PUBLISH_VACANCY = 'PUBLISH_VACANCY'
export const LOAD_PUBLICATION_CHANNELS = 'LOAD_PUBLICATION_CHANNELS'
export const LOAD_PUBLICATION_CHANNELS_JOB_STRATEGIES =
  'LOAD_PUBLICATION_CHANNELS_JOB_STRATEGIES'
export const LOAD_MIMIR_CHANNELS_DROPDOWNS = 'LOAD_MIMIR_CHANNELS_DROPDOWNS'

// selection committee
export const LOAD_SELECTION_COMMITTEE_PROSPECTS =
  'LOAD_SELECTION_COMMITTEE_PROSPECTS'
export const SAVE_SELECTION_COMMITEE_JUDGEMENTS =
  'SAVE_SELECTION_COMMITEE_JUDGEMENTS'
export const LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT =
  'LOAD_CURRENT_SELECTION_COMMITTEE_PROSPECT'

// gdpr
export const LOAD_GDPR_APPLICANTS = 'LOAD_GDPR_APPLICANTS'
export const ANONYMIZE_APPLICANTS = 'ANONYMIZE_APPLICANTS'
export const REQUEST_APPLICANT_CONSENT = 'REQUEST_APPLICANT_CONSENT'

// emails
export const LOAD_EMAIL_TEMPLATES = 'LOAD_EMAIL_TEMPLATES'
export const SAVE_EMAIL_TEMPLATE = 'SAVE_EMAIL_TEMPLATE'
export const LOAD_EMAIL_PLACEHOLDERS = 'LOAD_EMAIL_PLACEHOLDERS'
export const SEND_GENERAL_EMAIL = 'SEND_GENERAL_EMAIL'
export const SEND_KEEP_IN_RESERVE_EMAIL = 'SEND_KEEP_IN_RESERVE_EMAIL'
export const SEND_REJECT_EMAIL = 'SEND_REJECT_EMAIL'
export const DELETE_EMAIL_TEMPLATE = 'DELETE_EMAIL_TEMPLATE'

// candidate bank
export const LOAD_CANDIDATE_BANKS = 'LOAD_CANDIDATE_BANKS'

// merge files
export const CREATE_MERGE_JOB = 'CREATE_MERGE_JOB'
export const LOAD_MERGED_FILES = 'LOAD_MERGED_FILES'

// global search
export const LOAD_SEARCH_RESULTS = 'LOAD_SEARCH_RESULTS'
export const LOAD_EXTRA_SEARCH_RESULTS = 'LOAD_EXTRA_SEARCH_RESULTS'
export const CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
