<template>
  <div class="d-flex align-items-start">
    <div
      v-if="speechBubble"
      class="notification-icon left text-medium-gray"
      :class="mainContentBackground ? 'bg-light' : 'bg-background'"
    >
      <strong v-if="!isTodo"><i :class="notificationIcon"/></strong>
      <b-form-checkbox
        :disabled="viewerIsExternal || !canCreateToDos"
        plain
        @input="$emit('completeTodo', { isDone, id: notification.entityId })"
        v-if="isTodo"
        class="mr-0"
        v-model="isDone"
      />
    </div>
    <div
      class="w-100 mt-2 p-2 notification"
      :class="cssClasses"
      v-on="{
        click:
          !viewerIsExternal && canCreateNotes
            ? handleOpenNotification
            : () => false
      }"
    >
      <div class="d-flex justify-content-between">
        <div>
          <small>
            <strong v-if="isTask" class="text-black">Taak</strong>
            <strong v-if="isTodo" class="text-warning">{{ dueDate }}</strong>
            <strong v-if="!isTodo && !isTask" class="text-black">{{
              notification.selectorName
            }}</strong>
          </small>
        </div>
        <div v-if="date">
          <small class="font-italic text-black-50">{{ date }}</small>
        </div>
      </div>
      <div
        class="d-flex justify-content-between align-items-baseline flex-wrap"
      >
        <small>
          {{ notification.title }}
        </small>
        <small v-if="notification.hasAttachments"
          ><i class="fa fa-paperclip"></i
        ></small>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import includes from 'lodash/includes'
import {
  NOTIFTYPE_EMAIL,
  NOTIFTYPE_NOTE,
  NOTIFTYPE_TODO,
  NOTIFTYPE_TASK
} from '../../constants/prospect/prospect-notification-type'
import { TASKSTATUS_COMPLETED } from '../../constants/task-status'
import { VIEW_EXTERNALLY, CREATE_VACANCY_PROSPECT_NOTE, CREATE_VACANCY_PROSPECT_TODO } from '../../constants/permissions'

export default {
  props: {
    notification: {
      type: Object,
      default: () => ({})
    },
    speechBubble: {
      type: Boolean,
      default: true
    },
    mainContentBackground: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isDone: this.notification.isDone || (this.notification.lStatus === TASKSTATUS_COMPLETED && this.notification.lNotificationType === NOTIFTYPE_TASK)
    }
  },
  computed: {
    canCreateNotes () {
      return this.hasPermission(CREATE_VACANCY_PROSPECT_NOTE)
    },
    canCreateToDos () {
      return this.hasPermission(CREATE_VACANCY_PROSPECT_TODO)
    },
    date () {
      return this.notification.created ? moment(`${this.notification.created}`).format("D MMM 'YY") : ''
    },
    dueDate () {
      return moment(this.notification.dueDate).format('D MMM')
    },
    isClickable () {
      const clickableNotifications = [NOTIFTYPE_EMAIL, NOTIFTYPE_NOTE, NOTIFTYPE_TODO]
      return includes(clickableNotifications, this.notification.lNotificationType) && !this.viewerIsExternal && this.canCreateNotes
    },
    cssClasses () {
      return {
        'speech-bubble': this.speechBubble,
        'is-done': this.isDone,
        'is-clickable': this.isClickable,
        left: true
      }
    },
    notificationIcon () {
      const map = {
        [NOTIFTYPE_EMAIL]: 'fa fa-envelope-o',
        [NOTIFTYPE_NOTE]: 'fa fa-pencil-square-o',
        [NOTIFTYPE_TASK]: 'fa fa-exclamation-triangle text-new'
      }
      return map[this.notification.lNotificationType] || 'fa fa-circle small'
    },
    isTodo () {
      return this.notification.lNotificationType === NOTIFTYPE_TODO
    },
    isTask () {
      return this.notification.lNotificationType === NOTIFTYPE_TASK
    },
    viewerIsExternal () {
      return this.hasPermission(VIEW_EXTERNALLY)
    }
  },
  methods: {
    handleOpenNotification () {
      this.$emit('openNotification', {
        type: this.notification.lNotificationType,
        id: this.notification.entityId
      })
    }
  }
}
</script>
