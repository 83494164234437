<template>
  <div>
    <div class="row p-4 border-bottom border-medium-gray">
      <div class="w-100 d-flex justify-content-between">
        <b-link
          :to="{
            name: 'prospectJudgements',
            params: {
              vacancyId: vacancy.vacancyId,
              roundId: vacancy.latestRoundId
            }
          }"
        >
          <div>
            <i class="fa fa-angle-left" /> terug naar sollitantenoverzicht
          </div>
        </b-link>
        <div>
          <b-btn
            variant="white"
            class="mr-2 border border-medium-gray"
            size="sm"
            :disabled="!(prospectsOrder[currentProspect.prospectId] || {}).prev"
            :to="{
              name: 'prospectJudgementDetail',
              params: {
                vacancyId: vacancy.vacancyId,
                index: (prospectsOrder[currentProspect.prospectId] || {}).prev,
                roundId: vacancy.latestRoundId
              }
            }"
          >
            <i class="fa fa-chevron-left" />
          </b-btn>
          <b-btn
            variant="white"
            class="border border-medium-gray"
            size="sm"
            :disabled="!(prospectsOrder[currentProspect.prospectId] || {}).next"
            :to="{
              name: 'prospectJudgementDetail',
              params: {
                vacancyId: vacancy.vacancyId,
                index: (prospectsOrder[currentProspect.prospectId] || {}).next,
                roundId: vacancy.latestRoundId
              }
            }"
          >
            <i class="fa fa-chevron-right" />
          </b-btn>
        </div>
      </div>
    </div>
    <prospect-detail-header
      :candidate-source="candidateSource"
      :publishChannel="currentProspect.publishChannel"
      :fullname="fullname"
    />
    <prospect-detail-contact-info
      :phoneNumber="currentProspect.phoneNumber"
      :emailAddress="currentProspect.emailAddress"
    />
    <prospect-detail-motivation :motivation="currentProspect.motivation" />
    <prospect-detail-documents :attachments="currentProspect.attachments" />
  </div>
</template>

<script>
import {
  CURRENT_SELECTION_COMMITTEE_PROSPECT,
  SELECTION_COMMITTEE_PROSPECTS_ORDER,
  VACANCY
} from '../../store/keys-getters'
import ProspectDetailHeader from '../prospect-details-components/prospect-detail-header'
import ProspectDetailContactInfo from '../prospect-details-components/prospect-detail-contact-info'
import ProspectDetailMotivation from '../prospect-details-components/prospect-detail-motivation'
import ProspectDetailDocuments from '../prospect-details-components/prospect-detail-documents'

export default {
  components: {
    ProspectDetailDocuments,
    ProspectDetailMotivation,
    ProspectDetailContactInfo,
    ProspectDetailHeader
  },
  computed: {
    currentProspect () {
      return this.$store.getters[CURRENT_SELECTION_COMMITTEE_PROSPECT]
    },
    prospectsOrder () {
      return this.$store.getters[SELECTION_COMMITTEE_PROSPECTS_ORDER]
    },
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    candidateSource () {
      const map = {
        CHANNEL_CAT_HIDDEN: 'ONE sollicitant',
        CHANNEL_CAT_EXTERNAL: 'Externe sollicitant',
        CHANNEL_CAT_REGIONAL: 'Regionale sollicitant',
        CHANNEL_CAT_INTERNAL: 'Interne sollicitant'
      }
      return map[(this.currentProspect.publishChannel || {}).channelCat] || ''
    },
    fullname () {
      return `${this.currentProspect.firstname} ${this.currentProspect.middleName ? this.currentProspect.middleName : ''} ${this.currentProspect.lastname}`
    }
  }
}
</script>
