<template>
  <page>
    <vacancy-nav-header slot="header" />
    <vacancy-prospects slot="main-content" />
  </page>
</template>

<script>
import Page from '../../page-types/no-aside-page'
import VacancyNavHeader from '../vacancy-header/vacancy-header.vue'
import VacancyProspects from './prospects.vue'
import { SET_VACANCY_PROSPECTS } from '../../../store/keys-mutations'

export default {
  name: 'VacancyProspectsPage',
  components: {
    Page,
    VacancyNavHeader,
    VacancyProspects
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(SET_VACANCY_PROSPECTS, [])
    next()
  }
}
</script>
