import { LOAD_VACANCY_FORM, SAVE_VACANCY_FORM } from '../../keys-actions'
import { VACANCY, VACANCY_FORM } from '../../keys-getters'
import apiRequest from '../../../services/api-request'
import { SET_VACANCY_FORM } from '../../keys-mutations'

export default {
  state: {
    form: {
      config: [],
      lang: ''
    }
  },
  mutations: {
    [SET_VACANCY_FORM]: (state, { lang = '', config = [] } = {}) =>
      (state.form = { lang, config })
  },
  getters: {
    [VACANCY_FORM]: state => state.form
  },
  actions: {
    [LOAD_VACANCY_FORM]: ({ commit, getters }) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'GET',
        url: `/api/erecruiter-web-api/form-config/vacancy/${vacancyId}`
      }).then(data => {
        commit(SET_VACANCY_FORM, data)
      })
    },
    [SAVE_VACANCY_FORM]: (
      { state, dispatch, getters },
      { lang = state.lang, config } = {}
    ) => {
      const { vacancyId } = getters[VACANCY]

      return apiRequest({
        method: 'POST',
        url: '/api/erecruiter-web-api/form-config/vacancy',
        data: {
          rflId: vacancyId,
          config,
          lang
        }
      }).then(() => dispatch(LOAD_VACANCY_FORM))
    }
  }
}
