<template>
  <page>
    <reports-header slot="header" />
    <reports-list slot="main-content" />
    <Aside slot="side-content" />
  </page>
</template>

<script>
import Page from '../page-types/default-page'
import ReportsHeader from './reports-header.vue'
import Aside from '../aside/aside-content.vue'
import ReportsList from './reports-list'

export default {
  name: 'OverviewVacanciesPage',
  components: {
    Page,
    ReportsHeader,
    Aside,
    ReportsList
  }
}
</script>
