<template>
  <div>
    <div class="row bg-white border-bottom border-light">
      <div
        class="col-12 pl-lg-5 pr-lg-5"
        style="padding-left: 36px; border-bottom: 1px solid #e5e5e5;"
      >
        <slot name="header" />
      </div>
    </div>

    <div class="row" style="max-width: 1300px;">
      <div class="col-12 col-lg-8 pb-5 pt-4 pl-lg-5 pr-lg-5 bg-light">
        <slot name="main-content" />
      </div>
      <div class="col-12 col-lg-4 pl-lg-5 pb-5 pt-5">
        <slot name="side-content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    $validator: '$validator'
  }
}
</script>
