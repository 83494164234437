<template>
  <div>
    <div class="d-flex flex-column flex-sm-row justify-content-between pb-4">
      <h4>Sollicitanten</h4>
      <b-btn
        variant="primary"
        :disabled="!isJudgementEditor"
        @click="sendJudgements()"
      >
        <i v-if="loading" class="fa fa-spinner fa-spin"></i>Beoordelingen
        indienen
      </b-btn>
    </div>
    <div class="bg-white">
      <div v-if="privateProspects.length > 0">
        <div
          class="prospect-judgement-list border border-light"
          v-for="(prospect, index) in prospects"
          :key="prospect.prospectId"
        >
          <prospect-judgement-list-item
            :latestRoundId="vacancy.latestRoundId"
            :vacancyId="vacancy.vacancyId"
            :index="index"
            v-model="privateProspects[index]"
          ></prospect-judgement-list-item>
        </div>
      </div>
    </div>
    <div v-if="privateProspects.length === 0">Geen sollicitanten</div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import ProspectJudgementListItem from './prospect-judgement-list-item'
import { LOADING, VACANCY, SELECTION_COMMITTEE_PROSPECTS } from '../../store/keys-getters'
import { SAVE_SELECTION_COMMITEE_JUDGEMENTS, LOAD_SELECTION_COMMITTEE_PROSPECTS } from '../../store/keys-actions'
import { EDIT_SELECTION_COMMITEE_INTERFACE } from '../../constants/permissions'

export default {
  name: 'prospectJudgementList',
  components: {
    ProspectJudgementListItem
  },
  data () {
    return {
      privateProspects: []
    }
  },
  watch: {
    prospects () {
      this.privateProspects = cloneDeep(this.prospects)
    }
  },
  computed: {
    prospects () {
      return this.$store.getters[SELECTION_COMMITTEE_PROSPECTS]
    },
    loading () {
      return this.$store.getters[LOADING]
    },
    vacancy () {
      return this.$store.getters[VACANCY]
    },
    isJudgementEditor () {
      return this.hasPermission(EDIT_SELECTION_COMMITEE_INTERFACE)
    }
  },
  methods: {
    sendJudgements () {
      const prospects = this.privateProspects.map((prospect) => {
        return {
          prospectId: prospect.prospectId,
          prospectJudgementForCurrentUser: prospect.prospectJudgementForCurrentUser
        }
      })
      this.$store.dispatch(SAVE_SELECTION_COMMITEE_JUDGEMENTS, prospects)
    }
  },
  mounted () {
    this.$store.dispatch(LOAD_SELECTION_COMMITTEE_PROSPECTS)
  }
}
</script>
<style scoped>
.prospect-judgement-list:nth-child(even) {
  background-color: #fbfbfb;
}
</style>
