var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-2"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row justify-content-between"},[_c('div',{staticClass:"d-flex mb-2"},[_c('avatar',{attrs:{"fullname":_vm.fullname}}),_c('div',{staticClass:"pl-3"},[_c('b-link',{attrs:{"to":{
            name: 'prospectJudgementDetail',
            params: {
              vacancyId: _vm.vacancyId,
              index: _vm.privateProspect.prospectId,
              roundId: _vm.latestRoundId
            }
          }}},[_c('h4',[_vm._v(_vm._s(_vm.fullname))])]),(_vm.privateProspect.phoneNumber)?_c('div',{staticStyle:{"color":"#8498af"}},[_c('small',[_c('strong',[_vm._v(_vm._s(_vm.privateProspect.phoneNumber))])])]):_vm._e()],1)],1),_c('judgement-score-box',{attrs:{"private-prospect":_vm.privateProspect}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }