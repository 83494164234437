<template>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <div
      class="download-box d-flex flex-column align-items-center justify-content-center text-center shadow"
    >
      <template v-if="isLoading">
        <i class="fa fa-spinner fa-spin" />
      </template>
      <template v-else-if="isError">
        <h3>Er is een fout opgetreden</h3>
        <p>
          <strong
            >Er is een fout opgetreden tijdens het laden van het
            bestand.</strong
          >
          Probeer het over een paar minuten opnieuw en neem contact met ons op
          als het probleem zich blijft voordoen.
        </p>
      </template>
      <template v-else>
        <div class="download-box__container">
          <p>
            <i class="d-block icon icon-file-pdf mb-1"></i>
            <span>{{ title }}</span>
          </p>
          <a :href="pdfLink">
            <button class="btn btn-primary download-btn text-capitalize">
              download
            </button>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { CREATE_MERGE_JOB } from '../../store/keys-actions'
import {
  CURRENT_MERGED_JOB,
  MERGED_JOB_LOADING_STATUS,
  MERGED_JOB_ERROR_STATUS
} from '../../store/keys-getters'
export default {
  name: 'MergeJobsDetail',
  computed: {
    pospectIds () {
      return this.$route.params.prospectIds.split('_')
    },
    title () {
      return this.$store.getters[CURRENT_MERGED_JOB].title
    },
    isLoading () {
      return this.$store.getters[MERGED_JOB_LOADING_STATUS]
    },
    isError () {
      return this.$store.getters[MERGED_JOB_ERROR_STATUS]
    },
    pdfLink () {
      return this.$store.getters[CURRENT_MERGED_JOB].urlLocal
    }
  },
  async mounted () {
    await this.$store.dispatch(CREATE_MERGE_JOB, {
      vacancyId: this.$route.params.vacancyViewedId,
      prospectIds: this.pospectIds
    })
  }
}
</script>

<style lang="scss" scoped>
.download-box {
  max-width: 600px;
  background-color: white;
  color: #666666;
  padding: 25px 60px;
  .download-box__container {
    margin: 20px 0;
    .icon {
      font-size: 80px;
      color: #666666;
      margin: 0;
    }
    .icon-file-pdf:before {
      // content: "\e803";
      content: "\1f4c2";
    }
    .download-btn {
      border-radius: 3px !important;
    }
  }
}
</style>
