<template>
  <b-modal
    centered
    title="Nieuwe vacature"
    :visible="true"
    :ok-disabled="!vacancyName || errors.has('new-vacancy-name')"
    @ok="$emit('resolve', vacancyName)"
    @cancel="$emit('resolve', false)"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <input
      class="form-control"
      :class="{ 'is-invalid': errors.has('new-vacancy-name') }"
      v-validate="{ required: true }"
      data-vv-as="Veld"
      v-model="vacancyName"
      type="text"
      name="new-vacancy-name"
      id="new-vacancy-name"
      placeholder="Vacature naam"
    />
    <div class="invalid-feedback">
      {{ errors.first("new-vacancy-name") }}
    </div>
  </b-modal>
</template>

<script>
export default {
  data () {
    return {
      vacancyName: ''
    }
  }
}
</script>
