<template>
  <b-btn
    variant="outline-primary"
    class="text-center text-md-left px-4 py-3"
    @click="previewVacancy"
  >
    <span :style="styles[size]"><i class="fa fa-eye" /> Voorbeeld</span>
  </b-btn>
</template>

<script>
import PreviewVacancyModal from './preview-vacancy-modal'
import modal from '../../services/modal'

export default {
  name: 'PreviewButton',
  props: {
    privateDetails: {
      type: Object,
      required: true
    },
    dropdownOptions: {
      type: Object,
      required: true
    },
    contactPerson: {
      type: Object,
      default: () => null
    },
    size: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['md', 'lg'].indexOf(value) !== -1
      }
    }
  },
  computed: {
    vacancyDetails () {
      let {
        title,
        description,
        hoursPerWeek,
        hoursPerWeekMax,
        hoursPerWeekMin,
        hoursPerWeekType,
        training,
        salaryMax,
        salaryMin
      } = this.privateDetails
      const educationLevel = this.dropdownOptions.educationLevel.reduce(
        (acc, el) => {
          acc[el.lConst] = el.value
          return acc
        },
        {}
      )
      training = training && training.map(item => educationLevel[item])

      return {
        title,
        description,
        hoursPerWeek,
        hoursPerWeekMax,
        hoursPerWeekMin,
        hoursPerWeekType,
        training,
        salaryMax,
        salaryMin
      }
    },
    styles () {
      return {
        md: {},
        lg: {
          fontSize: '1.25rem',
          fontWeight: 'bold'
        }
      }
    }
  },
  methods: {
    previewVacancy () {
      modal(PreviewVacancyModal, {
        vacancyDetails: this.vacancyDetails,
        contactPerson: this.contactPerson
      })
    }
  }
}
</script>
