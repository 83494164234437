export const ADD_MODAL = 'ADD_MODAL'
export const REMOVE_MODAL = 'REMOVE_MODAL'
export const INCREMENT_HTTP_REQUESTS = 'INCREMENT_HTTP_REQUESTS'

export const SET_USERNAME = 'SET_USERNAME'
export const SET_JWT = 'SET_JWT'
export const SET_ROLES = 'SET_ROLES'
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS'
export const SET_USER = 'SET_USER'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER'
export const SET_USERS = 'SET_USERS'
export const SET_USER_PHOTO = 'SET_USER_PHOTO'
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR'

export const SET_VACANCY = 'SET_VACANCY'
export const SET_VACANCY_ID = 'SET_VACANCY_ID'
export const SET_VACANCY_ROUNDS = 'SET_VACANCY_ROUNDS'
export const SET_VACANCY_TEAM = 'SET_VACANCY_TEAM'
export const SET_VACANCY_DESCRIPTION_TEMPLATES =
  'SET_VACANCY_DESCRIPTION_TEMPLATES'
export const SET_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS =
  'SET_VACANCY_DESCRIPTION_DROPDOWN_OPTIONS'
export const SET_OPEN_VACANCIES = 'SET_OPEN_VACANCIES'
export const SET_ARCHIVED_VACANCIES = 'SET_ARCHIVED_VACANCIES'
export const SET_SELECTION_COMMITTEE_PROSPECTS =
  'SET_SELECTION_COMMITTEE_PROSPECTS'
export const SET_CURRENT_SELECTION_COMMITTEE_PROSPECT =
  'SET_CURRENT_SELECTION_COMMITTEE_PROSPECT'
export const SET_GDPR_APPLICANTS = 'SET_GDPR_APPLICANTS'
export const SET_TEAM_PERMISSIONS = 'SET_TEAM_PERMISSIONS'
export const SET_VACANCY_COMPETENCES = 'SET_VACANCY_COMPETENCES'
export const SET_VACANCY_COMPETENCES_OPTIONS = 'SET_VACANCY_COMPETENCES_OPTIONS'
export const SET_PUBLICATION_CHANNELS = 'SET_PUBLICATION_CHANNELS'
export const SET_PUBLICATION_CHANNELS_JOB_STRATEGIES =
  'SET_PUBLICATION_CHANNELS_JOB_STRATEGIES'
export const SET_MIMIR_CHANNELS_DROPDOWNS = 'SET_MIMIR_CHANNELS_DROPDOWNS'
export const SET_VACANCY_PROSPECTS = 'SET_VACANCY_PROSPECTS'
export const SET_SELECTION_ROUND_STATS = 'SET_SELECTION_ROUND_STATS'
export const SET_CURRENT_PROSPECT = 'SET_CURRENT_PROSPECT'
export const SET_CURRENT_NOTIFICATION = 'SET_CURRENT_NOTIFICATION'
export const SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION =
  'SET_RATING_TYPE_FOR_PROSPECT_NAVIGATION'
export const SET_VACANCY_SUBPROCESS_NOTIFICATIONS =
  'SET_VACANCY_SUBPROCESS_NOTIFICATIONS'
export const SET_VACANCY_PUBLICATION_SUBPROCESS_INFO =
  'SET_VACANCY_PUBLICATION_SUBPROCESS_INFO'
export const SET_CURRENT_TAB_PROSPECTS_OVERVIEW =
  'SET_CURRENT_TAB_PROSPECTS_OVERVIEW'
export const SET_VACANCY_STATS = 'SET_VACANCY_STATS'
export const SET_VACANCY_CURRENT_NOTIFICATION =
  'SET_VACANCY_CURRENT_NOTIFICATION'

export const SET_CURRENT_TENANT = 'SET_CURRENT_TENANT'
export const SET_AVAILABLE_TENANTS = 'SET_AVAILABLE_TENANTS'
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES'
export const SET_EMAIL_PLACEHOLDERS = 'SET_EMAIL_PLACEHOLDERS'
export const SET_APPLICANT_TYPES = 'SET_APPLICANT_TYPES'
export const SET_VACANCY_FORM = 'SET_VACANCY_FORM'
export const SET_CANDIDATE_BANKS = 'SET_CANDIDATE_BANKS'
export const SET_DEFAULT_SHARE_PER_EMAIL_TEMPLATE =
  'SET_DEFAULT_SHARE_PER_EMAIL_TEMPLATE'

export const SET_TOKEN_PROSPECT_DATA = 'SET_TOKEN_PROSPECT_DATA'
export const SET_TOKEN_PROSPECT_LOADING_STATE =
  'SET_TOKEN_PROSPECT_LOADING_STATE'

export const SET_CURRENT_MERGED_JOB = 'SET_CURRENT_MERGED_JOB'
export const SET_MERGED_JOB_LOADING_STATE = 'SET_MERGED_JOB_LOADING_STATE'
export const SET_MERGED_JOB_ERROR_STATE = 'SET_MERGED_JOB_ERROR_STATE'

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_EXTRA_SEARCH_RESULTS = 'SET_EXTRA_SEARCH_RESULTS'
export const SET_SEARCH_LOADING = 'SET_SEARCH_LOADING'
// export const SET_SEARCH_INPUT = 'SET_SEARCH_INPUT';
export const SET_EXTENDED_SEARCH_STATE = 'SET_EXTENDED_SEARCH_STATE'
export const RESET_EXTENDED_SEARCH_STATE = 'RESET_EXTENDED_SEARCH_STATE'
