<template>
  <div>
    <p class="forbidden-message">U heeft geen toegang tot deze pagina.</p>
    <hr />
    <b-btn @click="logout">Log out</b-btn>
  </div>
</template>

<script>
import { LOGOUT } from '../../store/keys-actions'

export default {
  name: 'NoPermission',
  data () {
    return {
      syncing: false,
      syncMessage: ''
    }
  },
  methods: {
    logout () {
      this.$store.dispatch(LOGOUT)
    }
  }
}
</script>

<style scoped>
.forbidden-message {
  margin-top: 24px;
}
</style>
