<template>
  <b-card class="row p-5" no-body>
    <h2 class="pb-3">Werkervaring</h2>
    <div
      v-for="(job, index) in orderedWorkExperience"
      :key="job.title"
      class="pb-3 pt-3"
      :class="
        orderedWorkExperience.length > 1 &&
        index !== orderedWorkExperience.length - 1
          ? 'border-bottom border-medium-gray'
          : ''
      "
    >
      <div>{{ job.title }}</div>
      <div>
        <strong>{{ job.employer }}</strong>
      </div>
      <div>
        <small class="font-weight-light">
          <span v-if="job.startDate"
            >{{ job.startDate | formatDate("MMM YYYY") }} -
          </span>
          <span v-if="job.endDate">{{
            job.endDate | formatDate("MMM YYYY")
          }}</span>
        </small>
      </div>
      <div>
        <small>{{ job.description }}</small>
      </div>
    </div>
  </b-card>
</template>

<script>
import orderBy from 'lodash/orderBy'
export default {
  props: {
    workExperience: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    orderedWorkExperience () {
      return orderBy(this.workExperience, ['endDate', 'startDate'], ['desc', 'desc'])
    }
  }
}
</script>
