const emailTemplateParser = (function () {
  'use strict'

  function replaceHtml (str) {
    return `<p>${str
      .replace(/&nbsp;|\n/g, '')
      .replace(/<br>|<br \/>/gi, '</p><p>')}</p>`
      .replace(/<p><p>/g, '<p>')
      .replace(/<\/p><\/p>/g, '</p>')
      .replace(/<p><\/p>/g, '')
      .replace(/^<p>$/g, '')
  }

  function parseContent ({ template, shouldParse = true } = {}) {
    if (!shouldParse) return template
    if (template.message) {
      const parsedMessage = replaceHtml(template.message)
      template = { ...template, message: parsedMessage }
    }
    if (template.body) {
      const parsedBody = replaceHtml(template.body)
      template = { ...template, body: parsedBody }
    }
    return template
  }

  return { parseContent }
})()

export default emailTemplateParser
