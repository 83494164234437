<template>
  <div class="p-2 d-flex flex-column flex-sm-row align-items-start">
    <div class="d-flex justify-content-center align-items-center">
      <avatar size="md" :fullname="user.fullName" />
    </div>
    <div class="pr-2 pl-4 d-flex flex-column">
      <h4 class="align-self-center align-self-sm-start">
        <strong>{{ user.fullName }}</strong>
      </h4>
      <div
        class="d-flex flex-column flex-sm-row justify-content-center justify-content-sm-start"
      >
        <div
          class="text-center"
          v-for="(role, index) in user.roles"
          :key="index"
        >
          <span class="d-none d-sm-inline-block" v-if="index !== 0"> / </span
          >{{ role.id | formatRole }}
        </div>
      </div>
      <div class="d-flex justify-content-center justify-content-sm-start">
        {{ user.title }}
      </div>
    </div>
  </div>
</template>
<script>
import Avatar from '../avatar'
export default {
  components: {
    Avatar
  },
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  filters: {
    formatRole (role) {
      const map = {
        1: 'Recruiter',
        2: 'Manager',
        7: 'Selectie commissie'
      }
      return map[role]
    }
  }
}
</script>
