<template>
  <div>
    <prospect-judgements
      v-if="prospectJudgements"
      :prospect-judgements="prospectJudgements.prospectJudgements"
      class="mb-3"
    ></prospect-judgements>
    <notification-list
      :list="currentProspect.prospectNotifications"
    ></notification-list>
  </div>
</template>
<script>
import { CURRENT_PROSPECT } from '../../../store/keys-getters'
import NotificationList from '../../notification-list/list'
import ProspectJudgements from '../../prospect-details-components/prospect-judgements'

export default {
  components: {
    NotificationList,
    ProspectJudgements
  },
  computed: {
    currentProspect () {
      return this.$store.getters[CURRENT_PROSPECT]
    },
    prospectJudgements () {
      return (
        this.currentProspect && this.currentProspect.prospectJudgements && this.currentProspect.prospectJudgements[0]
      )
    }
  }
}
</script>
