<template>
  <b-modal
    centered
    title="Nieuwe gebruiker"
    :visible="true"
    size="md"
    @ok="submit"
    @cancel="$emit('resolve')"
    @hidden="$emit('remove')"
    cancel-variant="medium-light"
    cancel-title="Annuleren"
    header-border-variant="light"
    footer-border-variant="light"
  >
    <div
      class="p-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between "
    >
      <label for="firstname">Voornaam *</label>
      <div class="w-75">
        <b-form-input
          v-validate="'required'"
          :class="{ 'is-invalid': errors.has('firstname') }"
          id="firstname"
          name="firstname"
          data-vv-as="Voornaam"
          v-model="user.firstname"
        />
        <div class="invalid-feedback">
          {{ errors.first('firstname') }}
        </div>
      </div>
    </div>

    <div
      class="p-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between "
    >
      <label for="lastname">Achternaam * </label>
      <div class="w-75">
        <b-form-input
          v-validate="'required'"
          :class="{ 'is-invalid': errors.has('lastname') }"
          id="lastname"
          name="lastname"
          data-vv-as="Achternaam"
          v-model="user.lastname"
        />
        <div class="invalid-feedback">
          {{ errors.first('lastname') }}
        </div>
      </div>
    </div>

    <div
      class="p-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between "
    >
      <label for="title">Functie * </label>
      <div class="w-75">
        <b-form-input
          v-validate="'required'"
          :class="{ 'is-invalid': errors.has('title') }"
          id="title"
          name="title"
          data-vv-as="Functie"
          v-model="user.title"
        />
        <div class="invalid-feedback">
          {{ errors.first('title') }}
        </div>
      </div>
    </div>

    <div
      class="p-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between "
    >
      <label for="emailAddress">Email * </label>
      <div class="w-75">
        <b-form-input
          v-validate="'required|email'"
          :class="{ 'is-invalid': errors.has('email') }"
          id="email"
          name="email"
          data-vv-as="Email"
          v-model="user.emailAddress"
        />
        <div class="invalid-feedback">
          {{ errors.first('email') }}
        </div>
      </div>
    </div>
    <div
      class="p-2 d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-between "
    >
      <label for="rolename">Rol * </label>
      <div class="w-75">
        <b-form-select
          id="rolename"
          name="rolename"
          v-model="user.rolename"
          :options="roles"
          class="mb-3"
        />
      </div>
    </div>
  </b-modal>
</template>

<script>
import merge from 'lodash/merge'
import { CURRENT_TENANT } from '../../store/keys-getters'
import { ALT_EMAIL_ORIGINAL } from '../../constants/email/email-sender'
import { MANAGER, RECRUITER, SELECTION_COMMITTEE } from '../../constants/roles'
import { SELCOMMAILNOTIFTYPE_DIRECT } from '../../constants/notifications'

export default {
  name: 'CreateUserModal',
  data () {
    return {
      user: {
        emailAddress: '',
        firstname: '',
        lastname: '',
        title: '',
        rolename: MANAGER
      }
    }
  },
  computed: {
    roles () {
      return [
        { value: MANAGER, text: 'Manager' },
        { value: RECRUITER, text: 'Recruiter' },
        { value: SELECTION_COMMITTEE, text: 'Selectie commissie' }
      ]
    },
    currentTenant () {
      return this.$store.getters[CURRENT_TENANT]
    },
    defaultValues () {
      return {
        address: this.currentTenant.address,
        postcode: this.currentTenant.postCode,
        city: this.currentTenant.location,
        telephone: this.currentTenant.telephone,
        selComEmailNotiftype: SELCOMMAILNOTIFTYPE_DIRECT,
        altFromEmailType: ALT_EMAIL_ORIGINAL
      }
    },
    newUser () {
      return merge(this.user, this.defaultValues)
    }
  },
  methods: {
    submit (evt) {
      evt.preventDefault()
      this.$validator.validate().then(isValid => {
        if (!isValid) {
          return
        }
        this.$emit('resolve', this.newUser)
        this.$emit('remove')
      })
    }
  }
}
</script>
