<template>
  <div class="d-flex p-2">
    <div class="pr-2">
      <avatar size="sm" :fullname="item.fullName"></avatar>
    </div>
    <div class="d-flex flex-column">
      <div class="d-flex align-items-baseline">
        <div class="text-dark pr-2">
          <small
            ><strong>{{ item.fullName }}</strong></small
          >
        </div>
      </div>
      <div>
        <small>{{ item.title }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from '../../avatar'

export default {
  components: {
    Avatar
  },
  props: {
    item: {
      type: Object
    }
  }
}
</script>
