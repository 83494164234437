<template>
  <div>
    <modal v-for="modal in modals" :key="modal.id" :modal="modal" />
  </div>
</template>

<script>
import Vue from 'vue'

const modal = Vue.extend({
  functional: true,
  render: (h, context) => {
    const { props, component, resolve, remove } = context.props.modal
    return h(component, { props, on: { resolve: resolve, remove: remove } })
  }
})

export default {
  name: 'modals',
  components: { modal },
  props: { modals: Array }
}
</script>
